import Moment from "moment";

export function format(formatType, value) {
  if (typeof value !== "undefined") {
    switch (formatType) {
      case "date":
        //before works
        // if (Moment(value).isAfter(Moment("01-01-0001")))
        //   return Moment(value).format("DD-MM-YYYY");
        // return "-";
        // new formater which handle 19's date
        if (Moment(value).isSame("0001-01-01T00:00:00")) {
          return "-";
        }
        return Moment(value).format("DD-MM-YYYY");
      case "dateTime":
        if (Moment(value).isAfter(Moment("01-01-0001")))
          return Moment(value).format("DD-MM-YYYY HH:mm");
        return "-";
      case "time":
        if (Moment(value).isAfter(Moment("01-01-0001")))
          return Moment(value).format("LT");
        return "-";
      case "number":
        return typeof value === "number"
          ? localStorage.getItem("lang") != "en"
            ? eurozone.format(value)
            : uszone.format(value)
          : typeof value !== "undefined"
          ? value
          : 0.0;
      default:
        return value;
    }
  } else return "-";
}

const eurozone = Intl.NumberFormat("de-DE", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});
const uszone = Intl.NumberFormat("en-US", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});
