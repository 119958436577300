import React, { useEffect, useRef, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
	FormControl,
	Checkbox,
	FormControlLabel,
	Typography,
	TextField,
	Grid,
	Card,
	CardContent,
	CardHeader,
	Box,
	ButtonGroup,
	Button,
} from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import ButtonWithLoading from "../../components/ButtonWithLoading";
import { useSnackbar } from "notistack";
import { getTranslation } from "../../heplers/translationHelper";
import { HtmlEditor } from "../../components/HtmlEditor";
import { buttonStyles } from "../tanents/components/recoveryprocedure/components/recoveryComponents/RecoveryPersons";
import { Get, Post } from "../../actions";
import {
	Get_AutoRecoveryProcedure_URL,
	Get_RecoveryProcedureDaysInterval_URL,
	Get_ReminderTemplate_URL,
	Get_SystemSetting_URL,
	Post_EnableAutoRecoveryProcedure_URL,
	Post_UpdateReminderTemplate_URL,
	Post_UpsertRecoveryProcedureDaysInterval_URL,
} from "../../constants/apiUrls";
import TextEditor from "./component/TextEditorCard";
import TestSmsDialoge from "./component/TestingDialoge";
import { FormLoader } from "../../components/FormLoader";

const RecoveryProcedureSetting = () => {
	const { enqueueSnackbar } = useSnackbar();
	const editorRef = useRef(null);
	const [reminderKey, setReminderKey] = useState("RecoveryProcedure");
	const [recoveryReminderCheck, setRecoveryReminderCheck] = useState(false);
	const [recoveryProcedureDaysInterval, setRecoveryProcedureDaysInterval] =
		useState(0);
	const [recoveryProcedureCost, setRecoveryProcedureCost] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [reminderTemplate, setReminderTemplate] = useState();
	const [enableReloading, setEnableReloading] = useState(false);
	const [selectedLanguage, setSelectedLanguage] = useState("english");
	const [testSmsDialoge, setSmsDialoge] = useState(false);
	const [editorValue, setEditorValue] = useState("");
	const [templateValue, setTemplateValue] = useState("");
	const [loading, setLoading] = useState(false);
	const [reminderTemplateLoading, setReminderTempateLoading] = useState(false);
	const [check, setCheck] = useState("");
	const [blurController, setBlurController] = useState(false);
	const getTemplateBasedOnLanguage = (template, language) => {
		switch (language) {
			case "english":
				setCheck(template?.template);
				return template?.template;
			case "french":
				setCheck(template?.templateFrench);
				return template?.templateFrench;
			case "german":
				setCheck(template?.templateGerman);
				return template?.templateGerman;
			default:
				return "";
		}
	};

	const handleLanguage = (language) => {
		setSelectedLanguage(language);
	};

	const openTestSmsDialoge = () => {
		setSmsDialoge(true);
	};

	const classes = buttonStyles();

	const AppendTemplate = (template, text, setFieldValue) => {
		editorRef.current.insertContent(text);
	};

	useEffect(() => {
		Get(
			{},
			Get_AutoRecoveryProcedure_URL,
			null,
			(resp) => {
				setRecoveryReminderCheck(resp?.data.enableAutoRecoveryProcedure);
			},
			(error) => {
				enqueueSnackbar(
					error?.data
						? getTranslation(error?.data, error?.data, error?.data)
						: getTranslation(
								"Something went wrong. Please contact with admin.",
								"Something went wrong. Please contact with admin.",
								"Something went wrong. Please contact with admin."
						  ),
					{
						variant: "error",
					}
				);
			}
		);
		getDaysInterval();
	}, []);

	useEffect(() => {
		Get(
			{},
			Get_SystemSetting_URL,
			null,
			(resp) => {
				setRecoveryProcedureDaysInterval(resp?.data);
			},
			(err) => {
				enqueueSnackbar(
					err.data
						? getTranslation(err.data, err.data, err.data)
						: getTranslation(
								"Something went wrong. Please contact with admin.",
								"Something went wrong. Please contact with admin.",
								"Something went wrong. Please contact with admin."
						  ),
					{
						variant: "error",
					}
				);
			}
		);
		setReminderTemplate(null);
		getReminderTemplate();
		getDaysInterval();
	}, [reminderKey]);

	useEffect(() => {
		getReminderTemplate();
	}, [reminderKey, selectedLanguage]);

	//post recovery template data
	const postRecoveryTemplate = (payload, actions) => {
		setIsLoading(true);
		setLoading(true);
		Post(
			payload,
			Post_UpdateReminderTemplate_URL,
			null,
			(resp) => {
				enqueueSnackbar("Recovery Reminder set successfully", {
					variant: "success",
				});
				setIsLoading(false);
				setLoading(false);
			},
			(error) => {
				enqueueSnackbar(
					error?.data
						? error?.data
						: getTranslation(
								"Something went wrong. Please try again.",
								"Something went wrong. Please try again.",
								"Something went wrong. Please try again."
						  ),
					{
						variant: "error",
					}
				);
				setIsLoading(false);
				setLoading(false);
			}
		);
	};

	//post recoveryProcedureDaysInterval
	const postRecoveryProcedureDaysInterval = (payload) => {
		if (recoveryProcedureCost === "") {
			enqueueSnackbar(
				getTranslation(
					"Recovery Cost Can't be null",
					"Recovery Cost Can't be null",
					"Recovery Cost Can't be null"
				),
				{
					variant: "error",
				}
			);
			return;
		}
		if (recoveryProcedureCost < 0) {
			enqueueSnackbar(
				getTranslation(
					"Recovery Cost Can't be negative",
					"Recovery Cost Can't be negative",
					"Recovery Cost Can't be negative"
				),
				{
					variant: "error",
				}
			);
			return;
		}
		if (recoveryProcedureDaysInterval === "") {
			enqueueSnackbar(
				getTranslation(
					"Recovery Days Interval can't be null",
					"Recovery Days Interval can't be null",
					"Recovery Days Interval can't be null"
				),
				{
					variant: "error",
				}
			);
			return;
		}
		if (recoveryProcedureDaysInterval < 0) {
			enqueueSnackbar(
				getTranslation(
					"Recovery Days Interval can't be negative",
					"Recovery Days Interval can't be negative",
					"Recovery Days Interval can't be negative"
				),
				{
					variant: "error",
				}
			);
			return;
		}
		Post(
			payload,
			Post_UpsertRecoveryProcedureDaysInterval_URL,
			null,
			(resp) => {
				enqueueSnackbar(
					getTranslation(
						"Updated successfully",
						"Updated successfully",
						"Updated successfully"
					),
					{
						variant: "success",
					}
				);
			},
			(err) => {
				enqueueSnackbar(
					err.data
						? err.data
						: getTranslation(
								"Something went wrong. Please try again.",
								"Something went wrong. Please try again.",
								"Something went wrong. Please try again."
						  ),
					{
						variant: "error",
					}
				);
			}
		);
	};

	//get recovery person template
	const getReminderTemplate = () => {
		setReminderTempateLoading(true);
		Get(
			{ reminderKey: reminderKey },
			Get_ReminderTemplate_URL,
			null,
			(resp) => {
				setReminderTemplate(resp?.data);
				setEnableReloading(true);
				setReminderTempateLoading(false);
			},
			(err) => {
				enqueueSnackbar(
					err.data
						? err.data
						: getTranslation(
								"Something went wrong. Please try again.",
								"Something went wrong. Please try again.",
								"Something went wrong. Please try again."
						  ),
					{
						variant: "error",
					}
				);
				setReminderTempateLoading(false);
			}
		);
	};

	//get daysinterval and x days

	const getDaysInterval = () => {
		Get(
			{},
			Get_RecoveryProcedureDaysInterval_URL,
			null,
			(resp) => {
				setRecoveryProcedureCost(resp?.data.recoveryProcedureCost);
				setRecoveryProcedureDaysInterval(
					resp?.data.recoveryProcedureDaysInterval
				);
			},
			(err) => {}
		);
	};

	const initialValues = {
		name: "",
		email: "",
		subject: "",
		template: "",
		attachStatement: false,
		attachDocuments: false,
		dayOfMonth: 0,
	};

	const basicValidationSchema = Yup.object().shape({
		template: Yup.string().required("Reminder text is required"),
		name: Yup.string().nullable().required("Person Name is required"),
		email: Yup.string().nullable().required("Person Email is required"),
		subject: Yup.string().nullable().required("Email Subject is required"),
		dayOfMonth: Yup.number().min(1).required("Day Interval is required"),
	});

	const basicValidationSchemaTenant = Yup.object().shape({
		subject: Yup.string().required("Tenant Email Subject is required"),
		template: Yup.string().required("Reminder text is required"),
	});

	const defaultValue = reminderTemplate ? reminderTemplate : initialValues;

	return (
		<>
			<FormControl>
				<FormControlLabel
					label={getTranslation(
						"Enable Automatic Recovery Procedure",
						"Enable Automatic Recovery Procedure",
						"Enable Automatic Recovery Procedure"
					)}
					labelPlacement="start"
					control={
						<Checkbox
							id="recoveryReminderCheck"
							style={{
								color: "rgb(229,60,22)",
							}}
							// disabled={smsReminderDisabled}
							checked={recoveryReminderCheck}
							onChange={(e, check) => {
								setRecoveryReminderCheck(check);
								Post(
									{ enableReminder: check },
									Post_EnableAutoRecoveryProcedure_URL,
									null,
									(resp) => {
										enqueueSnackbar(
											resp?.data
												? getTranslation(resp?.data, resp?.data, resp?.data)
												: getTranslation(
														"You request has been proccessed successfully",
														"You request has been proccessed successfully",
														"You request has been proccessed successfully"
												  ),
											{ variant: "success" }
										);
									},
									(error) => {
										enqueueSnackbar(
											error?.data
												? getTranslation(error?.data, error?.data, error?.data)
												: getTranslation(
														"Something went wrong. Please try again.",
														"Something went wrong. Please try again.",
														"Something went wrong. Please try again."
												  ),
											{
												variant: "error",
											}
										);
									}
								);
							}}
						/>
					}
				/>
			</FormControl>
			<div>
				<TextField
					style={{ width: "300px", margin: "10px" }}
					variant="outlined"
					type="number"
					id="recoveryProcedureCost"
					label={getTranslation(
						"Recovery Cost",
						"Recovery Cost",
						"Recovery Cost"
					)}
					name="recoveryProcedureCost"
					size="small"
					value={recoveryProcedureCost}
					InputProps={{ inputProps: { min: 0 } }}
					InputLabelProps={{
						shrink: true,
					}}
					onChange={(e) => {
						setRecoveryProcedureCost(e.target.value);
					}}
					onBlur={() => {
						postRecoveryProcedureDaysInterval({
							id: 1,
							recoveryProcedureCost: recoveryProcedureCost,
						});
					}}
				/>
			</div>

			<div style={{ display: "flex", alignItems: "center" }}>
				<Typography variant="h5">
					{getTranslation(
						"Start recovery procedure when due amount is greater than requested warranty and last payment date passed over"
					) + "  "}
				</Typography>
				<TextField
					style={{ marginLeft: "10px", marginBottom: "20px", width: "100px" }}
					required
					value={recoveryProcedureDaysInterval}
					type="number"
					id="recoveryProcedureDaysInterval"
					label={getTranslation("Enter X", "Enter X", "Enter X")}
					name="recoveryProcedureDaysInterval"
					size="small"
					onChange={(e) => {
						setRecoveryProcedureDaysInterval(e.target.value);
					}}
					onBlur={() => {
						postRecoveryProcedureDaysInterval({
							id: 1,
							recoveryProcedureDaysInterval: recoveryProcedureDaysInterval,
						});
					}}
					InputProps={{ inputProps: { min: 0 } }}
				/>
				<Typography>{getTranslation("days", "days", "days")} </Typography>
			</div>
			{/* ) : (
        ""
         )} */}

			<div>
				<ToggleButtonGroup
					size="small"
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
					id="reminderKey"
					value={reminderKey}
					exclusive
					onChange={(event, key) => {
						if (!blurController) {
							if (key == null) {
								setReminderKey("RecoveryProcedure");
							} else {
								setReminderKey(key);
							}
						}
						if (blurController) {
							enqueueSnackbar(
								getTranslation(
									"Looks Like You Have Changed Something in Template Kindly Save Setting",
									"Looks Like You Have Changed Something in Template Kindly Save Setting",
									"Looks Like You Have Changed Something in Template Kindly Save Setting"
								),
								{
									variant: "success",
								}
							);
						}
					}}
				>
					<ToggleButton value="RecoveryProcedure" aria-label="left aligned">
						<Typography variant="h6" required>
							{getTranslation("Tenant", "Tenant", "Tenant")}*
						</Typography>
					</ToggleButton>
					<ToggleButton value="FirstPerson" aria-label="left aligned">
						<Typography variant="h6" required>
							{getTranslation("1st Person", "1st Person", "1st Person")}*
						</Typography>
					</ToggleButton>

					<ToggleButton value="SecondPerson" aria-label="left aligned">
						<Typography variant="h6">
							{getTranslation("2nd Person", "2nd Person", "2nd Person")}{" "}
							(optional)
						</Typography>
					</ToggleButton>
					<ToggleButton value="ThirdPerson" aria-label="centered">
						<Typography variant="h6">
							{getTranslation("3rd Person", "3rd Person", "3rd Person")}{" "}
							(optional)
						</Typography>
					</ToggleButton>
				</ToggleButtonGroup>
				<br />
				<br />
				<FormLoader progress={reminderTemplateLoading}>
					<Formik
						enableReinitialize
						initialValues={defaultValue}
						validationSchema={
							reminderKey == "RecoveryProcedure"
								? basicValidationSchemaTenant
								: basicValidationSchema
						}
						onSubmit={(values, actions) => {
							actions.setSubmitting(true);
							values.reminderKey = reminderKey;
							setBlurController(false);
							postRecoveryTemplate(values, actions);
							actions.setSubmitting(false);
						}}
					>
						{({
							values,
							setFieldValue,
							errors,
							touched,
							handleChange,
							handleSubmit,
							isSubmitting,
							getFieldProps,
						}) => (
							<>
								<Form>
									<>
										{reminderKey !== "RecoveryProcedure" ? (
											<>
												<Grid
													container
													spacing={2}
													style={{ marginTop: "20px" }}
												>
													<Grid item xs={12} sm={6} md={4} lg={3}>
														<TextField
															fullWidth
															variant="outlined"
															required
															type="number"
															id="dayOfMonth"
															label={getTranslation(
																"Interval (Days)",
																"Intervalle en jours",
																"Interval (Tage)"
															)}
															name="dayOfMonth"
															size="small"
															{...getFieldProps("dayOfMonth")}
															error={
																touched.dayOfMonth && Boolean(errors.dayOfMonth)
															}
															helperText={
																touched.dayOfMonth && errors.dayOfMonth
															}
															InputLabelProps={{
																shrink: true,
															}}
															InputProps={{ inputProps: { min: 0 } }}
														/>
													</Grid>
													<Grid item xs={12} sm={6} md={4} lg={3}>
														<TextField
															fullWidth
															variant="outlined"
															required
															type="text"
															id="name"
															label={getTranslation(
																"Person Name",
																"Person Name",
																"Person Name"
															)}
															name="name"
															size="small"
															{...getFieldProps("name")}
															error={touched.name && Boolean(errors.name)}
															helperText={touched.name && errors.name}
															InputLabelProps={{
																shrink: true,
															}}
															onChange={(e) => {
																setFieldValue("name", e.target.value);
															}}
														/>
													</Grid>
													<Grid item xs={12} sm={6} md={4} lg={3}>
														<TextField
															fullWidth
															variant="outlined"
															required
															id="email"
															label={getTranslation(
																"Person Email",
																"Person Email",
																"Person Email"
															)}
															name="email"
															size="small"
															{...getFieldProps("email")}
															error={touched.email && Boolean(errors.email)}
															helperText={touched.email && errors.email}
															InputLabelProps={{
																shrink: true,
															}}
														/>
													</Grid>
													<Grid item xs={12} sm={6} md={4} lg={3}>
														<TextField
															fullWidth
															variant="outlined"
															required
															id="subject"
															label={getTranslation(
																"Email Subject",
																"Email Subject",
																"Email Subject"
															)}
															name="subject"
															size="small"
															{...getFieldProps("subject")}
															error={touched.subject && Boolean(errors.subject)}
															helperText={touched.subject && errors.subject}
															InputLabelProps={{
																shrink: true,
															}}
														/>
													</Grid>

													<Grid>
														<Box display="flex" justifyContent="center" mt={1}>
															<ButtonWithLoading
																title={getTranslation(
																	"Save Setting",
																	"Save Setting",
																	"Save Setting"
																)}
																style={{
																	backgroundColor: "rgb(229,60,22)",
																	color: "white",
																	fontWeight: "bold",
																}}
																size="medium"
																variant="contained"
																loading={loading}
																onClick={() => {
																	setBlurController(false);
																	switch (selectedLanguage) {
																		case "english":
																			setFieldValue(
																				"template",
																				editorRef?.current?.getContent()
																			);
																			break;
																		case "french":
																			setFieldValue(
																				"templateFrench",
																				editorRef?.current?.getContent()
																			);
																			break;
																		case "german":
																			setFieldValue(
																				"templateGerman",
																				editorRef?.current?.getContent()
																			);
																			break;
																		default:
																			setFieldValue(
																				"template",
																				editorRef?.current?.getContent()
																			);
																	}
																	handleSubmit();
																}}
															/>
														</Box>
													</Grid>
												</Grid>

												{/* check boxes */}
												<Grid container spacing={2}>
													<Grid item xs={12} sm={12} md={3} lg={3}>
														<FormControl>
															<FormControlLabel
																label={getTranslation(
																	"Attached complete statement",
																	"Attached complete statement",
																	"Attached complete statement"
																)}
																labelPlacement="start"
																control={
																	<Checkbox
																		id="attachStatement"
																		style={{
																			color: "rgb(229,60,22)",
																		}}
																		checked={values.attachStatement}
																	/>
																}
																onChange={(e, check) => {
																	setFieldValue("attachStatement", check);
																}}
															/>
														</FormControl>
													</Grid>

													<Grid item xs={12} sm={12} md={3} lg={4}>
														<FormControl>
															<FormControlLabel
																label={getTranslation(
																	"Attached copy of all documents",
																	"Attached copy of all documents",
																	"Attached copy of all documents"
																)}
																labelPlacement="start"
																control={
																	<Checkbox
																		id="attachDocuments"
																		style={{
																			color: "rgb(229,60,22)",
																		}}
																		checked={values.attachDocuments}
																	/>
																}
																onChange={(e, check) => {
																	setFieldValue("attachDocuments", check);
																}}
															/>
														</FormControl>
													</Grid>
													<Grid item xs={12} sm={12} md={12} lg={12}>
														<Typography
															variant="h5"
															style={{ marginLeft: "15px" }}
														>
															{getTranslation(
																"Recovery Procedure Text",
																"Recovery Procedure Text",
																"Recovery Procedure Text"
															)}
														</Typography>
													</Grid>
												</Grid>
											</>
										) : (
											<Grid container spacing={2} style={{ marginTop: "20px" }}>
												<Grid item xs={12} sm={6} md={4} lg={3}>
													<TextField
														fullWidth
														variant="outlined"
														required
														id="subject"
														label={getTranslation(
															"Email Subject",
															"Email Subject",
															"Email Subject"
														)}
														name="subject"
														size="small"
														{...getFieldProps("subject")}
														error={touched.subject && Boolean(errors.subject)}
														helperText={touched.subject && errors.subject}
														InputLabelProps={{
															shrink: true,
														}}
													/>
												</Grid>
												<Grid>
													<Box display="flex" justifyContent="center" mt={1}>
														<ButtonWithLoading
															title={getTranslation(
																"Save Setting",
																"Save Setting",
																"Save Setting"
															)}
															style={{
																backgroundColor: "rgb(229,60,22)",
																color: "white",
																fontWeight: "bold",
															}}
															size="medium"
															variant="contained"
															loading={loading}
															onClick={() => {
																setBlurController(false);
																switch (selectedLanguage) {
																	case "english":
																		setFieldValue(
																			"template",
																			editorRef?.current?.getContent()
																		);
																		break;
																	case "french":
																		setFieldValue(
																			"templateFrench",
																			editorRef?.current?.getContent()
																		);
																		break;
																	case "german":
																		setFieldValue(
																			"templateGerman",
																			editorRef?.current?.getContent()
																		);
																		break;
																	default:
																		setFieldValue(
																			"template",
																			editorRef?.current?.getContent()
																		);
																}
																handleSubmit();
															}}
														/>
													</Box>
												</Grid>
											</Grid>
										)}

										{/* dynamic buttons */}
										<div
											style={{
												display: "flex",
												justifyContent: "left",
												flexWrap: "wrap",
											}}
										>
											<Button
												className={classes.root}
												size="small"
												onClick={() => {
													AppendTemplate(
														values.template,
														"<span>[tenant_name]</span>",
														setFieldValue
													);
												}}
											>
												{getTranslation("Name", "Name", "Name")}
											</Button>
											<Button
												size="small"
												className={classes.root}
												onClick={() =>
													AppendTemplate(
														values.template,
														"<span>[tenant_email]</span>",
														setFieldValue
													)
												}
											>
												{getTranslation("Email", "Email", "Email")}
											</Button>
											<Button
												size="small"
												className={classes.root}
												onClick={() =>
													AppendTemplate(
														values.template,
														"<span>[tenant_appartment]</span>",
														setFieldValue
													)
												}
											>
												{getTranslation("Apartment", "Logement", "Wohnungen")}
											</Button>
											<Button
												size="small"
												className={classes.root}
												onClick={() =>
													AppendTemplate(
														values.template,
														"<span>[appartment_address]</span>",
														setFieldValue
													)
												}
											>
												{getTranslation(
													"Apartment Address",
													"Apartment Address",
													"Apartment Address"
												)}
											</Button>
											<Button
												size="small"
												className={classes.root}
												onClick={() =>
													AppendTemplate(
														values.template,
														"<span>[town]</span>",
														setFieldValue
													)
												}
											>
												{getTranslation("Town", "Town", "Town")}
											</Button>

											<Button
												size="small"
												className={classes.root}
												onClick={() =>
													AppendTemplate(
														values.template,
														"<span>[zip_code]</span>",
														setFieldValue
													)
												}
											>
												{getTranslation("Zip Code", "Zip Code", "Zip Code")}
											</Button>
											<Button
												size="small"
												className={classes.root}
												onClick={() =>
													AppendTemplate(
														values.template,
														"<span>[tenant_reference]</span>",
														setFieldValue
													)
												}
											>
												{getTranslation("Reference", "Reference", "Reference")}
											</Button>

											<Button
												size="small"
												className={classes.root}
												onClick={() =>
													AppendTemplate(
														values.template,
														"<span>[due_Date]</span>",
														setFieldValue
													)
												}
											>
												{getTranslation(
													"Due Date",
													"Date d'échéance",
													"Geburtstermin"
												)}
											</Button>
											<Button
												size="small"
												className={classes.root}
												onClick={() =>
													AppendTemplate(
														values.template,
														"<span>[current_date]</span>",
														setFieldValue
													)
												}
											>
												{getTranslation(
													"Current Date",
													"Current Date",
													"Current Date"
												)}
											</Button>
											<Button
												size="small"
												className={classes.root}
												onClick={() =>
													AppendTemplate(
														values.template,
														"<span>[due_Amount]</span>",
														setFieldValue
													)
												}
											>
												{getTranslation(
													"Total Due Amount",
													"Montant dû",
													"Geburtstermin"
												)}
											</Button>
											<Button
												size="small"
												className={classes.root}
												onClick={() =>
													AppendTemplate(
														values.template,
														"<span>[last_payment]</span>",
														setFieldValue
													)
												}
											>
												{getTranslation(
													"Last Payment",
													"Last Payment",
													"Last Payment"
												)}
											</Button>
											<Button
												size="small"
												className={classes.root}
												onClick={() =>
													AppendTemplate(
														values.template,
														"<span>[total_rent]</span>",
														setFieldValue
													)
												}
											>
												{getTranslation(
													"Total Rent",
													"Total Rent",
													"Total Rent"
												)}
											</Button>
											<Button
												size="small"
												className={classes.root}
												onClick={() =>
													AppendTemplate(
														values.template,
														"<span>[total_cost]</span>",
														setFieldValue
													)
												}
											>
												{getTranslation(
													"Total Cost",
													"Total Cost",
													"Total Cost"
												)}
											</Button>

											<Button
												size="small"
												className={classes.root}
												onClick={() =>
													AppendTemplate(
														values.template,
														"<span>[total_warranty]</span>",
														setFieldValue
													)
												}
											>
												{getTranslation(
													"Total Warranty",
													"Total Warranty",
													"Total Warranty"
												)}
											</Button>

											<Button
												size="small"
												className={classes.root}
												onClick={() =>
													AppendTemplate(
														values.template,
														"<span>[total_maintenance]</span>",
														setFieldValue
													)
												}
											>
												{getTranslation(
													"Total Maintenance",
													"Total Maintenance",
													"Total Maintenance"
												)}
											</Button>
										</div>

										<Grid container spacing={2}>
											<Grid item xs={12} sm={12} md={12} lg={12}>
												<TextEditor
													translatedText={
														reminderKey === "FirstPerson"
															? getTranslation(
																	"1st Person",
																	"1st Person",
																	"1st Person"
															  )
															: reminderKey === "SecondPerson"
															? getTranslation(
																	"2nd Person (Optional)",
																	"2nd Person (Optional)",
																	"2nd Person (Optional)"
															  )
															: reminderKey === "ThirdPerson"
															? getTranslation(
																	"3rd Person (Optional)",
																	"3rd Person (Optional)",
																	"3rd Person (Optional)"
															  )
															: getTranslation(
																	"Tenant Message",
																	"Tenant Message",
																	"Tenant Message"
															  )
													}
													selectedLanguage={selectedLanguage}
													reminderKey={reminderKey}
													openTestSmsDialoge={openTestSmsDialoge}
													handleLanguage={handleLanguage}
													title="Send Test Email"
												>
													<CardContent>
														<HtmlEditor
															ref={editorRef}
															text={getTemplateBasedOnLanguage(
																reminderTemplate,
																selectedLanguage
															)}
															onChange={(text) => {
																setEnableReloading(true);
																switch (selectedLanguage) {
																	case "english":
																		setFieldValue("template", text);
																		break;
																	case "french":
																		setFieldValue("templateFrench", text);
																		break;
																	case "german":
																		setFieldValue("templateGerman", text);
																		break;
																	default:
																		setFieldValue("template", text);
																}
																setEditorValue(text);
																setEnableReloading(false);
																handleSubmit();
															}}
															// buttonText={getTranslation(
															// 	"Save",
															// 	"Save",
															// 	"Save"
															// )}
															isLoading={false}
															isSubmitting={loading}
															setBlurController={setBlurController}
															blurhandler={true}
														/>

														<br />
														<Box
															pr={1}
															pb={1}
															pt={1}
															width="100%"
															display="flex"
															alignItems="center"
															justifyContent="center"
														></Box>
													</CardContent>
												</TextEditor>
												<TestSmsDialoge
													template={templateValue}
													title="Send Test Email"
													isOpen={testSmsDialoge}
													onClose={() => setSmsDialoge(false)}
													editor={editorValue}
												/>
											</Grid>
										</Grid>
									</>
								</Form>
							</>
						)}
					</Formik>
				</FormLoader>
			</div>
		</>
	);
};

export default RecoveryProcedureSetting;
