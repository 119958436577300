import {
	Box,
	Card,
	CardContent,
	Grid,
	TextField,
	Typography,
} from "@material-ui/core";
import ButtonWithLoading from "../../../../../components/ButtonWithLoading";
import { DatePicker } from "../../../../../components/DatePicker";
import DialogComponent from "../../../../../components/Dialog";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useParams } from "react-router";
import { getTranslation } from "../../../../../heplers/translationHelper";
import { useEffect, useState } from "react";
import SplitAmountMapper from "../../splitAmountMapper";
import { format } from "../../../../../heplers/format";
export const UpdateContractDialog = ({
	isOpen,
	contractId,
	currentAmount,
	onClose,
	onSubmit,
	additionalPayment,
	currentWarranty,
	contract,
}) => {
	useEffect(() => {
		let add = [
			{
				isIndexed: true,
				amount: 1716,
				type: 0,
				description: "",
			},
			{
				isIndexed: false,
				amount: 10,
				type: 1,
				description: "",
			},
			{
				isIndexed: false,
				amount: 20,
				type: 1,
				description: "",
			},
		];

		setAppartmentRent(currentAmount);
		setWarranty(currentWarranty);

		setFundToCategoryArray(
			additionalPayment
				? additionalPayment
				: [
						{
							isIndexed: true,
							amount: currentAmount,
							type: 0,
							description: "",
						},
						{
							isIndexed: false,
							amount: currentWarranty,
							type: 1,
							description: "",
						},
				  ]
		);
		//setFundToCategoryArray(add);
	}, [additionalPayment, isOpen]);
	const [fundToCategoryArray, setFundToCategoryArray] = useState([]);
	const [warranty, setWarranty] = useState(0);
	const [appartmentRent, setAppartmentRent] = useState();
	const [rentAmountError, setrentAmountError] = useState(false);
	const [update, setUpdate] = useState(true);
	const enableError = () => {
		setrentAmountError(true);
	};

	const disableError = () => {
		setrentAmountError(false);
	};

	const handleOnAdd = () => {
		const arr = fundToCategoryArray;
		setFundToCategoryArray([
			...arr,
			{ isIndexed: false, type: 0, amount: 0, description: "" },
		]);
	};

	const initialValues = {
		id: contractId,
	};

	const basicValidationSchema = Yup.object().shape({
		id: Yup.number().required("required"),
	});

	const defaultValue = initialValues;
	return (
		<DialogComponent
			maxWidth="lg"
			title="Update Contract Rent"
			open={isOpen}
			onClose={() => {
				onClose();
				setFundToCategoryArray([]);
				setAppartmentRent();
				setWarranty();
			}}
		>
			<Card>
				<CardContent>
					<Formik
						initialValues={defaultValue}
						validationSchema={basicValidationSchema}
						onSubmit={(values, actions) => {
							actions.setSubmitting(true);
							values.additionalPayments = fundToCategoryArray;
							values.newRent = appartmentRent;
							console.log(values);
							onSubmit(values, actions);
						}}
					>
						{({
							errors,
							touched,
							values,
							handleSubmit,
							isSubmitting,
							getFieldProps,
							setFieldValue,
						}) => (
							<Form>
								<Box
									style={{
										display: "flex",
										justifyContent: "space-between",
										marginBottom: "10px",
									}}
								>
									<Grid
										xs={12}
										sm={6}
										md={6}
										lg={6}
										xl={6}
										style={{ marginTop: "20px" }}
									>
										<TextField
											disabled={true}
											id="currentRent"
											fullWidth
											//type="number"
											label={getTranslation(
												"Total Rent",
												"Total Rent",
												"Total Rent"
											)}
											required
											size="small"
											variant="outlined"
											name={"currentRent"}
											value={format("number", appartmentRent)}
											InputLabelProps={{
												shrink: true,
											}}
											InputProps={{ inputProps: { min: 0 } }}
										/>
									</Grid>

									<Grid
										xs={12}
										sm={6}
										md={4}
										lg={3}
										style={{ marginTop: "20px" }}
									>
										<TextField
											id="warranty"
											fullWidth
											label={getTranslation("Warranty", "Warranty", "Warranty")}
											//type="number"
											size="small"
											disabled={true}
											variant="outlined"
											name={"warranty"}
											value={format("number", warranty)}
											InputLabelProps={{
												shrink: true,
											}}
											InputProps={{ inputProps: { min: 0 } }}
										/>
									</Grid>
								</Box>

								<SplitAmountMapper
									update={update}
									title="update"
									array={fundToCategoryArray}
									setArray={setFundToCategoryArray}
									setWarranty={setWarranty}
									appartmentRent={appartmentRent}
									setAppartmentRent={setAppartmentRent}
									enableError={enableError}
									disableError={disableError}
									initialRent={currentAmount}
								/>
								<Box
									style={{
										display: "flex",
										alignItems: "flex-start",
										justifyContent: "flex-start",
										marginTop: "20px",
									}}
								>
									<ButtonWithLoading
										title={
											<>
												<Typography
													variant="h5"
													style={{
														fontWeight: "bold",
														color: "rgb(0,68,103)",
													}}
												>
													{getTranslation(
														"Add New Record",
														"Add New Record",
														"Add New Record"
													)}
													&nbsp;&nbsp;{" "}
												</Typography>
												{/* <AddCircleIcon style={{ color: "rgb(251,94,57)" }} /> */}
											</>
										}
										size="small"
										variant="contained"
										// loading={isSubmitting}
										onClick={handleOnAdd}
										style={{
											background: "rgb(244,246,248)",
											boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.1)",
											padding: "12px 10px 12px 8px",
										}}
									/>
								</Box>
								<Box
									style={{
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										marginTop: "20px",
									}}
								>
									<ButtonWithLoading
										title={
											<Typography
												variant="h5"
												style={{ fontWeight: "bold", color: "white" }}
											>
												{getTranslation(
													"Update Contract",
													"Update Contract",
													"Update Contract"
												)}
											</Typography>
										}
										size="small"
										variant="contained"
										loading={isSubmitting}
										disabled={rentAmountError}
										// onClick={handleSubmit}
										onClick={handleSubmit}
										style={{
											boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.1)",
											padding: "12px 10px 12px 8px",
											// background: "rgb(251,94,57)",
											background: rentAmountError
												? "rgb(170,170,170)"
												: "rgb(251,94,57)",
											display: appartmentRent == 0 ? "none" : "",
											borderRadius: "20px",
											width: "230px",
										}}
									/>
								</Box>
								{rentAmountError && (
									<p
										style={{
											color: "red",
											textAlign: "center",
											paddingTop: "15px",
										}}
									>
										{getTranslation(
											"Amount of Rent Cant be less then the initail rent",
											"Amount of Rent Cant be less then the initail rent",
											"Amount of Rent Cant be less then the initail rent"
										)}
									</p>
								)}
							</Form>
						)}
					</Formik>

					{/* <Formik
            initialValues={defaultValue}
            validationSchema={basicValidationSchema}
            onSubmit={(values, actions) => {
              actions.setSubmitting(true);
              onSubmit(values, actions);
            }}
          >
            {({
              errors,
              touched,
              values,
              handleSubmit,
              isSubmitting,
              getFieldProps,
              setFieldValue,
            }) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid xs={12} sm={12} md={12} lg={12}>
                    <TextField
                      fullWidth
                      id='newRent'
                      label={getTranslation("Rent", "Loyer", "Miete")}
                      required
                      size='small'
                      variant='outlined'
                      type='number'
                      name='newRent'
                      {...getFieldProps("newRent")}
                      error={touched.newRent && Boolean(errors.newRent)}
                      helperText={touched.newRent && errors.newRent}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    style={{
                      display: "flex",
                      alignItems: "flex-end",
                      justifyContent: "flex-end",
                    }}
                  >
                    <ButtonWithLoading
                      title='Update'
                      color='primary'
                      style={{ marginTop: "1em" }}
                      variant='contained'
                      size='small'
                      loading={isSubmitting}
                      onClick={handleSubmit}
                    />
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik> */}
				</CardContent>
			</Card>
		</DialogComponent>
	);
};

export default UpdateContractDialog;
