import React, { useState } from "react";
import {
	AppBar,
	Box,
	Typography,
	Button,
	Toolbar,
	Avatar,
	Menu,
	IconButton,
	MenuItem,
	Tooltip,
	Select,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";
import { HiOutlineLogout, HiUserCircle } from "react-icons/hi";
import useStyles from "../../assests/styles/layout/topbar";
import SubMenus from "./submenu";
import { Logout } from "../../actions";
import authUtils from "../../utils/authUtils";
import clsx from "clsx";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { ContactSupportOutlined, HelpOutline } from "@material-ui/icons";
import {
	EnglishLanguageIcon,
	FrenchLanguageIcon,
	GermanLanguageIcon,
} from "../../components/icons";
import { getTranslation } from "../../heplers/translationHelper";
import { format } from "../../heplers/format";
import DialogComponent from "../../components/Dialog";
import { WarningOutlined } from "@material-ui/icons";
import ButtonWithLoading from "../../components/ButtonWithLoading";
import authService from "../../utils/authUtils";

function TopBar({
	className,
	navOpen,
	isMobile,
	onMobileClose,
	handleDrawerOpen,
	...rest
}) {
	const dispatch = useDispatch();
	const classes = useStyles();
	const history = useHistory();
	var [profileMenu, setProfileMenu] = useState(null);
	const [openModal, setOpenModal] = useState(false);
	const [modalContent, setModalContent] = useState("");

	const userData = useSelector((state) => state.auth.user);
	const { user } = useSelector((state) => state.auth);
	const handleNavigateToProfile = () => {
		history.push("/account/profile");
		setProfileMenu(null);
	};

	const handleLogout = () => {
		dispatch(Logout()).then((resp) => {});
		authUtils.logout();
		history.push("/login");
	};

	const handleClick = () => {
		const objToSend = {
			token: authService.getUserToken(),
			refreshToken: authService.getUserRefreshToken(),
			refreshTokenExpiry: authService.getUserRefreshTokenExpiry(),
			isRefreshing: authService.getIsRefreshing(),
			customerId: authService.getCustomerId(),
			redirectURL: authService.removeRedirectURL(),
			user: authService.getUser(),
			redirectUrlBack: `${process.env.REACT_APP_CLIC_RENT_DOMAIN}upgrade`,
			target: "trial end",
		};
		const stringifiedObj = JSON.stringify(objToSend);

		const base64Obj = btoa(stringifiedObj); // Convert to Base64

		const url = `${
			process.env.REACT_APP_APP_MANAGER_DOMAIN
		}identity?data=${encodeURIComponent(base64Obj)}`;
		window.location.href = url;
	};

	const handleDrawerClose = () => {
		onMobileClose();
	};

	const handleContactUs = () => {
		// window.open("https://form.jotform.com/220413974982361", "_blank");
		window.open("https://qollex.com/", "_blank");
	};

	const getStatusText = (packageStatus, trialEndDate) => {
		if (packageStatus === 1) {
			return "Active";
		} else if (packageStatus === 2) {
			return "Inactive";
		} else if (packageStatus === 3) {
			const currentDate = new Date();
			const endDate = new Date(trialEndDate);

			if (endDate < currentDate) {
				return "Trial Expired";
			} else {
				return "Trial";
			}
		} else {
			return "";
		}
	};

	const getStatusColor = (packageStatus, trialEndDate) => {
		if (packageStatus === 1) {
			return "green";
		} else if (
			packageStatus === 2 ||
			(packageStatus === 3 && new Date(trialEndDate) < new Date())
		) {
			return "red";
		} else {
			return "inherit";
		}
	};

	return navOpen && isMobile ? (
		<AppBar
			className={clsx(classes.root, {
				[classes.rootshift]: !navOpen,
			})}
			style={{ backgroundColor: "#F6F7FF", boxShadow: "none" }}
			{...rest}
		>
			<Toolbar className={classes.toolbar}>
				<div
					onClick={() => handleDrawerClose()}
					style={{
						width: "25px",
						height: "25px",
						backgroundColor: "rgb(0,63,103)",
						borderRadius: "50%",
						position: "relative",
						left: -35,
						cursor: "pointer",
					}}
				>
					<ChevronLeftIcon style={{ color: "white" }} />
				</div>
				<IconButton
					color="inherit"
					aria-label="open drawer"
					onClick={handleDrawerOpen}
					edge="start"
					className={clsx(classes.menuButton, navOpen && classes.hide)}
				>
					<MenuIcon />
				</IconButton>
				<SubMenus isMobile={isMobile} />

				<Box
					width="100%"
					display="flex"
					alignItems="center"
					justifyContent="flex-end"
				>
					<Select
						style={{
							marginRight: "20px",
							height: "34px",
							borderRadius: "17px",
						}}
						variant="outlined"
						defaultValue={
							localStorage.getItem("lang") ? localStorage.getItem("lang") : "en"
						}
						onChange={(e) => {
							localStorage.setItem("lang", e.target.value);
							window.location.reload();
						}}
					>
						<MenuItem value="en">
							<IconButton>
								<EnglishLanguageIcon />
							</IconButton>
							{isMobile == false
								? getTranslation("English", "English", "English")
								: null}
						</MenuItem>
						<MenuItem value="fr">
							<IconButton>
								<FrenchLanguageIcon />
							</IconButton>
							{isMobile == false
								? getTranslation("French", "French", "French")
								: null}
						</MenuItem>
						<MenuItem value="gr">
							<IconButton>
								<GermanLanguageIcon />
							</IconButton>
							{isMobile == false
								? getTranslation("German", "German", "German")
								: null}
						</MenuItem>
					</Select>

					<MenuItem
						target="_blank"
						href="https://appmanager.impact-soft.com/customerCredit"
						variant="h5"
						component="a"
					>
						<Typography>
							{getTranslation("Balance", "Balance", "Balance") +
								" " +
								format(
									"number",
									!userData?.smsCredit
										? "0.00"
										: format("number", userData?.smsCredit)
								) +
								" €"}
						</Typography>
					</MenuItem>
					<IconButton
						aria-haspopup="true"
						aria-controls="profile-menu"
						variant="circular"
						className={classes.avatar}
						onClick={(e) => {
							setProfileMenu(e.currentTarget);
						}}
					>
						{user?.firstName?.slice(0, 1)}
						{user?.lastName?.slice(0, 1)}
					</IconButton>

					<Menu
						id="profile-menu"
						open={Boolean(profileMenu)}
						anchorEl={profileMenu}
						onClose={() => setProfileMenu(null)}
						disableAutoFocusItem
						className={classes.menuBar}
						MenuListProps={{ className: classes.profileMenuList }}
					>
						<MenuItem>
							<Box
								onClick={handleNavigateToProfile}
								display="flex"
								alignItems="center"
							>
								<HiUserCircle className={classes.icon} />
								<Typography variant="body1">
									{getTranslation("My Account", "Mon compte", "Mein Konto")}
								</Typography>
							</Box>
						</MenuItem>
						<MenuItem>
							<Box display="flex" alignItems="center" onClick={handleLogout}>
								<HiOutlineLogout className={classes.icon} />
								<Typography variant="body1">
									{getTranslation("Logout", "Logout", "Logout")}
								</Typography>
							</Box>
						</MenuItem>
					</Menu>
				</Box>
			</Toolbar>
		</AppBar>
	) : (
		<AppBar
			className={clsx(classes.root, {
				[classes.rootshift]: !navOpen,
			})}
			style={{ backgroundColor: "#F6F7FF", boxShadow: "none" }}
			{...rest}
		>
			<Toolbar className={classes.toolbar}>
				<div
					onClick={() => handleDrawerClose()}
					style={{
						width: "25px",
						height: "25px",
						backgroundColor: "rgb(0,63,103)",
						borderRadius: "50%",
						position: "relative",
						left: -35,
						cursor: "pointer",
					}}
				>
					<ChevronLeftIcon style={{ color: "white" }} />
				</div>
				<IconButton
					color="inherit"
					aria-label="open drawer"
					onClick={handleDrawerOpen}
					edge="start"
					className={clsx(classes.menuButton, navOpen && classes.hide)}
				>
					<MenuIcon />
				</IconButton>
				<SubMenus isMobile={isMobile} />

				<Box
					width="100%"
					display="flex"
					alignItems="center"
					justifyContent="flex-end"
				>
					{/* contact us erea */}
					<IconButton className={classes.helpButton} onClick={handleContactUs}>
						<Tooltip
							title={getTranslation(
								"Contact Us",
								"Contactez-nous",
								"Kontaktieren Sie uns"
							)}
							arrow
						>
							<HelpOutline fontSize="large" />
						</Tooltip>
					</IconButton>

					{/* balance */}
					<MenuItem
						target="_blank"
						href="https://appmanager.impact-soft.com/customerCredit"
						variant="h5"
						component="a"
					>
						<Typography>
							{getTranslation("Balance", "Balance", "Balance") +
								" " +
								(!userData?.smsCredit
									? "0.00"
									: format("number", userData?.smsCredit)) +
								" €"}
						</Typography>
					</MenuItem>

					{/* select organization menu */}
					<Select
						style={{
							marginRight: "20px",
							width: "160px",
							height: "34px",
							borderRadius: "17px",
						}}
						variant="outlined"
						labelId="demo-simple-select-label"
						id="demo-simple-select"
						label={getTranslation(
							"Select Organization",
							"S�lectionnez l'organisation",
							"W�hlen Sie Organisation aus"
						)}
						onChange={(e) => {
							authUtils.setCustomerId(e.target.value);
							const selectedOrg = authUtils
								.getCustomerOrganizations()
								.find((x) => x.customerId == e.target.value);

							const currentDate = new Date(); // Get the current date

							if (selectedOrg) {
								if (selectedOrg?.packageStatus === 2) {
									setOpenModal(true);
									console.log(
										"Package has expired. Show modal for packageStatus 2."
									);
									setModalContent(
										"Your Package has been expired kindly checkout."
									);
									return;
								} else if (
									selectedOrg?.packageStatus === 3 &&
									new Date(selectedOrg?.trialEndDate) < currentDate
								) {
									setOpenModal(true);
									setModalContent(
										"Your trial period for organization has expired. Kindly checout."
									);
									console.log(
										"Package has expired due to trial end date. Show modal for packageStatus 3."
									);
									return;
								} else {
									authUtils.setIsStarter(selectedOrg?.isStarter);
									window.location.reload();
								}
							}
						}}
						defaultValue={authUtils.getCustomerId()}
						// disabled={authUtils.getCustomerOrganizations()?.length <= 1}
					>
						{authUtils.getCustomerOrganizations()?.map((app) => (
							<MenuItem value={app.customerId}>
								{app.organizationName}- {"   "}
								<span
									style={{
										fontSize: "12px",
										color: getStatusColor(app.packageStatus, app.trialEndDate),
									}}
								>
									({getStatusText(app.packageStatus, app.trialEndDate)})
								</span>
							</MenuItem>
						))}
						<MenuItem>
							<a
								target="_blank"
								href="https://appmanager.impact-soft.com/myapps/1"
							>
								{getTranslation(
									"Wants to have more organizations?",
									"Wants to have more organizations?",
									"Wants to have more organizations?"
								)}
							</a>
						</MenuItem>
					</Select>

					<DialogComponent
						open={openModal}
						//open={true}
						maxWidth={"sm"}
						fullWidth
						title={getTranslation(
							"Expired Alert",
							"Expired Alert",
							"Expired Alert"
						)}
						// onClose={() => setOpenAlert(false)}
					>
						<div style={{ textAlign: "center" }}>
							<WarningOutlined />
						</div>
						<Typography align="center">
							{getTranslation(modalContent, modalContent, modalContent)}
						</Typography>

						<div
							style={{
								//backgroundColor: "red",
								display: "flex",
								justifyContent: "center",
							}}
						>
							<Select
								style={{
									marginRight: "20px",
									width: "160px",
									height: "34px",
									borderRadius: "17px",
								}}
								variant="outlined"
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								label={getTranslation(
									"Select Organization",
									"S�lectionnez l'organisation",
									"W�hlen Sie Organisation aus"
								)}
								onChange={(e) => {
									authUtils.setCustomerId(e.target.value);
									const selectedOrg = authUtils
										.getCustomerOrganizations()
										.find((x) => x.customerId == e.target.value);

									const currentDate = new Date(); // Get the current date

									if (selectedOrg) {
										if (selectedOrg?.packageStatus === 2) {
											setOpenModal(true);
											console.log(
												"Package has expired. Show modal for packageStatus 2."
											);
											setModalContent(
												"Your Package has been expired kindly checkout."
											);
											return;
										} else if (
											selectedOrg?.packageStatus === 3 &&
											new Date(selectedOrg?.trialEndDate) < currentDate
										) {
											setOpenModal(true);
											setModalContent(
												"Your trial period for organization has expired. Kindly checout."
											);
											console.log(
												"Package has expired due to trial end date. Show modal for packageStatus 3."
											);
											return;
										} else {
											authUtils.setIsStarter(selectedOrg?.isStarter);
											window.location.reload();
										}
									}
								}}
								defaultValue={authUtils.getCustomerId()}
								// disabled={authUtils.getCustomerOrganizations()?.length <= 1}
							>
								{authUtils.getCustomerOrganizations()?.map((app) => (
									<MenuItem value={app.customerId}>
										{app.organizationName}- {"   "}
										<span
											style={{
												fontSize: "12px",
												color: getStatusColor(
													app.packageStatus,
													app.trialEndDate
												),
											}}
										>
											({getStatusText(app.packageStatus, app.trialEndDate)})
										</span>
									</MenuItem>
								))}
								<MenuItem>
									<a
										style={{ marginRight: "50px" }}
										target="_blank"
										href="https://appmanager.impact-soft.com/myapps/1"
									>
										{getTranslation(
											"Wants to have more organizations?",
											"Wants to have more organizations?",
											"Wants to have more organizations?"
										)}
									</a>
								</MenuItem>
							</Select>
						</div>

						<div
							style={{
								display: "flex",
								justifyContent: "center",
								marginTop: "10px",
							}}
						>
							<ButtonWithLoading
								onClick={handleClick}
								variant="contained"
								style={{
									backgroundColor: "rgb(229,60,22)",
									color: "white",
									fontWeight: "bold",
								}}
								title={getTranslation("Checkout", "Checkout", "Checkout")}
								size="medium"
							/>
						</div>

						{/* <ButtonWithLoading
              onClick={handleClick}
              variant='contained'
              style={{
                backgroundColor: "rgb(229,60,22)",
                color: "white",
                fontWeight: "bold",
              }}
              title={getTranslation("Checkout", "Checkout", "Checkout")}
              size='medium'
            /> */}
					</DialogComponent>
					{/* logout menu */}

					<IconButton
						aria-haspopup="true"
						aria-controls="profile-menu"
						variant="circular"
						className={classes.avatar}
						onClick={(e) => {
							setProfileMenu(e.currentTarget);
						}}
					>
						{user?.firstName?.slice(0, 1)}
						{user?.lastName?.slice(0, 1)}
					</IconButton>

					<Menu
						id="profile-menu"
						open={Boolean(profileMenu)}
						anchorEl={profileMenu}
						onClose={() => setProfileMenu(null)}
						disableAutoFocusItem
						className={classes.menuBar}
						MenuListProps={{ className: classes.profileMenuList }}
					>
						<MenuItem>
							<Box
								onClick={handleNavigateToProfile}
								display="flex"
								alignItems="center"
							>
								<HiUserCircle className={classes.icon} />
								<Typography variant="body1">
									{getTranslation("My Account", "Mon compte", "Mein Konto")}
								</Typography>
							</Box>
						</MenuItem>
						<MenuItem onClick={handleLogout}>
							<Box display="flex" alignItems="center">
								<HiOutlineLogout className={classes.icon} />
								<Typography variant="body1">
									{getTranslation("Logout", "Se d�connecter", "Ausloggen")}
								</Typography>
							</Box>
						</MenuItem>
						<MenuItem>
							<Select
								style={{
									marginRight: "20px",

									height: "34px",
									borderRadius: "17px",
								}}
								variant="outlined"
								defaultValue={
									localStorage.getItem("lang")
										? localStorage.getItem("lang")
										: "en"
								}
								onChange={(e) => {
									localStorage.setItem("lang", e.target.value);
									window.location.reload();
								}}
							>
								<MenuItem value="en">
									<IconButton>
										<EnglishLanguageIcon />
									</IconButton>
									{isMobile == false
										? getTranslation("English", "English", "English")
										: null}
								</MenuItem>
								<MenuItem value="fr">
									<IconButton>
										<FrenchLanguageIcon />
									</IconButton>
									{isMobile == false
										? getTranslation("French", "French", "French")
										: null}
								</MenuItem>
								<MenuItem value="gr">
									<IconButton>
										<GermanLanguageIcon />
									</IconButton>
									{isMobile == false
										? getTranslation("German", "German", "German")
										: null}
								</MenuItem>
							</Select>
						</MenuItem>
					</Menu>
				</Box>
			</Toolbar>
		</AppBar>
	);
}

export default withRouter(TopBar);
