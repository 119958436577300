import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import TenantRecoveryProcedure from "./components/list";

const TenantRecovery = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: "Clear_All_BreadCrumb" });
    dispatch({
      type: "Add_BreadCrumb",
      payload: { title: "RecoveryProcedure", url: "/recoveryprocedure" },
    });
  }, []);

  return (
    <div>
      <TenantRecoveryProcedure />
    </div>
  );
};
export default TenantRecovery;
