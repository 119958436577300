import { Card, CardContent, CardHeader, Grid } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import Table from "../../../../../components/table";
import { getTranslation } from "../../../../../heplers/translationHelper";
import { Box, IconButton, Tooltip, Typography } from "@material-ui/core";
import { AddPostDueDate } from "../../addPostponDueDate";
import { ReminderToAll, PostponIcon } from "../../../../../components/icons";
import { DownloadFile, Get, Post } from "../../../../../actions";
import {
	Get_DeleteTenantBank_URL,
	Get_GetTenantBanks_URL,
	Get_NotesWithPagination_URL,
	Get_TenantDuesSummary_URL,
	Post_PostPoneDue_URL,
} from "../../../../../constants/apiUrls";
import { useSnackbar } from "notistack";
import { format } from "../../../../../heplers/format";
import { DownloadButton } from "../../../../../components/ButttonsWithIcons";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { DeleteSharp } from "@material-ui/icons";
import TableComponent from "../../../../../components/table";
import DialogComponent from "../../../../../components/Dialog";
import AddNoteDialog from "../../recoveryprocedure/details/components/addNoteDialog";

const noteColumns = [
	{
		id: "date",
		label: getTranslation("Start Date", "Start Date", "Start Date"),
		align: "left",
		component: ({ row }) => {
			return <Typography>{format("date", row?.date)}</Typography>;
		},
	},

	{
		id: "comments",
		label: getTranslation("Comment", "Comment", "Comment"),
		format: "left",
	},
	{
		id: "actions",
		label: getTranslation("Actions", "Actions", "Actions"),
		align: "right",
	},
];

const TenantNotes = ({ tenantId }) => {
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [searchString, setSearchString] = useState("");
	const [noteStatus, setNoteStatus] = useState("all");
	const [addNoteDialog, setAddNoteDialog] = useState(false);
	const [refresh, setRefresh] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [notesData, setNotesData] = useState();

	const { enqueueSnackbar } = useSnackbar();

	//handlers
	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};
	//set rowperpage
	const handleChangeRowsPerPage = (event) => {
		localStorage.setItem("sizeDue", parseInt(event.target.value));
		setRowsPerPage(localStorage.getItem("sizeDue"));
		setPage(0);
	};
	const handleSearchDetails = (searchString) => {
		setSearchString(searchString);
	};

	//get notes data
	useEffect(() => {
		getNotesData();
		setRefresh(false);
	}, [noteStatus, refresh, page, rowsPerPage, searchString]);

	//get tenant notes api
	const getNotesData = () => {
		setIsLoading(true);
		Get(
			{
				tenantId: tenantId,
				type:
					noteStatus == "recoveryprocedure" ? 0 : noteStatus == "all" ? 2 : 1,
				pageNumber: page,
				pageSize: rowsPerPage ? rowsPerPage : 10,
				searchString: searchString,
			},
			Get_NotesWithPagination_URL,
			null,
			(resp) => {
				setNotesData(resp?.data);
				setIsLoading(false);
			},
			(err) => {
				enqueueSnackbar(
					err?.data
						? err?.data
						: getTranslation(
								"Something went wrong. Please contact with admin.",
								"Something went wrong. Please contact with admin.",
								"Something went wrong. Please contact with admin."
						  ),
					{
						variant: "error",
					}
				);
				setIsLoading(false);
			}
		);
	};

	return (
		<>
			{/* tenant notes area  */}
			<Card style={{ marginTop: "20px" }}>
				<br />
				<br />
				<ToggleButtonGroup
					size="medium"
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						marginTop: "20px",
					}}
					id="tenantDetailStatus"
					value={noteStatus}
					exclusive
					onChange={(event, status) => {
						if (status !== null) {
							setNoteStatus(status);
						}
					}}
				>
					<ToggleButton value={"all"} aria-label="centered">
						<Typography>{getTranslation("All", "All", "All")}</Typography>
					</ToggleButton>
					<ToggleButton value={"recoveryprocedure"} aria-label="centered">
						<Typography>
							{getTranslation(
								"Recovery Procedure",
								"Recovery Procedure",
								"Recovery Procedure"
							)}
						</Typography>
					</ToggleButton>
					<ToggleButton value={"phonecall"} aria-label="centered">
						<Typography>
							{getTranslation("Phone call", "Phone call", "Phone call")}
						</Typography>
					</ToggleButton>
				</ToggleButtonGroup>
				<CardContent>
					<TableComponent
						loading={isLoading}
						dense
						title={getTranslation("Notes", "Notes", "Notes")}
						colums={noteColumns}
						rows={notesData?.data}
						count={notesData?.totalCount}
						page={page}
						rowsPerPage={rowsPerPage}
						handleChangePage={handleChangePage}
						handleChangeRowsPerPage={handleChangeRowsPerPage}
						viewSearch
						viewCreate
						onCreate={() => {
							setAddNoteDialog(true);
						}}
						onSearch={(searchString) => {
							handleSearchDetails(searchString);
						}}
						actions={[
							{
								component: (row) =>
									row.document !== "" ? (
										<Tooltip
											title={getTranslation("Note", "Noter", "Notiz")}
											arrow
											placement="top"
										>
											<DownloadButton
												onClick={() => DownloadFile(row?.document)}
											/>
										</Tooltip>
									) : null,
							},
						]}
					/>
				</CardContent>
			</Card>

			{/* add note dialog  */}
			<DialogComponent
				open={addNoteDialog}
				onClose={() => setAddNoteDialog(false)}
				title={getTranslation("Add Notes", "Add Notes", "Add Notes")}
				fullWidth
			>
				<AddNoteDialog
					setAddNoteDialog={setAddNoteDialog}
					noteStatus={noteStatus}
					tenantId={tenantId}
					setRefresh={setRefresh}
				/>
			</DialogComponent>
		</>
	);
};
export default TenantNotes;
