import React from "react";
import {
	Box,
	Card,
	CardHeader,
	Paper,
	TextField,
	Typography,
} from "@material-ui/core";
import Table from "../../../components/table";
import { useState, useEffect } from "react";
import DeleteDialog from "../../../components/DeleteDialog";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";

import { Post, Get } from "../../../actions/apiActions";
import { BankSettings } from "./addBankSettings";
import {
	Get_AllBanksWithPagination_URL,
	Post_DeleteBank_URL,
	Post_UpdateBankStatus_URL,
} from "../../../constants/apiUrls";
import AlertDialog from "../../../components/DeleteDialog";
import authService from "../../../utils/authUtils";
import { Upgrade } from "../../common/Upgrade";
import { getTranslation } from "../../../heplers/translationHelper";
import Switch from "../../../components/Switch";
import PontoVerified from "../../../assests/icons/withPonto.png";
import PontoUnVerified from "../../../assests/icons/withoutPonot.png";

const columns = [
	{
		id: "iban",
		numeric: true,
		disablePadding: true,
		label: "IBAN",
	},
	{
		id: "securityKey",
		numeric: true,
		disablePadding: true,
		label: getTranslation(
			"Security Key",
			"Clef de sécurité",
			"Sicherheitsschlüssel"
		),
	},
	{
		id: "publicKey",
		numeric: true,
		disablePadding: true,
		label: getTranslation(
			"Public Key",
			"Clé publique",
			"Öffentlicher Schlüssel"
		),
	},
	{
		id: "shortDescription",
		numeric: true,
		disablePadding: true,
		label: getTranslation("Description", "La description", "Beschreibung"),
	},
	{
		id: "pontoStatus",
		numeric: true,
		disablePadding: true,
		label: getTranslation("Ponto Status", "Ponto Status", "Ponto Status"),
		component: ({ row }) => {
			return (
				<>
					<Typography variant="h5">
						{row?.pontoStatus == true ? (
							<button
								className="button"
								style={{
									backgroundColor: "green",
									borderRadius: "20px",
									color: "white",
									fontSize: "10px",
									fontWeight: "bold",
									padding: "3px 10px 3px 10px",
									border: "none",
								}}
								disabled={true}
							>
								{getTranslation("Verified", "Verified", "Verified")}
							</button>
						) : (
							<button
								className="button"
								style={{
									backgroundColor: "red",
									borderRadius: "20px",
									color: "white",
									fontSize: "10px",
									fontWeight: "bold",
									padding: "3px 10px 3px 10px",
									border: "none",
								}}
								disabled={true}
							>
								{getTranslation("Not Verified", "Not Verified", "Not Verified")}
							</button>
							// <img src={PontoUnVerified} />
						)}
					</Typography>
				</>
			);
		},
	},
	{
		id: "actions",
		numeric: true,
		disablePadding: true,
		label: getTranslation("Actions", "Actions", "Aktions"),
		align: "right",
	},
];

export const BankSettingList = ({ loading, refreshDues, onEdit }) => {
	const [searchString, setSearchString] = useState("");
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(
		localStorage.getItem("sizeBanks")
	);
	const [editBank, setEditBank] = useState();
	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
	const [deletedRow, setDeletedRow] = useState();
	const [deleting, setDeleting] = useState(false);

	const [multipleBanks, setMultipleBanks] = useState([]);
	const [refreshBanks, setRefreshBanks] = useState(false);
	const history = useHistory();

	const [filterItems, setFilterItems] = useState({
		searchString: searchString,
		pageNumber: 0,
		pageSize: 10,
	});
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		loadBanksSettings();
	}, [filterItems, refreshBanks]);

	const loadBanksSettings = () => {
		Get(
			{},
			Get_AllBanksWithPagination_URL,
			null,
			(resp) => {
				setMultipleBanks(resp?.data);
				setRefreshBanks(false);
			},
			(err) => {
				enqueueSnackbar(
					err?.data
						? err?.data
						: getTranslation(
								"Something went wrong. Please contact with admin.",
								"Something went wrong. Please contact with admin.",
								"Something went wrong. Please contact with admin."
						  ),
					{
						variant: "error",
					}
				);
				setRefreshBanks(false);
			}
		);
	};

	//handlers
	const handleChangePage = (newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		localStorage.setItem("sizeBanks", parseInt(event.target.value));
		setRowsPerPage(localStorage.getItem("sizeBanks"));
		setPage(0);
	};

	//handleSearchdue
	const handleSearchDue = (searchString) => {
		setSearchString(searchString);
	};

	const handleOpenDeleteDialog = (row) => {
		setDeletedRow(row);
		setOpenDeleteDialog(true);
	};

	const handleDelete = () => {
		setDeleting(true);
		Post(
			{ id: deletedRow.id },
			Post_DeleteBank_URL,
			null,
			(resp) => {
				//onDelete(deletedRow);
				enqueueSnackbar(
					getTranslation(
						"Bank deleted successfuly",
						"Bank deleted successfuly",
						"Bank deleted successfuly"
					),
					{ variant: "success" }
				);
				loadBanksSettings();
				setDeleting(false);
				setOpenDeleteDialog(false);
			},
			(error) => {
				enqueueSnackbar(
					getTranslation(
						"Unable to delete bank because it exist transactions",
						"Unable to delete bank because it exist transactions",
						"Unable to delete bank because it exist transactions"
					),
					{
						variant: "error",
					}
				);
				setOpenDeleteDialog(false);
				setDeleting(false);
			}
		);
	};

	const handleEditBank = (row) => {
		setEditBank(row);
	};

	const changeBankStatus = (rowData) => {
		return (
			<>
				<Switch
					checked={rowData.status}
					onChange={(e, checked) => {
						Post(
							{
								bankId: rowData.id,
								status: checked,
							},
							Post_UpdateBankStatus_URL,
							null,
							(resp) => {
								window.location.reload(true);
							},
							(error) => {}
						);
					}}
				/>
			</>
		);
	};

	return authService.getIsStarter() == true ? (
		<Upgrade />
	) : (
		<>
			<BankSettings
				updateBank={editBank}
				refresh={() => {
					loadBanksSettings(filterItems);
					setEditBank(null);
				}}
				setRefreshBanks={setRefreshBanks}
			/>
			<br />
			<Table
				loading={loading}
				dense
				title={getTranslation("Bank List", "Bank List", "Bank List")}
				colums={columns}
				sortBy="name"
				rows={multipleBanks?.data}
				count={multipleBanks?.totalCount}
				page={page}
				rowsPerPage={rowsPerPage}
				handleChangePage={handleChangePage}
				handleChangeRowsPerPage={handleChangeRowsPerPage}
				viewDelete
				onDelete={(row) => handleOpenDeleteDialog(row)}
				viewSearch
				onSearch={(searchString) => handleSearchDue(searchString)}
				viewEdit
				onEdit={(row) => handleEditBank(row)}
				actions={[
					{
						component: (rowData) => changeBankStatus(rowData),
					},
				]}
			/>
			<AlertDialog
				open={openDeleteDialog}
				deleting={deleting}
				onClose={() => setOpenDeleteDialog(false)}
				onSubmit={handleDelete}
			/>
		</>
	);
};
