import { makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  heading: {
    marginBottom: theme.spacing(1.5),
    marginTop: theme.spacing(1.5),
    fontWeight: "700",
    fontSize: "35px",
    color: "rgb(35,48,68)",
  },
  form: {
    width: "100%",
    // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    borderRadius: "16px 16px 16px 16px",
    backgroundColor: "rgb(0,68,103)",
  },
  logo: {
    height: "150px",
    width: "150px",
    marginBottom: theme.spacing(3),
  },
  loader: {
    color: theme.palette.common.white,
  },
  changeLoginColor: {
    color: theme.palette.primary.dark,
  },
  backgroundText: {
    color: "rgb(0,68,103)",
    fontWeight: "bold",
    marginLeft: "auto",
    marginRight: "auto",
  },
  link: {
    fontSize: "14px",
    textDecoration: "none",
    color: "black",
    "&:hover": {
      color: theme.palette.primary.main,
      textDecoration: "underline",
      cursor: "pointer",
    },
  },
}));

export default useStyles;
