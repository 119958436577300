import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import MakeCallStep from "./makeCallStep";
import ActionsStep from "./actionsStep";
import ClosingStep from "./closingStep";
import { Paper, Slide } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
	},
	backButton: {
		marginRight: theme.spacing(1),
	},
	instructions: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
		opacity: 0,
		transition: "opacity 0.5s ease-in-out",
	},
	fade: {
		opacity: 1,
	},
}));

function getSteps() {
	return ["Make a Call", "Actions", "Closing"];
}

// function getStepContent(stepIndex) {
// 	switch (stepIndex) {
// 		case 0:
//         //return "Select campaign settings...";
//             return <MakeCallStep tanent/>
// 		case 1:
// 			return "What is an ad group anyways?";
// 		case 2:
// 			return "This is the bit I really care about!";
// 		default:
// 			return "Unknown stepIndex";
// 	}
// }

const PhoneCallStepper = ({
	activeStep,
	setActiveStep,
	handleNext,
	handleBack,
	handleReset,
	tanent,
	dueList,
	setDueList,
	page,
	rowsPerPage,
	handleChangePage,
	handleChangeRowsPerPage,
	tenantDetailStatus,
	setTenantDetailStatus,
	isLoading,
	setIsLoading,
	notesData,
	pageNotes,
	rowsPerPageNotes,
	handleChangePageNotes,
	handleChangeRowsPerPageNotes,
	callCenterComment,
	setCallCenterComment,
	postNotesCommment,
	handleAddPostpone,
	handleSubmitPostpone,
	postPonePayload,
	setPostPonePayload,
	setRecoveryPayload,
	recoveryPayload,
	recoveryBtn,
	postponeBtn,
	animate,
	setAnimate,
	setIsOpenPostPone,
}) => {
	const classes = useStyles();
	// const [activeStep, setActiveStep] = React.useState(0);
	const [transitionName, setTransitionName] = React.useState("fade");
	const steps = getSteps();

	function getStepContent(stepIndex) {
		switch (stepIndex) {
			case 0:
				//return "Select campaign settings...";
				return (
					<MakeCallStep
						tanent={tanent}
						className={transitionName === "fade" ? "fade-in" : ""}
						setAnimate={setAnimate}
						dueList={dueList}
						setDueList={setDueList}
						page={page}
						rowsPerPage={rowsPerPage}
						handleChangePage={handleChangePage}
						handleChangeRowsPerPage={handleChangeRowsPerPage}
						tenantDetailStatus={tenantDetailStatus}
						setTenantDetailStatus={setTenantDetailStatus}
						isLoading={isLoading}
						setIsLoading={setIsLoading}
						notesData={notesData}
						pageNotes={pageNotes}
						rowsPerPageNotes={rowsPerPageNotes}
						handleChangePageNotes={handleChangePageNotes}
						handleChangeRowsPerPageNotes={handleChangeRowsPerPageNotes}
					/>
				);
			case 1:
				//return "What is an ad group anyways?";
				return (
					<ActionsStep
						dueList={dueList}
						setDueList={setDueList}
						page={page}
						rowsPerPage={rowsPerPage}
						handleChangePage={handleChangePage}
						handleChangeRowsPerPage={handleChangeRowsPerPage}
						tenantDetailStatus={tenantDetailStatus}
						setTenantDetailStatus={setTenantDetailStatus}
						isLoading={isLoading}
						setIsLoading={setIsLoading}
						notesData={notesData}
						pageNotes={pageNotes}
						rowsPerPageNotes={rowsPerPageNotes}
						handleChangePageNotes={handleChangePageNotes}
						handleChangeRowsPerPageNotes={handleChangeRowsPerPageNotes}
						className={transitionName === "fade" ? "fade" : ""}
						setAnimate={setAnimate}
					/>
				);
			case 2:
				return (
					<ClosingStep
						callCenterComment={callCenterComment}
						setCallCenterComment={setCallCenterComment}
						postNotesCommment={postNotesCommment}
						handleAddPostpone={handleAddPostpone}
						handleSubmitPostpone={handleSubmitPostpone}
						postPonePayload={postPonePayload}
						recoveryPayload={recoveryPayload}
						recoveryBtn={recoveryBtn}
						postponeBtn={postponeBtn}
						className={transitionName === "fade" ? "fade" : ""}
						setAnimate={setAnimate}
						setActiveStep={setActiveStep}
						setPostPonePayload={setPostPonePayload}
						setRecoveryPayload={setRecoveryPayload}
						setIsOpenPostPone={setIsOpenPostPone}
					/>
				);
			default:
				return "Unknown stepIndex";
		}
	}

	// const handleNext = () => {
	// 	setActiveStep((prevActiveStep) => prevActiveStep + 1);
	// };

	// const handleBack = () => {
	// 	setActiveStep((prevActiveStep) => prevActiveStep - 1);
	// };

	// const handleReset = () => {
	// 	setActiveStep(0);
	// };
	return (
		<div className={classes.root}>
			<Stepper activeStep={activeStep} alternativeLabel>
				{steps.map((label) => (
					<Step key={label}>
						<StepLabel>{label}</StepLabel>
					</Step>
				))}
			</Stepper>
			<div>
				{/* {activeStep === steps.length ? (
					<div>
						<Typography className={classes.instructions}>
							All steps completed
						</Typography>
						<Button onClick={handleReset}>Reset</Button>
					</div>
				) : ( */}
				{/* <Slide direction="fade" in={animate} mountOnEnter unmountOnExit> */}
				<div className={`transition-container ${transitionName}`}>
					<Typography
						className={`${classes.instructions} ${
							transitionName === "fade" ? classes.fade : ""
						}`}
					>
						{getStepContent(activeStep)}
					</Typography>
					{/* <div>
							<Button
								disabled={activeStep === 0}
								onClick={handleBack}
								className={classes.backButton}
							>
								Back
							</Button>
							<Button variant="contained" color="primary" onClick={handleNext}>
								{activeStep === steps.length - 1 ? "Finish" : "Next"}
							</Button>
						</div> */}
				</div>
				{/* </Slide> */}

				{/* )} */}
			</div>
		</div>
	);
};

export default PhoneCallStepper;
