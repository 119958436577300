import React, { useRef } from "react";
import { useState, useEffect } from "react";
import TableComponent from "../../../../../components/table";
import { Get, Post, DownloadFile } from "../../../../../actions";
import {
  Get_GetAllTenantFilesWithPagination_URL,
  Post_DeleteTenantFile_URL,
} from "../../../../../constants/apiUrls";
import { useParams } from "react-router";
import { getTranslation } from "../../../../../heplers/translationHelper";
import {
  DeleteButton,
  DownloadButton,
  EditButton,
} from "../../../../../components/ButttonsWithIcons";
import { useSnackbar } from "notistack";
import AlertDialog from "../../../../../components/DeleteDialog";
import { Typography } from "@material-ui/core";

const columns = [
  {
    id: "fileName",
    label: getTranslation("Name", "Name", "Name"),
  },
  {
    id: "description",
    label: getTranslation("Description", "Description", "Description"),
  },
  {
    id: "actions",
    label: getTranslation("Actions", "Actions", "Actions"),
    align: "right",
  },
];

export const DisplayTenantDocs = ({ refereshState, onEdit }) => {
  const { tenantId } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const [tenantDocs, setTenantDocs] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState();
  const [page, setPage] = useState();
  const [searchString, setSearchString] = useState();
  const [deleteDocument, setDeleteDocument] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [referesh, setReferesh] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    TenantFilesWithPagination();
  }, [referesh, refereshState, rowsPerPage, page, searchString]);

  const handleChangeRowsPerPage = (event) => {
    localStorage.setItem("documentsSize", parseInt(event.target.value));
    setRowsPerPage(localStorage.getItem("documentsSize"));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSearchDocuments = (searchString) => {
    setSearchString(searchString);
  };

  const TenantFilesWithPagination = () => {
    setLoading(true);
    Get(
      {
        tenantId: tenantId,
        pageNumber: page ? page : 0,
        pageSize: rowsPerPage ? rowsPerPage : 10,
        searchString: searchString ? searchString : "",
        sortDirection: 1,
        sort: "fileName",
      },
      Get_GetAllTenantFilesWithPagination_URL,
      null,
      (res) => {
        setTenantDocs(res?.data);
        setLoading(false);
      },
      (err) => {
        enqueueSnackbar(
          err
            ? err.data
            : "An error occurred while loading tenant files. Please contact with admin"
        );
        setLoading(false);
      }
    );
  };

  const DeleteTenantFile = (documentId) => {
    Post(
      { tenantId: tenantId, id: documentId },
      Post_DeleteTenantFile_URL,
      null,
      (res) => {
        enqueueSnackbar(res ? res.data : "File Deleted Successfully", {
          variant: "success",
        });
        setDeleting(false);
        setIsOpen(false);
        setReferesh(true);
      },
      (err) => {
        enqueueSnackbar(
          err
            ? err.data
            : "An error occurred while processing your request. Please contact with admin",
          { variant: "error" }
        );
        setDeleting(false);
        setIsOpen(false);
      }
    );
    setReferesh(false);
  };
  return (
    <>
      <TableComponent
        loading={loading}
        colums={columns}
        dense
        rows={tenantDocs?.data}
        rowsPerPage={rowsPerPage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        page={page}
        handleChangePage={handleChangePage}
        count={tenantDocs?.totalCount}
        viewSearch
        onSearch={(searchString) => handleSearchDocuments(searchString)}
        actions={[
          {
            component: (row) => (
              <EditButton
                onClick={() => {
                  onEdit(row);
                }}
              />
            ),
          },
          {
            component: (row) => (
              <DeleteButton
                onClick={() => {
                  setDeleteDocument(row);
                  setIsOpen(true);
                }}
              />
            ),
          },
          {
            component: (row) => (
              <DownloadButton
                onClick={() => {
                  DownloadFile(row?.id);
                }}
              />
            ),
          },
        ]}
      />
      <AlertDialog
        open={isOpen}
        deleting={deleting}
        onClose={() => setIsOpen(false)}
        onSubmit={() => {
          setDeleting(true);
          DeleteTenantFile(deleteDocument?.id);
        }}
      />
    </>
  );
};
