import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@material-ui/core";
import { getTranslation } from "../../../../../heplers/translationHelper";

export const BuildingInformation = ({ buildingInfo }) => {
  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            {getTranslation(
              "Building Information",
              "Building Information",
              "Building Information"
            )}
          </Typography>
        }
      />
      <CardContent>
        <Grid
          container
          spacing={2}
          style={{
            marginLeft: "5%",
            marginTop: "-1.5%",
          }}
        >
          {/* /////////////////// Headers ///////////////////// */}
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <Box display="flex">
              <Typography variant="h5" style={{ marginRight: "5px" }}>
                {getTranslation("Name:", "Name:", "Name:")}
              </Typography>
              <Typography variant="h5" style={{ fontWeight: "bold" }}>
                {buildingInfo?.name}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={2}>
            <Box display="flex">
              <Typography variant="h5" style={{ marginRight: "5px" }}>
                {getTranslation("Town:", "Town:", "Town:")}
              </Typography>
              <Typography variant="h5" style={{ fontWeight: "bold" }}>
                {buildingInfo?.town}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={3} xl={2}>
            <Box display="flex">
              <Typography variant="h5" style={{ marginRight: "5px" }}>
                {getTranslation("Zipcode:", "Zipcode:", "Zipcode:")}
              </Typography>
              <Typography variant="h5" style={{ fontWeight: "bold" }}>
                {buildingInfo?.zipCode}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={5}
            style={{ marginRight: "10px" }}
          >
            <Box display="flex">
              <Typography variant="h5" style={{ marginRight: "5px" }}>
                {getTranslation("Address:", "Address:", "Address:")}
              </Typography>
              <Typography variant="h5" style={{ fontWeight: "bold" }}>
                {buildingInfo?.address}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
