import React from "react";
import Upload from "../assests/icons/uploadIcon.png";
import Add from "../assests/icons/addIcon.png";
import Close from "../assests/icons/closeIcon.png";
import Scanner from "../assests/icons/scannerIcon.png";
import Person from "../assests/icons/PersonIcon.png";
import supplierIcon from "../assests/icons/supplierIcon.svg";
import GenerateDueIconSvg from "../assests/icons/generateDues.svg";
import PreviousDueIconSvg from "../assests/icons/previousDues.svg";
import Home from "../assests/icons/home.png";
import Done from "../assests/icons/done.png";
import Wallet from "../assests/icons/wallet.png";
import GenerateReportIcon from "../assests/icons/generateReportIcon.svg";
import ReportAllIconSVG from "../assests/icons/ReportIcon.png";
import ReportSingleIconSVG from "../assests/icons/reportSingleIcon.svg";
import SendRemindersToAll from "../assests/icons/sendRemindersToAll.png";
import bankSVG from "../assests/icons/Tenantbank.svg";
import settingsSubmenuIcon from "../assests/icons/settings.svg";
import activateReservedContractSVG from "../assests/icons/activateReservedContract.svg";
import newContractSVG from "../assests/icons/newContract.svg";
import endContractSVG from "../assests/icons/endContract.svg";
import commentIcon from "../assests/icons/comments-icons.png";
import postponIcon from "../assests/icons/postpon.png";
import Logo_English_Language from "../assests/icons/english.png";
import Logo_French_Language from "../assests/icons/french.png";
import Logo_German_Language from "../assests/icons/german.png";
import List from "../assests/icons/List.png";
import Contract from "../assests/icons/TenantContract.svg";
import Notes from "../assests/icons/tenantNotes.svg";
import Summary from "../assests/icons/tenantSummary.svg";
import PersonList from "../assests/icons/TenantList.svg";
import IndexationIcons from "../assests/icons/indexationSubMenuIcon.svg";
import { Tooltip } from "@material-ui/core";
import { getTranslation } from "../heplers/translationHelper";
import DashboradIcon from "../assests/icons/dashboard.png";
import BuildingIcon from "../assests/icons/building.png";
import TenantsIcon from "../assests/icons/tenant 2.png";
import DuesIcon from "../assests/icons/dues.png";
import BanksIcon from "../assests/icons/bank 2.png";
import SuppliersIcon from "../assests/icons/supplier.png";
import SettingsIcon from "../assests/icons/newsetting.png";
import RecoveryProcedureIcon from "../assests/icons/recoveryProcedure.svg";
import TenantDetails from "../assests/icons/tenantDetails.svg";

export const UploadIcon = (props) => {
  return <img alt="Logo" src={Upload} {...props} />;
};

export const IconAdd = (props) => {
  return <img alt="Logo" src={Add} {...props} />;
};
export const HomeIcon = (props) => {
  return (
    <img
      alt="Logo"
      src={Home}
      {...props}
      style={{ height: "15px", width: "15px" }}
    />
  );
};
export const DoneIcon = (props) => {
  return (
    <img
      alt="Logo"
      src={Done}
      {...props}
      style={{ height: "15px", width: "15px" }}
    />
  );
};
export const WalletIcon = (props) => {
  return (
    <img
      alt="Logo"
      src={Wallet}
      {...props}
      style={{ height: "15px", width: "15px" }}
    />
  );
};

export const GenerateReport = (props) => {
  return (
    <img
      alt="Icon"
      src={GenerateReportIcon}
      {...props}
      style={{ width: "22px", height: "22px" }}
    />
  );
};

export const CloseIcon = (props) => {
  return <img alt="close" src={Close} {...props} />;
};

export const IconScanner = (props) => {
  return (
    <img
      alt="close"
      src={Scanner}
      {...props}
      style={{ height: "40px", width: "40px" }}
    />
  );
};

export const PersonIcon = (props) => {
  return <img alt="PersonIcon" src={Person} {...props} />;
};

export const SupplierIcon = (props) => {
  return <img alt="TruckIcon" src={supplierIcon} {...props} />;
};

export const GenerateDueIcon = (props) => {
  return (
    <Tooltip
      title={getTranslation(
        "Generated Dues",
        "Generated Dues",
        "Generated Dues"
      )}
    >
      <img
        alt="TruckIcon"
        style={{ height: "30px", width: "30px" }}
        src={GenerateDueIconSvg}
        {...props}
      />
    </Tooltip>
  );
};

export const CommentsIcon = (props) => {
  return <img alt="Comments icon" src={commentIcon} {...props} />;
};

export const PostponIcon = (props) => {
  return <img alt="Postpone icon" src={postponIcon} {...props} />;
};

export const PreviousDueIcon = (props) => {
  return (
    <Tooltip
      title={getTranslation("Previous Dues", "Previous Dues", "Previous Dues")}
    >
      <img
        alt="TruckIcon"
        style={{ height: "30px", width: "30px" }}
        src={PreviousDueIconSvg}
        {...props}
      />
    </Tooltip>
  );
};

export const IndexationIcon = (props) => {
  return (
    <Tooltip title={getTranslation("Indexation", "Indexation", "Indexation")}>
      <img
        alt="indexation"
        src={IndexationIcons}
        {...props}
        style={{ width: "30px", height: "30px" }}
      />
    </Tooltip>
  );
};

export const ReportAllIcon = (props) => {
  return <img alt="Logo" src={ReportAllIconSVG} {...props} />;
};
export const ReportSingleIcon = (props) => {
  return <img alt="Logo" src={ReportSingleIconSVG} {...props} />;
};
export const ReminderToAll = (props) => {
  return;
  <img
    alt="Logo"
    src={SendRemindersToAll}
    {...props}
    style={{ width: "160px", height: "40px" }}
  />;
};

export const TenantBankIcon = (props) => {
  return (
    <Tooltip title={getTranslation("Bank", "Bank", "Bank")}>
      <img
        alt="Logo"
        src={bankSVG}
        width="30"
        height="30"
        // style={{ margin: "-1px" }}
        {...props}
      />
    </Tooltip>
  );
};

export const BankIcon = (props) => {
  return (
    <Tooltip
      title={getTranslation("Bank Settings", "Bank Settings", "Bank Settings")}
    >
      <img
        alt="Logo"
        src={bankSVG}
        width="32"
        height="32"
        style={{ margin: "-2px" }}
        {...props}
      />
    </Tooltip>
  );
};

export const TenantDetailsIcon = (props) => {
  return (
    <Tooltip
      title={getTranslation(
        "Tenant Details",
        "Tenant Details",
        "Tenant Details"
      )}
    >
      <img alt="logo" src={TenantDetails} width="30" height="30" {...props} />
    </Tooltip>
  );
};

export const EmailIcon = (props) => {
  return (
    <Tooltip
      title={getTranslation(
        "Reminder Settings",
        "Reminder Settings",
        "Reminder Settings"
      )}
    >
      <img
        alt="Logo"
        src={settingsSubmenuIcon}
        width="28px"
        height="28px"
        {...props}
      />
    </Tooltip>
  );
};

export const ActivateReservedIcon = (props) => {
  return <img alt="Logo" src={activateReservedContractSVG} {...props} />;
};

export const StartNewContractIcon = (props) => {
  return <img alt="Logo" src={newContractSVG} {...props} />;
};

export const EndContractIcon = (props) => {
  return <img alt="Logo" src={endContractSVG} {...props} />;
};

export const EnglishLanguageIcon = () => (
  <img
    title="English"
    alt="English"
    src={Logo_English_Language}
    width="20"
    height="20"
  />
);
export const FrenchLanguageIcon = () => (
  <img alt="French" src={Logo_French_Language} width="20" height="20" />
);
export const GermanLanguageIcon = () => (
  <img alt="German" src={Logo_German_Language} width="20" height="20" />
);

export const ListIcon = () => (
  <img alt="listicon" src={List} width="20" height="20" />
);

export const ContractIcon = () => (
  <Tooltip title={getTranslation("Contracts", "Contracts", "Contracts")}>
    <img
      alt="Contract Icon"
      src={Contract}
      width="30"
      height="30"
      // style={{ margin: "-1.5px" }}
    />
  </Tooltip>
);

export const NotesIcon = () => (
  <Tooltip title={getTranslation("Notes", "Notes", "Notes")}>
    <img alt="noteicon" src={Notes} width="30" height="30" />
  </Tooltip>
);

export const SummaryIcon = () => (
  <Tooltip title={getTranslation("Summary", "Summary", "Summary")}>
    <img alt="summryicon" src={Summary} width="30" height="30" />
  </Tooltip>
);

export const PersonListIcon = () => (
  <Tooltip title={getTranslation("Tenants", "Tenants", "Tenants")}>
    <img
      alt="personicon"
      src={PersonList}
      width="29"
      height="29"
      style={{ margin: "-2px" }}
    />
  </Tooltip>
);

export const TenantRecoveryProcedure = () => (
  <Tooltip
    title={getTranslation(
      "Recovery Procedure",
      "Recovery Procedure",
      "Recovery Procedure"
    )}
  >
    <img alt="personicon" src={RecoveryProcedureIcon} width="25" height="25" />
  </Tooltip>
);

export const DashboardIcon = () => (
  <img
    alt="personicon"
    src={DashboradIcon}
    width="28"
    height="28"
    style={{ marginRight: "10px" }}
  />
);

export const NewBuildingIcon = () => (
  <img
    alt="personicon"
    src={BuildingIcon}
    width="28"
    height="28"
    style={{ marginRight: "10px" }}
  />
);

export const NewTenantIcon = () => (
  <img
    alt="personicon"
    src={TenantsIcon}
    width="28"
    height="28"
    style={{ marginRight: "10px" }}
  />
);

export const NewDuesIcon = () => (
  <img
    alt="personicon"
    src={DuesIcon}
    width="28"
    height="28"
    style={{ marginRight: "10px" }}
  />
);

export const NewBankIcon = () => (
  <img
    alt="personicon"
    src={BanksIcon}
    width="28"
    height="28"
    style={{ marginRight: "10px" }}
  />
);

export const NewSupplierIcon = () => (
  <img
    alt="personicon"
    src={SuppliersIcon}
    width="28"
    height="28"
    style={{ marginRight: "10px" }}
  />
);

export const NewSettingIcon = () => (
  <img
    alt="personicon"
    src={SettingsIcon}
    width="28"
    height="28"
    style={{ marginRight: "10px" }}
  />
);
