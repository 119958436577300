import React, { useRef } from "react";
import { Formik, Form, Field, useFormikContext } from "formik";
import { useState, useEffect } from "react";
import { useParams } from "react-router";
import { useSnackbar } from "notistack";
import { Get, Post } from "../../../../../actions";
import { SiAddthis } from "react-icons/si";
import { GrAdd } from "react-icons/gr";
import {
  EditButton,
  DeleteButton,
} from "../../../../../components/ButttonsWithIcons";
import { AddBox, HelpOutline } from "@material-ui/icons";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  TextField,
  Typography,
  Box,
  MenuItem,
  Button,
  IconButton,
  Checkbox,
  FormControl,
  FormControlLabel,
  Tooltip,
} from "@material-ui/core";
import { getTranslation } from "../../../../../heplers/translationHelper";
import ButtonWithLoading from "../../../../../components/ButtonWithLoading";
import * as Yup from "yup";
import Table from "../../../../../components/table";
import AlertDialog from "../../../../../components/DeleteDialog";
import { format } from "../../../../../heplers/format";
import { DatePicker } from "../../../../../components/DatePicker";
import Dialog from "../../../../../components/Dialog";
import { Autocomplete } from "@material-ui/lab";

const Owner = ({
  owners,
  onSubmit,
  onUpdateOwner,
  onDeleteOwner,
  ownerDeleting,
  onUpdateBuildingOwner,
  isOpen,
  handleDeleteDialog,
  getOwners,
  onCreate,
  openOwnerDialog,
  onClose,
  allOwners,
  selectedValue,
  addBuildingOwner,
  isLoading,
  buildingPercentage,
}) => {
  const [editOwner, setEditOwner] = useState();
  // const [selectedOwner, setSelectedOwner] = useState("");
  const [deleteOwner, setDeleteOwner] = useState();
  const [rowsPerPage, setRowsPerPage] = useState();
  const [page, setPage] = useState();
  const [searchString, setSearchString] = useState();
  const [disabled, setDisabled] = useState(false);
  const [editOwnership, setEditOwnership] = useState();
  const id = useParams();
  const propertyPercentageRef = useRef(null);

  useEffect(() => {
    getOwners(rowsPerPage, page, searchString);
  }, [rowsPerPage, page, searchString]);
  const handleSearchOwner = (searchString) => {
    setSearchString(searchString);
  };
  const handleEditOwner = (owner) => {
    setEditOwner(owner);
    onCreate();
  };
  const handleChangeRowsPerPage = (event) => {
    localStorage.setItem("sizeOwners", parseInt(event.target.value));
    setRowsPerPage(localStorage.getItem("sizeOwners"));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleEditOwnerShip = (rowData) => {
    setEditOwnership(rowData);
    propertyPercentageRef?.current?.focus();
  };
  const columns = [
    {
      id: "fullName",
      label: "Full Name",
      component: ({ row }) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography>{row.fullName}</Typography>{" "}
            <EditButton onClick={() => handleEditOwner(row)} />
          </div>
        );
      },
    },
    { id: "email", label: "Email" },
    { id: "phone", label: "Phone Number" },
    {
      id: "ownerType",
      label: "Type",
      component: ({ row }) => {
        return (
          <Typography>
            {row?.isAgent == true && row?.isSteward == true ? (
              "Agent , Steward"
            ) : row?.isAgent == true ? (
              "Agent"
            ) : row?.isSteward == true ? (
              "Steward"
            ) : (
              <Typography align="center">-</Typography>
            )}
          </Typography>
        );
      },
    },
    {
      id: "propertyPercentage",
      label: "Property Percentage (%)",
      format: "number",
      align: "right",
      component: (row) => {
        return (
          <Typography>
            {format("number", row?.row?.propertyPercentage)}
          </Typography>
        );
      },
    },
    { id: "company", label: "Company" },
    { id: "actions", label: "Actions", align: "right" },
  ];

  const ValidationSchemaOwner = Yup.object().shape({
    firstName: Yup.string().required(
      getTranslation(
        "First Name is required",
        "First Name is required",
        "First Name is required"
      )
    ),
    lastName: Yup.string()
      .test(
        "no-numbers",
        getTranslation(
          "Name should not contain numbers",
          "Name should not contain numbers",
          "Name should not contain numbers"
        ),
        (value) => !/\d/.test(value)
      )
      .required(
        getTranslation(
          "Last Name is required",
          "Last Name is required",
          "Last Name is required"
        )
      ),
    email: Yup.string()
      .email(getTranslation("Invalid Email", "Invalid Email", "Invalid Email"))
      .required(
        getTranslation(
          "Email is required",
          "Email is required",
          "Email is required"
        )
      ),
    phone: Yup.string().matches(/^(\+\d{1,3})?\d*$/, {
      message: getTranslation(
        "Invalid Phone number",
        "Invalid Phone number",
        "Invalid Phone number"
      ),
      excludeEmptyString: true,
    }),
    address: Yup.string().required(
      getTranslation(
        "Address is required",
        "Address is required",
        "Address is required"
      )
    ),
    city: Yup.string().required(
      getTranslation("City is required", "City is required", "City is required")
    ),
    zipCode: Yup.string()
      .matches(
        /^\d+$/,
        getTranslation(
          "ZIP code should only contain digits",
          "ZIP code should only contain digits",
          "ZIP code should only contain digits"
        )
      )
      .required(
        getTranslation(
          "Zip Code is required",
          "Zip Code is required",
          "Zip Code is required"
        )
      ),
  });

  const initialValuesOwner = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "+",
    birthDate: new Date(),
    birthTown: "",
    address: "",
    city: "",
    zipCode: "",
    company: "",
    vatNumber: "",
    isAgent: false,
    isSteward: false,
  };
  const initialValuesBuildingOwner = {
    selectedBuildingOwner: editOwnership ? editOwnership : "",
    propertyPercentage: editOwnership ? editOwnership.propertyPercentage : "",
  };
  const validationSchemaBuildingOwner = Yup.object().shape({
    selectedBuildingOwner: Yup.object()
      .nullable()
      .required(
        getTranslation(
          "Building owner is required",
          "Building owner is required",
          "Building owner is required"
        )
      ),
    propertyPercentage: Yup.string()
      .nullable()
      .required(() => {
        if (buildingPercentage?.remainingPercentage === 0) {
          return getTranslation(
            "Remaining shares are 0",
            "Remaining shares are 0",
            "Remaining shares are 0"
          );
        }
        return getTranslation(
          "Property percentage is required",
          "Property percentage is required",
          "Property percentage is required"
        );
      }),
  });
  const defaultValues = editOwner ? editOwner : initialValuesOwner;

  return (
    <>
      <div>
        <Formik
          enableReinitialize
          initialValues={initialValuesBuildingOwner}
          validationSchema={validationSchemaBuildingOwner}
          onSubmit={(values, actions) => {
            values.buildingId = id?.buildingId;

            const {
              buildingId,
              propertyPercentage,
              selectedBuildingOwner,
              ...restValues
            } = values;
            const {
              propertyPercentage: selectedBuildingOwnerPropertyPercentage,
              buildingId: selectedBuildingOwnerBuildingId,
              ...newSelectedBuildingOwner
            } = selectedBuildingOwner;
            const modifiedValues = {
              ...restValues,
              buildingId,
              propertyPercentage,
              selectedBuildingOwner: newSelectedBuildingOwner,
            };

            {
              editOwnership
                ? onUpdateBuildingOwner(modifiedValues)
                : addBuildingOwner(values);
            }
            actions.setSubmitting(false);
            actions.resetForm();
            setEditOwnership("");
            setDisabled(false);
          }}
        >
          {({
            isSubmitting,
            getFieldProps,
            setFieldValue,
            setFieldTouched,
            setFieldError,
            handleBlur,
            values,
            errors,
            touched,
          }) => (
            <Form>
              <Box p={2}>
                <Grid container spacing={2}>
                  <Grid item xs={10} sm={9} md={3} lg={3}>
                    <Field name="selectedBuildingOwner">
                      {({ field }) => (
                        <Autocomplete
                          {...field}
                          options={allOwners ? allOwners : []}
                          getOptionLabel={(option) =>
                            option ? option.fullName : ""
                          }
                          value={values.selectedBuildingOwner}
                          disabled={editOwnership}
                          onChange={(event, value) => {
                            setFieldValue("selectedBuildingOwner", value);
                            if (value) {
                              setDisabled(true);
                            } else {
                              setDisabled(false);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={getTranslation(
                                "Select Owner",
                                "Select Owner",
                                "Select Owner"
                              )}
                              variant="outlined"
                              size="small"
                              fullWidth
                              error={
                                touched.selectedBuildingOwner &&
                                Boolean(errors.selectedBuildingOwner)
                              }
                              helperText={
                                touched.selectedBuildingOwner &&
                                errors.selectedBuildingOwner
                              }
                            />
                          )}
                        />
                      )}
                    </Field>
                  </Grid>
                  {!editOwnership && (
                    <Grid item xs={2} sm={3} md={2} lg={2}>
                      <Tooltip
                        title={getTranslation(
                          "Add new owner",
                          "Add new owner",
                          "Add new owner"
                        )}
                        arrow
                        placement="bottom-mid"
                      >
                        <ButtonWithLoading
                          disabled={disabled}
                          variant="contained"
                          title={getTranslation("Add", "Add", "Add")}
                          style={
                            !disabled
                              ? {
                                  marginLeft: "10px",
                                  backgroundColor: "rgb(229,60,22)",
                                  color: "white",
                                  fontWeight: "bold",
                                }
                              : { color: "gray" }
                          }
                          onClick={() => onCreate()}
                        />
                      </Tooltip>
                    </Grid>
                  )}

                  <Grid item xs={8} sm={6} md={3} lg={3}>
                    <Field name="propertyPercentage">
                      {({ field }) => (
                        <TextField
                          inputRef={propertyPercentageRef}
                          {...field}
                          fullWidth
                          type="number"
                          value={values.propertyPercentage}
                          id="propertyPercentage"
                          name="propertyPercentage"
                          variant="outlined"
                          size="small"
                          label={getTranslation(
                            "Property Percentage %",
                            "Property Percentage %",
                            "Property Percentage %"
                          )}
                          {...getFieldProps("propertyPercentage")}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            min: "1",
                          }}
                          helperText={errors.propertyPercentage}
                          error={Boolean(errors.propertyPercentage)}
                          onChange={(e) => {
                            if (editOwnership) {
                              if (
                                e.target.value >
                                buildingPercentage?.remainingPercentage +
                                  editOwnership?.propertyPercentage
                              ) {
                                setFieldError(
                                  "propertyPercentage",
                                  getTranslation(
                                    "Value should not be greater than",
                                    "Value should not be greater than",
                                    "Value should not be greater than"
                                  ) +
                                    " " +
                                    (buildingPercentage?.remainingPercentage +
                                      editOwnership?.propertyPercentage)
                                );
                              } else {
                                setFieldValue(
                                  "propertyPercentage",
                                  e.target.value
                                );
                              }
                            }
                            if (!editOwnership) {
                              if (
                                e.target.value >
                                buildingPercentage?.remainingPercentage
                              ) {
                                setFieldError(
                                  "propertyPercentage",
                                  getTranslation(
                                    "Value should not be greater than",
                                    "Value should not be greater than",
                                    "Value should not be greater than"
                                  ) +
                                    " " +
                                    buildingPercentage?.remainingPercentage
                                );
                              } else
                                setFieldValue(
                                  "propertyPercentage",
                                  e.target.value
                                );
                            }
                          }}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <ButtonWithLoading
                      loading={isLoading}
                      variant="contained"
                      title={
                        editOwnership
                          ? getTranslation("Update", "Update", "Update")
                          : getTranslation("Submit", "Submit", "Submit")
                      }
                      style={{
                        marginLeft: "10px",
                        backgroundColor: "rgb(229,60,22)",
                        color: "white",
                        fontWeight: "bold",
                      }}
                      type="submit"
                    />
                  </Grid>
                </Grid>
              </Box>
            </Form>
          )}
        </Formik>
        <Dialog
          open={openOwnerDialog}
          title={
            editOwner
              ? getTranslation("Edit Owner", "Edit Owner", "Edit Owner")
              : getTranslation("Add Owner", "Add Owner", "Add Owner")
          }
          onClose={() => {
            onClose();
            setEditOwner(null);
          }}
          fullWidth
        >
          <Formik
            enableReinitialize
            initialValues={defaultValues}
            validationSchema={ValidationSchemaOwner}
            onSubmit={(values, { resetForm }, actions) => {
              if (editOwner) {
                onUpdateOwner(values);
              } else {
                onSubmit(values);
              }
              setEditOwner(null);
              // setSelectedOwner(null);
              resetForm();
            }}
          >
            {({
              errors,
              touched,
              values,
              handleSubmit,
              getFieldProps,
              setFieldValue,
              handleBlur,
            }) => (
              <Form>
                <Box style={{ display: "flex", justifyContent: "center" }}>
                  <Grid container spacing={2}>
                    <Grid item xl={6} lg={6} sm={6} xs={12}>
                      <br />
                      <TextField
                        fullWidth
                        id="firstName"
                        name="firstName"
                        label={getTranslation(
                          "First Name",
                          "First Name",
                          "First Name"
                        )}
                        variant="outlined"
                        required
                        size="small"
                        {...getFieldProps("firstName")}
                        error={touched.firstName && Boolean(errors.firstName)}
                        helperText={touched.firstName && errors.firstName}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xl={6} lg={6} sm={6} xs={12}>
                      <br />
                      <TextField
                        fullWidth
                        id="lastName"
                        name="lastName"
                        label={getTranslation(
                          "Last Name",
                          "Last Name",
                          "Last Name"
                        )}
                        variant="outlined"
                        required
                        size="small"
                        {...getFieldProps("lastName")}
                        error={touched.lastName && Boolean(errors.lastName)}
                        helperText={touched.lastName && errors.lastName}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xl={6} lg={6} sm={6} xs={12}>
                      <TextField
                        fullWidth
                        id="email"
                        name="email"
                        label={getTranslation("Email", "Email", "Email")}
                        variant="outlined"
                        required
                        size="small"
                        {...getFieldProps("email")}
                        error={touched.email && Boolean(errors.email)}
                        helperText={touched.email && errors.email}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xl={6} lg={6} sm={6} xs={12}>
                      <TextField
                        fullWidth
                        id="phone"
                        name="phone"
                        label={getTranslation(
                          "Phone Number",
                          "Phone Number",
                          "Phone Number"
                        )}
                        value={values.phone}
                        onChange={(e) => {
                          let newValue = e.target.value;
                          if (!newValue.startsWith("+")) {
                            newValue = "+" + newValue;
                            setFieldValue("phone", newValue);
                          } else setFieldValue("phone", newValue);
                        }}
                        variant="outlined"
                        required
                        size="small"
                        onBlur={handleBlur}
                        error={touched.phone && Boolean(errors.phone)}
                        helperText={touched.phone && errors.phone}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xl={6} lg={6} sm={6} xs={12}>
                      <DatePicker
                        fullWidth
                        id="birthDate"
                        name="birthDate"
                        label={getTranslation(
                          "Birth Date",
                          "Birth Date",
                          "Birth Date"
                        )}
                        value={values?.birthDate}
                        onChange={(newDate) => {
                          setFieldValue("birthDate", newDate);
                        }}
                        onBlur={handleBlur}
                        size="small"
                        error={touched.birthDate && Boolean(errors.birthDate)}
                        helperText={touched.birthDate && errors.birthDate}
                        maxDate={new Date()}
                      />
                    </Grid>
                    <Grid item xl={6} lg={6} sm={6} xs={12}>
                      <TextField
                        fullWidth
                        id="birthTown"
                        name="birthTown"
                        label={getTranslation(
                          "Town Birth",
                          "Town Birth",
                          "Town Birth"
                        )}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        {...getFieldProps("birthTown")}
                        size="small"
                      />
                    </Grid>
                    <Grid item xl={6} lg={6} sm={6} xs={12}>
                      <TextField
                        fullWidth
                        id="address"
                        name="address"
                        label={getTranslation("Address", "Address", "Address")}
                        variant="outlined"
                        required
                        size="small"
                        {...getFieldProps("address")}
                        error={touched.address && Boolean(errors.address)}
                        helperText={touched.address && errors.address}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xl={6} lg={6} sm={6} xs={12}>
                      <TextField
                        fullWidth
                        id="city"
                        name="city"
                        label={getTranslation("City", "City", "City")}
                        required
                        size="small"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        {...getFieldProps("city")}
                        error={touched.city && Boolean(errors.city)}
                        helperText={touched.city && errors.city}
                      />
                    </Grid>
                    <Grid item xl={6} lg={6} sm={6} xs={12}>
                      <TextField
                        fullWidth
                        id="zipCode"
                        name="zipCode"
                        label={getTranslation(
                          "Zip Code",
                          "Zip Code",
                          "Zip Code"
                        )}
                        variant="outlined"
                        required
                        size="small"
                        {...getFieldProps("zipCode")}
                        error={touched.zipCode && Boolean(errors.zipCode)}
                        helperText={touched.zipCode && errors.zipCode}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xl={6} lg={6} sm={6} xs={12}>
                      <TextField
                        fullWidth
                        id="company"
                        name="company"
                        label={getTranslation("Company", "Company", "Company")}
                        variant="outlined"
                        {...getFieldProps("company")}
                        error={touched.company && Boolean(errors.company)}
                        helperText={touched.company && errors.company}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        size="small"
                      />
                    </Grid>
                    <Grid item xl={6} lg={6} sm={6} xs={12}>
                      <TextField
                        fullWidth
                        id="vatNumber"
                        name="vatNumber"
                        label={getTranslation(
                          "VAT Number",
                          "VAT Number",
                          "VAT Number"
                        )}
                        variant="outlined"
                        size="small"
                        {...getFieldProps("vatNumber")}
                        error={touched.vatNumber && Boolean(errors.vatNumber)}
                        helperText={touched.vatNumber && errors.vatNumber}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    {/* <Grid
                    item
                    container
                    xl={6}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    style={{ backgroundColor: "red" }}
                  > */}
                    <FormControl style={{ width: "58%", marginLeft: "5px" }}>
                      <Grid
                        item
                        container
                        sm={12}
                        md={12}
                        lg={12}
                        style={{
                          direction: "row",
                          justify: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Grid item md={6} lg={6}>
                          <FormControlLabel
                            label={getTranslation("Agent", "Agent", "Agent")}
                            //labelPlacement="start"
                            control={
                              <Checkbox
                                id="isAgent"
                                checked={values?.isAgent}
                                onChange={(e, check) => {
                                  setFieldValue("isAgent", check);
                                }}
                                style={{ color: "rgb(229,60,22)" }}
                              />
                            }
                          />
                        </Grid>
                        <Grid item md={6} lg={6}>
                          <FormControlLabel
                            label={getTranslation(
                              "Steward",
                              "Steward",
                              "Steward"
                            )}
                            control={
                              <Checkbox
                                id="isSteward"
                                checked={values?.isSteward}
                                onChange={(e, check) => {
                                  setFieldValue("isSteward", check);
                                }}
                                style={{ color: "rgb(229,60,22)" }}
                              />
                            }
                          />
                        </Grid>
                      </Grid>
                    </FormControl>
                    <Grid item container lg={12}>
                      <ButtonWithLoading
                        loading={isLoading}
                        variant="contained"
                        title={
                          editOwnership
                            ? getTranslation("Update", "Update", "Update")
                            : getTranslation("Submit", "Submit", "Submit")
                        }
                        style={{
                          //   marginLeft: "10px",
                          backgroundColor: "rgb(229,60,22)",
                          color: "white",
                          fontWeight: "bold",
                          margin: "auto",
                        }}
                        type="submit"
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Form>
            )}
          </Formik>
        </Dialog>
        <Table
          dense
          rows={owners?.data}
          colums={columns}
          rowsPerPage={rowsPerPage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleChangePage={handleChangePage}
          page={page}
          count={owners?.totalCount}
          viewSearch
          onSearch={(searchString) => handleSearchOwner(searchString)}
          viewDelete
          onDelete={(row) => {
            setDeleteOwner(row);
            handleDeleteDialog(true);
          }}
          actions={[
            {
              component: (rowData) => (
                <EditButton
                  onClick={() => {
                    handleEditOwnerShip(rowData);
                    //formDivRef.current.focus();
                  }}
                />
              ),
            },
          ]}
        />
        <AlertDialog
          open={isOpen}
          deleting={ownerDeleting}
          onClose={() => handleDeleteDialog(false)}
          onSubmit={() => onDeleteOwner(deleteOwner?.id)}
        />
      </div>
    </>
  );
};

export default Owner;
