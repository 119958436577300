import React, { useEffect, useState } from "react";
import {
	Container,
	CssBaseline,
	Typography,
	TextField,
	Box,
	Grid,
	Select,
	FormControl,
	MenuItem,
	InputLabel,
	IconButton,
	useMediaQuery,
	Divider,
} from "@material-ui/core";
import { Formik } from "formik";
import { Link, withRouter, useLocation } from "react-router-dom";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import useStyles from "../../assests/styles/views/auth/login";
import Copyright from "../../utils/copyRightUtils";
import { TransparentLogo } from "../../components/Logo";
import ButtonWithLoading from "../../components/ButtonWithLoading";
import {
	userLogin,
	getCustomerApps,
	Post,
	verifyInternalAppLogin,
} from "../../actions";
import checkResponse from "../../heplers/responseHelper";
import { useSnackbar } from "notistack";
import authUtils from "../../utils/authUtils";
import authService from "../../utils/authUtils";
import { useParams } from "react-router";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getTranslation } from "../../heplers/translationHelper";
import { ArrowBack } from "@material-ui/icons";
import { Helmet } from "react-helmet";
import LoginNavBar from "./NavBar";
import signupBackgroundImage from "../../assests/icons/signupbackground.png";
import signupBackgroundInsideImage from "../../assests/icons/signupinsideimage.png";
// import signUpsideImage from "../../assests/icons/signupsideimage.png";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import FacebookLogin from "react-facebook-login";
import appmanagerApi from "../../heplers/appManagerApiHelper";
import LoginFooter from "./Footer";

const Login = ({ history }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const [customerAppsLoading, setCustomerAppsLoading] = useState(false);
	const params = useParams();
	const [showStep, setShowStep] = useState(false);
	const [isLoginInfoLoaded, setIsLoginInfoLoaded] = useState(false);
	const isMobileTab = useMediaQuery((theme) => theme.breakpoints.down("sm"));

	useEffect(() => {
		if (localStorage.getItem("lang") === null) {
			localStorage.setItem("lang", "fr");
			window.location.reload();
		}
		const token = params.token;
		if (token) {
			verifyInternalAppLogin(
				{ token: token },
				(res) => {
					getCustomerApps(
						{
							email: res.data.appUser.email,
							appId: 1,
						},
						(resp) => {
							if (resp?.length === 0) {
								enqueueSnackbar(
									getTranslation(
										"No customer apps exists",
										"No customer apps exists",
										"No customer apps exists"
									),
									{
										variant: "error",
									}
								);
								setCustomerAppsLoading(false);
								// actions.setSubmitting(false);
							} else {
								try {
									if (
										authUtils.getCustomerId() != null &&
										resp.findIndex(
											(x) => x.customerId == authUtils.getCustomerId()
										) >= 0
									) {
										res.data.customerId = authUtils.getCustomerId();
									} else {
										res.data.customerId = resp[0].customerId;
									}
									authUtils.setUser(res.data.appUser);
									authUtils.setSession(res.data.accessToken);
									authUtils.setUserRefreshToken(
										res.data.refreshToken,
										res.data.refreshTokenExpire
									);
									authUtils.setCustomerId(res.data.customerId);
									// const org=customerApps.find(x=>x.customerId==values.customerId)
									authUtils.setCustomerOrganizations(resp);
									const selectedOrg = resp.find(
										(x) => x.customerId == res.data.customerId
									);

									authUtils.setIsStarter(selectedOrg.isStarter);

									history.push("/");
									// actions.setSubmitting(false);
								} catch (e) {}
							}
						},
						(error) => {
							setCustomerAppsLoading(false);
							enqueueSnackbar(error.data, {
								variant: "error",
							});
						}
					);
				},
				(error) => {
					setIsLoginInfoLoaded(true);
					enqueueSnackbar(
						getTranslation("Invalid Token", "Invalid Token", "Invalid Token"),
						{
							variant: "error",
						}
					);
				}
			);
		} else if (
			authService.isAuthenticated() ||
			authService.isRefreshAuthenticated()
		) {
			history.push("/");
		} else {
			setIsLoginInfoLoaded(true);
		}
	}, []);

	let schema = Yup.string().email("Invalid");

	function onExternalLogin(provider, params) {
		appmanagerApi()
			.post("Auth/ExternalLogin", {
				provider: provider,
				accessToken: params.accessToken,
				appId: 1,
			})
			.then((res) => {
				getCustomerApps(
					{
						email: res.data.user.email,
						appId: 1,
					},
					(resp) => {
						if (resp?.length === 0) {
							enqueueSnackbar(
								getTranslation(
									"No customer apps exists",
									"No customer apps exists",
									"No customer apps exists"
								),
								{
									variant: "error",
								}
							);
						} else {
							try {
								if (
									authUtils.getCustomerId() != null &&
									resp.findIndex(
										(x) => x.customerId == authUtils.getCustomerId()
									) >= 0
								) {
									res.data.customerId = authUtils.getCustomerId();
								} else {
									res.data.customerId = resp[0].customerId;
								}
								authUtils.setUser(res.data.user);
								authUtils.setSession(res.data.accessToken);
								authUtils.setUserRefreshToken(
									res.data.refreshToken,
									res.data.refreshTokenExpire
								);
								authUtils.setCustomerId(res.data.customerId);
								authUtils.setCustomerOrganizations(resp);
								const selectedOrg = resp.find(
									(x) => x.customerId == res.data.customerId
								);
								authUtils.setIsStarter(selectedOrg.isStarter);
								history.push("/");
							} catch (e) {}
						}
					},
					(error) => {
						enqueueSnackbar(error.data, {
							variant: "error",
						});
					}
				);
			})
			.catch((error) => {
				// enqueueSnackbar("")
			});
	}
	return (
		//<div style={{ width: "99%" }}>
		<>
			<Helmet>
				<title>{getTranslation("Login", "Login", "Login")}</title>
			</Helmet>
			<LoginNavBar />
			<Grid
				container
				// spacing={2}
				style={{ height: "100vh" }}
			>
				<Grid
					sm={12}
					lg={6}
					md={6}
					style={{
						// backgroundColor: "blue",
						marginTop: "64px",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<Box
						// mt={20}
						display="flex"
						alignItems="center"
						justifyContent="center"
						margin="0px 10% "
					>
						{" "}
						{/* <Container component="main" maxWidth="xs"> */}
						{/* <CssBaseline /> */}
						{isLoginInfoLoaded ? (
							<div className={classes.paper}>
								<Typography
									className={classes.heading}
									component="h1"
									variant="h4"
								>
									{getTranslation("Login", "Login", "Login")}!
								</Typography>
								{showStep == true ? (
									<div>
										<Box display="flex" justifyContent="flex-start">
											<IconButton onClick={() => setShowStep(false)}>
												<ArrowBack />
											</IconButton>
										</Box>
									</div>
								) : null}
								<Formik
									initialValues={{
										email: "",
										password: "",
										organizationName: "",
										appId: 1,
									}}
									validationSchema={Yup.object().shape({
										email: Yup.string().email(
											getTranslation(
												"Please enter a valid Email",
												"Please enter a valid Email",
												"Please enter a valid Email"
											)
										),
										password: Yup.string().required(
											getTranslation(
												"Password is required",
												"Password is required",
												"Password is required"
											)
										),
									})}
									onSubmit={(values, actions) => {
										actions.setSubmitting(true);
										getCustomerApps(
											values,
											(resp) => {
												if (resp?.length === 0) {
													enqueueSnackbar(
														getTranslation(
															"No customer apps exists",
															"No customer apps exists",
															"No customer apps exists"
														),
														{
															variant: "error",
														}
													);
													setCustomerAppsLoading(false);
													actions.setSubmitting(false);
												} else {
													try {
														if (
															authUtils.getCustomerId() != null &&
															resp.findIndex(
																(x) => x.customerId == authUtils.getCustomerId()
															) >= 0
														) {
															values.customerId = authUtils.getCustomerId();
														} else {
															values.customerId = resp[0].customerId;
														}
														dispatch(userLogin(values)).then((res) => {
															const errors = checkResponse(res);
															if (!errors) {
																authUtils.setUser(res.data.appUser);
																authUtils.setSession(res.data.accessToken);
																authUtils.setUserRefreshToken(
																	res.data.refreshToken,
																	res.data.refreshTokenExpire
																);
																authUtils.setCustomerId(values.customerId);
																authUtils.setCustomerOrganizations(resp);
																const selectedOrg = resp.find(
																	(x) => x.customerId == values.customerId
																);

																authUtils.setIsStarter(selectedOrg.isStarter);

																history.push("/dashboard");
																actions.setSubmitting(false);
															} else {
																actions.setSubmitting(false);
																errors.forEach((error) => {
																	enqueueSnackbar(error.message, {
																		variant: "error",
																	});
																});
															}
														});
													} catch (e) {}
												}
											},
											(error) => {
												setCustomerAppsLoading(false);
												enqueueSnackbar(error.data, {
													variant: "error",
												});
											}
										);
									}}
								>
									{({
										values,
										errors,
										touched,
										handleChange,
										handleSubmit,
										validateForm,
										setFieldValue,
										isSubmitting,
									}) => (
										<form className={classes.form}>
											<>
												<TextField
													style={{
														boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.2)",
														borderRadius: "5px",
														height: "40px",
														marginBottom:
															touched.email && errors.email ? "20px" : "8px",
													}}
													variant="outlined"
													margin="normal"
													required
													fullWidth
													id="email"
													label={getTranslation(
														"Email Address",
														"Email Address",
														"Email Address"
													)}
													name="email"
													autoComplete="email"
													autoFocus
													size="small"
													value={values.email}
													onChange={handleChange}
													error={touched.email && Boolean(errors.email)}
													helperText={
														touched.email && errors.email ? (
															<span
																style={{
																	boxShadow: "none",
																}}
															>
																{errors.email}
															</span>
														) : null
													}
												/>
												<TextField
													style={{
														boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.2)",
														borderRadius: "5px",
														height: "40px",
													}}
													variant="outlined"
													margin="normal"
													required
													fullWidth
													size="small"
													name="password"
													label={getTranslation(
														"Password",
														"Mot de passe",
														"Kennwort"
													)}
													type="password"
													id="password"
													value={values.password}
													onChange={handleChange}
													// helperText={touched.password && errors.password}
													error={touched.password && Boolean(errors.password)}
													helperText={
														touched.password && errors.password ? (
															<span
																style={{
																	boxShadow: "none",
																}}
															>
																{errors.password}
															</span>
														) : null
													}
												/>

												<ButtonWithLoading
													color="primary"
													type="submit"
													title={getTranslation("Login", "Login", "Login")}
													fullWidth
													variant="contained"
													loading={isSubmitting}
													onClick={handleSubmit}
													className={classes.submit}
												/>
												<Box
													mt={1}
													width="100%"
													display="flex"
													alignItems="center"
													justifyContent="center"
												>
													<Link
														to={`/registration/1/${process.env.REACT_APP_PACKAGE_ID}`}
														variant="h6"
														className={classes.link}
													>
														{getTranslation(
															"Don't have an account?",
															"Don't have an account?",
															"Don't have an account?"
														)}
														<span style={{ fontWeight: "bold" }}>Sign Up</span>
													</Link>
												</Box>
											</>
										</form>
									)}
								</Formik>

								{/* <Box
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "30px",
                marginBottom: "25px",
              }}
            >
              <Divider style={{ flex: 1 }} />
              <p style={{ margin: "0 5px" }}>
                {getTranslation(
                  "or Login with",
                  "or Login with",
                  "or Login with"
                )}
              </p>
              <Divider style={{ flex: 1 }} />
            </Box>

            <Box
              mt={1}
              width="110%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              // style={{ backgroundColor: "red" }}
            >
              <FacebookLogin
                appId="652403710332409"
                locale={navigator.language}
                callback={(params) =>
                  onExternalLogin("Facebook", params)
                }
                size="small"
                icon="fa-facebook"
                buttonStyle={{
                  textTransform: "capitalize",
                  borderRadius: "3px",
                  fontSize: "14px",
                  background: "rgb(8,120,255)",
                  border: "none",
                  marginRight: "10px",
                  height: "35px",
                  width: "200px",
                }}
                textButton="sign in with facebook"
              ></FacebookLogin>

              <GoogleOAuthProvider clientId="855687747582-df46p0efc8cdcq0ns207otn7b24p1rf2.apps.googleusercontent.com">
                <GoogleLogin
                  size="large"
                  locale={navigator.language}
                  onSuccess={(params) =>
                    onExternalLogin("Google", {
                      accessToken: params.credential,
                    })
                  }
                  onError={(params) => {
                  }}
                ></GoogleLogin>
              </GoogleOAuthProvider>
            </Box> */}
							</div>
						) : (
							<Grid
								container
								spacing={0}
								direction="column"
								alignItems="center"
								justifyContent="center"
								//style={{ minHeight: "100vh" }}
							>
								<CircularProgress />
							</Grid>
						)}
						{/* </Container> */}
					</Box>
				</Grid>
				{isMobileTab ? (
					""
				) : (
					<Grid
						sm={12}
						lg={6}
						md={6}
						xl={6}
						style={{
							// backgroundColor: "yellow",
							padding: "20px 20px 20px 0px",
							marginTop: "64px",
						}}
					>
						<Box
							display="flex"
							flexDirection="column"
							justifyContent="center"
							style={{
								background: `url(${signupBackgroundImage})`,
								height: "100%",
								borderRadius: "20px",
							}}
						>
							<img
								src={signupBackgroundInsideImage}
								style={{
									width: "80%",
									marginLeft: "auto",
									marginRight: "auto",
									marginBottom: "20px",
								}}
							/>
							<Typography variant="h2" className={classes.backgroundText}>
								{getTranslation(
									"The easiest way to manage",
									"The easiest way to manage",
									"The easiest way to manage"
								)}
							</Typography>
							<Typography
								variant="h2"
								className={classes.backgroundText}
								style={{ marginBottom: "10px" }}
							>
								{getTranslation(
									"your information",
									"your information",
									"your information"
								)}
							</Typography>
							<Typography
								variant="p"
								style={{
									color: "rgb(163,157,159)",
									marginLeft: "auto",
									marginRight: "auto",
								}}
							>
								{getTranslation(
									"Join our community now!",
									"Join our community now!",
									"Join our community now!"
								)}
							</Typography>
						</Box>
					</Grid>
				)}
			</Grid>
		</>
		// {/* <LoginFooter /> */}
		// </div>
	);
};

export default withRouter(Login);
