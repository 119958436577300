import React, { useState, useEffect } from "react";
import {
	Box,
	Card,
	CardContent,
	CardHeader,
	Grid,
	IconButton,
	TextField,
	Tooltip,
	Typography,
} from "@material-ui/core";
import { getTranslation } from "../../../../../../heplers/translationHelper";
import ButtonWithLoading from "../../../../../../components/ButtonWithLoading";
import { DownloadFile, Get, Post } from "../../../../../../actions";
import {
	Get_AllFundActivitiesWithPagination_URL,
	Get_AllTanentsWithPagination_URL,
	Get_NotesWithPagination_URL,
	Get_Tanenet_URL,
	Get_TenantDuesSummary_URL,
	Post_AddNote_URL,
	Post_InsertNotes_URL,
	Post_PoneRecoveryProcedure_URL,
	Post_PostPoneDue_URL,
	Post_StartRecoveryProcedure_URL,
	GET_GetLatestFundActivity_URL,
} from "../../../../../../constants/apiUrls";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";

function ClosingStep({
	callCenterComment,
	setCallCenterComment,
	postNotesCommment,
	handleAddPostpone,
	handleSubmitPostpone,
	postPonePayload,
	recoveryPayload,
	recoveryBtn,
	postponeBtn,
	setAnimate,
	setActiveStep,
	setPostPonePayload,
	setRecoveryPayload,
	setIsOpenPostPone,
}) {
	const [isLoading, setIsLoading] = useState(false);
	const { enqueueSnackbar } = useSnackbar();
	const [showComponent, setShowComponent] = useState(false);
	const history = useHistory();

	useEffect(() => {
		setShowComponent(true);
		setAnimate(true);
	}, []);

	const handleSave = () => {
		setIsLoading(true);
		if (recoveryBtn) {
			setIsLoading(true);
			Post(
				recoveryPayload,
				Post_StartRecoveryProcedure_URL,
				null,
				(resp) => {
					enqueueSnackbar(
						getTranslation(
							"Recovery Procedure Added successfully ",
							"Recovery Procedure Added successfully ",
							"Recovery Procedure Added successfully "
						),
						{
							variant: "success",
						}
					);
					setIsLoading(false);
					postNotesCommment();
					setTimeout(() => {
						window.location.href = "/public/thankyou";
					}, 2000);
					//setStartRecoveryDialog(false);
				},
				(err) => {
					enqueueSnackbar(
						err?.data
							? err?.data
							: getTranslation(
									"Something went wrong. Please try again.",
									"Something went wrong. Please try again.",
									"Something went wrong. Please try again."
							  ),
						{
							variant: "error",
						}
					);
					setIsLoading(false);
				}
			);
			return;
		}

		if (postponeBtn) {
			setIsLoading(true);
			Post(
				postPonePayload,
				Post_PostPoneDue_URL,
				null,
				(resp) => {
					// setAddPostponDueDate(false);
					enqueueSnackbar(
						getTranslation(
							"Postpone Date Added successfully",
							"Postpone Date Added successfully ",
							"Postpone Date Added successfully "
						),
						{
							variant: "success",
						}
					);
					postNotesCommment();
					setIsLoading(false);
					setTimeout(() => {
						window.location.href = "/public/thankyou";
					}, 2000);
					// setIsOpenPostPone(false);
					// setRefreshNote(true);
				},
				(err) => {
					console.log(err);
					setIsLoading(false);
					enqueueSnackbar(
						err?.data
							? err?.data
							: getTranslation(
									"Something went wrong. Please try again.",
									"Something went wrong. Please try again.",
									"Something went wrong. Please try again."
							  ),
						{
							variant: "error",
						}
					);
				}
			);
			return;
		}
		postNotesCommment();
		setIsLoading(true);
		setTimeout(() => {
			window.location.href = "/public/thankyou";
		}, 2000);
	};
	return (
		<Card>
			<CardContent>
				<div
					style={{
						opacity: showComponent ? 1 : 0,
						transition: "opacity 0.9s ease-in-out",
					}}
				>
					<Box
						style={{
							display: "flex",
							justifyContent: "space-around",
							marginTop: "20px",
						}}
					>
						<TextField
							style={{ marginTop: "20ox" }}
							fullWidth
							id="comments"
							label={getTranslation("Comment", "Comment", "Comment")}
							size="small"
							variant="outlined"
							multiline
							rows={5}
							name="comments"
							value={callCenterComment}
							onChange={(e) => {
								setCallCenterComment(e.target.value);
							}}
							//onBlur={postNotesCommment}
							InputLabelProps={{
								shrink: true,
							}}
						/>
					</Box>

					<div style={{ display: "flex", justifyContent: "center" }}>
						<ButtonWithLoading
							title={getTranslation("Back", "Back", "Back")}
							size="medium"
							variant="contained"
							type="submit"
							//loading={isLoading}
							style={{
								backgroundColor: "rgb(229,60,22)",
								color: "white",
								marginTop: "10px",
								marginRight: "10px",
							}}
							onClick={() => {
								if (recoveryBtn || postponeBtn) {
									setActiveStep(1);
									setRecoveryPayload();
									setPostPonePayload();
									setIsOpenPostPone(false);
								} else setActiveStep(0);
							}}
						/>{" "}
						<ButtonWithLoading
							title={getTranslation(
								"Confirm and Close",
								"Confirm and Close",
								"Confirm and Close"
							)}
							size="medium"
							variant="contained"
							type="submit"
							loading={isLoading}
							style={{
								backgroundColor: "rgb(229,60,22)",
								color: "white",
								marginTop: "10px",
							}}
							onClick={handleSave}
						/>{" "}
					</div>
				</div>
			</CardContent>
		</Card>
	);
}

export default ClosingStep;
