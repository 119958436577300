import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { GetPackageInfo, getUserData } from "../actions";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useCookies } from "react-cookie";
import { Grid } from "@material-ui/core";
import { useSnackbar } from "notistack";
import authService from "../utils/authUtils";
import { SET_PACKAGEINFO_DATA, SET_USER_DATA } from "../constants/types";

const Auth = (props) => {
	const { enqueueSnackbar } = useSnackbar();
	const dispatch = useDispatch();
	const history = useHistory();
	const [loading, setLoading] = useState(true);

	const useStyles = makeStyles((theme) => ({
		root: {
			display: "flex",
			"& > * + *": {
				marginLeft: theme.spacing(2),
			},
		},
	}));
	const classes = useStyles();
	const initAuth = async () => {
		if (authService.isAuthenticated() || authService.isRefreshAuthenticated()) {
			dispatch(
				getUserData(
					(resp) => {
						GetPackageInfo()
							.then((packageResp) => {
								dispatch({
									type: SET_PACKAGEINFO_DATA,
									payload: packageResp.data,
								});
							})
							.catch((error) => {});
						dispatch({ type: SET_USER_DATA, payload: resp.data });
					},
					(error) => {
						enqueueSnackbar(error.data, { variant: "error" });
					}
				)
			);
		} else {
			if (window.location.pathname.includes("market"))
				history.push("/register");
			else history.push("/login");
		}

		setLoading(false);
	};

	useEffect(() => {
		initAuth();
	}, []);
	if (loading)
		return (
			<Grid
				container
				spacing={0}
				direction="column"
				alignItems="center"
				justify="center"
				style={{ minHeight: "100vh" }}
			>
				<CircularProgress />
			</Grid>
		);
	return props.children;
};

export default Auth;
