import React, { useEffect, useRef, useState } from "react";

import {
	Typography,
	Grid,
	Card,
	CardContent,
	CardHeader,
	Box,
	ButtonGroup,
	Button,
	Dialog,
	TextField,
} from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { ClassicTextEditor } from "../../../components/TextEditor";
import ButtonWithLoading from "../../../components/ButtonWithLoading";
import { Get, Post } from "../../../actions";
import {
	Get_ReminderTemplate_URL,
	Get_SendManualReminders_URL,
	Post_SendReminderEmailToTenant_URL,
	Post_TenantEmailPostMessage_URL,
} from "../../../constants/apiUrls";
import { useSnackbar } from "notistack";
import { getTranslation } from "../../../heplers/translationHelper";
import DialogComponent from "../../../components/Dialog";
import { HtmlEditor } from "../../../components/HtmlEditor";
import { buttonStyles } from "./recoveryprocedure/components/recoveryComponents/RecoveryPersons";
import TextEditor from "../../setting/component/TextEditorCard";
import TestSmsDialoge from "../../setting/component/TestingDialoge";

export const ReminderTemplateDialog = ({
	sendManually,
	reminderToTenant,
	isOpen,
	onClose,
	sendByPost,
	onSendByPost,
}) => {
	const { enqueueSnackbar } = useSnackbar();
	//const [reminderTemplateText, setReminderTempateText] = useState();
	const [enableReloading, setEnableReloading] = useState(false);
	const [reminderKey, setReminderKey] = useState(
		sendByPost ? "FirstPostReminder" : "FirstReminder"
	);
	const [template, setTemplate] = useState();
	const [subject, setSubject] = useState("");
	const editorRef = useRef(null);
	const [submitReminderLoading, setSubmitReminderLoading] = useState(false);
	const [selectedLanguage, setSelectedLanguage] = useState("english");
	const [testSmsDialoge, setSmsDialoge] = useState(false);
	const [check, setCheck] = useState("");
	const [editorValue, setEditorValue] = useState("");
	const [loading, setLoading] = useState(false);
	const getTemplateBasedOnLanguage = (template, language) => {
		switch (language) {
			case "english":
				setCheck(template?.template);
				setTemplate(template?.template);
			// return template?.template;
			case "french":
				setCheck(template?.templateFrench);
				setTemplate(template?.templateFrench);
			// return template?.templateFrench;
			case "german":
				setCheck(template?.templateGerman);
				setTemplate(template?.templateGerman);
			//return template?.templateGerman;
			default:
				setTemplate(template?.template);
			// return '';
		}
	};

	const handleLanguage = (language) => {
		setSelectedLanguage(language);
	};

	const openTestSmsDialoge = () => {
		setSmsDialoge(true);
	};

	const classes = buttonStyles();

	const SendMenualReminder = (text) => {
		setLoading(true);
		setSubmitReminderLoading(true);
		if (sendByPost && text) {
			Post(
				{
					tenantId: reminderToTenant?.id,
					templateText: text,
				},
				Post_TenantEmailPostMessage_URL,
				null,
				(resp) => {
					setLoading(false);
					onSendByPost(resp?.data);
					setSubmitReminderLoading(false);
					// enqueueSnackbar("Post Reminder sent Successfully", {
					//   variant: "success",
					// });
				},
				(error) => {
					enqueueSnackbar(error?.data, { variant: "error" });
					setSubmitReminderLoading(false);
					setLoading(false);
				}
			);
			return;
		} else if (!sendByPost) {
			Post(
				{
					tenantId: reminderToTenant?.id,
					templateText: text,
					subject: subject,
				},
				sendManually
					? Post_SendReminderEmailToTenant_URL
					: Get_SendManualReminders_URL,
				null,
				(resp) => {
					enqueueSnackbar("Reminders sent", { variant: "success" });
					setSubmitReminderLoading(false);
					onClose(false);
				},
				(error) => {
					enqueueSnackbar(error?.data, { variant: "error" });
					setSubmitReminderLoading(false);
					onClose(false);
				}
			);
		} else {
			enqueueSnackbar("Please write some message to send.", {
				variant: "info",
			});
			setSubmitReminderLoading(false);
		}
	};

	useEffect(() => {
		GetReminderTemplate(reminderKey);
	}, [reminderKey, selectedLanguage]);

	const GetReminderTemplate = (reminderKey) => {
		setLoading(true);
		Get(
			{ reminderKey: reminderKey },
			Get_ReminderTemplate_URL,
			null,
			(resp) => {
				// setReminderTempateText(resp?.data?.template);
				// setTemplate(resp?.data?.template != null ? resp?.data?.templateFrench : " ");
				// setSubject( resp?.data?.subject);

				let templateToSet = "";
				if (selectedLanguage === "english") {
					templateToSet = resp?.data?.template;
				} else if (selectedLanguage === "french") {
					templateToSet = resp?.data?.templateFrench;
				} else if (selectedLanguage === "german") {
					templateToSet = resp?.data?.templateGerman;
				}
				setTemplate(templateToSet || " ");
				setSubject(resp?.data?.subject);
				setLoading(false);
			},
			(error) => {
				enqueueSnackbar(error.data, { variant: "error" });
				setLoading(false);
			}
		);
	};

	const AppendTemplate = (template, text) => {
		editorRef.current.insertContent(text);
	};

	return (
		<>
			<DialogComponent
				open={isOpen}
				onClose={onClose}
				maxWidth
				title={
					sendByPost
						? getTranslation("Post Reminder", "Post Reminder", "Post Reminder")
						: getTranslation(
								"Email Reminder",
								"Email Rappel",
								"Email Erinnerung"
						  )
				}
			>
				<ToggleButtonGroup
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						marginBottom: "15px",
					}}
					size="small"
					id="reminderKey"
					value={reminderKey}
					exclusive
					onChange={(event, reminderkey) => {
						if (reminderkey == null) {
							setReminderKey(
								sendByPost ? "FirstPostReminder" : "FirstReminder"
							);
						} else {
							setReminderKey(reminderkey);
						}
					}}
				>
					<ToggleButton
						value={sendByPost ? "FirstPostReminder" : "FirstReminder"}
						aria-label="left aligned"
					>
						<Typography variant="h6">
							{getTranslation("1st Reminder", "1er rappel", "Erste Erinnerung")}
						</Typography>
					</ToggleButton>
					<ToggleButton
						value={sendByPost ? "SecondPostReminder" : "SecondReminder"}
						aria-label="left aligned"
					>
						<Typography variant="h6">
							{getTranslation(
								"2nd Reminder",
								"2ème rappel",
								"Zweite Erinnerung"
							)}
						</Typography>
					</ToggleButton>
					<ToggleButton
						value={sendByPost ? "ThirdPostReminder" : "ThirdReminder"}
						aria-label="centered"
					>
						<Typography variant="h6">
							{getTranslation(
								"3rd Reminder",
								"3ème rappel",
								"Dritte Erinnerung"
							)}
						</Typography>
					</ToggleButton>
				</ToggleButtonGroup>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={12} md={3} lg={3}>
						<TextField
							fullWidth
							variant="outlined"
							required
							id="subject"
							label={getTranslation("Subject", "Matière", "Thema")}
							name="subject"
							size="small"
							value={subject}
							onChange={(e) => {
								setSubject(e.target.value);
							}}
							InputLabelProps={{
								shrink: true,
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={12} lg={12}>
						<Typography variant="h5">
							{getTranslation(
								"Reminder Text",
								"Texte de rappel",
								"Erinnerungstext"
							)}
						</Typography>
					</Grid>
				</Grid>

				{/* dynamic Buttons */}
				<div
					style={{
						display: "flex",
						justifyContent: "left",
						flexWrap: "wrap",
					}}
				>
					<Button
						className={classes.root}
						size="small"
						onClick={() =>
							AppendTemplate(template, "<span>[tenant_name]</span>")
						}
					>
						{getTranslation("Name", "Name", "Name")}
					</Button>

					<Button
						size="small"
						className={classes.root}
						onClick={() =>
							AppendTemplate(template, "<span>[tenant_email]</span>")
						}
					>
						{getTranslation("Email", "Email", "Email")}
					</Button>

					<Button
						size="small"
						className={classes.root}
						onClick={() =>
							AppendTemplate(template, "<span>[tenant_reference]</span>")
						}
					>
						{getTranslation("Reference", "Reference", "Reference")}
					</Button>

					<Button
						size="small"
						className={classes.root}
						onClick={() => AppendTemplate(template, "<span>[due_Date]</span>")}
					>
						{getTranslation("Due Date", "Date d'échéance", "Geburtstermin")}
					</Button>

					<Button
						size="small"
						className={classes.root}
						onClick={() =>
							AppendTemplate(template, "<span>[tenant_appartment]</span>")
						}
					>
						{getTranslation("Apartment", "Logement", "Wohnungen")}
					</Button>

					<Button
						size="small"
						className={classes.root}
						onClick={() => AppendTemplate(template, "<span>[town]</span>")}
					>
						{getTranslation("Town", "Town", "Town")}
					</Button>

					<Button
						size="small"
						className={classes.root}
						onClick={() => AppendTemplate(template, "<span>[zip_code]</span>")}
					>
						{getTranslation("Zip Code", "Zip Code", "Zip Code")}
					</Button>

					<Button
						size="small"
						className={classes.root}
						onClick={() =>
							AppendTemplate(template, "<span>[current_date]</span>")
						}
					>
						{getTranslation("Current Date", "Current Date", "Current Date")}
					</Button>

					<Button
						size="small"
						className={classes.root}
						onClick={() =>
							AppendTemplate(template, "<span>[due_Amount]</span>")
						}
					>
						{getTranslation("Due Amount", "Montant dû", "Geburtstermin")}
					</Button>

					<Button
						size="small"
						className={classes.root}
						onClick={() =>
							AppendTemplate(template, "<span>[appartment_address]</span>")
						}
					>
						{getTranslation(
							"Apartment Address",
							"Apartment Address",
							"Apartment Address"
						)}
					</Button>
				</div>

				<TextEditor
					translatedText={
						reminderKey === "FirstReminder" ||
						reminderKey == "FirstPostReminder"
							? getTranslation("1st Reminder", "1er Rappel", "1. Erinnerung")
							: reminderKey === "SecondReminder" ||
							  reminderKey == "SecondPostReminder"
							? getTranslation("2nd Reminder", "2ème Rappel", "2nd Erinnerung")
							: getTranslation("3rd Reminder", "3rd Rappel", "3rd Erinnerung")
					}
					selectedLanguage={selectedLanguage}
					reminderKey={reminderKey}
					openTestSmsDialoge={openTestSmsDialoge}
					handleLanguage={handleLanguage}
					// title="Send Test Email"
				>
					<CardContent>
						<HtmlEditor
							ref={editorRef}
							onChange={(text) => SendMenualReminder(text)}
							text={template}
							isLoading={false}
							submitReminderLoading={submitReminderLoading}
							isSubmitting={loading}
							buttonText={getTranslation("Send", "Send", "Send")}
						/>

						<br />
					</CardContent>
				</TextEditor>
				{/* <TestSmsDialoge
					template={template}
					title="Send Test Email"
					isOpen={testSmsDialoge}
					onClose={() => setSmsDialoge(false)}
					editor={editorValue}
				/> */}
			</DialogComponent>
		</>
	);
};
