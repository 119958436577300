import React from "react";
import ButtonWithLoading from "../../../../../../components/ButtonWithLoading";
import { UploadFiles } from "../../../../../../components/Upload";
import { Box, Grid, TextField, Typography } from "@material-ui/core";
import { getTranslation } from "../../../../../../heplers/translationHelper";
import { useState } from "react";
import { Post } from "../../../../../../actions";
import { Post_InsertNotes_URL } from "../../../../../../constants/apiUrls";
import { useSnackbar } from "notistack";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { DatePicker } from "../../../../../../components/DatePicker";

const AddNoteDialog = ({
	setAddNoteDialog,
	noteStatus,
	tenantId,
	recoveryProcedureId,
	setRefresh,
}) => {
	const [fileAddress, setFileAddress] = useState();
	const [noteLoading, setNoteLoading] = useState(false);
	const { enqueueSnackbar } = useSnackbar();

	const PostNotes = (payload) => {
		setNoteLoading(true);
		Post(
			payload,
			Post_InsertNotes_URL,
			null,
			(resp) => {
				enqueueSnackbar("Notes added successfully", { variant: "success" });
				setNoteLoading(false);
				setAddNoteDialog(false);
				setRefresh(true);
			},
			(err) => {
				enqueueSnackbar(
					err?.data
						? err?.data
						: getTranslation(
								"Something went wrong. Please try again.",
								"Something went wrong. Please try again.",
								"Something went wrong. Please try again."
						  ),
					{
						variant: "error",
					}
				);
				setNoteLoading(false);
			}
		);
	};

	const initialValues = {
		tenantId: tenantId ? tenantId : "",
		recoveryProcedureId: recoveryProcedureId ? recoveryProcedureId : null,
		comments: "",
		document: "",
		type: noteStatus == "recoveryprocedure" ? 0 : noteStatus == "all" ? 2 : 1,
		date: new Date(),
	};

	const basicValidationSchema = Yup.object().shape({});

	const defaultValue = initialValues;

	return (
		<>
			<Formik
				enableReinitialize
				initialValues={defaultValue}
				validationSchema={basicValidationSchema}
				onSubmit={(values, actions) => {
					actions.setSubmitting(true);
					PostNotes(values, actions);
					actions.setSubmitting(false);
				}}
			>
				{({
					values,
					setFieldValue,
					errors,
					touched,
					handleChange,
					handleSubmit,
					isSubmitting,
					getFieldProps,
				}) => (
					<Form>
						<Grid container style={{ marginTop: "10px" }}>
							<Grid item xs={12} sm={12} md={12} lg={12}>
								<DatePicker
									fullWidth
									variant="outlined"
									id="date"
									disabled={true}
									label={getTranslation("Date", "Date", "Date")}
									value={values.date}
									onChange={(date) => {
										setFieldValue("date", date);
									}}
									error={touched.date && Boolean(errors.date)}
								/>
							</Grid>

							<Grid
								item
								xs={12}
								sm={12}
								md={12}
								lg={12}
								style={{ marginTop: "20px" }}
							>
								<TextField
									fullWidth
									id="comments"
									label={getTranslation("Comment", "Comment", "Comment")}
									size="small"
									variant="outlined"
									multiline
									rows={5}
									name="comments"
									{...getFieldProps("comments")}
									error={touched.comments && Boolean(errors.comments)}
									helperText={touched.comments && errors.comments}
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</Grid>
							<Grid
								item
								xs={6}
								sm={6}
								md={6}
								lg={6}
								style={{ marginTop: "20px" }}
							>
								<UploadFiles
									uploadedFiles={values.document ? [values.document] : []}
									onAddFile={(file) => {
										setFieldValue("document", file);
									}}
									getFileName={(file) => file}
									onDeleteFile={() => {}}
									multiple={false}
									onClick={() => {}}
								/>
								<Typography variant="h5">
									{getTranslation(
										"Upload Attachment",
										"Upload Attachment",
										"Upload Attachment"
									)}
								</Typography>
							</Grid>

							<Box
								pr={1}
								pb={1}
								width="100%"
								display="flex"
								alignItems="center"
								justifyContent="center"
							>
								<ButtonWithLoading
									style={{
										marginTop: "10px",
										backgroundColor: "rgb(229,60,22)",
										color: "white",
									}}
									title={getTranslation("Add", "Add", "Add")}
									size="small"
									variant="contained"
									color="primary"
									loading={noteLoading}
									onClick={handleSubmit}
								/>
							</Box>
						</Grid>
					</Form>
				)}
			</Formik>
		</>
	);
};

export default AddNoteDialog;
