import Dialog from "../../../components/Dialog";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Get, Post } from "../../../actions/apiActions";
import {
  CardContent,
  CardActions,
  Grid,
  TextField,
  InputLabel,
  Box,
  Typography,
  MenuItem,
  FormControl,
  Checkbox,
} from "@material-ui/core";
import Select from "@material-ui/core/Select";

import * as Yup from "yup";
import { Form, Formik } from "formik";
import ButtonWithLoading from "../../../components/ButtonWithLoading";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  Get_AllBuildings_URL,
  Get_AllSuppliers_Url,
  Get_AllBanks_URL,
  Post_AddFundToSupplier_URL,
  Get_AttachedSupplierCategories_URL,
  Get_BankTransaction_URL,
} from "../../../constants/apiUrls";
import { getTranslation } from "../../../heplers/translationHelper";
import { Alert } from "@material-ui/lab";

export const AddFundsToSupplierDialog = ({
  counterpartName,
  remittanceinformation,
  company,
  enableEdit,
  amount,
  isOpen,
  clearLoading,
  editSupFunds,
  onClose,
  onSubmit,
  clearTransction
}) => {
  console.log("ajhsgjhasgds supplier",isOpen)
  const [bankId, setBankId] = useState();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [buildingList, setBuildingList] = useState([]);
  const [supplierList, setSupplierList] = useState([]);
  const [supplierCategories, setSupplierCategories] = useState([]);
  const [selectedSupplierCategory, setSelectedSupplierCategory] = useState(0);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [counterPartName, setCounterPartName] = useState();
  const [supplierAmount, setSupplierAmount] = useState();
  const [supplierRemittanceinformation, setSupplierRemittanceinformation] =
    useState();
  const [enableRecurring, setEnableRecurring] = useState(false);
  const [isCounterpartNameEnabled, setIsCounterpartNameEnabled] =
    useState(false);
  const [isAmountEnabled, setIsAmountEnabled] = useState(false);
  const [isRemittanceInformationEnabled, setIsRemittanceInformationEnabled] =
    useState(false);
    const [selectedBuilding, setSelectedBuilding] = useState(null);

  //////////////////////////////// Get Buildings ///////////////////
  useEffect(() => {
    loadBuilding();
  }, []);
  const loadBuilding = (values) => {
    Get(
      values,
      Get_AllBuildings_URL,
      history,
      (resp) => {
        setBuildingList(resp?.data);
      },
      (error) => {
        enqueueSnackbar(
          getTranslation(
            " Can't Load the building!! ",
            " Impossible de charger le b�timent�! ",
            " Das Geb�ude kann nicht geladen werden! "
          ),
          { variant: "error" }
        );
      }
    );
  };
  //////////////////////////////// Get Supplier ///////////////////
  useEffect(() => {
    loadSupplier();
  }, []);
  const loadSupplier = (values) => {
    Get(
      values,
      Get_AllSuppliers_Url,
      history,
      (resp) => {
        setSupplierList(resp?.data);
      },
      (error) => {
        enqueueSnackbar(
          getTranslation(
            " Can't load suppliers! ",
            " Impossible de charger les fournisseurs�! ",
            " Lieferanten k�nnen nicht geladen werden! "
          ),
          { variant: "error" }
        );
      }
    );
  };

  useEffect(() => {
    if (counterpartName) {
      var supplier = supplierList.find((x) =>
        x.company
          .toLowerCase()
          .startsWith(counterpartName.slice(0, 3).toLowerCase(), 0)
      );
      if (supplier) {
        setSelectedSupplier(supplier);
        //loadSupplierCategories(supplier?.id);
      } else {
        setSelectedSupplier(null);
        //loadSupplierCategories(supplier?.id);
      }
      setEnableRecurring(false);
    }
  }, [counterpartName]);

  useEffect(() => {
    setCounterPartName(counterpartName);
    setSupplierAmount(amount);
    setSupplierRemittanceinformation(remittanceinformation);
    setIsCounterpartNameEnabled(false);
    setIsAmountEnabled(false);
    setIsRemittanceInformationEnabled(false);
    if (enableRecurring) {
      setIsCounterpartNameEnabled(true);
      setIsAmountEnabled(true);
      setIsRemittanceInformationEnabled(true);
    }
  }, [counterpartName, enableRecurring]);

  // useEffect(() => {
  //   if (editSupFunds) loadSupplierCategories(editSupFunds.supplierId);
  // }, [editSupFunds]);

  const [loadingCategories, setLoadingCategories] = useState(false);

  const loadSupplierCategories = (id,supplierCategoryId) => {
   
    setLoadingCategories(true);
    Get(
      { supplierId: id },
      Get_AttachedSupplierCategories_URL,
      history,
      (resp) => {
        setSupplierCategories(resp?.data);
        if(supplierCategoryId && resp.data.length > 0)
          setSelectedSupplierCategory(resp?.data.find(x=>x.id==supplierCategoryId));
        else if (resp.data.length > 0){
          setSelectedSupplierCategory(resp?.data[0]?.id);}
        setLoadingCategories(false);
      },
      (error) => {
        setLoadingCategories(false);
        enqueueSnackbar(
          getTranslation(
            " Can't load supplier categories! ",
            " Impossible de charger les cat�gories de fournisseurs�! ",
            " Lieferantenkategorien k�nnen nicht geladen werden! "
          ),
          {
            variant: "error",
          }
        );
      }
    );
  };

  const [fundActivity,setFundActivity] =useState()
  useEffect(() => {
    if (editSupFunds?.supplierId) {
      setSelectedSupplier(
        supplierList.find((x) => x.id == editSupFunds?.supplierId)
      );
      Get(
        {
          bankTransactionId: editSupFunds?.id,
        },
        Get_BankTransaction_URL,
        history,
        (resp) => {
          if (resp?.data.length > 0) {
            setFundActivity(resp?.data[0])
            // setSelectedBuilding(
            //   buildingList.find((x) => x.id == resp?.data[0]?.buildingId)
            // );
            loadSupplierCategories(editSupFunds.supplierId,resp?.data[0]?.supplierCategoryId);

          }
        },
        (error) => {
          enqueueSnackbar(error.data, { variant: "error" });
        }
      );
    }
  }, [editSupFunds]);
  const initialValues = {
    supplierId: 0,
    buildingId: 0,
    supplier:{},
    building:{},
    supplierCategoryId: 0,
    IsMakeRecurringEnabled: false,
    supplierAutoMatchingOptions: {
      counterpartName: counterpartName,
      amount: amount,
      remittanceinformation: remittanceinformation,
      isCounterpartNameEnabled: false,
      isAmountEnabled: false,
      isRemittanceInformationEnabled: false,
    },
  };

  const basicValidationSchema = Yup.object().shape({
    supplierId: Yup.number()
      .min(1)
      .required(
        getTranslation(
          " An apartment number is required ",
          " Un num�ro d'appartement est requis ",
          " Eine Wohnungsnummer ist erforderlich "
        )
      ),
    buildingId: Yup.number()
      .min(1)
      .required(
        getTranslation(
          " Rent is required ",
          " Le loyer est obligatoire ",
          " Miete ist erforderlich "
        )
      ),
    // supplierCategoryId: Yup.number()
    //   .min(1)
    //   .required("Supplier Category is required"),
  });

  const defaultValue = editSupFunds ? {...editSupFunds,buildingId:fundActivity?.buildingId,supplierCategoryId:fundActivity?.supplierCategoryId,building:{},supplier:{}} : initialValues;

  const [errorMessage, setErrorMessage] = useState();

  return (
    <Dialog
      open={isOpen}
      title={
        enableEdit
          ? getTranslation(
              "Update funds of supplier",
              "Aktualisieren Sie die Mittel des Lieferanten",
              "Mettre à jour les fonds du fournisseur"
            )
          : getTranslation(
              "Add funds to supplier",
              "Ajouter des fonds au fournisseur",
              "Fügen Sie dem Lieferanten Geld hinzu"
            )
      }
      onClose={() => {
        setSupplierCategories([]);
        setSelectedSupplierCategory(0);
        onClose();
      }}
      maxWidth="sm"
    >
      <div>
        <Formik
          enableReinitialize
          initialValues={defaultValue}
          validationSchema={basicValidationSchema}
          onSubmit={(values, actions) => {
            actions.setSubmitting(true);
            if (selectedSupplierCategory == 0) {
              setErrorMessage("No Supplier category is selected");
              return;
            }
            if (values.supplierId == 0) {
              setErrorMessage("No Supplier is selected");
              return;
            }
            if (values.buildingId == 0) {
              setErrorMessage("No Building is selected");
              return;
            }
            //values.supplierCategoryId = selectedSupplierCategory;

            if (enableRecurring) {
              if (
                isAmountEnabled ||
                isCounterpartNameEnabled ||
                isRemittanceInformationEnabled
              ) {
                onSubmit(values, actions);
              } else {
                enqueueSnackbar("Select atleast one recurring option", {
                  variant: "error",
                });
                actions.setSubmitting(false);
              }
            } else onSubmit(values, actions);
          }}
        >
          {({
            errors,
            touched,
            values,
            handleSubmit,
            isSubmitting,
            getFieldProps,
            setFieldValue,
          }) => (
            <Form>
              {console.log("ajhksdgsajhkdgkjad",values.building)}
              {values?.supplier?.id && supplierList.length>0
                ? null :
                values?.supplierId && supplierList.length>0?
                setFieldValue("supplier", supplierList.find(x=>x.id==values.supplierId))
                : null}

                {values?.building?.id && buildingList.length>0
                ? null :
                values?.buildingId && buildingList.length>0?
                setFieldValue("building", buildingList.find(x=>x.id==values.buildingId))
                : null}

            
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Autocomplete
                      options={supplierList}
                      getOptionLabel={(option) => option.company}
                      value={values.supplier}
                      size="small"
                      onChange={(e, supplier) => {
                        setFieldValue("supplierId", supplier?.id);
                        setSelectedSupplier(supplier);
                        if (supplier) {
                          loadSupplierCategories(supplier?.id);
                          setFieldValue("supplier", supplier);
                        } else {
                          setFieldValue("supplier", null);
                          setFieldValue("supplierId", 0);
                          setSelectedSupplierCategory(0);
                          setSupplierCategories([]);
                          //setFieldValue("supplierCategoryId", 0);
                        }
                        //setSupplierCategories([]);
                      }}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          {...params}
                          label={getTranslation(
                            "Select Supplier",
                            "Selectionnez le fournisseur",
                            "Wehlen Sie Lieferant aus"
                          )}
                          variant="outlined"
                          error={
                            touched.supplierId && Boolean(errors.supplierId)
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Autocomplete
                      options={buildingList}
                      getOptionLabel={(option) => option.name}
                      value={values.building}
                      size="small"
                      
                      onChange={(e, building) => {
                        setFieldValue("building", building);
                        //setSelectedBuilding(building);
                        setFieldValue("buildingId", building?.id);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={getTranslation(
                            "Select Building",
                            "S�lectionnez le b�timent",
                            "W�hlen Sie Geb�ude aus"
                          )}
                          variant="outlined"
                          error={
                            touched.buildingId && Boolean(errors.buildingId)
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <FormControl fullWidth size="small" variant="outlined">
                      <InputLabel shrink htmlFor="outlined-age-native-simple">
                        {getTranslation(
                          "Select Supplier Category",
                          "Sélectionnez la catégorie de fournisseur",
                          "Wählen Sie Lieferantenkategorie aus"
                        )}
                      </InputLabel>
                      <Select
                        native
                        disabled={supplierCategories.length === 0}
                        options={supplierCategories}
                        value={values.supplierCategoryId}
                       
                        label={getTranslation(
                          "Select Supplier Category",
                          "Sélectionnez la catégorie de fournisseur",
                          "Wählen Sie Lieferantenkategorie aus"
                        )}
                        onChange={(e) => {
                          setSelectedSupplierCategory(e.target.value);
                          setFieldValue("supplierCategoryId", e.target.value);
                          setFieldValue("supplierCategory", null);
                        }}
                      >
                        {supplierCategories && supplierCategories.length > 0 ? (
                          supplierCategories.map((category, index) => (
                            <option value={category.id}>{category.name}</option>
                          ))
                        ) : (
                          <option value={0}>
                            {getTranslation("No Category Found")}
                          </option>
                        )}
                      </Select>
                      {errorMessage ? (
                        <Alert severity="error" style={{ marginTop: "10px" }}>
                          {errorMessage}
                        </Alert>
                      ) : null}
                    </FormControl>
                  </Grid>
                </Grid>
              </CardContent>

              <Grid item xs={12} sm={12} md={12} lg={12}>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <Checkbox
                      disabled={editSupFunds?.isProcessed}

                          checked={enableRecurring}
                          style={{ color: "rgb(229,60,22)" }}
                          onChange={(e) => {
                            setEnableRecurring(!enableRecurring);
                            setFieldValue(
                              "IsMakeRecurringEnabled",
                              !enableRecurring
                            );
                            setFieldValue(
                              "supplierAutoMatchingOptions.isCounterpartNameEnabled",
                              e.target.checked
                            );
                            setFieldValue(
                              "supplierAutoMatchingOptions.isAmountEnabled",
                              e.target.checked
                            );
                            setFieldValue(
                              "supplierAutoMatchingOptions.isRemittanceInformationEnabled",
                              e.target.checked
                            );
                          }}
                        />
                      </td>
                      <td>
                        <Typography style={{ fontWeight: "bold" }}>
                          {getTranslation(
                            "Enable Recurring",
                            "Enable Recurring",
                            "Enable Recurring"
                          )}
                        </Typography>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <table>
                  <tboday>
                    <tr>
                      <td>
                        {enableRecurring ? (
                          <Checkbox
                            checked={isCounterpartNameEnabled}
                            style={{ color: "rgb(229,60,22)" }}
                            onChange={(e) => {
                              setIsCounterpartNameEnabled(e.target.checked);
                              setFieldValue(
                                "supplierAutoMatchingOptions.isCounterpartNameEnabled",
                                e.target.checked
                              );
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </td>
                      <td>
                        <Typography style={{ fontWeight: "bold" }}>
                          {getTranslation(
                            "Counter Part Name",
                            "Nom contre-partie",
                            "Name Gegenpartei"
                          )}
                          :{" "}
                        </Typography>
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>
                        {isCounterpartNameEnabled ? (
                          <TextField
                            variant="outlined"
                            size="small"
                            multiline
                            inputProps={{ cols: 50 }}
                            value={counterPartName}
                            onChange={(e) => {
                              setCounterPartName(e.target.value);
                              setFieldValue(
                                "supplierAutoMatchingOptions.counterpartName",
                                e.target.value
                              );
                            }}
                          />
                        ) : (
                          <Typography>
                            {counterPartName
                              ? counterPartName
                              : counterpartName}
                          </Typography>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {enableRecurring ? (
                          <Checkbox
                            checked={isAmountEnabled}
                            style={{ color: "rgb(229,60,22)" }}
                            onChange={(e) => {
                              setIsAmountEnabled(e.target.checked);
                              setFieldValue(
                                "supplierAutoMatchingOptions.isAmountEnabled",
                                e.target.checked
                              );
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </td>
                      <td>
                        <Typography style={{ fontWeight: "bold" }}>
                          {getTranslation(
                            "Amount  :",
                            "Montant  :",
                            "Menge  :"
                          )}
                        </Typography>
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>
                        {isAmountEnabled ? (
                          <TextField
                            variant="outlined"
                            size="small"
                            value={supplierAmount}
                            onChange={(e) => {
                              setSupplierAmount(e.target.value);
                              setFieldValue(
                                "supplierAutoMatchingOptions.amount",
                                e.target.value
                              );
                            }}
                          />
                        ) : (
                          <Typography>
                            {supplierAmount
                              ? supplierAmount
                              : amount?.toFixed(2)}
                          </Typography>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {enableRecurring ? (
                          <Checkbox
                            checked={isRemittanceInformationEnabled}
                            style={{ color: "rgb(229,60,22)" }}
                            onChange={(e) => {
                              setIsRemittanceInformationEnabled(
                                e.target.checked
                              );
                              setFieldValue(
                                "supplierAutoMatchingOptions.isRemittanceInformationEnabled",
                                e.target.checked
                              );
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </td>
                      <td>
                        <Typography style={{ fontWeight: "bold" }}>
                          {getTranslation(
                            "Remittance Information :",
                            "Renseignements sur les remises",
                            "Überweisungsinformationen"
                          )}
                        </Typography>
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>
                        {isRemittanceInformationEnabled ? (
                          <TextField
                            variant="outlined"
                            size="small"
                            value={supplierRemittanceinformation}
                            onChange={(e) => {
                              setSupplierRemittanceinformation(e.target.value);
                              setFieldValue(
                                "supplierAutoMatchingOptions.remittanceInformation",
                                e.target.value
                              );
                            }}
                            multiline
                            inputProps={{ cols: 90 }}
                          />
                        ) : (
                          <Typography>
                            {supplierRemittanceinformation
                              ? supplierRemittanceinformation
                              : remittanceinformation}
                          </Typography>
                        )}
                      </td>
                    </tr>
                  </tboday>
                </table>
                <br />
              </Grid>
              <CardActions>
                <Box
                  pr={1}
                  pb={1}
                  width="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <ButtonWithLoading
                    title={getTranslation("Confirm", "Confirmer", "Bestätigen")}
                    size="small"
                    variant="contained"
                    style={{
                      backgroundColor: "rgb(229,60,22)",
                      color: "white",
                      fontWeight: "bold",
                      marginLeft:"10px"
                    }}
                    loading={isSubmitting}
                    onClick={handleSubmit}
                  />


                  {editSupFunds?.isProcessed && (
                    <ButtonWithLoading

                    title={getTranslation("Reset Transaction", "Reset Transaction", "Reset Transaction")}
                    size="small"
                    variant="contained"
                    style={{
                      backgroundColor: "rgb(229,60,22)",
                      color: "white",
                      fontWeight: "bold",
                      marginLeft:"10px"
                    }}
                    loading={clearLoading}
                    onClick={clearTransction}
                  />
                  )}
                </Box>
              </CardActions>
            </Form>
          )}
        </Formik>
      </div>
    </Dialog>
  );
};
