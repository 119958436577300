import React from "react";
import {
	makeStyles,
	Typography,
	Box,
	Grid,
	Card,
	CardContent,
	Divider,
} from "@material-ui/core";
import Moment from "moment";
import moment from "moment";
import { getTranslation } from "../../../../../heplers/translationHelper";
import { ReportLayout, reportStyles } from "../../../../report/ReportLayout";
import _ from "lodash";
import renderHTML from "react-render-html";
import authService from "../../../../../utils/authUtils";
import { LetterReportLayout } from "../../../../report/LetterReportLayou";
import { format } from "../../../../../heplers/format";

const PrintLetterDialog = React.forwardRef(
	(
		{
			rows,
			tenant,
			activeContract,
			sendByPost,
			message,
			filterItems,
			tenantDuesSummary,
		},
		ref
	) => {
		const classes = reportStyles();
		const noData = typeof rows === "undefined";
		var organization = authService
			.getCustomerOrganizations()
			?.find((x) => x.customerId == authService.getCustomerId());

		var dateCollection = _.groupBy(rows?.data, (x) => moment(x.date).year());
		var years = _.keys(dateCollection).sort().reverse();

		return (
			<LetterReportLayout ref={ref} noData={noData} tenant={tenant}>
				{sendByPost ? (
					<div style={{ marginBottom: "20px" }}>
						{renderHTML(
							message
								.replace("[zip_code]", tenant?.buildingZipCode)
								.replace("[appartment_address]", tenant?.aparmentAddress)
								.replace("[town]", tenant?.buildingTown)
								.replace("due_Amount", tenant?.accountBalance)
						)}
					</div>
				) : null}

				{/* <div
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "flex-start",
					}}
				>
					<div>
						<Typography className={classes.dateStyle} variant="h5">
							{getTranslation("Sort by:", "Sort by:", "Sort by:") +
								" " +
								filterItems?.sort}
						</Typography>
						<Typography className={classes.dateStyle}>
							{getTranslation(
								"Sort direction:",
								"Sort direction:",
								"Sort direction:"
							) + " "}{" "}
							{filterItems?.sortDirection == 0
								? getTranslation("Ascending", "Ascending", "Ascending")
								: getTranslation("Descending", "Descending", "Descending")}
						</Typography>
						<Typography className={classes.dateStyle} variant="h5">
							{getTranslation(
								"DuesCategory:",
								"DuesCategory:",
								"DuesCategory:"
							) +
								" " +
								filterItems?.duescategory}
						</Typography>
						<Typography className={classes.dateStyle} variant="h5">
							{getTranslation("Search By:", "Search By:", "Search By:") +
								" " +
								(filterItems?.searchString ? filterItems?.searchString : "-")}
						</Typography>
					</div>
				</div> */}
				<div>
					<Card
						style={{
							pageBreakBefore: "always",
							marginBottom: "20px",
							marginTop: "20px",
						}}
					>
						<CardContent>
							<Grid container>
								<Grid xs={9} sm={9} md={9}>
									<Grid container>
										{/* Row1 */}
										<Grid item xs={2} sm={2} md={2}>
											<Typography className={classes.titleStyles}>
												{getTranslation("Reference", "Reference", "Reference")}
											</Typography>
										</Grid>
										<Grid item xs={2} sm={4} md={4}>
											<Typography className={classes.subtitle}>
												{tenant?.referenceCode}
											</Typography>
										</Grid>
										<Grid item xs={2} sm={2} md={2}>
											<Typography className={classes.titleStyles}>
												{getTranslation("Apartment", "Logement", "Wohnung")}
											</Typography>
										</Grid>
										<Grid item xs={3} sm={4} md={4}>
											<Typography className={classes.subtitle}>
												{activeContract
													? activeContract?.apartmentNo
													: getTranslation(
															"No active Apartment",
															"No active Apartment",
															"No active Apartment"
													  )}
											</Typography>
										</Grid>

										{/* Row2 */}
										<Grid item xs={2} sm={2} md={2}>
											<Typography className={classes.titleStyles}>
												{getTranslation("Name", "Name", "Name")}
											</Typography>
										</Grid>
										<Grid item xs={3} sm={4} md={4}>
											<Typography className={classes.subtitle}>
												{tenant?.name}
											</Typography>
										</Grid>
										{activeContract ? (
											<>
												<Grid item xs={2} sm={2} md={2}>
													<Typography className={classes.titleStyles}>
														{getTranslation("Contract", "Contract", "Contract")}
													</Typography>
												</Grid>
												<Grid item xs={3} sm={4} md={4}>
													<Typography className={classes.subtitle}>
														{activeContract
															? format("date", activeContract?.startDate)
															: null}
													</Typography>
													<Typography className={classes.subtitle}>
														{sendByPost
															? Moment(activeContract.lastPaymentDate).format(
																	"DD-MM-YYYY"
															  )
															: activeContract?.statusText
																	.replace(
																		"Occupied",
																		getTranslation(
																			"Occupied",
																			"Occupied",
																			"Occupied"
																		)
																	)
																	.replace(
																		"Reserved",
																		getTranslation(
																			"Reserved",
																			"Reserved",
																			"Reserved"
																		)
																	)
																	.replace(
																		"Available",
																		getTranslation(
																			"Available",
																			"Available",
																			"Available"
																		)
																	)
																	.replace(
																		"On Notice Period",
																		getTranslation(
																			"On Notice Period",
																			"On Notice Period",
																			"On Notice Period"
																		)
																	)}
													</Typography>
													{activeContract && activeContract.status == 0 ? (
														<Typography className={classes.subtitle}>
															{format("date", activeContract?.endDate)}
														</Typography>
													) : null}
												</Grid>
											</>
										) : null}

										{/* row 3  */}

										<Grid item xs={2} sm={2} md={2}>
											<Typography className={classes.titleStyles}>
												{getTranslation("ID", "ID", "ID")}
											</Typography>
										</Grid>
										<Grid item xs={3} sm={4} md={4}>
											<Typography className={classes.subtitle}>
												{tenant?.idCard}{" "}
											</Typography>
										</Grid>
										<Grid item xs={2} sm={2} md={2}>
											<Typography className={classes.titleStyles}>
												{getTranslation("Address", "Address", "Address")}
											</Typography>
										</Grid>

										<Grid item xs={3} sm={4} md={4}>
											<Typography className={classes.subtitle}>
												{tenant?.buildingAddress + ","} <br />{" "}
												{tenant?.buildingZipCode + "," + tenant?.buildingTown}
											</Typography>
										</Grid>
										{/* Row4 */}
										<Grid item xs={2} sm={2} md={2}>
											<Typography className={classes.titleStyles}>
												{getTranslation("Phone", "T�l�phone", "Telefon")}
											</Typography>
										</Grid>
										<Grid item xs={3} sm={4} md={4}>
											<Typography className={classes.subtitle}>
												{tenant?.telephone == "+" ? "-" : tenant?.telephone}
											</Typography>
										</Grid>
									</Grid>
									<Grid item container>
										<Grid item xs={2} sm={2} md={2}>
											<Typography className={classes.titleStyles}>
												{getTranslation("Email", "Email", "Email")}
											</Typography>
										</Grid>
										<Grid item xs={3} sm={4} md={4}>
											<Typography className={classes.subtitle}>
												{tenant?.email}
											</Typography>
										</Grid>
									</Grid>
								</Grid>
								<Grid>
									<Divider orientation="vertical" variant="middle" />
								</Grid>
								<Grid xs={2} sm={2} md={2}>
									<Box style={{ textAlign: "center" }}>
										<Typography>
											{getTranslation("Balance", "Solde", "Saldo")}
										</Typography>
										<br />
										<Typography className={classes.maltoseColor}>
											{format("number", tenant?.accountBalance)}
										</Typography>
									</Box>
								</Grid>
							</Grid>
						</CardContent>
					</Card>

					<div>
						{/* {sendByPost ? (
							<div style={{ marginBottom: "20px" }}>
								<Typography className={classes.titleStyles}>
									{getTranslation("Message", "Message", "Message")}:
								</Typography>
								{renderHTML(message)}
							</div>
						) : null} */}
						<Card style={{ marginBottom: "20px" }}>
							<CardContent>
								{/* tenantDuesSummary section  */}
								<Grid container>
									<Grid xs={2} sm={2} md={4} lg={4}>
										<Box display="flex">
											<tr>
												<Typography variant="h5">
													{getTranslation("Rent", "Rent", "Rent")}
												</Typography>
												<td>
													<Typography variant="h6">
														{format("number", tenantDuesSummary?.totalRent)} €
													</Typography>
												</td>
											</tr>
										</Box>
									</Grid>
									<Grid xs={2} sm={2} md={4} lg={4}>
										<Box display="flex">
											<tr>
												<Typography variant="h5">
													{getTranslation("Cost", "Cost", "Cost")}
												</Typography>
												<td>
													<Typography variant="h6">
														{format("number", tenantDuesSummary?.totalCost)} €
													</Typography>
												</td>
											</tr>
										</Box>
									</Grid>

									<Grid xs={3} sm={3} md={4} lg={4}>
										<Box display="flex">
											<tr>
												<Typography variant="h5">
													{getTranslation(
														"Repair And Maintenance",
														"Repair And Maintenance",
														"Repair And Maintenance"
													)}
												</Typography>
												<td>
													<Typography variant="h6">
														{" "}
														{format(
															"number",
															tenantDuesSummary?.totalRepairAndMaintenance
														)}{" "}
														€
													</Typography>
												</td>
											</tr>
										</Box>
									</Grid>

									{/* <Grid xs={3} sm={3} md={4} lg={4}>
										<Box display="flex">
											<tr>
												<Typography variant="h5">
													{getTranslation(
														"Amount Due",
														"Amount Due",
														"Amount Due"
													)}
												</Typography>
												<td>
													<Typography variant="h6">
														{" "}
														{format(
															"number",
															tenantDuesSummary?.totalAmountDue
														)}{" "}
														€
													</Typography>
												</td>
											</tr>
										</Box>
									</Grid> */}

									<Grid xs={2} sm={2} md={4} lg={4}>
										<Box display="flex">
											<tr>
												<Typography variant="h5">
													{getTranslation("Warranty", "Warranty", "Warranty")}
												</Typography>
												<td>
													<Typography variant="h6">
														{format("number", tenantDuesSummary?.totalWarranty)}{" "}
														€
													</Typography>
												</td>
											</tr>
										</Box>
									</Grid>
								</Grid>
							</CardContent>
						</Card>

						{/* table data  */}
						<table className={classes.table}>
							<thead>
								<tr>
									<th>
										<Typography className={classes.titleStyles} align="left">
											{getTranslation("Date", "Date", "Date")}
										</Typography>
									</th>
									<th>
										<Typography className={classes.titleStyles} align="left">
											{getTranslation(
												"Description",
												"La description",
												"Beschreibung"
											)}
										</Typography>
									</th>
									<th>
										<Typography className={classes.titleStyles} align="left">
											{getTranslation("Type", "Type", "Type")}
										</Typography>
									</th>
									<th style={{ textAlign: "right" }}>
										<Typography className={classes.titleStyles}>
											{getTranslation("Due", "Payable", "F�llig")}
										</Typography>
									</th>
									<th style={{ textAlign: "right" }}>
										<Typography className={classes.titleStyles}>
											{getTranslation("Bank", "Banque", "Bank")}
										</Typography>
									</th>
								</tr>
							</thead>
							<tbody>
								{years.map((year, key) =>
									Object.entries(
										_.groupBy(dateCollection[year], (x) => x.activity)
									).map((item, key) => (
										<RenderActivities
											activities={item[1]}
											classes={classes}
											filterItems={filterItems}
										/>
									))
								)}
							</tbody>
							<tfoot>
								<tr>
									<td colSpan={4} style={{ height: "14px" }}></td>
								</tr>
								<tr>
									<td
										align={"center"}
										style={{
											background: "#EB5C3E",
											color: "#fff",
										}}
									>
										<Typography className={classes.endCards} align="left">
											{getTranslation(" Total ", " Totale ", " Gesamt ")}
										</Typography>
									</td>
									<td
										style={{
											background: "#EB5C3E",
											color: "#fff",
										}}
									></td>
									<td
										style={{
											background: "#EB5C3E",
											color: "#fff",
										}}
									></td>
									<td
										align="right"
										style={{
											background: "#233044",
											color: "#fff",
										}}
									>
										<Typography className={classes.endCards}>
											&euro;{" "}
											{format("number", _.sumBy(rows?.data, "dueGenerated"))}
										</Typography>
									</td>
									<td
										align="right"
										style={{
											background: "#233044",
											color: "#fff",
										}}
									>
										<Typography className={classes.endCards}>
											&euro;{" "}
											{format("number", _.sumBy(rows?.data, "amountDeposit"))}
										</Typography>
									</td>
								</tr>
							</tfoot>
						</table>
					</div>
				</div>
			</LetterReportLayout>
		);
	}
);

export default PrintLetterDialog;

const RenderActivities = ({ activities, classes, filterItems }) => {
	const groupByActivities = _.groupBy(activities, (x) => x.activity);

	return Object.entries(groupByActivities).map((activityData) => {
		return (
			<>
				{_.sortBy(activityData[1], (x) => x.filterItems?.sort).map((item) => (
					<tr>
						<td width="150px">
							<Typography className={classes.subtitle}>
								{Moment(item.date).format("DD-MM-YYYY")}
							</Typography>
						</td>
						<td style={{ verticalAlign: "top" }} width="700px">
							<Typography className={classes.subtitle} align="left">
								{item.description
									.replace("Amount from", "")
									.replace("account", "")
									.replace("Account", "")
									.replace(
										"Generated By Admin",
										getTranslation(
											"Generated By Admin",
											"Transation générée par le system ",
											"Operation vom System generiert"
										)
									)}
							</Typography>
							<Typography
								style={{
									color: "red",
									fontSize: "9px",
								}}
							>
								{item.postponedReason}
							</Typography>
						</td>
						<td align="left" style={{ verticalAlign: "top" }} width="130px">
							<Typography className={classes.subtitle}>
								{getTranslation(item.activity, item.activity, item.activity)}
							</Typography>
						</td>
						<td align="right" style={{ verticalAlign: "top" }} width="100px">
							<Typography className={classes.subtitle}>
								{format("number", item.dueGenerated)}
							</Typography>
						</td>
						<td align="right" style={{ verticalAlign: "top" }} width="100px">
							<Typography className={classes.subtitle}>
								{format("number", item.amountDeposit)}
							</Typography>
						</td>
					</tr>
				))}
				<tr>
					<td
						align={"center"}
						style={{
							background: "#233044",
							color: "#fff",
						}}
					>
						<Typography className={classes.endCards} align="left">
							{getTranslation("Sub Total", "Sub Total", "Sub Total")}
						</Typography>
					</td>
					<td
						style={{
							background: "#233044",
							color: "#fff",
							fontSize: "12px",
							fontWeight: "bold",
						}}
					>
						{Moment(activities[0].date).format("YYYY")}
						<span style={{ marginLeft: "4px" }}>
							{getTranslation(
								activities[0]?.activity,
								activities[0]?.activity,
								activities[0]?.activity
							)}
						</span>
					</td>
					<td
						style={{
							background: "#233044",
							color: "#fff",
							fontSize: "12px",
							fontWeight: "bold",
						}}
					>
						<span style={{ marginRight: "4px" }}>
							{getTranslation("Difference", "Difference", "Difference")}
						</span>
						{format(
							"number",
							_.sumBy(activities, "dueGenerated") -
								_.sumBy(activities, "amountDeposit")
						)}
					</td>
					<td
						align="right"
						style={{
							background: "#EB5C3E",
							color: "#fff",
						}}
					>
						<Typography className={classes.endCards}>
							&euro; {format("number", _.sumBy(activities, "dueGenerated"))}
						</Typography>
					</td>
					<td
						align="right"
						style={{
							background: "#EB5C3E",
							color: "#fff",
						}}
					>
						<Typography className={classes.endCards}>
							&euro; {format("number", _.sumBy(activities, "amountDeposit"))}
						</Typography>
					</td>
				</tr>
			</>
		);
	});
};
