import React from "react";
import { useParams } from "react-router-dom";
import {
	Grid,
	TextField,
	Box,
	MenuItem,
	RadioGroup,
	FormControlLabel,
	Radio,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import ButtonWithLoading from "../../../../../components/ButtonWithLoading";
import * as Yup from "yup";
import Dialog from "../../../../../components/Dialog";
import { getTranslation } from "../../../../../heplers/translationHelper";

const AddAppartmentDialog = ({
	isOpen,
	apartment,
	enableEdit,
	onSubmit,
	onClose,
	isLoading,
}) => {
	const { buildingId } = useParams();
	const initialValues = {
		id: apartment?.id,
		apartmentNo: "",
		amount: "",
		buildingId: buildingId,
		floor: "",
		apartmentType: "",
		surface: "",
		peb: "",
		baseCode: "2013",
	};

	const basicValidationSchema = Yup.object().shape({
		apartmentNo: Yup.string()
			.required("Apartment number is required")
			.nullable(),
		amount: Yup.number().min(1).required("Rent is required").nullable(),
		floor: Yup.string().required("Floor is required").nullable(),
		apartmentType: Yup.string().required("Type is required").nullable(),
		surface: Yup.number().min(1).required("Surface is required").nullable(),
	});

	// const defaultValue = apartment ? apartment : initialValues;
	const defaultValue = apartment
		? {
				...apartment,
				baseCode: apartment.baseCode.toString(),
		  }
		: initialValues;

	return (
		<Dialog
			open={isOpen}
			title={
				apartment
					? getTranslation("Edit Apartment", "Edit Apartment", "Edit Apartment")
					: getTranslation(
							"Add New Apartment",
							"Add New Apartment",
							"Add New Apartment"
					  )
			}
			onClose={onClose}
			fullWidth
		>
			<Formik
				enableReinitialize
				initialValues={defaultValue}
				validationSchema={basicValidationSchema}
				onSubmit={(values, actions) => {
					values.baseCode = parseInt(values.baseCode);
					onSubmit(values);
					actions.resetForm();
				}}
			>
				{({
					touched,
					errors,
					values,
					handleSubmit,
					getFieldProps,
					setFieldValue,
				}) => (
					<Form>
						<Grid container spacing={2} style={{ margin: "1%" }}>
							<Grid item xs={12} sm={12} md={6} lg={6}>
								<TextField
									fullWidth
									id="apartmentNo"
									label={getTranslation(
										"Apartment Number",
										"Apartment Number",
										"Apartment Number"
									)}
									required
									size="small"
									variant="outlined"
									name="apartmentNo"
									{...getFieldProps("apartmentNo")}
									error={touched.apartmentNo && Boolean(errors.apartmentNo)}
									helperText={touched.apartmentNo && errors.apartmentNo}
									InputLabelProps={{
										shrink: true,
									}}
									// inputProps={{
									//   pattern: "^[A-Za-z0-9#\\s]+$",
									//   onKeyPress: (event) => {
									//     const charCode = event.charCode;
									//     if (
									//       !/[A-Za-z0-9#\s]/.test(String.fromCharCode(charCode))
									//     ) {
									//       event.preventDefault();
									//     }
									//   },
									// }}
								/>
							</Grid>
							<Grid item xs={12} sm={12} md={6} lg={6}>
								<TextField
									fullWidth
									id="amount"
									label={getTranslation("Rent", "Rent", "Rent")}
									required
									size="small"
									variant="outlined"
									type="number"
									InputProps={{ inputProps: { min: 1 } }}
									name="amount"
									{...getFieldProps("amount")}
									error={touched.amount && Boolean(errors.amount)}
									helperText={touched.amount && errors.amount}
									InputLabelProps={{
										shrink: true,
									}}
									disabled={apartment}
								/>
							</Grid>
							<Grid item xs={12} sm={12} md={6} lg={6}>
								<TextField
									fullWidth
									id="floor"
									label={getTranslation("Floor", "Floor", "Floor")}
									required
									size="small"
									variant="outlined"
									name="floor"
									{...getFieldProps("floor")}
									error={touched.floor && Boolean(errors.floor)}
									helperText={touched.floor && errors.floor}
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={12} md={6} lg={6}>
								<TextField
									fullWidth
									id="apartmentType"
									label={getTranslation("Type", "Type", "Type")}
									required
									size="small"
									variant="outlined"
									select
									name="apartmentType"
									{...getFieldProps("apartmentType")}
									error={touched.apartmentType && Boolean(errors.apartmentType)}
									helperText={touched.apartmentType && errors.apartmentType}
									InputLabelProps={{
										shrink: true,
									}}
									value={values.apartmentType}
									onChange={(e) => {
										let selectedIndex;
										switch (e.target.value) {
											case "0":
												setFieldValue("apartmentType", 0);
												break;
											case "1":
												setFieldValue("apartmentType", 1);
												break;
											case "2":
												setFieldValue("apartmentType", 2);
												break;
											case "3":
												setFieldValue("apartmentType", 3);
												break;
										}
									}}
								>
									<MenuItem value="0">
										{getTranslation("House", "House", "House")}
									</MenuItem>
									<MenuItem value="1">
										{getTranslation("Apartment", "Apartment", "Apartment")}
									</MenuItem>
									<MenuItem value="2">
										{getTranslation("Office", "Office", "Office")}
									</MenuItem>
									<MenuItem value="3">
										{getTranslation("Storage", "Storage", "Storage")}
									</MenuItem>
								</TextField>
							</Grid>
							<Grid item xs={12} sm={12} md={6} lg={6}>
								<TextField
									fullWidth
									id="surface"
									label={getTranslation("Surface", "Surface", "Surface")}
									size="small"
									variant="outlined"
									type="number"
									name="surface"
									{...getFieldProps("surface")}
									error={touched.surface && Boolean(errors.surface)}
									helperText={touched.surface && errors.surface}
									InputLabelProps={{
										shrink: true,
									}}
									InputProps={{
										endAdornment: <span> m²</span>,
										inputProps: { min: 0 },
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={12} md={6} lg={6}>
								<TextField
									fullWidth
									id="peb"
									label={getTranslation("PEB", "PEB", "PEB")}
									size="small"
									variant="outlined"
									name="peb"
									{...getFieldProps("peb")}
									error={touched.peb && Boolean(errors.peb)}
									helperText={touched.peb && errors.peb}
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</Grid>
							{/* <Grid item xs={12} sm={12} md={6} lg={6}>
								<TextField
									fullWidth
									id="baseCode"
									label={getTranslation("Base Code", "Base Code", "Base Code")}
									size="small"
									variant="outlined"
									type="number"
									name="baseCode"
									{...getFieldProps("baseCode")}
									error={touched.baseCode && Boolean(errors.baseCode)}
									helperText={touched.baseCode && errors.baseCode}
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</Grid> */}
							<Grid item xs={12} sm={12} md={12} lg={12}>
								{/* <TextField
									fullWidth
									id="baseCode"
									label={getTranslation("Base Code", "Base Code", "Base Code")}
									size="small"
									variant="outlined"
									select
									name="baseCode"
									{...getFieldProps("baseCode")}
									error={touched.baseCode && Boolean(errors.baseCode)}
									helperText={touched.baseCode && errors.baseCode}
									InputLabelProps={{
										shrink: true,
									}}
									value={values.baseCode}
									onChange={(e) => {
										let selectedIndex;
										switch (e.target.value) {
											case "1988":
												setFieldValue("baseCode", 1988);
												break;
											case "1996":
												setFieldValue("baseCode", 1996);
												break;
											case "2004":
												setFieldValue("baseCode", 2004);
												break;
											case "2013":
												setFieldValue("baseCode", 2013);
												break;
										}
									}}
								>
									<MenuItem value="1988">1988</MenuItem>
									<MenuItem value="1996">1996</MenuItem>
									<MenuItem value="2004">2004</MenuItem>
									<MenuItem value="2013">2013</MenuItem>
								</TextField> */}
								<span>
									{getTranslation("Base Code", "Base Code", "Base Code")}
								</span>
								<RadioGroup
									aria-label="baseCode"
									name="baseCode"
									value={values.baseCode}
									{...getFieldProps("baseCode")}
									onChange={(e) => {
										setFieldValue("baseCode", e.target.value);
									}}
									row
								>
									<FormControlLabel
										value="1988"
										control={<Radio />}
										label="1988"
									/>
									<FormControlLabel
										value="1996"
										control={<Radio />}
										label="1996"
									/>
									<FormControlLabel
										value="2004"
										control={<Radio />}
										label="2004"
									/>
									<FormControlLabel
										value="2013"
										control={<Radio />}
										label="2013"
									/>
								</RadioGroup>
							</Grid>
						</Grid>
						<Box
							pr={1}
							pb={1}
							width="100%"
							display="flex"
							alignItems="center"
							justifyContent="center"
							style={{ marginTop: "20px" }}
						>
							<ButtonWithLoading
								title={
									enableEdit
										? getTranslation("Update", "Update", "Update")
										: getTranslation("Add", "Add", "Add")
								}
								style={{
									backgroundColor: "rgb(229,60,22)",
									color: "rgb(255,255,255)",
								}}
								size="small"
								variant="contained"
								type="submit"
								loading={isLoading}
								onClick={handleSubmit}
							/>
						</Box>
					</Form>
				)}
			</Formik>
		</Dialog>
	);
};

export default AddAppartmentDialog;
