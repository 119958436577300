import React from "react";
import SplitFundtoTenant from "./splitFundtoTenant";
import { Grid } from "@material-ui/core";
import _ from "lodash";

function FundtoCategorymapper({
  transactionAmount,
  array,
  setArray,
  handleOnAdd,
  remainingItems,
  handleOnChange,
  enableAmountError,
  disableAmountError
}) {
  const removeItem = (index, values) => {
    var newArray = array;
    const deletedRecord=array.find((item, i) => i == index);
    newArray = array.filter((item, i) => i !== index);
    const lastItem = newArray[newArray.length - 1];
   lastItem.amount = parseFloat(lastItem.amount) + parseFloat(deletedRecord.amount);
    setArray([...newArray]);
  };
  return (
    <div>
      {array.map((item, index) => {
        var itemDisabled =
          !array || array.length <= 1
            ? false
            : array[array.length - 1] == item
            ? false
            : true;
            if (item?.id) itemDisabled = true;
        return (
          <Grid item xs={12} sm={12} md={12} lg={12} key={index}>
            <SplitFundtoTenant
            enableAmountError={enableAmountError}
            disableAmountError={disableAmountError}
              isDisable={itemDisabled}
              showButton={array.length - 1 == index}
              item={item}
              index={index}
              remainingItems={remainingItems}
              onSubmit={handleOnAdd}
              onChanged={handleOnChange}
              balance={
                transactionAmount -
                _.sumBy(array.slice(0, -1), (x) => parseInt(x.amount))
              }
              deleteItem={removeItem}
            />
          </Grid>
        );
      })}
    </div>
  );
}

export default FundtoCategorymapper;
