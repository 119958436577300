import React, { useState } from "react";
import { getTranslation } from "../../../../../heplers/translationHelper";
import {
	Box,
	Card,
	CardContent,
	CardHeader,
	Grid,
	TextField,
	Typography,
	useMediaQuery,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import ButtonWithLoading from "../../../../../components/ButtonWithLoading";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { DatePicker } from "../../../../../components/DatePicker";

const StartRecoveryDialog = ({
	tenantDetails,
	tenantData,
	isEdit,
	initialValues,
	NextStep,
	activeStep,
	phoneCall,
}) => {
	const [tenantId, setTenantId] = useState(
		tenantData ? tenantData.id : isEdit ? initialValues?.tenant.id : 0
	);
	const selectedTenant = tenantData
		? tenantData
		: tenantDetails?.find(
				(x) =>
					x.id == (initialValues?.tenant ? initialValues?.tenant.id : tenantId)
		  );
	if (isEdit) {
		initialValues.tenantId = tenantId;
	}
	if (tenantData) {
		initialValues.tenantId = tenantId;
		initialValues.tenant = tenantData;
	}
	const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

	const basicValidationSchema = Yup.object().shape({
		tenantId: Yup.number().min(1).required("Tenant is required"),
	});

	const defaultValue = { ...initialValues };
	return (
		<div>
			<Formik
				initialValues={defaultValue}
				validationSchema={basicValidationSchema}
				onSubmit={(values, actions) => {
					actions.setSubmitting(true);
					values.tenantId = tenantId;
					NextStep(values);
					actions.setSubmitting(false);
				}}
			>
				{({
					values,
					setFieldValue,
					errors,
					touched,
					handleChange,
					handleSubmit,
					isSubmitting,
					getFieldProps,
					setTouched,
				}) => (
					<Form onSubmit={handleSubmit}>
						<>
							<Grid container>
								<Grid item xs={12} sm={12} md={5} lg={5}>
									<Autocomplete
										disabled={phoneCall || isEdit ? true : false}
										options={tenantDetails || []}
										getOptionLabel={(option) =>
											`${option?.name} | ${option?.accountBalance.toFixed(2)}`
										}
										value={values.tenant}
										size="small"
										id="tenant"
										name="tenant"
										onChange={(e, tenant) => {
											// if (tenant) {
											//   setTenantId(tenant?.id);
											//   setFieldValue("tenant", tenant);
											//   setFieldValue("tenantId", tenant.id);
											// }
											if (!tenant) {
												setFieldValue("tenant", "");
												setFieldValue("tenantId", "");
												setTenantId(0);
											} else {
												setTenantId(tenant?.id);
												setFieldValue("tenant", tenant);
												setFieldValue("tenantId", tenant.id);
											}
										}}
										renderInput={(params) => (
											<TextField
												{...params}
												label={getTranslation("Tenant", "Locataires", "Mieter")}
												variant="outlined"
												{...getFieldProps("tenant")}
												error={
													(touched.tenantId || touched.tenant) &&
													Boolean(errors.tenantId || errors.tenant)
												}
												helperText={
													(touched.tenantId || touched.tenant) &&
													(errors.tenantId || errors.tenant)
												}
												// error={touched.tenant && Boolean(errors.tenant)}
												// helperText={touched.tenant && errors.tenant}
												InputLabelProps={{
													shrink: true,
												}}
											/>
										)}
									/>
								</Grid>

								<Grid
									item
									xs={12}
									sm={12}
									md={11}
									lg={11}
									style={{ marginTop: "20px" }}
								>
									<TextField
										fullWidth
										variant="outlined"
										type="text"
										id="comments"
										name="comments"
										value={values?.comments}
										label={getTranslation("Comment", "Comment", "Comment")}
										size="small"
										onChange={(e) => {
											setFieldValue("comments", e.target.value);
										}}
										multiline
										rows={5}
										cols={30}
										{...getFieldProps("comments")}
										error={touched.comments && Boolean(errors.comments)}
										helperText={touched.comments && errors.comments}
										InputLabelProps={{
											shrink: true,
										}}
									/>
								</Grid>

								{selectedTenant ? (
									<Card
										style={{
											width: isMobile ? "100%" : "92%",
											marginTop: "20px",
										}}
									>
										<CardHeader
											title={getTranslation(
												"Tenant Details",
												"Tenant Details",
												"Tenant Details"
											)}
										/>
										<CardContent>
											<Grid container>
												<Grid item xs={12} sm={6} md={6} lg={6}>
													<Typography>
														<strong>
															{getTranslation(
																"Tenant Name",
																"Tenant Name",
																"Tenant Name"
															)}
														</strong>
													</Typography>
												</Grid>
												<Grid item xs={12} sm={6} md={6} lg={6}>
													<Typography>{selectedTenant?.name}</Typography>
												</Grid>
												<Grid item xs={12} sm={6} md={6} lg={6}>
													<Typography>
														<strong>
															{getTranslation(
																"Appartment No",
																"Appartment No",
																"Appartment No"
															)}
														</strong>
													</Typography>
												</Grid>
												<Grid item xs={12} sm={6} md={6} lg={6}>
													<Typography>
														{selectedTenant?.contracts[0]?.apartmentNo}.
													</Typography>
												</Grid>
												<Grid item xs={12} sm={6} md={6} lg={6}>
													<Typography>
														<strong>
															{getTranslation(
																"Building Address",
																"Building Address",
																"Building Address"
															)}
														</strong>
													</Typography>
												</Grid>
												<Grid item xs={12} sm={6} md={6} lg={6}>
													<Typography>
														{/* {selectedTenant?.buildingAddress}. */}
														{selectedTenant?.aparmentAddress}
													</Typography>
												</Grid>
												{/* <Grid item xs={12} sm={6} md={6} lg={6}>
													<Typography>
														<strong>
															{getTranslation(
																"Building Name",
																"Building Name",
																"Building Name"
															)}
														</strong>
													</Typography>
												</Grid>
												<Grid item xs={12} sm={6} md={6} lg={6}>
													<Typography>
														{selectedTenant?.contracts[0]?.buildingName}.
													</Typography>
												</Grid> */}
											</Grid>
										</CardContent>
									</Card>
								) : (
									""
								)}
							</Grid>
							<Box
								pr={1}
								pb={1}
								pt={1}
								width="100%"
								display="flex"
								alignItems="right"
								justifyContent="right"
							>
								<ButtonWithLoading
									title={getTranslation(
										"Save & Next",
										"Save & Next",
										"Save & Next"
									)}
									size="small"
									variant="contained"
									color="primary"
									type="submit"
									loading={isSubmitting}
									onClick={handleSubmit}
								/>
							</Box>
						</>
					</Form>
				)}
			</Formik>
		</div>
	);
};

export default StartRecoveryDialog;
