import React, { useEffect, useRef, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
	FormControl,
	Checkbox,
	FormControlLabel,
	Typography,
	TextField,
	Grid,
	Card,
	CardContent,
	CardHeader,
	Box,
	ButtonGroup,
	Button,
	FormHelperText,
	makeStyles,
	Paper,
} from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { ClassicTextEditor } from "../../components/TextEditor";
import ButtonWithLoading from "../../components/ButtonWithLoading";
import { Get, Post } from "../../actions";
import {
	Get_EnableAutomaticSendReminder_URL,
	Get_EnablePostAutomaticSendReminder_URL,
	Get_GetReminderCosts_URL,
	Get_ManageReminderForAppUser_URL,
	Get_ReminderTemplate_URL,
	Post_EnableAutomaticSendReminder_URL,
	Post_EnablePostAutomaticSendReminder_URL,
	Post_ManageCallReminderForAppUser_URL,
	Post_ManagePostReminderForAppUser_URL,
	Post_ReminderCosts_URL,
	Post_UpdateReminderTemplate_URL,
} from "../../constants/apiUrls";
import { useSnackbar } from "notistack";
import { FormLoader } from "../../components/FormLoader";
import { getTranslation } from "../../heplers/translationHelper";
import authService from "../../utils/authUtils";
import { UpgradeEmailReminder } from "../common/UpgradeEmailReminder";
import { HtmlEditor } from "../../components/HtmlEditor";
import { buttonStyles } from "../tanents/components/recoveryprocedure/components/recoveryComponents/RecoveryPersons";
import TestSmsDialoge from "./component/TestingDialoge";
import { useSelector, useDispatch } from "react-redux";
import CardComponent from "../../components/Card";
import TextEditor from "./component/TextEditorCard";
import { useReactToPrint } from "react-to-print/lib";
import renderHTML from "react-render-html";

export const ReminderSettings = ({ reminderType }) => {
	const { enqueueSnackbar } = useSnackbar();
	const [reminderTemplate, setReminderTempate] = useState({});
	const [postReminderTemplate, setPostReminderTempate] = useState({});
	const [reminderTemplateLoading, setReminderTempateLoading] = useState(false);
	const [enableReloading, setEnableReloading] = useState(false);
	const [autoReminderCheck, setAutoReminderCheck] = useState(false);
	const [autoPostReminderCheck, setAutoPostReminderCheck] = useState(false);
	const [sendReminderType, setSendReminderType] = useState("ForYou");
	const [selectedLanguage, setSelectedLanguage] = useState("english");
	const [testSmsDialoge, setSmsDialoge] = useState(false);
	const [editorValue, setEditorValue] = useState("");
	const [templateValue, setTemplateValue] = useState("");
	const [subject, setSubject] = useState("");
	const [loading, setLoading] = useState(false);
	const [isYourLetterRegistered, setIsYourLetterRegistered] = useState(false);
	const [blurController, setBlurController] = useState(false);
	const userData = useSelector((state) => state.auth.user);
	const { user } = useSelector((state) => state.auth);
	const handleLanguage = (language) => {
		setSelectedLanguage(language);
	};
	const openTestSmsDialoge = () => {
		//if (reminderType == "sendByPost") handlePrintBuildingReport();
		//else setSmsDialoge(true);
		setSmsDialoge(true);
	};
	const editorRef = useRef(null);
	const classes = buttonStyles();
	const [reminderKey, setReminderKey] = useState(
		reminderType == "sendByPost" ? "FirstPostReminder" : "FirstReminder"
	);
	const [reminderCost, setReminderCost] = useState(0);

	var URL =
		reminderType == "sendByPost"
			? Get_EnablePostAutomaticSendReminder_URL
			: Get_EnableAutomaticSendReminder_URL;

	var Post_URL =
		reminderType == "sendByPost"
			? Post_EnablePostAutomaticSendReminder_URL
			: Post_EnableAutomaticSendReminder_URL;

	useEffect(() => {
		getEnableAutoReminder();
	}, []);

	useEffect(() => {
		GetReminderTemplate(reminderKey);
	}, [reminderKey, selectedLanguage]);

	const GetReminderTemplate = (reminderKey) => {
		setReminderTempateLoading(true);
		Get(
			{ reminderKey: reminderKey },
			Get_ReminderTemplate_URL,
			null,
			(resp) => {
				reminderType == "sendByPost" || reminderKey == "FirstPostReminder"
					? setPostReminderTempate(resp?.data)
					: setReminderTempate(resp?.data);

				let templateValue;
				let templateSubject;

				switch (selectedLanguage) {
					case "english":
						templateValue = resp?.data?.template;
						templateSubject = resp?.data?.subject;
						break;
					case "french":
						templateValue = resp?.data?.templateFrench;
						templateSubject = resp?.data?.subjectFrench;
						break;
					case "german":
						templateValue = resp?.data?.templateGerman;
						templateSubject = resp?.data?.subjectGerman;
						break;
					default:
						templateValue = resp?.data?.template;
						templateSubject = resp?.data?.subject;
				}

				// Set the template value and subject based on the selected language
				setTemplateValue(templateValue);
				setSubject(templateSubject);
				setEnableReloading(true);
				setReminderTempateLoading(false);
			},
			(error) => {
				enqueueSnackbar(error.data, { variant: "error" });
			}
		);
	};

	//get auto reminder checkboxes
	const getEnableAutoReminder = () => {
		Get({}, URL, null, (resp) => {
			setIsYourLetterRegistered(resp?.data?.isRegisteredPost);
			if (reminderType == "sendByPost") {
				setAutoPostReminderCheck(resp?.data?.enablePostAutoReminder);
				setSendReminderType(resp?.data?.sendReminderType);
			} else setAutoReminderCheck(resp?.data?.enableAutoReminder);
			if (resp?.data?.enableAutoReminder) {
				GetReminderTemplate(
					reminderType == "sendByPost" ? "FirstPostReminder" : "FirstReminder"
				);
			}
		});
	};

	//post manage app user reminder setting
	const postManagePostReminder = (payload) => {
		Post(
			payload,
			Post_ManagePostReminderForAppUser_URL,
			null,
			(resp) => {},
			(err) => {
				enqueueSnackbar(
					err?.data
						? err?.data
						: getTranslation(
								"Something went wrong. Please try again.",
								"Something went wrong. Please try again.",
								"Something went wrong. Please try again."
						  ),
					{
						variant: "error",
					}
				);
			}
		);
	};

	//post reminder cost
	const postReminderCost = (value) => {
		if (!value) {
			enqueueSnackbar(
				getTranslation(
					"Reminder Cost Can't be null",
					"Reminder Cost Can't be null",
					"Reminder Cost Can't be null"
				),
				{
					variant: "error",
				}
			);
			return;
		}
		if (value < 0) {
			enqueueSnackbar(
				getTranslation(
					"Reminder Cost Can't be negative",
					"Reminder Cost Can't be negative",
					"Reminder Cost Can't be negative"
				),
				{
					variant: "error",
				}
			);
			return;
		}
		Post(
			{
				reminderCost: value,
				costType: 1,
			},
			Post_ReminderCosts_URL,
			null,
			(resp) => {
				enqueueSnackbar(
					getTranslation(
						"Updated successfully",
						"Updated successfully",
						"Updated successfully"
					),
					{
						variant: "success",
					}
				);
			},
			(err) => {
				enqueueSnackbar(err?.data, { variant: "error" });
			}
		);
	};

	//get reminder cost api
	const getReminderCost = () => {
		Get(
			{},
			Get_GetReminderCosts_URL,
			null,
			(resp) => {
				setReminderCost(resp?.data?.postReminderCost);
			},
			(err) => {
				enqueueSnackbar(err?.data, { variant: "error" });
			}
		);
	};

	const AppendTemplate = (template, text, setFieldValue) => {
		editorRef.current.insertContent(text);
	};
	const initialValues = {
		dayOfMonth: 0,
		template: "",
		subject: "",
		postRemainderCost: 0,
	};

	const basicValidationSchema = Yup.object().shape({
		template: Yup.string().required("Reminder text is required"),
		dayOfMonth: Yup.number().min(0).required("Interval day(s) is required"),
		subject: Yup.string().nullable().required("subject is required"),
		postRemainderCost: Yup.number().min(0),
	});

	const basicPostValidationSchema = Yup.object().shape({
		template: Yup.string().required("Reminder text is required"),
		dayOfMonth: Yup.number().min(0).required("Interval day(s) is required"),
	});

	const defaultValue =
		reminderType == "sendByPost" ? postReminderTemplate : reminderTemplate;

	const componentAllBuildingsReport = useRef();

	const handlePrintBuildingReport = useReactToPrint({
		documentTitle: getTranslation(
			"Building Report",
			"Building Report",
			"Building Report"
		),
		content: () => componentAllBuildingsReport.current,
	});

	return authService.getIsStarter() == true ? (
		<UpgradeEmailReminder />
	) : (
		<>
			{reminderType != "sendByPost" || reminderType == "sendByPost" ? (
				<>
					<FormControl>
						<FormControlLabel
							label={getTranslation(
								"Enable Auto Reminder",
								"Activer le rappel automatique",
								"Automatische Erinnerung aktivieren"
							)}
							labelPlacement="start"
							control={
								<Checkbox
									id="autoReminderCheck"
									style={{
										color: "rgb(229,60,22)",
									}}
									checked={
										reminderType == "sendByPost"
											? autoPostReminderCheck
											: autoReminderCheck
									}
									onChange={(e, check) => {
										setAutoReminderCheck(check);
										setAutoPostReminderCheck(check);
										Post(
											{
												enableReminder: check,
												isRegisteredPost: isYourLetterRegistered,
											},
											Post_URL,
											null,
											// (resp) => {
											// 	actions.setSubmitting(false);
											// 	enqueueSnackbar(
											// 		getTranslation(
											// 			"Reminder template updated successfully",
											// 			"Reminder template updated successfully",
											// 			"Reminder template updated successfully"
											// 		),
											// 		{
											// 			variant: "success",
											// 		}
											// 	);
											// 	setLoading(false);
											// },

											(resp) => {
												reminderType == "sendByPost"
													? GetReminderTemplate("FirstPostReminder")
													: GetReminderTemplate("FirstReminder");
											},
											(err) => {
												enqueueSnackbar(
													err.data
														? err.data
														: getTranslation(
																"Something went wrong. Please contact with admin.",
																"Something went wrong. Please contact with admin.",
																"Something went wrong. Please contact with admin."
														  ),
													{
														variant: "error",
													}
												);
											}
										);
									}}
								/>
							}
						/>
					</FormControl>
					{/* Code by Huzefa Tariq */}
					{reminderType == "sendByPost" && sendReminderType === "ForYou" && (
						<FormControl>
							<FormControlLabel
								label="Is Your Letter Registered?"
								labelPlacement="start"
								control={
									<Checkbox
										id="isYourLetterRegistered"
										style={{
											color: "rgb(229,60,22)",
										}}
										checked={isYourLetterRegistered}
										onChange={(e, check) => {
											setIsYourLetterRegistered(check);
											Post(
												{
													enableReminder: autoReminderCheck,
													isRegisteredPost: check,
												},
												Post_EnablePostAutomaticSendReminder_URL,
												null,
												(resp) => {
													reminderType == "sendByPost"
														? GetReminderTemplate("FirstPostReminder")
														: GetReminderTemplate("FirstReminder");
												},
												(error) => {}
											);
										}}
									/>
								}
							/>
						</FormControl>
					)}
				</>
			) : null}
			<div>
				{/* enable auto reminder byus or by yourself */}
				{reminderType == "sendByPost" ? (
					<FormControl
						size="small"
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							marginBottom: "20px",
						}}
					>
						<ToggleButtonGroup
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}
							size="small"
							id="sendReminderType"
							value={sendReminderType}
							exclusive
							onChange={(event, reminderType) => {
								if (!blurController) {
								if (reminderType == null) {
									setSendReminderType("ForYou");
								} else {
									setSendReminderType(reminderType);
								}
								}
								if (blurController) {
									enqueueSnackbar(
										getTranslation(
											"Looks Like You Have Changed Something in Template Kindly Save Setting",
											"Looks Like You Have Changed Something in Template Kindly Save Setting",
											"Looks Like You Have Changed Something in Template Kindly Save Setting"
										),
										{
											variant: "info",
										}
									);
								}
							}}
						>
							<ToggleButton value={"ForYou"} aria-label="left aligned">
								<Typography variant="h6">
									{getTranslation("For You", "For You", "For You")}
								</Typography>
							</ToggleButton>
							<ToggleButton value={"ByYou"} aria-label="left aligned">
								<Typography variant="h6">
									{getTranslation("By You", "By You", "By You")}
								</Typography>
							</ToggleButton>
						</ToggleButtonGroup>
						{sendReminderType == "ForYou" ? (
							<FormHelperText style={{ color: "red" }}>
								{getTranslation(
									"Enable reminder by us might occur cost for sending reminder",
									"Enable reminder by us might occur cost for sending reminder",
									"Enable reminder by us might occur cost for sending reminder"
								)}
							</FormHelperText>
						) : (
							""
						)}
					</FormControl>
				) : (
					""
				)}
				{reminderType == "sendByPost" ? (
					<Grid
						container
						spacing={2}
						style={{ marginTop: "10px", marginBottom: "10px" }}
					>
						<Grid item xs={12} sm={6} md={4} lg={3}>
							<TextField
								fullWidth
								variant="outlined"
								type="number"
								value={reminderCost}
								label={getTranslation(
									"Remainder Cost",
									"Remainder Cost",
									"Remainder Cost"
								)}
								size="small"
								InputProps={{ inputProps: { min: 0 } }}
								onChange={(e) => {
									setReminderCost(e.target.value);
								}}
								onBlur={(e) => {
									postReminderCost(e.target.value);
								}}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
					</Grid>
				) : (
					""
				)}

				{/* reminder setting toggle button  */}
				<ToggleButtonGroup
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
					size="small"
					id="reminderKey"
					value={reminderKey}
					exclusive
					onChange={(event, reminderkey) => {
						if (!blurController) {
						if (reminderkey == null) {
							setReminderKey(
								reminderType == "sendByPost"
									? "FirstPostReminder"
									: "FirstReminder"
							);
						} else {
							setReminderKey(reminderkey);
						}
						}
						if (blurController) {
							enqueueSnackbar(
								getTranslation(
									"Looks Like You Have Changed Something in Template Kindly Save Setting",
									"Looks Like You Have Changed Something in Template Kindly Save Setting",
									"Looks Like You Have Changed Something in Template Kindly Save Setting"
								),
								{
									variant: "success",
								}
							);
						}
					}}
				>
					<ToggleButton
						value={
							reminderType == "sendByPost"
								? "FirstPostReminder"
								: "FirstReminder"
						}
						aria-label="left aligned"
					>
						<Typography variant="h6">
							{getTranslation("1st Reminder", "1er rappel", "Erste Erinnerung")}
						</Typography>
					</ToggleButton>
					<ToggleButton
						value={
							reminderType == "sendByPost"
								? "SecondPostReminder"
								: "SecondReminder"
						}
						aria-label="left aligned"
					>
						<Typography variant="h6">
							{getTranslation(
								"2nd Reminder",
								"2ème rappel",
								"Zweite Erinnerung"
							)}
						</Typography>
					</ToggleButton>
					<ToggleButton
						value={
							reminderType == "sendByPost"
								? "ThirdPostReminder"
								: "ThirdReminder"
						}
						aria-label="centered"
					>
						<Typography variant="h6">
							{getTranslation(
								"3rd Reminder",
								"3ème rappel",
								"Dritte Erinnerung"
							)}
						</Typography>
					</ToggleButton>
				</ToggleButtonGroup>

				<FormLoader progress={reminderTemplateLoading}>
					<Formik
						enableReinitialize
						initialValues={defaultValue}
						validationSchema={
							reminderType == "sendByPost"
								? basicPostValidationSchema
								: basicValidationSchema
						}
						onSubmit={(values, actions) => {
							actions.setSubmitting(true);
							values.reminderKey = reminderKey;
							if (reminderType == "sendByPost") {
								postManagePostReminder({
									GetManageReminderForAppUser: sendReminderType,
								});
							}
							setLoading(true);
							setBlurController(false);
							Post(
								values,
								Post_UpdateReminderTemplate_URL,
								null,
								(resp) => {
									actions.setSubmitting(false);
									enqueueSnackbar(
										getTranslation(
											"Reminder template updated successfully",
											"Reminder template updated successfully",
											"Reminder template updated successfully"
										),
										{
											variant: "success",
										}
									);
									setLoading(false);
								},
								(error) => {
									actions.setSubmitting(false);
									enqueueSnackbar(error.data, { variant: "error" });
									setLoading(false);
								}
							);
						}}
					>
						{({
							values,
							setFieldValue,
							errors,
							touched,
							handleChange,
							handleSubmit,
							isSubmitting,
							getFieldProps,
						}) => (
							<>
								<Form style={{ marginTop: "20px" }}>
									<>
										<Grid container spacing={2}>
											<Grid item xs={12} sm={6} md={4} lg={3}>
												<TextField
													fullWidth
													variant="outlined"
													required
													type="number"
													id="dayOfMonth"
													label={getTranslation(
														"Interval (Days)",
														"Intervalle en jours",
														"Interval (Tage)"
													)}
													name="dayOfMonth"
													size="small"
													//value={values.dayOfMonth}
													{...getFieldProps("dayOfMonth")}
													error={
														touched.dayOfMonth && Boolean(errors.dayOfMonth)
													}
													inputProps={{ min: 0 }}
													helperText={touched.dayOfMonth && errors.dayOfMonth}
													InputLabelProps={{
														shrink: true,
													}}
													InputProps={{ inputProps: { min: 0 } }}
												/>
											</Grid>

											<Grid item xs={12} sm={6} md={4} lg={3}>
												<TextField
													fullWidth
													variant="outlined"
													required
													id="subject"
													label={getTranslation("Subject", "Matière", "Thema")}
													name="subject"
													size="small"
													value={subject}
													onChange={(e) => {
														setSubject(e.target.value);
														console.log(e.target.value);
														//setFieldValue("subject", subject);

														switch (selectedLanguage) {
															case "english":
																setFieldValue("subject", subject);
																break;
															case "french":
																setFieldValue("subjectFrench", subject);
																break;
															case "german":
																setFieldValue("subjectGerman", subject);
																break;
															default:
																setFieldValue("subject", subject);
														}
													}}
													//value={values.dayOfMonth}
													//{...getFieldProps("subject")}
													error={touched.subject && Boolean(errors.subject)}
													helperText={touched.subject && errors.subject}
													InputLabelProps={{
														shrink: true,
													}}
												/>
											</Grid>

											<Grid>
												<Box display="flex" justifyContent="center" mt={1}>
													<ButtonWithLoading
														title={getTranslation(
															"Save Setting",
															"Save Setting",
															"Save Setting"
														)}
														style={{
															backgroundColor: "rgb(229,60,22)",
															color: "white",
															fontWeight: "bold",
														}}
														size="medium"
														variant="contained"
														loading={loading}
														onClick={() => {
															setBlurController(false);
															switch (selectedLanguage) {
																case "english":
																	setFieldValue(
																		"template",
																		editorRef?.current?.getContent()
																	);
																	break;
																case "french":
																	setFieldValue(
																		"templateFrench",
																		editorRef?.current?.getContent()
																	);
																	break;
																case "german":
																	setFieldValue(
																		"templateGerman",
																		editorRef?.current?.getContent()
																	);
																	break;
																default:
																	setFieldValue(
																		"template",
																		editorRef?.current?.getContent()
																	);
															}
															handleSubmit();
														}}
													/>
												</Box>
										</Grid>
										</Grid>

										<Grid container spacing={2}>
											<Grid item xs={12} sm={12} md={12} lg={12}>
												<Typography variant="h5">
													{getTranslation(
														"Reminder Text",
														"Texte de rappel",
														"Erinnerungstext"
													)}
												</Typography>
											</Grid>
										</Grid>
										{/* dynamic buttons */}
										<div
											style={{
												display: "flex",
												justifyContent: "left",
												flexWrap: "wrap",
											}}
										>
											<Button
												className={classes.root}
												size="small"
												onClick={() => {
													AppendTemplate(
														values.template,
														"<span>[tenant_name]</span>",
														setFieldValue
													);
												}}
											>
												{getTranslation("Name", "Name", "Name")}
											</Button>
											<Button
												size="small"
												className={classes.root}
												onClick={() =>
													AppendTemplate(
														values.template,
														"<span>[tenant_email]</span>",
														setFieldValue
													)
												}
											>
												{getTranslation("Email", "Email", "Email")}
											</Button>
											<Button
												size="small"
												className={classes.root}
												onClick={() =>
													AppendTemplate(
														values.template,
														"<span>[tenant_reference]</span>",
														setFieldValue
													)
												}
											>
												{getTranslation("Reference", "Reference", "Reference")}
											</Button>
											<Button
												size="small"
												className={classes.root}
												onClick={() =>
													AppendTemplate(
														values.template,
														"<span>[due_Date]</span>",
														setFieldValue
													)
												}
											>
												{getTranslation(
													"Due Date",
													"Date d'échéance",
													"Geburtstermin"
												)}
											</Button>
											<Button
												size="small"
												className={classes.root}
												onClick={() =>
													AppendTemplate(
														values.template,
														"<span>[tenant_appartment]</span>",
														setFieldValue
													)
												}
											>
												{getTranslation("Apartment", "Logement", "Wohnungen")}
											</Button>

											<Button
												size="small"
												className={classes.root}
												onClick={() =>
													AppendTemplate(
														values.template,
														"<span>[town]</span>",
														setFieldValue
													)
												}
											>
												{getTranslation("Town", "Town", "Town")}
											</Button>
											<Button
												size="small"
												className={classes.root}
												onClick={() =>
													AppendTemplate(
														values.template,
														"<span>[zip_code]</span>",
														setFieldValue
													)
												}
											>
												{getTranslation("Zip Code", "Zip Code", "Zip Code")}
											</Button>

											<Button
												size="small"
												className={classes.root}
												onClick={() =>
													AppendTemplate(
														values.template,
														"<span>[current_date]</span>",
														setFieldValue
													)
												}
											>
												{getTranslation(
													"Current Date",
													"Current Date",
													"Current Date"
												)}
											</Button>
											<Button
												size="small"
												className={classes.root}
												onClick={() =>
													AppendTemplate(
														values.template,
														"<span>[due_Amount]</span>",
														setFieldValue
													)
												}
											>
												{getTranslation(
													"Due Amount",
													"Montant dû",
													"Geburtstermin"
												)}
											</Button>

											<Button
												size="small"
												className={classes.root}
												onClick={() =>
													AppendTemplate(
														values.template,
														"<span>[appartment_address]</span>",
														setFieldValue
													)
												}
											>
												{getTranslation(
													"Apartment Address",
													"Apartment Address",
													"Apartment Address"
												)}
											</Button>
											<Button
												size="small"
												className={classes.root}
												onClick={() => {
													var parser = new DOMParser();
													var htmlDoc = parser.parseFromString(
														editorRef.current.getContent(),
														"text/html"
													);
													var element = htmlDoc.getElementById("letterHead");
													if (element) element.remove();
													var XML = new XMLSerializer();

													editorRef.current.setContent(
														`<table id="letterHead" style="width: 100%;border:none">
							  							<tr style="height:20mm;min-height:20mm">
							  							<td  width="113mm" style="vertical-align: center;" ><div >Marcel Schons</div><div>Zur Kaiserbaracke</div><div>4780 Recht</div><div>0496 / 270 899</div></td><td  width="67mm"></td>
														</tr>
														<tr style="height:40mm;" style="vertical-align: top; border:1px solid black">
														<td width="113mm"  style="vertical-align: top;"></td>
														<td width="67mm" style="padding-left:10mm;vertical-align: top;">
														<div style="text-decoration: underline;"><strong>Letter envelop</strong></div>
														<div style="margin-top:5mm;">[tenant_appartment]</div>
														<div>[address]</div>
														<div>[zip_code] [town]</div>
														</td>														
														</tr>														
														</table>
														` + XML.serializeToString(htmlDoc)
													);
												}}
											>
												{getTranslation(
													"Add Letter Header",
													"Add Letter Header",
													"Add Letter Header"
												)}
											</Button>
										</div>

										<Grid container spacing={2}>
											<Grid item xs={12} sm={12} md={12} lg={12}>
												<TextEditor
													translatedText={
														reminderKey === "FirstReminder" ||
														reminderKey === "FirstPostReminder"
															? getTranslation(
																	"1st Reminder",
																	"1er Rappel",
																	"1. Erinnerung"
															  )
															: reminderKey === "SecondReminder" ||
															  reminderKey === "SecondPostReminder"
															? getTranslation(
																	"2nd Reminder",
																	"2ème Rappel",
																	"2nd Erinnerung"
															  )
															: getTranslation(
																	"3rd Reminder",
																	"3rd Rappel",
																	"3rd Erinnerung"
															  )
													}
													selectedLanguage={selectedLanguage}
													reminderKey={reminderKey}
													openTestSmsDialoge={openTestSmsDialoge}
													handleLanguage={handleLanguage}
													title={
														reminderType == "sendByPost"
															? "Send Test Email"
															: "Send Test Email"
													}
												>
													<CardContent>
														<HtmlEditor
															ref={editorRef}
															text={
																selectedLanguage === "english"
																	? reminderType === "sendByPost"
																		? postReminderTemplate?.template
																		: reminderTemplate?.template
																	: selectedLanguage === "french"
																	? reminderType === "sendByPost"
																		? postReminderTemplate?.templateFrench
																		: reminderTemplate?.templateFrench
																	: selectedLanguage === "german"
																	? reminderType === "sendByPost"
																		? postReminderTemplate?.templateGerman
																		: reminderTemplate?.templateGerman
																	: ""
															}
															onChange={(text) => {
																setEnableReloading(true);
																switch (selectedLanguage) {
																	case "english":
																		setFieldValue("template", text);
																		break;
																	case "french":
																		setFieldValue("templateFrench", text);
																		break;
																	case "german":
																		setFieldValue("templateGerman", text);
																		break;
																	default:
																		setFieldValue("template", text);
																}
																setEditorValue(text);
																setEnableReloading(false);
																handleSubmit();
															}}
															// buttonText={getTranslation(
															// 	"Save Settings",
															// 	"Save Settings",
															// 	"Save Settings"
															// )}
															isLoading={false}
															isSubmitting={loading}
															setBlurController={setBlurController}
															blurhandler={true}
														/>
														<br />
														<Box
															pr={1}
															pb={1}
															width="100%"
															display="flex"
															alignItems="center"
															justifyContent="center"
														></Box>
													</CardContent>
												</TextEditor>
												<TestSmsDialoge
													template={editorRef?.current?.getContent()}
													title="Send Test Email"
													isOpen={testSmsDialoge}
													onClose={() => setSmsDialoge(false)}
													editor={editorValue}
													reminderType={reminderType}
												/>
											</Grid>
										</Grid>
									</>
								</Form>
							</>
						)}
					</Formik>
				</FormLoader>
			</div>

			<br />
		</>
	);
};
