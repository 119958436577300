import {
	Box,
	Button,
	Card,
	CardContent,
	CardHeader,
	Divider,
	Grid,
	Typography,
	CircularProgress,
} from "@material-ui/core";
import { useState } from "react";
import { DoneIcon, HomeIcon, WalletIcon } from "../../../components/icons";
import { getTranslation } from "../../../heplers/translationHelper";
import { format } from "../../../heplers/format";
import styles from "./dashBoardCard.module.css";
import CardComponent from "../../../components/Card";
import { useHistory } from "react-router-dom";

export const DashboardCard = ({ title, data, onClickDetails }) => {
	const history = useHistory();

	const handleCardClick = () => {
		if (data?.title === "Banks") {
			history.push("/bank");
		} else if (data?.title === "Dues") {
			history.push("/dues/duesGenerated");
		} else if (data?.title === "Occupied Apartments") {
			history.push("/buildings");
		}
	};
	return (
		<>
			{data ? (
				<Card
					style={{
						boxShadow: "3px 3px 4px rgb(0,68,103,0.3)",
					}}
					className={styles.dashboardCard}
					onClick={() => {
						handleCardClick();
					}}
				>
					<CardHeader
						title={getTranslation(data?.title, data?.title, data?.title)}
						style={{
							backgroundColor: "rgb(0,68,103)",
							color: "white",
							padding: "10px 20px",
							height: "27px",
							borderRadius: "0px 0px 2px 2px",
						}}
					/>
					<CardContent
						style={{
							overflow: "auto",
							backgroundColor: "rgb(230,231,245)",
							height: "100%",
							padding: "20px 20px",
							color: "rgba(0, 0, 0, 0.8)",
						}}
					>
						{data?.data?.length !== 0 ? (
							data.data.map((element, index) => (
								<div key={index} className={styles.dashboardCardContent}>
									<Typography style={{ width: "100%", fontSize: "12px" }}>
										{getTranslation(element?.key, element?.key, element?.key)}
									</Typography>
									<Typography
										noWrap
										align="right"
										style={{ width: "40%", fontSize: "12px" }}
									>
										{element?.value
											? element?.key === "Rented Apartments:"
												? format("number", element?.value)
												: format("number", parseFloat(element?.value)) +
												  " " +
												  "€"
											: "-"}
									</Typography>
								</div>
							))
						) : (
							<div
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									marginTop: "10%",
								}}
							>
								{getTranslation(
									"No Record Found",
									"No Record Found",
									"No Record Found"
								)}
							</div>
						)}
						<br />
						<br />
					</CardContent>
				</Card>
			) : (
				<Card
					style={{
						boxShadow: "0px 3px 4px rgba(0, 0, 0, 0.2)",
						backgroundColor: "rgb(230,231,245)",
						textAlign: "center",
					}}
					className={styles.dashboardCard}
				>
					<CardHeader
						title={getTranslation(
							data?.title ? data.title : "",
							data?.title ? data.title : "",
							data?.title ? data.title : ""
						)}
						style={{
							backgroundColor: "rgb(0,68,103)",
							color: "white",
							padding: "10px 15px",
						}}
					/>

					<CardContent style={{ width: "100%", overflow: "auto" }}>
						<CircularProgress />
					</CardContent>
				</Card>
			)}
		</>
	);
};

const GetIcon = (type) => {
	return (
		<div>
			{type.type === 0 ? (
				<WalletIcon />
			) : type.type === 1 ? (
				<HomeIcon />
			) : (
				<DoneIcon />
			)}
		</div>
	);
};
