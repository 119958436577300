import { Box, Button, CardContent, Typography } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Get } from "../../actions";
import { Get_BuildingsProfitBar_URL } from "../../constants/apiUrls";
import { FinancialReportBuildings } from "./components/financialReportBuildings";
import { DashBoardCardList } from "./dashboardCardList";
import { getTranslation } from "../../heplers/translationHelper";
import { Helmet } from "react-helmet";
import {
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	Paper,
	TextField,
	Grid,
	Tab,
	Tabs,
	TabPanel,
} from "@material-ui/core";
import CardComponent from "../../components/Card";
import DashBoardDataTable from "./components/dashbordDataTable";
import { ActionsRequired } from "./components/actionsRequired";

export const Dashboard = () => {
	const initialTableData = [
		{
			apartment: "Apartment 1",
			rent: 550,
			proposal: 633.67,
			loss: 50,
			newRent: parseFloat(
				(633.67 - 550 - (633.67 - 550) * (50 / 100) + 550).toFixed(2)
			),
		},
		{
			apartment: "Apartment 2",
			rent: 1200,
			proposal: 12,
			loss: 99,
			newRent: parseFloat(
				(12 - 1200 - (12 - 1200) * (99 / 100) + 1200).toFixed(2)
			),
		},
	];

	const [tableData, setTableData] = useState(initialTableData);
	const [tabValue, setTabValue] = useState("0");
	const [indexationList, setIndexationList] = useState([]);
	const [indexationListCount, setIndexationListCount] = useState([]);
	const [indexationListData, setIndexationLisData] = useState([]);
	const [recoveryList, setRecoveryList] = useState([]);
	const [dueList, setDueList] = useState([]);

	const handleChange = (event, newValue) => {
		if (newValue != null) setTabValue(newValue);
	};

	const [profitbarData, setProfitbarData] = useState([]);
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch({ type: "Clear_All_BreadCrumb" });
		dispatch({
			type: "Add_BreadCrumb",
			payload: {
				title: getTranslation(
					" Dashboard ",
					" Tableau de bord ",
					" Armaturenbrett "
				),
				url: "/dashboard",
			},
		});
	}, []);

	useEffect(() => {
		Get(
			{},
			Get_BuildingsProfitBar_URL,
			null,
			(resp) => {
				setProfitbarData(resp?.data);
			},
			(error) => {}
		);
	}, []);

	return (
		<div>
			<Helmet>
				<title>{getTranslation("Dashboard", "Dashboard", "Dashboard")}</title>
			</Helmet>
			<DashBoardCardList />
			<br />
			<ActionsRequired />
			{/* {indexationList?.length == 0 && recoveryList?.length == 0 ? (
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "28vh",
					}}
				>
					<Typography
						style={{
							padding: "10px",
							borderRadius: "5px",
							color: "grey",
						}}
						variant="h4"
					>
						No Actions Required
					</Typography>
				</div>
			) : (
				<> {" "}*/}
			<br />
			<div
				style={{
					display: "flex",
					justifyContent: "center",
				}}
			>
				<ToggleButtonGroup
					value={tabValue}
					exclusive
					onChange={handleChange}
					aria-label="tab"
				>
					<ToggleButton
						value="0"
						style={{
							backgroundColor: tabValue === "0" ? "rgb(0, 68, 103)" : "inherit",
							color: tabValue === "0" ? "white" : "rgb(0, 68, 103)",
							fontWeight: "bold",
							borderRadius: "7px",
							height: "45px",
							fontSize: "12px",
							marginRight: "10px",
						}}
					>
						{getTranslation(
							"Indexation Pending",
							"Indexation Pending",
							"Indexation Pending"
						)}
						<div
							style={{
								display: "flex",
								width: "21px",
								height: "21px",
								borderRadius: "25px",
								backgroundColor: "rgb(229,60,22)",
								color: "white",
								fontWeight: "bold",
								marginBottom: "10px",
								marginLeft: "10px",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<span>
								{indexationListCount?.length > 10
									? "9+"
									: indexationListCount?.totalCount}
							</span>
						</div>
					</ToggleButton>
					<ToggleButton
						value="1"
						style={{
							backgroundColor: tabValue === "1" ? "rgb(0, 68, 103)" : "inherit",
							color: tabValue === "1" ? "white" : "rgb(0, 68, 103)",
							fontWeight: "bold",
							borderRadius: "7px",
							height: "45px",
							fontSize: "12px",
							marginRight: "10px",
						}}
					>
						{getTranslation(
							"Recovery Procedure",
							"Recovery Procedure",
							"Recovery Procedure"
						)}
						<div
							style={{
								display: "flex",
								width: "21px",
								height: "21px",
								borderRadius: "25px",
								backgroundColor: "rgb(229,60,22)",
								color: "white",
								fontWeight: "bold",
								marginBottom: "10px",
								marginLeft: "10px",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<span>
								{recoveryList?.length > 10 ? "9+" : recoveryList?.totalCount}
							</span>
						</div>
					</ToggleButton>
					<ToggleButton
						value="2"
						style={{
							backgroundColor: tabValue === "2" ? "rgb(0, 68, 103)" : "inherit",
							color: tabValue === "2" ? "white" : "rgb(0, 68, 103)",
							fontWeight: "bold",
							borderRadius: "7px",
							height: "45px",
							fontSize: "12px",
							marginRight: "10px",
						}}
					>
						{getTranslation("Confirm Dues", "Confirm Dues", "Confirm Dues")}
						<div
							style={{
								display: "flex",
								width: "21px",
								height: "21px",
								borderRadius: "25px",
								backgroundColor: "rgb(229,60,22)",
								color: "white",
								fontWeight: "bold",
								marginBottom: "10px",
								marginLeft: "10px",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<span>
								{recoveryList?.length > 10 ? "9+" : dueList?.totalCount}
							</span>
						</div>
					</ToggleButton>
				</ToggleButtonGroup>
			</div>
			<br />
			<DashBoardDataTable
				dataType={tabValue}
				setRecoveryList={setRecoveryList}
				setIndexationList={setIndexationList}
				setIndexationListCount={setIndexationListCount}
				recoveryList={recoveryList}
				indexationList={indexationList}
				indexationListCount={indexationListCount}
				dueList={dueList}
				setDueList={setDueList}
			/>
			{/* </>
			)} */}
		</div>
	);
};
