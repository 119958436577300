import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Typography from "@material-ui/core/Typography";
import StartRecoveryDialog from "./startRecoveryDialog";
import { useSnackbar } from "notistack";
import RecoveryPersons from "./recoveryComponents/RecoveryPersons";
import { getTranslation } from "../../../../../heplers/translationHelper";

export const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
	},
	backButton: {
		marginRight: theme.spacing(1),
	},
	instructions: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
}));

function getSteps() {
	return [
		getTranslation("Select tenant", "Select tenant", "Select tenant"),
		getTranslation("First Person *", "First Person *", "First Person *"),
		getTranslation(
			"Second Person (Optional)",
			"Second Person (Optional)",
			"Second Person (Optional)"
		),
		getTranslation(
			"Third Person (Optional)",
			"Third Person (Optional)",
			"Third Person (Optional)"
		),
	];
}

const RecoveryProcedureStepper = ({
	tenantList,
	tenantDetailLis,
	tenantData,
	isEdit,
	editRecoveryData,
	onClose,
	handleSubmit,
	onUpdate,
	isLoading,
	phoneCall,
}) => {
	const initialValue = {
		tenantId: "",
		comments: "",
		recoveryProcedureStatus: "",
		recoveryPersons: [],
	};

	const classes = useStyles();
	const [activeStep, setActiveStep] = React.useState(0);
	const { enqueueSnackbar } = useSnackbar();
	const [recoveryProcedureStepperData, setRecoveryProcedureStepperData] =
		useState(editRecoveryData ? editRecoveryData : initialValue);

	const postRecoveryProcedure = (payload) => {
		if (isEdit) {
			onUpdate(payload);
		} else handleSubmit(payload);
	};

	const steps = getSteps();

	//call the child compoenet step by step
	function getStepContent(stepIndex, tenantDetails, tenantData) {
		switch (stepIndex) {
			case 0:
				return (
					<StartRecoveryDialog
						tenantDetails={tenantDetails}
						tenantData={tenantData}
						NextStep={handleNext}
						isEdit={isEdit}
						initialValues={recoveryProcedureStepperData}
						activeStep={activeStep}
						phoneCall={phoneCall}
					/>
				);
			case 1:
				return (
					<RecoveryPersons
						NextStep={handleNext}
						BackStep={handleBack}
						initialValue={recoveryProcedureStepperData}
						activeStep={activeStep}
						personKey="FirstPerson"
						editValue={recoveryProcedureStepperData.recoveryPersons.find(
							(x) => x.recoveryPersonKey == "FirstPerson"
						)}
					/>
				);
			case 2:
				return (
					<RecoveryPersons
						NextStep={handleNext}
						BackStep={handleBack}
						initialValue={recoveryProcedureStepperData}
						activeStep={activeStep}
						personKey="SecondPerson"
						isEdit={isEdit}
						editValue={recoveryProcedureStepperData.recoveryPersons.find(
							(x) => x.recoveryPersonKey == "SecondPerson"
						)}
					/>
				);
			case 3:
				return (
					<RecoveryPersons
						NextStep={handleNext}
						BackStep={handleBack}
						initialValue={recoveryProcedureStepperData}
						activeStep={activeStep}
						personKey="ThirdPerson"
						isLoading={isLoading}
						isEdit={isEdit}
						editValue={recoveryProcedureStepperData.recoveryPersons.find(
							(x) => x.recoveryPersonKey == "ThirdPerson"
						)}
						phoneCall={phoneCall}
					/>
				);
			default:
				return "Unknown stepIndex";
		}
	}

	// move forword to next step
	const handleNext = (values) => {
		setRecoveryProcedureStepperData(values);
		if (activeStep == 3) {
			const temp = values.recoveryPersons.filter((x) => x.name != "");
			values.recoveryPersons = temp;
			postRecoveryProcedure(values);
		}
		if (activeStep != 3) {
			setActiveStep((prevActiveStep) => prevActiveStep + 1);
		}
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	return (
		<div className={classes.root}>
			<Stepper activeStep={activeStep} alternativeLabel>
				{steps.map((label) => (
					<Step key={label}>
						<StepLabel>{label}</StepLabel>
					</Step>
				))}
			</Stepper>
			<div>
				<div>
					<Typography className={classes.instructions}>
						{getStepContent(activeStep, tenantDetailLis, tenantData)}
					</Typography>
				</div>
			</div>
		</div>
	);
};

export default RecoveryProcedureStepper;
