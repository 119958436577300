import { getTranslation } from '../../../../heplers/translationHelper';
import TableComponent from '../../../../components/table';
import ButtonWithLoading from '../../../../components/ButtonWithLoading';
import { Box } from '@material-ui/core';
import GetAppIcon from "@material-ui/icons/GetApp";
import { useState } from 'react';

const columns = [
  {
    id: "valueDate",
    numeric: false,
    disablePadding: true,
    label:getTranslation("Value Date", "Value Date", "Value Date"),
  },
  {
    id: "counterPartName",
    numeric: true,
    disablePadding: true,
    label:  getTranslation(
          "CounterPart Name",
          "CounterPart Name",
          "CounterPart Name"
        )
  },
  {
    id: "counterPartRefrence",
    numeric: true,
    disablePadding: true,
    label:  getTranslation(
          "CounterPart Refrence",
          "CounterPart Refrence",
          "CounterPart Refrence"
        )
  },
  {
    id: "amount",
    align: "left",
    label:getTranslation("Amount", "Montant", "Betrag") ,
  },
];

function PreviewUploadDocument() {
  const [csvButtonLoading,setCsvButtonLoading]=useState(false);

  const rowsData=
  [
    {
      "valueDate": "MM/DD/YYYY",
      "counterPartName": "string type",
      "counterPartRefrence":"BE1234567890",
      "amount": -10000
    },
    {
      "valueDate": "MM/DD/YYYY",
      "counterPartName": "string type",
      "counterPartRefrence":"BE1234567890",
      "amount": 100
    },
    {
      "valueDate": "MM/DD/YYYY",
      "counterPartName": "string type",
      "counterPartRefrence":"BE1234567890",
      "amount": 258.36
    },
    {
      "valueDate": "MM/DD/YYYY",
      "counterPartName": "string type",
      "counterPartRefrence":"BE1234567890",
      "amount": -150
    },
    {
      "valueDate": "MM/DD/YYYY",
      "counterPartName": "string type",    
      "counterPartRefrence":"BE1234567890",
      "amount": 150
    },
    {
      "valueDate": "MM/DD/YYYY",
      "counterPartName": "string type",
      "counterPartRefrence":"BE1234567890",
      "amount": 575
    },
    {
      "valueDate": "MM/DD/YYYY",
      "counterPartName": "string type",
      "counterPartRefrence":"BE1234567890",
      "amount": -50
    },
    {
      "valueDate": "MM/DD/YYYY",
      "counterPartName": "string type",
      "counterPartRefrence":"BE1234567890",
      "amount": 1725
    },
    {
      "valueDate": "MM/DD/YYYY",
      "counterPartName": "string type",
      "counterPartRefrence":"BE1234567890",
      "amount": 622
    },
    {
      "valueDate": "MM/DD/YYYY",
      "counterPartName": "string type",
      "counterPartRefrence":"BE1234567890",
      "amount": 37
    },

   ]
   
   var csvFileData = [
    ['07/08/2023', 'Marcel jack','BE1234567890', '30'],
    ['07/08/2023', 'Marcel jack','BE1234567890', '30'],
    ['07/08/2023', 'Marcel jack','BE1234567890', '60'],
    ['07/08/2023', 'Marcel jack','BE1234567890', '60'],
    ['07/08/2023', 'Marcel jack','BE1234567890', '30'],
    ['07/08/2023', 'Marcel jack','BE1234567890', '70'],
    ['07/08/2023', 'Marcel jack','BE1234567890', '40'],
    ['07/08/2023', 'Marcel jack','BE1234567890', '30'],
    ['07/08/2023', 'Marcel jack','BE1234567890', '-80'],
    ['07/08/2023', 'Marcel jack','BE1234567890', '-97'],
    ['07/08/2023', 'Marcel jack','BE1234567890', '45'],
    ['07/08/2023', 'Marcel jack','BE1234567890', '34'],
    ['07/08/2023', 'Marcel jack','BE1234567890', '78'],
    ['07/08/2023', 'Marcel jack','BE1234567890', '-14'],


];

//create a user-defined function to download CSV file   
const download_csv_file=()=> {
    //define the heading for each row of the data  
    var csv = 'valueDate,counterPartRefrence,counterPartName,amount\n';

    //merge the data with CSV  
    csvFileData.forEach(function(row) {
        csv += row.join(',');
        csv += "\n";
    });

    var hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
    hiddenElement.target = '_blank';

    //provide the name for the CSV file to be downloaded  
    hiddenElement.download = 'Bank-Transaction-Sample.csv';
    hiddenElement.click();
}

  return (
    <div>
       <Box
            style={{
              display: "flex",
              justifyContent: "flex-end",
              margin: "20px",
              flexWrap: "wrap",
            }}
          >
            <ButtonWithLoading
              onClick={()=>{
                download_csv_file();
              }}
              variant="contained"
              color="primary"
              title={
                <>
                  {" "}
                  <GetAppIcon />
                  &nbsp;{" "}
                  {getTranslation(
                    "Download Sample(.CSV)",
                    "Download Sample(.CSV)",
                    "Download Sample(.CSV)"
                  )}
                </>
              }
              size="medium"
              loading={csvButtonLoading}
            />
          </Box>
      <TableComponent
      dense 
      colums={columns}
      rows={rowsData}
      count={rowsData.length}
      />
     
    </div>
  );
}

export default PreviewUploadDocument;