import { Box, Grid, Typography, makeStyles } from "@material-ui/core";
import React from "react";
import { getTranslation } from "../../heplers/translationHelper";
import _ from "lodash";
import "./reportStyle.css";
import authService from "../../utils/authUtils";
import LogoFile2 from "../../assests/images/Latest-Logo.png";

export const reportStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		height: "100%",
	},
	headSection: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
	},
	titleStyles: {
		fontWeight: "bold",
		fontSize: "12px",
	},

	subtitle: {
		fontSize: "12px",
	},
	endCards: {
		fontWeight: "bold",
		fontSize: "10px",
	},
	dateStyle: {
		color: "#EB5C3E !important",
		fontWeight: 500,
		fontSize: "12px",
		marginRight: "5px",
	},
	maltoseTitle: {
		color: "#EB5C3E !important",
		fontWeight: 400,
		fontSize: "12px",
	},
	filterSection: {
		margin: "40px 0px",
		width: "100%",
		height: "100px",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		flexDirection: "column",
	},
	table: {
		width: "100%",
		borderCollapse: "collapse",
		marginBottom: "5px",
		"& tfoot": {
			pageBreakInside: "avoid",
			pageBreakAfter: "avoid",
			pageBreakBefore: "avoid",
		},
		"& th ": {
			border: "2px solid #fff !important",
			padding: "5px",
			backgroundColor: "#EBEAE8",
		},
		"& td": {
			padding: "2px",
			margin: "2px",
		},
		"& tr": {
			pageBreakInside: "avoid",
			pageBreakAfter: "auto",
		},
		" & tr:nth-child(even) ": {
			backgroundColor: "#EBEAE8",
		},
	},
}));

export const LetterReportLayout = React.forwardRef(
	({ children, noData, tenant }, ref) => {
		const classes = reportStyles();
		var user = authService.getUser();
		var organization = authService
			.getCustomerOrganizations()
			?.find((x) => x.customerId == authService.getCustomerId());

		return (
			<div ref={ref} className={classes.root}>
				<table>
					<thead>
						{/* <div style={{ marginBottom: "50px" }}>
							<Box
								style={{
									width: "100%",
									padding: "10px",
									marginBottom: "20px",
								}}
							>
								<Grid container align="right">
									<Grid item sm={12} xs={12} style={{ textAlign: "left" }}>
										<img alt="Logo" src={LogoFile2} style={{ width: "20%" }} />
									</Grid>
								</Grid>
							</Box>
						</div> */}
					</thead>
					<tbody>
						<tr>
							<td>
								{noData ? (
									<Typography>
										{getTranslation(
											" No Data Found ",
											" Aucune donn�e disponible ",
											" Keine Daten gefunden "
										)}
									</Typography>
								) : (
									<div style={{ marginTop: "20px" }}>{children}</div>
								)}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		);
	}
);
