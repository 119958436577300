import {
  Box,
  Container,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import React from "react";
import { SignUpLogo } from "../../components/Logo";
import Copyright from "../../utils/copyRightUtils";
import { getTranslation } from "../../heplers/translationHelper";
import styles from "../../assests/styles/views/auth/Register";
import {
  EnglishLanguageIcon,
  FrenchLanguageIcon,
  GermanLanguageIcon,
} from "../../components/icons";

function LoginFooter() {
  const classes = styles();
  const isMobileTab = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isTabletTab = useMediaQuery((theme) => theme.breakpoints.down("md"));

  return (
    <div>
      <footer style={{ backgroundColor: "white", margin: "120px 0px 0px 0px" }}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            md={2}
            lg={2}
            xl={2}
            style={
              isMobileTab ? { marginLeft: "20px" } : { marginLeft: "40px" }
            }
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <SignUpLogo
                style={
                  isMobileTab
                    ? {
                        width: "20%",
                        marginLeft: "-30px",
                        //   padding: "6px 6px 6px 6px",
                      }
                    : {}
                }
              />
              <Typography
                variant="body2"
                color="primary"
                align="left"
                fontWeight="bold"
              >
                {getTranslation(
                  "Clic Rent is property management software for professionals and individuals. Automate the management of your rents in just a few clicks",
                  "Clic Rent is property management software for professionals and individuals. Automate the management of your rents in just a few clicks",
                  "Clic Rent is property management software for professionals and individuals. Automate the management of your rents in just a few clicks"
                )}
                .
              </Typography>
            </div>
          </Grid>
          <Grid
            item
            xs={6}
            md={3}
            lg={2}
            xl={2}
            style={{ marginTop: "30px", textAlign: "left" }}
          >
            <Typography
              variant="h4"
              fontWeight="bold"
              gutterBottom
              color="primary"
            >
              {getTranslation("LEARN MORE", "LEARN MORE", "LEARN MORE")}
            </Typography>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                fontSize: "12px",
              }}
              color="primary"
            >
              <a
                className={classes.link}
                href="https://clic-rent.com/a-propos/"
                color="primary"
                style={{ marginTop: "10px" }}
              >
                {getTranslation("Presentation", "Presentation", "Presentation")}
              </a>
              <a
                className={classes.link}
                href="https://clic-rent.com/fonctionnalites/"
                color="primary"
                style={{ marginTop: "10px" }}
              >
                {getTranslation("Features", "Features", "Features")}
              </a>
              <a
                className={classes.link}
                href="https://clic-rent.com/tarifs/"
                color="primary"
                style={{ marginTop: "10px" }}
              >
                {getTranslation("Our prices", "Our prices", "Our prices")}
              </a>
            </Box>
          </Grid>
          <Grid
            item
            xs={6}
            md={3}
            lg={2}
            xl={2}
            style={{ marginTop: "30px", textAlign: "left" }}
          >
            <Typography
              variant="h4"
              fontWeight="bold"
              gutterBottom
              color="primary"
            >
              {getTranslation("RESOURCES", "RESOURCES", "RESOURCES")}
            </Typography>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                fontSize: "12px",
              }}
              color="primary"
            >
              <a
                className={classes.link}
                href="https://clic-rent.com/video-demo/"
                color="primary"
                style={{ marginTop: "10px" }}
              >
                {getTranslation("Demo video", "Demo video", "Demo video")}
              </a>
              <a
                className={classes.link}
                href="https://clic-rent.com/faq/"
                color="primary"
                style={{ marginTop: "10px" }}
              >
                {getTranslation(
                  "Frequently Asked Questions",
                  "Frequently Asked Questions",
                  "Frequently Asked Questions"
                )}
              </a>
              <a
                className={classes.link}
                href="https://clic-rent.com/telechargement/"
                color="primary"
                style={{ marginTop: "10px" }}
              >
                {getTranslation(
                  "Download the guide",
                  "Download the guide",
                  "Download the guide"
                )}
              </a>
            </Box>
          </Grid>
          <Grid
            item
            xs={6}
            md={3}
            lg={2}
            xl={2}
            style={{ marginTop: "30px", textAlign: "left" }}
          >
            <Typography
              variant="h4"
              fontWeight="bold"
              gutterBottom
              color="primary"
            >
              {getTranslation("OUR OFFERS", "OUR OFFERS", "OUR OFFERS")}
            </Typography>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                fontSize: "12px",
              }}
              color="primary"
            >
              <a
                className={classes.link}
                href="https://clic-rent.com/essai-gratuit/"
                color="primary"
                style={{ marginTop: "10px" }}
              >
                {getTranslation("Free try", "Free try", "Free try")}
              </a>
              <a
                className={classes.link}
                href="https://clic-rent.com/masterclass-clic-rent/"
                color="primary"
                style={{ marginTop: "10px" }}
              >
                {getTranslation("Masterclass", "Masterclass", "Masterclass")}
              </a>
              <a
                className={classes.link}
                href="https://clic-rent.com/lifetime-deal/"
                color="primary"
                style={{ marginTop: "10px" }}
              >
                {getTranslation(
                  "Lifetime offer",
                  "Lifetime offer",
                  "Lifetime offer"
                )}
              </a>
            </Box>
          </Grid>
          <Grid
            item
            xs={6}
            md={3}
            lg={2}
            xl={2}
            style={
              isTabletTab && !isMobileTab
                ? { marginLeft: "40px", marginTop: "30px", textAlign: "left" }
                : { marginTop: "30px", textAlign: "left" }
            }
          >
            <Typography
              variant="h4"
              fontWeight="bold"
              gutterBottom
              color="primary"
            >
              {getTranslation("SUPPORT", "SUPPORT", "SUPPORT")}
            </Typography>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                fontSize: "12px",
              }}
              color="primary"
            >
              <a
                className={classes.link}
                href="https://clic-rent.com/contact/"
                color="primary"
                style={{ marginTop: "10px" }}
              >
                {getTranslation("Contact us", "Contact us", "Contact us")}
              </a>
              <a
                className={classes.link}
                href="https://clic-rent.com/politique-de-confidentialite/"
                color="primary"
                style={{ marginTop: "10px" }}
              >
                {getTranslation(
                  "Privacy Policy",
                  "Privacy Policy",
                  "Privacy Policy"
                )}
              </a>
            </Box>
          </Grid>
          <Grid
            item
            xs={6}
            md={3}
            lg={1}
            xl={1}
            style={{ marginTop: "30px", textAlign: "left" }}
          >
            <Typography
              variant="h4"
              fontWeight="bold"
              gutterBottom
              color="primary"
            >
              {getTranslation("Languages", "Languages", "Languages")}
            </Typography>
            <Select
              style={{
                height: "34px",
                borderRadius: "17px",
              }}
              variant="outlined"
              defaultValue={
                localStorage.getItem("lang")
                  ? localStorage.getItem("lang")
                  : "en"
              }
              onChange={(e) => {
                localStorage.setItem("lang", e.target.value);
                window.location.reload();
              }}
            >
              <MenuItem value="en">
                <IconButton>
                  <EnglishLanguageIcon />
                </IconButton>
                {isMobileTab == false
                  ? getTranslation("English", "English", "English")
                  : null}
              </MenuItem>
              <MenuItem value="fr">
                <IconButton>
                  <FrenchLanguageIcon />
                </IconButton>

                {isMobileTab == false
                  ? getTranslation("French", "French", "French")
                  : null}
              </MenuItem>
              <MenuItem value="gr">
                <IconButton>
                  <GermanLanguageIcon />
                </IconButton>
                {isMobileTab == false
                  ? getTranslation("German", "German", "German")
                  : null}
              </MenuItem>
            </Select>
          </Grid>
        </Grid>

        <div style={{ backgroundColor: "white", padding: "1rem" }}>
          <Box mt={3}>
            <Copyright />
          </Box>
        </div>
      </footer>
    </div>
  );
}

export default LoginFooter;
