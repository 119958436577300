import React from "react";
import { getTranslation } from "../../../../../../heplers/translationHelper";

const Thankyou = () => {
	return (
		<html lang="en">
			<head>
				<meta charset="utf-8" />
				<meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1" />
				<meta name="viewport" content="width=device-width, initial-scale=1" />
				<title></title>
				<link
					href="https://fonts.googleapis.com/css?family=Lato:300,400|Montserrat:700"
					rel="stylesheet"
					type="text/css"
				/>
				<style>
					{`
            @import url(//cdnjs.cloudflare.com/ajax/libs/normalize/3.0.1/normalize.min.css);
            @import url(//maxcdn.bootstrapcdn.com/font-awesome/4.2.0/css/font-awesome.min.css);
            /* Your other styles here */
          `}
				</style>
				<link
					rel="stylesheet"
					href="https://2-22-4-dot-lead-pages.appspot.com/static/lp918/min/default_thank_you.css"
				/>
				<script src="https://2-22-4-dot-lead-pages.appspot.com/static/lp918/min/jquery-1.9.1.min.js"></script>
				<script src="https://2-22-4-dot-lead-pages.appspot.com/static/lp918/min/html5shiv.js"></script>
			</head>
			<body>
				<header className="site-header" id="header">
					<h1 className="site-header__title" data-lead-id="site-header-title">
						{getTranslation("THANK YOU!", "THANK YOU!", "THANK YOU!")}
					</h1>
				</header>

				<div className="main-content">
					<i className="fa fa-check main-content__checkmark" id="checkmark"></i>
					<p className="main-content__body" data-lead-id="main-content-body">
						{getTranslation(
							"Thanks a bunch for filling that out. It means a lot to us, just like you do! We really appreciate you giving us a moment of your time today. Thanks for being you.",
							"Thanks a bunch for filling that out. It means a lot to us, just like you do! We really appreciate you giving us a moment of your time today. Thanks for being you.",
							"Thanks a bunch for filling that out. It means a lot to us, just like you do! We really appreciate you giving us a moment of your time today. Thanks for being you."
						)}
					</p>
				</div>

				<footer className="site-footer" id="footer">
					<p className="site-footer__fineprint" id="fineprint">
						Copyright ©2024 | Qollex
					</p>
				</footer>
			</body>
		</html>
	);
};

export default Thankyou;
