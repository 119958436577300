import React, { useEffect, useState } from "react";
import {
	Typography,
	Grid,
	Card,
	CardContent,
	CardHeader,
	Box,
	ButtonGroup,
	Button,
	DialogContent,
	CircularProgress,
} from "@material-ui/core";
import ButtonWithLoading from "../../../components/ButtonWithLoading";
import { Get, Post } from "../../../actions";
import {
	Get_ReminderTemplate_URL,
	Get_SMSReminderTemplate_URL,
	Post_SendSMSReminderToTenant_URL,
} from "../../../constants/apiUrls";
import { useSnackbar } from "notistack";
import { getTranslation } from "../../../heplers/translationHelper";
import { useRef } from "react";
import DialogComponent from "../../../components/Dialog";
import { HtmlEditor } from "../../../components/HtmlEditor";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { buttonStyles } from "./recoveryprocedure/components/recoveryComponents/RecoveryPersons";
import TextEditor from "../../setting/component/TextEditorCard";
import TestSmsDialoge from "../../setting/component/TestingDialoge";

export const SMSReminderTemplateDialog = ({
	reminderToTenant,
	isOpen,
	onClose,
	sendByPost,
	onSubmit,
}) => {
	const { enqueueSnackbar } = useSnackbar();
	const [smsReminderTemplate, setSmsReminderTemplate] = useState();
	const [template, setTemplate] = useState("");
	const [reminderKey, setReminderKey] = useState("FirstSMSReminder");
	const [enableReloading, setEnableReloading] = useState(false);
	const editorRef = useRef(null);
	const classes = buttonStyles();
	const [submitReminderLoading, setSubmitReminderLoading] = useState(false);
	const [selectedLanguage, setSelectedLanguage] = useState("english");
	const [testSmsDialoge, setSmsDialoge] = useState(false);
	const [check, setCheck] = useState("");
	const [editorValue, setEditorValue] = useState("");
	const [loading, setLoading] = useState(false);
	const [templateValue, setTemplateValue] = useState("");
	const [apiLoading, setApiLoading] = useState(false);
	const getTemplateBasedOnLanguage = (template, language) => {
		switch (language) {
			case "english":
				setCheck(template?.template);
				setTemplate(template?.template);
			// return template?.template;
			case "french":
				setCheck(template?.templateFrench);
				setTemplate(template?.templateFrench);
			// return template?.templateFrench;
			case "german":
				setCheck(template?.templateGerman);
				setTemplate(template?.templateGerman);
			//return template?.templateGerman;
			default:
				setTemplate(template?.template);
			// return '';
		}
	};

	const handleLanguage = (language) => {
		setSelectedLanguage(language);
	};

	const openTestSmsDialoge = () => {
		setSmsDialoge(true);
	};

	// useEffect(() => {
	//   GetReminderTemplate(reminderKey);
	// }, []);
	useEffect(() => {
		GetReminderTemplate();
	}, [isOpen, selectedLanguage, reminderKey]);
	const GetReminderTemplate = () => {
		setApiLoading(true);
		Get(
			{ reminderKey: "FirstSMSReminder" },
			Get_ReminderTemplate_URL,
			null,
			(resp) => {
				let templateToSet = "";

				if (selectedLanguage === "english") {
					templateToSet = resp?.data?.template;
				} else if (selectedLanguage === "french") {
					templateToSet = resp?.data?.templateFrench;
				} else if (selectedLanguage === "german") {
					templateToSet = resp?.data?.templateGerman;
				}
				setSmsReminderTemplate(templateToSet);
				setApiLoading(false);
				// setSmsReminderTemplate(resp?.data);
			},
			(error) => {
				enqueueSnackbar("Some thing went wrong", { variant: "error" });
				setApiLoading(false);
			}
		);
	};

	const SendMenualReminder = (temp) => {
		setLoading(true);
		// let temp = template

		// if (template === "") {
		//   temp = smsReminderTemplate?.template
		// }
		// if (sendByPost) {
		//   onSubmit(temp);
		// }
		setSubmitReminderLoading(true);
		Post(
			{
				tenantId: reminderToTenant?.id,
				templateText: temp,
			},
			Post_SendSMSReminderToTenant_URL,
			null,
			(resp) => {
				enqueueSnackbar(
					resp?.data
						? getTranslation(resp?.data, resp?.data, resp?.data)
						: getTranslation(
								"Reminder sent successfully",
								"Reminder sent successfully",
								"Reminder sent successfully"
						  ),
					{ variant: "success" }
				);
				setSubmitReminderLoading(false);
				onClose(false);
				setLoading(false);
			},
			(error) => {
				setLoading(false);
				enqueueSnackbar(error?.data, { variant: "error" });
				setSubmitReminderLoading(false);
			}
		);
	};

	const textRef = useRef();
	const AppendTemplate = (template, text) => {
		editorRef.current.insertContent(text);
	};

	return (
		<>
			<DialogComponent
				open={isOpen}
				onClose={onClose}
				maxWidth
				title={
					sendByPost
						? getTranslation("Send By Post", "Send By Post", "Send By Post")
						: getTranslation("SMS Reminder", "SMS Rappel", "SMS Erinnerung")
				}
			>
				<DialogContent>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={12} md={12} lg={12}>
							<ToggleButtonGroup
								size="small"
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
								}}
								id="reminderKey"
								value={reminderKey}
								exclusive
								onChange={(event, key) => {
									if (key == null) {
										setReminderKey("FirstSMSReminder");
									} else {
										setReminderKey(key);
									}
								}}
							>
								<ToggleButton
									value="FirstSMSReminder"
									aria-label="left aligned"
								>
									<Typography variant="h6">
										{getTranslation(
											"1st Reminder",
											"1er rappel",
											"Erste Erinnerung"
										)}
									</Typography>
								</ToggleButton>
								<ToggleButton
									value="SecondSMSReminder"
									aria-label="left aligned"
								>
									<Typography variant="h6">
										{getTranslation(
											"2nd Reminder",
											"2�me rappel",
											"Zweite Erinnerung"
										)}
									</Typography>
								</ToggleButton>
								<ToggleButton value="ThirdSMSReminder" aria-label="centered">
									<Typography variant="h6">
										{getTranslation(
											"3rd Reminder",
											"3�me rappel",
											"Dritte Erinnerung"
										)}
									</Typography>
								</ToggleButton>
							</ToggleButtonGroup>
						</Grid>
					</Grid>

					{/* dynamic Buttons */}
					<Grid container spacing={2}>
						<Grid item xs={12} sm={12} md={12} lg={12}>
							<Typography variant="h5">
								{getTranslation(
									"Reminder Text",
									"Texte de rappel",
									"Erinnerungstext"
								)}
							</Typography>
						</Grid>
					</Grid>

					{/* dynamic buttons */}
					<div
						style={{
							display: "flex",
							justifyContent: "left",
							flexWrap: "wrap",
						}}
					>
						<Button
							className={classes.root}
							size="small"
							onClick={() =>
								AppendTemplate(
									smsReminderTemplate,
									"<span>[tenant_name]</span>"
								)
							}
						>
							{getTranslation("Name", "Name", "Name")}
						</Button>

						<Button
							size="small"
							className={classes.root}
							onClick={() =>
								AppendTemplate(
									smsReminderTemplate,
									"<span>[tenant_email]</span>"
								)
							}
						>
							{getTranslation("Email", "Email", "Email")}
						</Button>

						<Button
							size="small"
							className={classes.root}
							onClick={() =>
								AppendTemplate(
									smsReminderTemplate,
									"<span>[tenant_reference]</span>"
								)
							}
						>
							{getTranslation("Reference", "Reference", "Reference")}
						</Button>

						<Button
							size="small"
							className={classes.root}
							onClick={() =>
								AppendTemplate(
									smsReminderTemplate,
									"<span>[tenant_appartment]</span>"
								)
							}
						>
							{getTranslation("Apartment", "Logement", "Wohnungen")}
						</Button>

						<Button
							size="small"
							className={classes.root}
							onClick={() =>
								AppendTemplate(smsReminderTemplate, "<span>[zip_code]</span>")
							}
						>
							{getTranslation("Zip code", "Zip code", "Zip code")}
						</Button>

						<Button
							size="small"
							className={classes.root}
							onClick={() =>
								AppendTemplate(smsReminderTemplate, "<span>[due_Amount]</span>")
							}
						>
							{getTranslation("Due Amount", "Montant dû", "Geburtstermin")}
						</Button>
					</div>

					{/* Text Editor */}
					{apiLoading ? (
						<div
							style={{
								width: "100%",
								display: "flex",
								justifyContent: "center",
							}}
						>
							<CircularProgress />
						</div>
					) : (
						<Grid container spacing={2}>
							<Grid item xs={12} sm={12} md={12} lg={12}>
								<TextEditor
									translatedText={
										reminderKey === "FirstSMSReminder"
											? "1st Reminder"
											: reminderKey === "SecondSMSReminder"
											? "2nd Reminder"
											: "3rd Reminder"
									}
									selectedLanguage={selectedLanguage}
									reminderKey={reminderKey}
									openTestSmsDialoge={openTestSmsDialoge}
									handleLanguage={handleLanguage}
									// title="Send Test Message"
								>
									<CardContent>
										<HtmlEditor
											ref={editorRef}
											onChange={(text) => SendMenualReminder(text)}
											text={smsReminderTemplate}
											isLoading={false}
											buttonText={getTranslation("Send", "Send", "Send")}
											isSubmitting={loading}
										/>

										<br />
									</CardContent>
								</TextEditor>
							</Grid>
						</Grid>
					)}

					{/* <Grid container spacing={2}>
						<Grid item xs={12} sm={12} md={12} lg={12}>
							<TextEditor
								translatedText={
									reminderKey === "FirstSMSReminder"
										? "1st Reminder"
										: reminderKey === "SecondSMSReminder"
										? "2nd Reminder"
										: "3rd Reminder"
								}
								selectedLanguage={selectedLanguage}
								reminderKey={reminderKey}
								openTestSmsDialoge={openTestSmsDialoge}
								handleLanguage={handleLanguage}
								// title="Send Test Message"
							>
								<CardContent>
									<HtmlEditor
										ref={editorRef}
										onChange={(text) => SendMenualReminder(text)}
										text={smsReminderTemplate}
										isLoading={false}
										buttonText={getTranslation("Send", "Send", "Send")}
										isSubmitting={loading}
									/>

									<br />
								</CardContent>
							</TextEditor>
						</Grid>
					</Grid> */}
				</DialogContent>
			</DialogComponent>
		</>
	);
};

// <Card>
// <CardHeader
//   title={
//     reminderKey === "FirstSMSReminder"
//       ? "1st Reminder"
//       : reminderKey === "SecondSMSReminder"
//         ? "2nd Reminder"
//         : "3rd Reminder"
//   }
//   style={{
//     background: "#233044",
//     color: "#FFFFFF",
//   }}
// />
// <CardContent>
//   <HtmlEditor
//     ref={editorRef}
//     onChange={(text) => {
//       setEnableReloading(true);

//       setTemplate(text)
//       setEnableReloading(false);
//     }}
//     text={smsReminderTemplate?.template}
//     isLoading={false}
//   />
//   <Box
//     pt={4}
//     width="100%"
//     display="flex"
//     alignItems="center"
//     justifyContent="center"
//   >
//     <ButtonWithLoading
//       title={
//         sendByPost
//           ? getTranslation("Print", "Print", "Print")
//           : getTranslation(
//             "Send SMS Reminder",
//             "Send SMS Reminder",
//             "Send SMS Reminder"
//           )
//       }
//       size="small"
//       variant="contained"
//       color="primary"
//       loading={submitReminderLoading}
//       onClick={() => SendMenualReminder()}
//     />
//   </Box>
// </CardContent>
// </Card>
