import { useEffect, useState } from "react";
import { Collapser } from "../../components/Collapser";
import { getTranslation } from "../../heplers/translationHelper";
import { ReminderTemplateDialog } from "../tanents/components/reminderTemplateDialog";
import { ReminderSettings } from "./reminderSettings";
import { Settings } from "./settingsSMTP";
import { SMSReminderSettings } from "./smsReminderSettings";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { Button, Grid, TextField } from "@material-ui/core";
import { Get, Post } from "../../actions";
import {
  Get_EmailSenderName_URL,
  Post_UpdateEmailSenderName_URL,
} from "../../constants/apiUrls";
import { useSnackbar } from "notistack";
import RecoveryProcedureSetting from "./recoveryProcedureSetting";
import PhoneCallsRemainder from "./phoneCallsRemainder";
import { IndexationReminderSettings } from "./indexationReminderSettings";
export const SettingList = () => {
  const dispatch = useDispatch();
  const [emailSenderName, setEmailSenderName] = useState();
  const [emailSenderId, setEmailSenderId] = useState();

  const { enqueueSnackbar } = useSnackbar();

  const postEmailSenderName = () => {
    if (emailSenderName === "") {
      enqueueSnackbar(
        getTranslation(
          "Can't Update without sender name",
          "Can't Update without sender name",
          "Can't Update without sender name"
        ),
        {
          variant: "error",
        }
      );
      return;
    }
    Post(
      {
        emailSenderName: emailSenderName,
        id: emailSenderId,
      },
      Post_UpdateEmailSenderName_URL,
      null,
      (resp) => {
        enqueueSnackbar(
          getTranslation(
            "Email sender name updated successfully",
            "Email sender name updated successfully",
            "Email sender name updated successfully"
          ),
          {
            variant: "success",
          }
        );
      },
      (err) => {
        enqueueSnackbar(
          err
            ? err.data
            : "An error occurred while processing your request. Please contact with admin",
          {
            variant: "error",
          }
        );
      }
    );
  };

  const getEmailSenderName = () => {
    Get(
      null,
      Get_EmailSenderName_URL,
      null,
      (resp) => {
        setEmailSenderName(resp?.data?.emailSenderName);
        setEmailSenderId(resp?.data?.id);
      },
      (err) => {}
    );
  };

  useEffect(() => {
    getEmailSenderName();
    dispatch({ type: "Clear_All_BreadCrumb" });
    dispatch({
      type: "Add_BreadCrumb",
      payload: {
        title: getTranslation("Setting", "Setting", "Setting"),
        url: "/settings",
      },
    });
  }, []);
  return (
    <>
      <Helmet>
        <title>{getTranslation("Setting", "Setting", "Setting")}</title>
      </Helmet>
      <Collapser
        title={getTranslation(
          "SMTP Settings",
          "SMTP Settings",
          "SMTP Settings"
        )}
      >
        <Settings />
      </Collapser>
      <br />
      <Collapser
        title={getTranslation(
          "E-mail Reminder Settings",
          "E-mail Reminder Settings",
          "E-mail Reminder Settings"
        )}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            style={{ marginBottom: "10px" }}
          >
            <TextField
              fullWidth
              variant='outlined'
              required
              id='emailsendername'
              label={getTranslation(
                "Email Sender Name",
                "Email Sender Name",
                "Email Sender Name"
              )}
              name='emailsendername'
              size='small'
              value={emailSenderName}
              onChange={(e) => setEmailSenderName(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={3} style={{ marginLeft: "10px" }}>
            <Button
              variant='contained'
              onClick={postEmailSenderName}
              style={{
                backgroundColor: "rgb(229,60,22)",
                color: "white",
                fontWeight: "bold",
              }}
            >
              {getTranslation("Update", "Update", "Update")}
            </Button>
          </Grid>
        </Grid>
        <ReminderSettings reminderType='sendByEmail' />
      </Collapser>
      <br />
      <Collapser
        title={getTranslation(
          "SMS Reminder Settings",
          "SMS Reminder Settings",
          "SMS Reminder Settings"
        )}
      >
        <SMSReminderSettings />
      </Collapser>
      <br />
      <Collapser
        title={getTranslation(
          "By Post Reminder Settings",
          "By Post Reminder Settings",
          "By Post Reminder Settings"
        )}
      >
        <ReminderSettings reminderType='sendByPost' />
      </Collapser>
      <br />
      <Collapser
        title={getTranslation(
          "Recovery Procedure Settings",
          "Recovery Procedure Settings",
          "Recovery Procedure Settings"
        )}
      >
        <RecoveryProcedureSetting />
      </Collapser>
      <br />
      <Collapser
        title={getTranslation(
          "Phone Call Settings",
          "Phone Call Settings",
          "Phone Call Settings"
        )}
      >
        <PhoneCallsRemainder />
      </Collapser>
      {/* <br />
			<Collapser
				title={getTranslation(
					"Indexation Settings",
					"Indexation Settings",
					"Indexation Settings"
				)}
			>
				<IndexationReminderSettings />
			</Collapser> */}
    </>
  );
};
