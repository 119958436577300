import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MoreIcon from "@material-ui/icons/MoreVert";
import { Button, Select, useMediaQuery } from "@material-ui/core";
import { SignUpLogo } from "../../components/Logo";
import { getTranslation } from "../../heplers/translationHelper";
import {
  EnglishLanguageIcon,
  FrenchLanguageIcon,
  GermanLanguageIcon,
} from "../../components/icons";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
    // lineHeight: "30px",
    // height: "8%",
  },
  // menuButton: {
  //   marginRight: theme.spacing(2),
  // },
  title: {
    // display: "none",
    // marginLeft: "100px",
    width: "6%",
    // marginLeft: "-10px",
    // padding: "6px 6px 6px 6px",
    // [theme.breakpoints.up("sm")]: {
    //   display: "block",
    // },
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

export default function LoginNavBar() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const isMobileTab = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <Button aria-label="Welcome" color="inherit">
          <a
            href="https://qollex.com/"
            rel="noopener noreferrer"
            style={{
              textDecoration: "none",
              color: "black",
              fontWeight: "bold",
            }}
          >
            {getTranslation("Welcome", "Welcome", "Welcome")}
          </a>
        </Button>
      </MenuItem>
      <MenuItem>
        <Button aria-label="About" color="inherit">
          <a
            href="https://qollex.com/about-us-fr/"
            rel="noopener noreferrer"
            style={{
              textDecoration: "none",
              color: "black",
              fontWeight: "bold",
            }}
          >
            {getTranslation("About", "About", "About")}
          </a>
        </Button>
      </MenuItem>
      <MenuItem>
        <Button aria-label="Prices" color="inherit">
          <a
            href="https://qollex.com/fr/clic-rent-landing/pricing/"
            rel="noopener noreferrer"
            style={{
              textDecoration: "none",
              color: "black",
              fontWeight: "bold",
            }}
          >
            {getTranslation("Prices", "Prices", "Prices")}
          </a>
        </Button>
      </MenuItem>
      <MenuItem>
        <Button aria-label="Features" color="inherit">
          <a
            href="https://qollex.com/fr/clic-rent-landing/features/"
            rel="noopener noreferrer"
            style={{
              textDecoration: "none",
              color: "black",
              fontWeight: "bold",
            }}
          >
            {getTranslation("Features", "Features", "Features")}
          </a>
        </Button>
      </MenuItem>
      <MenuItem>
        <Button aria-label="Login" color="inherit">
          <a
            href="/login"
            rel="noopener noreferrer"
            style={{
              textDecoration: "none",
              color: "black",
              fontWeight: "bold",
            }}
          >
            {getTranslation("Login", "Login", "Login")}
          </a>
        </Button>
      </MenuItem>
      <MenuItem>
        <Button aria-label="SignUp" color="inherit">
          <a
            href="/registration"
            rel="noopener noreferrer"
            style={{
              textDecoration: "none",
              color: "black",
              fontWeight: "bold",
            }}
          >
            {getTranslation("SignUp", "SignUp", "SignUp")}
          </a>
        </Button>
      </MenuItem>
      <MenuItem></MenuItem>
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar position="fixed" style={{ background: "rgb(231,238,251)" }}>
        <Toolbar style={{ marginRight: "70px" }}>
          <SignUpLogo
            className={classes.title}
            style={
              isMobileTab
                ? {
                    width: "20%",
                    marginLeft: "-30px",
                    //   padding: "6px 6px 6px 6px",
                  }
                : {}
            }
          />
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <Button aria-label="Welcome" color="inherit">
              <a
                href="https://qollex.com/"
                rel="noopener noreferrer"
                style={{
                  textDecoration: "none",
                  color: "rgb(35,48,68)",
                  fontWeight: "bold",
                }}
              >
                {getTranslation("Welcome", "Welcome", "Welcome")}
              </a>
            </Button>

            <Button aria-label="About" color="inherit">
              <a
                href="https://qollex.com/about-us-fr/"
                rel="noopener noreferrer"
                style={{
                  textDecoration: "none",
                  color: "rgb(35,48,68)",
                  fontWeight: "bold",
                }}
              >
                {getTranslation("About", "About", "About")}
              </a>
            </Button>
            <Button aria-label="Prices" color="inherit">
              <a
                href="https://qollex.com/fr/clic-rent-landing/pricing/"
                rel="noopener noreferrer"
                style={{
                  textDecoration: "none",
                  color: "rgb(35,48,68)",
                  fontWeight: "bold",
                }}
              >
                {getTranslation("Prices", "Prices", "Prices")}
              </a>
            </Button>
            <Button aria-label="Features" color="inherit">
              <a
                href="https://qollex.com/fr/clic-rent-landing/features/"
                rel="noopener noreferrer"
                style={{
                  textDecoration: "none",
                  color: "rgb(35,48,68)",
                  fontWeight: "bold",
                }}
              >
                {getTranslation("Features", "Features", "Features")}
              </a>
            </Button>
            <Button aria-label="Login" color="inherit">
              <a
                href="/login"
                rel="noopener noreferrer"
                style={{
                  textDecoration: "none",
                  color: "rgb(35,48,68)",
                  fontWeight: "bold",
                }}
              >
                {getTranslation("Login", "Login", "Login")}
              </a>
            </Button>
            <Button aria-label="SignUp" color="inherit">
              <a
                href="/registration"
                rel="noopener noreferrer"
                style={{
                  textDecoration: "none",
                  color: "rgb(35,48,68)",
                  fontWeight: "bold",
                }}
              >
                {getTranslation("SignUp", "SignUp", "SignUp")}
              </a>
            </Button>
          </div>
          <Select
            style={{
              height: "34px",
              borderRadius: "17px",
            }}
            variant="outlined"
            defaultValue={
              localStorage.getItem("lang") ? localStorage.getItem("lang") : "en"
            }
            onChange={(e) => {
              localStorage.setItem("lang", e.target.value);
              window.location.reload();
            }}
          >
            <MenuItem value="en">
              <IconButton>
                <EnglishLanguageIcon />
              </IconButton>
              {isMobileTab == false
                ? getTranslation("English", "English", "English")
                : null}
            </MenuItem>
            <MenuItem value="fr">
              <IconButton>
                <FrenchLanguageIcon />
              </IconButton>

              {isMobileTab == false
                ? getTranslation("French", "French", "French")
                : null}
            </MenuItem>
            <MenuItem value="gr">
              <IconButton>
                <GermanLanguageIcon />
              </IconButton>
              {isMobileTab == false
                ? getTranslation("German", "German", "German")
                : null}
            </MenuItem>
          </Select>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="primary"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
    </div>
  );
}
