import React, { useState } from "react";
import { NavLink as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Button, Collapse, ListItem, makeStyles } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

const useStyles = makeStyles((theme) => ({
  item: {
    display: "block",
    paddingTop: 0,
    paddingBottom: 0,
    color: theme.palette.common.black,
  },
  itemLeaf: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
    color: theme.palette.common.black,
  },
  button: {
    color: "rgb(0,68,103)",
    padding: "10px 8px",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
  },

  buttonLeaf: {
    position: "relative",
    overflow: "hidden",
    color: theme.palette.common.white,
    padding: "10px 20px",
    marginTop: "5px",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
    fontWeight: theme.typography.fontWeightRegular,
    "&.depth-0": {
      "& $title": {
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette.common.black,
      },
    },
  },

  icon: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1),
    color: "rgb(0,68,103)",
  },
  title: {
    marginRight: "auto",
    color: "rgb(94,103,121)",
    fontWeight: "bold",
  },
  active: {
    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
    "&::before": {
      content: "''",
      position: "absolute",
      left: 8,
      bottom: 0,
      height: "100%",
      width: "5px",
      backgroundColor: "rgb(0,63,103)",
      transform: "skew(13deg)",
    },

    color: "rgb(0,68,103)",
    backgroundColor: theme.palette.common.white,
    "&:hover": {
      color: theme.palette.common.white,
    },
    "& $title ": {
      color: "rgb(0,68,103)",
      fontWeight: "bold",
    },
    "& $icon": {
      color: "rgb(0,68,103)",
    },
  },
  collapse: {},
}));

function NavItem({
  title,
  href,
  depth,
  children,
  icon: Icon,
  src,
  className,
  open: openProp,
  info: Info,
  component,
  ...rest
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(openProp);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  let paddingLeft = 8;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  const style = { paddingLeft };

  if (children) {
    return (
      <ListItem
        className={clsx(classes.item, className)}
        disableGutters
        key={title}
        {...rest}
      >
        <Button className={classes.button} onClick={handleToggle} style={style}>
          {Icon && <Icon className={classes.icon} size="20" />}
          <span className={classes.title}>{title}</span>
          {open ? (
            <ExpandLessIcon className="" color="inherit" />
          ) : (
            <ExpandMoreIcon color="inherit" />
          )}
        </Button>
        <Collapse className={classes.collapse} in={open}>
          {children}
        </Collapse>
      </ListItem>
    );
  }
  const Component = component;
  return (
    <>
      <ListItem
        className={clsx(classes.itemLeaf, className)}
        disableGutters
        key={title}
        {...rest}
      >
        <Button
          activeClassName={classes.active}
          className={classes.buttonLeaf}
          component={RouterLink}
          style={style}
          to={href}
        >
          <div style={{ paddingLeft: "25px", display: "flex" }}>
            {Icon && <Icon className={classes.icon} size="20" />}

            {component ? (
              <Component />
            ) : (
              <span className={classes.title}>{title}</span>
            )}
            {Info && <Info className={""} />}
          </div>
        </Button>
      </ListItem>
    </>
  );
}

NavItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.any,
  info: PropTypes.any,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
  src: PropTypes.string,
};

NavItem.defaultProps = {
  open: false,
};

export default NavItem;
