import { makeStyles } from "@material-ui/core";
export default makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 1000,

    "&::-webkit-scrollbar": {
      height: "13px",
      width: "13px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgb(19,68,102)",
      borderRadius: "6px",
      border: "3px solid #fff",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: theme.palette.background.paper,
      borderRadius: "8px",
      marginRight: "100px",
      marginTop: "60px",
    },
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },

  table: {
    "& .MuiTableCell-root": {
      borderRight: "1px solid rgba(251, 103, 57, 0.5)",
      borderBottom: "1px solid rgba(251, 103, 57, 0.5)",
    },
    "& .MuiTableCell-root:last-child": {
      borderRight: "none",
      paddingRight: "20px",
    },
    "& .MuiTableRow-root:last-child .MuiTableCell-root": {
      borderBottom: "none",
    },
    "& .MuiTableHead-root .MuiTableCell-root": {
      borderBottom: "none",
      borderRight: "none",
    },
    "& .MuiTableCell-root:first-child": {
      paddingLeft: "20px",
    },
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  title: {
    flex: "1 1 100%",
  },
  tableHeader: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
  },
  tableRow: {
    cursor: "pointer",
    height: "30px",
  },
  searchBar: {
    borderRadius: "20px",
  },
  headerRow: {
    backgroundColor: "rgb(19,68,102)",
    height: "50px",
  },
}));
