import { Typography, Divider } from "@material-ui/core";
import styles from "./actionsRequired.module.css";
import React from "react";
import { getTranslation } from "../../../heplers/translationHelper";

export const ActionsRequired = () => {
  return (
    <div className={styles.container}>
      <Typography
        style={{
          color: "rgb(94,103,121)",
          fontWeight: "bold",
        }}
        variant="h4"
      >
        {getTranslation(
          "Actions Required",
          "Actions Required",
          "Actions Required"
        )}
      </Typography>
      <Divider
        style={{
          width: "40%",
          backgroundColor: "rgb(229,60,22)",
          border: "1px #FB5E39 solid",
          marginTop: "10px",
        }}
      />
    </div>
  );
};
