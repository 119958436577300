import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  root: {
    borderRadius: theme.spacing(1.5),
  },
  container: {
    maxWidth: "600px",
    [theme.breakpoints.up("sm")]: {
      justifyContent: "center",
    },
  },
  paper: {
    padding: theme.spacing(6, 8),
  },
  avatar: {
    margin: theme.spacing(1),
    fontSize: "80px",
  },
  heading: {
    marginBottom: theme.spacing(1.5),
    marginTop: theme.spacing(1.5),
    fontWeight: "700",
    fontSize: "35px",
    color: "rgb(35,48,68)",
  },

  submitbtn: {
    margin: theme.spacing(3, 0, 2),
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    borderRadius: "16px 16px 16px 16px",
    backgroundColor: "rgb(0,68,103)",
  },
  backgroundText: {
    color: "rgb(0,68,103)",
    fontWeight: "bold",
    marginLeft: "auto",
    marginRight: "auto",
  },

  link: {
    fontSize: "14px",
    textDecoration: "none",
    color: "black",
    "&:hover": {
      color: theme.palette.primary.main,
      textDecoration: "underline",
      cursor: "pointer",
    },
  },
}));
