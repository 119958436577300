import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React from "react";
import { getTranslation } from "../../../../../../heplers/translationHelper";
import { Form, Formik } from "formik";
import ButtonWithLoading from "../../../../../../components/ButtonWithLoading";
import {
  Autocomplete,
  ToggleButton,
  ToggleButtonGroup,
} from "@material-ui/lab";
import TableComponent from "../../../../../../components/table";
import { useState } from "react";
import DialogComponent from "../../../../../../components/Dialog";
import { DownloadButton } from "../../../../../../components/ButttonsWithIcons";
import { UploadFiles } from "../../../../../../components/Upload";
import AddNoteDialog from "./addNoteDialog";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Get } from "../../../../../../actions";
import { Get_NotesWithPagination_URL, Get_RecoveryProcedure_URL, Get_TenantDuesSummary_URL } from "../../../../../../constants/apiUrls";
import { useEffect } from "react";
import { format } from "../../../../../../heplers/format";

const columns = [
  {
    id: "date",
    label: getTranslation("Start Date", "Start Date", "Start Date"),
    align: "left",
    component: ({ row }) => {
      return (
        <Typography >
          {format("date", row?.date)}
        </Typography>
      );
    }
  },

  {
    id: "comments",
    label: getTranslation("Comment", "Comment", "Comment"),
    format: "left",
  },
  {
    id: "actions",
    label: getTranslation("Actions", "Actions", "Actions"),
    align: "right",
  },
];

const RecoveryTenantDetails = () => {
  const [addNoteDialog, setAddNoteDialog] = useState(false);
  const [notesData, setNotesData] = useState();
  const [recoveryProcedureData,setRecoveryProcedureData]=useState();
  const [tenanDuesSummary, setTenanDuesSummary]=useState();
  const [refresh,setRefresh]=useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { recoveryId } = useParams();

  useEffect(() => {
    if(recoveryProcedureData)
      getTenantDuesSummary();
    else{
      getRecoveryData();
    }
  }, [recoveryProcedureData]);


  //get notes data 
  useEffect(()=>{
    getNotesData();
  },[refresh])

  const getNotesData = () => {
    Get(
      {
        recoveryProcedureId: recoveryId,
        type: 0,
        pageNumber: 0,
        pageSize: 10,
        searchString: "",
      },
      Get_NotesWithPagination_URL,
      null,
      (resp) => {
        setNotesData(resp?.data?.data);
      },
      (err) => {
        enqueueSnackbar(
          err?.data
            ? err?.data
            : getTranslation(
                "Something went wrong. Please contact with admin.",
                "Something went wrong. Please contact with admin.",
                "Something went wrong. Please contact with admin."
              ),
          {
            variant: "error",
          }
        );
      }
    );
  };

  const getRecoveryData=()=>{
    Get(
      {recoveryProcedureId:recoveryId},
      Get_RecoveryProcedure_URL,
      null,
      (resp)=>{
        setRecoveryProcedureData(resp?.data);
      },
      (err) => {
        enqueueSnackbar(
          err.data
            ? err.data
            : getTranslation(
                "Something went wrong. Please try again.",
                "Something went wrong. Please try again.",
                "Something went wrong. Please try again."
              ),
          {
            variant: "error",
          }
        );
      }
    )
  }

  const getTenantDuesSummary=()=>{
    Get(
      {tenantId:recoveryProcedureData?.tenant?.id},
      Get_TenantDuesSummary_URL,
      null,
      (res)=>{
        setTenanDuesSummary(res?.data);
      },
      (err) => {
        enqueueSnackbar(
          err.data
            ? err.data
            : getTranslation(
                "Something went wrong. Please try again.",
                "Something went wrong. Please try again.",
                "Something went wrong. Please try again."
              ),
          {
            variant: "error",
          }
        );
      }
    )
  }


  return (
    <div>
      {/* tenant recovery deatails  */}
      <Card>
        <CardHeader
          title={getTranslation(
            "Tenant Recovery Details",
            "Tenant Recovery Details",
            "Tenant Recovery Details"
          )}
        />
        <CardContent>
          <Grid
            container
            style={{
              marginTop: "10px",
            }}
          >
            <Grid item xs={6} sm={4} md={3} lg={2}>
              <Box display="flex">
                <tr>
                  <Typography variant="h5" style={{ fontWeight: "bold" }}>
                    {getTranslation(
                      "Tenant Name",
                      "Tenant Name",
                      "Tenant Name"
                    )}
                  </Typography>
                  <td>
                    <Typography variant="h5">
                    {recoveryProcedureData?.tenant.name}
                    </Typography>
                  </td>
                </tr>
              </Box>
            </Grid>
            <Grid item xs={6} sm={4} md={3} lg={2}>
              <Box display="flex">
                <tr>
                  <Typography variant="h5" style={{ fontWeight: "bold" }}>
                    {getTranslation(
                      "Tenant Building",
                      "Tenant Building",
                      "Tenant Building"
                    )}
                  </Typography>
                  <td>
                    <Typography variant="h5">
                      {recoveryProcedureData?.tenant?.buildingName}
                    </Typography>
                  </td>
                </tr>
              </Box>
            </Grid>
            <Grid item xs={6} sm={4} md={3} lg={2}>
              <Box display="flex">
                <tr>
                  <Typography variant="h5" style={{ fontWeight: "bold" }}>
                    {getTranslation("Appartment", "Appartment", "Appartment")}
                  </Typography>
                  <td>
                    <Typography variant="h5">
                    {recoveryProcedureData?.tenant?.apartmentNo}
                    </Typography>
                  </td>
                </tr>
              </Box>
            </Grid>

            <Grid item xs={6} sm={4} md={3} lg={2}>
              <Box display="flex">
                <tr>
                  <Typography variant="h5" style={{ fontWeight: "bold" }}>
                    {getTranslation("Start Date", "Start Date", "Start Date")}
                  </Typography>
                  <td>
                    <Typography variant="h5">
                    {format("date",recoveryProcedureData?.startDate)}
                    </Typography>
                  </td>
                </tr>
              </Box>
            </Grid>
            <Grid item xs={6} sm={4} md={3} lg={2}>
              <Box display="flex">
                <tr>
                  <Typography variant="h5" style={{ fontWeight: "bold" }}>
                    {getTranslation(
                      "Tenant Email",
                      "Tenant Email",
                      "Tenant Email"
                    )}
                  </Typography>
                  <td>
                    <Typography variant="h5">
                    {recoveryProcedureData?.tenant?.email}
                    </Typography>
                  </td>
                </tr>
              </Box>
            </Grid>
            <Grid item xs={6} sm={4} md={3} lg={2}>
              <Box display="flex">
                <tr>
                  <Typography variant="h5" style={{ fontWeight: "bold" }}>
                    {getTranslation("Phone", "Phone", "Phone")}
                  </Typography>
                  <td>
                    <Typography variant="h5">
                    {recoveryProcedureData?.tenant?.telephone}
                    </Typography>
                  </td>
                </tr>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* recovery summary  */}
      <Card
        style={{
          marginTop: "20px",
        }}
      >
        <CardHeader
          title={getTranslation(
            "Tenant Dues",
            "Tenant Dues",
            "Tenant Dues"
          )}
        />
        <CardContent>
          <Grid
            container
            style={{
              marginTop: "10px",
            }}
          >
            <Grid item xs={6} sm={4} md={3} lg={2}>
              <Box display="flex">
                <tr>
                  <Typography variant="h5" style={{ fontWeight: "bold" }}>
                    {getTranslation("Total Rent", "Total Rent", "Total Rent")}
                  </Typography>
                  <td>
                    <Typography variant="h5">
                     {tenanDuesSummary?.totalRent.toFixed(2)}
                    </Typography>
                  </td>
                </tr>
              </Box>
            </Grid>
            <Grid item xs={6} sm={4} md={3} lg={2}>
              <Box display="flex">
                <tr>
                  <Typography variant="h5" style={{ fontWeight: "bold" }}>
                    {getTranslation("Total Cost", "Total Cost", "Total Cost")}
                  </Typography>
                  <td>
                    <Typography variant="h5">
                  {tenanDuesSummary?.totalCost}
                    </Typography>
                  </td>
                </tr>
              </Box>
            </Grid>
            <Grid item xs={6} sm={4} md={3} lg={2}>
              <Box display="flex">
                <tr>
                  <Typography variant="h5" style={{ fontWeight: "bold" }}>
                    {getTranslation(
                      "Total warranty",
                      "Total warranty",
                      "Total warranty"
                    )}
                  </Typography>
                  <td>
                    <Typography variant="h5">
                    {tenanDuesSummary?.totalWarranty}
                    </Typography>
                  </td>
                </tr>
              </Box>
            </Grid>
            <Grid item xs={6} sm={4} md={3} lg={2}>
              <Box display="flex">
                <tr>
                  <Typography variant="h5" style={{ fontWeight: "bold" }}>
                    {getTranslation(
                      "Total Maintenance",
                      "Total Maintenance",
                      "Total Maintenance"
                    )}
                  </Typography>
                  <td>
                    <Typography variant="h5">
                     {tenanDuesSummary?.totalRepairAndMaintenance}
                    </Typography>
                  </td>
                </tr>
              </Box>
            </Grid>
            <Grid item xs={6} sm={4} md={3} lg={2}>
              <Box display="flex">
                <tr>
                  <Typography variant="h5" style={{ fontWeight: "bold" }}>
                    {getTranslation(
                      "Recovery Amount",
                      "Recovery Amount",
                      "Recovery Amount"
                    )}
                  </Typography>
                  <td>
                    <Typography variant="h5">
                      120
                    </Typography>
                  </td>
                </tr>
              </Box>
            </Grid>
            <Grid item xs={6} sm={4} md={3} lg={2}>
              <Box display="flex">
                <tr>
                  <Typography variant="h5" style={{ fontWeight: "bold" }}>
                    {getTranslation(
                      "Recovery Date",
                      "Recovery Date",
                      "Recovery Date"
                    )}
                  </Typography>
                  <td>
                    <Typography variant="h5">
                      12/12/12
                    </Typography>
                  </td>
                </tr>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* notes table */}
      <Card style={{ marginTop: "20px" }}>
        <CardContent>
          <TableComponent
            loading={false}
            dense
            title={getTranslation("Notes", "Notes", "Notes")}
            colums={columns}
            rows={notesData}
            count={notesData?.length}
            viewSearch
            viewCreate
            onCreate={() => {
              setAddNoteDialog(true);
            }}
            actions={[
              {
                component: (row) => (
                  <Tooltip
                    title={getTranslation("Note", "Noter", "Notiz")}
                    arrow
                    placement="top"
                  >
                    <DownloadButton
                    />
                  </Tooltip>
                ),
              },
            ]}
          />
        </CardContent>
      </Card>

      {/* add note dialog */}
      <DialogComponent
        open={addNoteDialog}
        onClose={() => setAddNoteDialog(false)}
        title={getTranslation("Add Notes", "Add Notes", "Add Notes")}
        fullWidth
      >
        <AddNoteDialog
          setAddNoteDialog={setAddNoteDialog}
          type={0}
          recoveryProcedureId={recoveryId}
          setRefresh={setRefresh}
        />
      </DialogComponent>
    </div>
  );
};
export default RecoveryTenantDetails;
