import { Grid } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Get } from "../../actions";
import {
  Get_BankCards_URL,
  Get_DashboardCards_URL,
} from "../../constants/apiUrls";
import { DashboardCard } from "./components/dashBoardCard";
import {
  Get_TotalDuesCard_URL,
  Get_OccupationCard_URL,
  Get_ROICard_URL,
} from "../../constants/apiUrls";
import { useSnackbar } from "notistack";
import { getTranslation } from "../../heplers/translationHelper";

export const DashBoardCardList = () => {
  const { enqueueSnackbar } = useSnackbar();

  const history = useHistory();
  const [cards, setCards] = useState([]);
  const [dues, setDues] = useState();
  const [occupationData, setOccupationData] = useState();
  const [ROIData, setROIData] = useState();
  const [bankData, setBankData] = useState();

  useEffect(() => {
    TotalDuesCard();
    OccupationCard();
    ROICard();

    BankCard();
    // Get(
    //   {},
    //   Get_DashboardCards_URL,
    //   null,
    //   (resp) => {
    //     setCards(resp.data);
    //   },
    //   (error) => {}
    // );
  }, []);

  const handleCallBack = (details) => {
    return (
      <div>
        {details.cardType === 0
          ? history.push({
              pathname: "/bank/",
              search: "?query=iban",
              state: {
                filter: details.title,
              },
            })
          : details.cardType === 1
          ? history.push("/tanents")
          : details.cardType === 2
          ? history.push("/dues/duesGenerated")
          : details.cardType === 3
          ? history.push("/bank/")
          : details.cardType === 4
          ? history.push("/tanents/recoveryprocedure")
          : null}
      </div>
    );
  };

  const TotalDuesCard = () => {
    Get(
      {},
      Get_TotalDuesCard_URL,
      null,
      (res) => {
        setDues(res?.data);
      },
      (err) => {
     
      
        if (err?.data == "Network Error") {
          window.location.reload();
        }
        enqueueSnackbar(
          err
            ? err.data
            : getTranslation(
                "Can't load dues. Please contact with admin",
                "Can't load dues. Please contact with admin",
                "Can't load dues. Please contact with admin"
              ),
          { variant: "error" }
        );
      }
    );
  };

  const OccupationCard = () => {
    Get(
      {},
      Get_OccupationCard_URL,
      null,
      (res) => {
        setOccupationData(res?.data);
      },
      (err) => {
        if (err?.data == "Network Error") {
          window.location.reload();
        }
        enqueueSnackbar(
          err
            ? err.data
            : getTranslation(
                "Can't load dues. Please contact with admin",
                "Can't load dues. Please contact with admin",
                "Can't load dues. Please contact with admin"
              ),
          { variant: "error" }
        );
      }
    );
  };

  const ROICard = () => {
    Get(
      {},
      Get_ROICard_URL,
      null,
      (res) => {
        setROIData(res?.data);
      },
      (err) => {
        if (err?.data == "Network Error") {
          window.location.reload();
        }
        enqueueSnackbar(
          err
            ? err.data
            : getTranslation(
                "Can't load dues. Please contact with admin",
                "Can't load dues. Please contact with admin",
                "Can't load dues. Please contact with admin"
              ),
          { variant: "error" }
        );
      }
    );
  };

  const BankCard = () => {
    Get(
      {},
      Get_BankCards_URL,
      null,
      (res) => {
        setBankData(res?.data);
      },
      (err) => {
        if (err?.data == "Network Error") {
          window.location.reload();
        }
        enqueueSnackbar(
          err
            ? err.data
              ? err.data
              : getTranslation(
                  "Can't load Indexation. Please contact with admin",
                  "Can't load Indexation. Please contact with admin",
                  "Can't load Indexation. Please contact with admin"
                )
            : getTranslation(
                "Can't load Indexation. Please contact with admin",
                "Can't load Indexation. Please contact with admin",
                "Can't load Indexation. Please contact with admin"
              ),
          { variant: "error" }
        );
      }
    );
  };
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
        <DashboardCard data={bankData ? bankData : ""} />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
        <DashboardCard data={dues ? dues : ""} />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
        <DashboardCard data={occupationData ? occupationData : ""} />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
        <DashboardCard data={ROIData ? ROIData : ""} />
      </Grid>
    </Grid>
  );
};
