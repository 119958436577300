import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@material-ui/core";
import { format } from "../../../../../heplers/format";
import { getTranslation } from "../../../../../heplers/translationHelper";

export const SummaryBuilding = ({ summaryBuilding }) => {
  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            {getTranslation("Summary", "Summary", "Summary")}
          </Typography>
        }
      />
      <CardContent>
        <Grid
          container
          spacing={2}
          style={{
            marginLeft: "5%",
            // marginTop: "-1.5%",
          }}
        >
          {/* /////////////////// Headers ///////////////////// */}
          <Grid item xs={12} sm={6} md={4} lg={4} xl={2}>
            <Box display="flex">
              <Typography variant="h5" style={{ marginRight: "5px" }}>
                {getTranslation(
                  "Rent Generated:",
                  "Rent Generated:",
                  "Rent Generated:"
                )}
              </Typography>
              <Typography variant="h5" style={{ fontWeight: "bold" }}>
                {format("number", summaryBuilding?.rentGenerated)} €
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} xl={2}>
            <Box display="flex">
              <Typography variant="h5" style={{ marginRight: "5px" }}>
                {getTranslation("Rent Paid:", "Rent Paid:", "Rent Paid:")}
              </Typography>
              <Typography variant="h5" style={{ fontWeight: "bold" }}>
                {format("number", summaryBuilding?.rentPaid)} €
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} xl={2}>
            <Box display="flex">
              <Typography variant="h5" style={{ marginRight: "5px" }}>
                {getTranslation("Unpaid:", "Unpaid:", "Unpaid:")}
              </Typography>
              <Typography variant="h5" style={{ fontWeight: "bold" }}>
                {format("number", summaryBuilding?.unpaid)} €
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} xl={2}>
            <Box display="flex">
              <Typography variant="h5" style={{ marginRight: "5px" }}>
                {getTranslation("Expenses:", "Expenses:", "Expenses:")}
              </Typography>
              <Typography variant="h5" style={{ fontWeight: "bold" }}>
                {format("number", summaryBuilding?.cost)} €
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} xl={2}>
            <Box display="flex">
              <Typography variant="h5" style={{ marginRight: "5px" }}>
                {getTranslation("Profit:", "Profit:", "Profit:")}
              </Typography>
              <Typography variant="h5" style={{ fontWeight: "bold" }}>
                {format("number", summaryBuilding?.profit)} €
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
