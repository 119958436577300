import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { getTranslation } from "../../../../../heplers/translationHelper";
import {
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import ButtonWithLoading from "../../../../../components/ButtonWithLoading";
import Table from "../../../../../components/table";
import AddIcon from "@material-ui/icons/Add";
import CancelIcon from "@material-ui/icons/Cancel";
import DialogComponent from "../../../../../components/Dialog";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import CloseRecoveryDialog from "./closeRecoveryDialog";
import { useSnackbar } from "notistack";
import {
  Get_AllRecoveryProceduresWithPagination_URL,
  Get_AllTanenets_URL,
  Get_AllTanentsWithPagination_URL,
  Post_ConfirmRecoveryProcedures_URL,
  Post_DeleteRecoveryProcedure_URL,
  Post_EndRecoveryProcedure_URL,
  Post_StartRecoveryProcedure_URL,
  Post_UpdateRecoveryProcedure_URL,
} from "../../../../../constants/apiUrls";
import { Get, Post } from "../../../../../actions";
import StartRecoveryDialog from "./startRecoveryDialog";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import RecoveryProcedureStepper from "./recoveryProcedureStepper";
import { format } from "../../../../../heplers/format";
import { EditButton } from "../../../../../components/ButttonsWithIcons";
import { FlashOnOutlined, DeleteSharp } from "@material-ui/icons";
import AlertDialog from "../../../../../components/DeleteDialog";

const columns = [
  {
    id: "tenant",
    numeric: false,
    disablePadding: true,
    label: getTranslation("Tenant Name", "Tenant Name", "Tenant Name"),
    component: ({ row }) => {
      return <Typography>{row?.tenant?.name}</Typography>;
    },
  },
  {
    id: "apartmentAddress",
    label: getTranslation(
      "Appartment Address",
      "Appartment Address",
      "Appartment Address"
    ),
    align: "left",
    component: ({ row }) => {
      return <Typography>{row?.appartmentAddress}</Typography>;
    },
  },
  {
    id: "recoverystatus",
    label: getTranslation("Status", "Status", "Status"),
    align: "left",
    component: ({ row }) => {
      return (
        <Typography>
          {row?.recoveryProcedureStatus == 0
            ? "Active"
            : row?.recoveryProcedureStatus == 1
            ? "Closed"
            : "Draft"}
        </Typography>
      );
    },
  },
  {
    id: "recoveryPersons",
    disablePadding: true,
    label: getTranslation(
      "Recovery Person",
      "Recovery Person",
      "Recovery Person"
    ),
    component: ({ row }) => {
      return (
        <>
          <Typography>
            {row?.recoveryPersons[0]?.name} | {row?.recoveryPersons[0]?.email}
          </Typography>
          <Typography>
            {row?.recoveryPersons[1]
              ? row?.recoveryPersons[1]?.name + " | "
              : ""}{" "}
            {row?.recoveryPersons[1] ? row?.recoveryPersons[1]?.email : ""}
          </Typography>
          <Typography>
            {row?.recoveryPersons[2]
              ? row?.recoveryPersons[2]?.name + " | "
              : ""}{" "}
            {row?.recoveryPersons[2] ? row?.recoveryPersons[2]?.email : ""}
          </Typography>
        </>
      );
    },
  },
  {
    id: "actions",
    label: getTranslation("Action", "Action", "Action"),
    align: "right",
  },
];

const TenantRecoveryProcedure = () => {
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [recoveryInfo, setRecoveryInfo] = useState();
  const [tenantList, setTenantList] = useState();
  const [tenantDetailList, setTenantDetailList] = useState();
  const [startRecoveryDialog, setStartRecoveryDialog] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [recoveryData, setRecoveryData] = useState();
  const [status, setStatus] = useState("active");
  const [searchString, setSearchString] = useState("");
  const [page, setPage] = useState(0);
  let pageSize = localStorage.getItem("sizeRecoveryTent");
  if (pageSize == null) {
    localStorage.setItem("sizeRecoveryTent", 10);
    pageSize = 10;
  }
  const [sortBy, setSortBy] = useState("startDate");
  const [sortDirection, setSortDirecion] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(pageSize);
  const [refResh, setRefResh] = useState(false);
  const [closeRecoveryData, setCloseRecoveryData] = useState();
  const [editRecoveryData, setEditRecoveryData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deletedRow, setDeletedRow] = useState();
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [selectRow, setSelectRow] = useState([]);

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    localStorage.setItem("sizeRecoveryTent", parseInt(event.target.value));
    setRowsPerPage(localStorage.getItem("sizeRecoveryTent"));
    setPage(0);
  };

  const handleSearchTanents = (searchString) => {
    setSearchString(searchString);
  };

  const openConformationDialog = (rowData) => {
    setIsOpenDialog(true);
    setRecoveryInfo(rowData);
  };

  const postCloseRecovery = (payload) => {
    setIsLoading(true);
    Post(
      payload,
      Post_EndRecoveryProcedure_URL,
      null,
      (resp) => {
        enqueueSnackbar("Recovery Procedure Closed Successfully", {
          variant: "success",
        });
        setIsLoading(false);
        setRefResh(true);
        setIsOpenDialog(false);
      },
      (err) => {
        enqueueSnackbar(
          err.data
            ? err.data
            : getTranslation(
                "Something went wrong. Please try again.",
                "Something went wrong. Please try again.",
                "Something went wrong. Please try again."
              ),
          {
            variant: "error",
          }
        );
        setIsLoading(false);
      }
    );
  };

  //about the checkbox of the table
  const handleSelectionChecked = (row) => {
    if (selectRow) setSelectRow([...selectRow, row]);
    else setSelectRow([row]);
  };

  //about uncheck
  const handleSelectionUnChecked = (row) => {
    let updateArray = selectRow.filter((x) => x.id != row);
    setSelectRow(updateArray);
  };

  //check or uncheck all
  const handleSelectAll = (row) => {
    if (row.length == selectRow.length) {
      setSelectRow([]);
    } else setSelectRow(row);
  };

  useEffect(() => {
    loadTenants();
    getAllTenantInformation();
  }, []);

  //get all recovery procedure data
  useEffect(() => {
    setRefResh(false);
    getAllRecoveryProcedureData();
    setSelectRow([]);
  }, [refResh, status, rowsPerPage, page]);

  //get all tenant information  with pagination
  const getAllTenantInformation = (values) => {
    Get(
      {
        pageNumber: 0,
        pageSize: 100,
        searchString: "",
        sort: "name",
        sortDirection: 0,
        status: "active",
      },
      Get_AllTanentsWithPagination_URL,
      history,
      (resp) => {
        setTenantDetailList(resp?.data?.data);
      },
      (error) => {
        enqueueSnackbar(
          getTranslation(
            "Can't Load the building! Internal server Error",
            "Can't Load the building! Internal server Error",
            "Can't Load the building! Internal server Error"
          ),
          { variant: "error" }
        );
      }
    );
  };

  // get all tenant
  const loadTenants = (values) => {
    Get(
      values,
      Get_AllTanenets_URL,
      history,
      (resp) => {
        setTenantList(resp?.data);
      },
      (error) => {
        enqueueSnackbar(
          getTranslation(
            "Can't Load the building! Internal server Error",
            "Can't Load the building! Internal server Error",
            "Can't Load the building! Internal server Error"
          ),
          { variant: "error" }
        );
      }
    );
  };

  //get all recovery procedure data with pagination
  const getAllRecoveryProcedureData = () => {
    setIsLoading(true);
    Get(
      {
        sort: sortBy,
        sortDirection: sortDirection,
        pageNumber: page,
        pageSize: rowsPerPage,
        status: status,
        searchString: searchString,
      },
      Get_AllRecoveryProceduresWithPagination_URL,
      null,
      (resp) => {
        setRecoveryData(resp?.data);
        setIsLoading(false);
      },
      (err) => {
        enqueueSnackbar(
          err?.data
            ? err?.data
            : getTranslation(
                "Something went wrong. Please try again.",
                "Something went wrong. Please try again.",
                "Something went wrong. Please try again."
              ),
          {
            variant: "error",
          }
        );
        setIsLoading(false);
      }
    );
  };

  //handle details route
  const handleEditActivity = (row) => {
    const url = `/tanents/recoveryprocedure/${row.id}/details`;
    dispatch({
      type: "Add_BreadCrumb",
      payload: { title: row.tenantName, url: "/tanents/recoveryprocedure" },
    });
    history.push(url);
  };

  const closeRecovery = (rowData) => {
    setCloseRecoveryData(rowData);
    setIsOpenDialog(true);
  };
  const onEditRecovery = (recoveryData) => {
    setEditRecoveryData(recoveryData);
    setIsEdit(true);
    setStartRecoveryDialog(true);
  };

  //post recovery procedure
  const postRecoveryProcedure = (payload) => {
    setIsLoading(true);
    Post(
      payload,
      Post_StartRecoveryProcedure_URL,
      null,
      (resp) => {
        setRefResh(true);
        enqueueSnackbar(
          resp.data ? resp.data : "Recovery Procedure Added successfully",
          {
            variant: "success",
          }
        );
        setIsLoading(false);
        setStartRecoveryDialog(false);
      },
      (err) => {
        enqueueSnackbar(
          err?.data
            ? err?.data
            : getTranslation(
                "Something went wrong. Please try again.",
                "Something went wrong. Please try again.",
                "Something went wrong. Please try again."
              ),
          {
            variant: "error",
          }
        );
        setIsLoading(false);
      }
    );
  };

  //update recovery procedure
  const upDateRecoveryProcedure = (payload) => {
    setIsLoading(true);

    Post(
      payload,
      Post_UpdateRecoveryProcedure_URL,
      null,
      (resp) => {
        enqueueSnackbar("Update Recovery Procedure Successfully", {
          variant: "success",
        });
        setRefResh(true);
        setStartRecoveryDialog(false);
        setIsLoading(false);
      },
      (err) => {
        enqueueSnackbar(
          err.data
            ? err.data
            : getTranslation(
                "Something went wrong. Please try again.",
                "Something went wrong. Please try again.",
                "Something went wrong. Please try again."
              ),
          {
            variant: "error",
          }
        );
        setIsLoading(false);
      }
    );
  };

  //confirm draft recovery or start recovery procedures
  const confirmRecoveryProcedures = (payload) => {
    if (payload.length == 0) {
      enqueueSnackbar("No tenant selected to start Recovery Procedure", {
        variant: "error",
      });
      return;
    }
    var selectedRecoveryProcedures = payload.map((x) => {
      return x.id;
    });
    setIsLoading(true);
    Post(
      selectedRecoveryProcedures,
      Post_ConfirmRecoveryProcedures_URL,
      null,
      (resp) => {
        enqueueSnackbar("Recovery Procedures Started Successfully", {
          variant: "success",
        });
        setRefResh(true);
        setIsLoading(false);
      },
      (err) => {
        enqueueSnackbar(
          err.data
            ? err.data
            : getTranslation(
                "Something went wrong. Please try again.",
                "Something went wrong. Please try again.",
                "Something went wrong. Please try again."
              ),
          {
            variant: "error",
          }
        );
        setIsLoading(false);
      }
    );
  };

  //delete recovery procedure
  const handleDeleteRecovery = () => {
    setIsLoading(true);
    Post(
      {
        id: deletedRow.id,
      },
      Post_DeleteRecoveryProcedure_URL,
      null,
      (resp) => {
        enqueueSnackbar("Recovery Procedure Deleted Successfully", {
          variant: "success",
        });
        setRefResh(true);
        setIsLoading(false);
        setOpenDeleteDialog(false);
      },
      (err) => {
        enqueueSnackbar(
          err.data
            ? err.data
            : getTranslation(
                "Something went wrong. Please try again.",
                "Something went wrong. Please try again.",
                "Something went wrong. Please try again."
              ),
          {
            variant: "error",
          }
        );
        setIsLoading(false);
      }
    );
  };

  //delete dialog open
  const handleOpenDeleteDialog = (row) => {
    setDeletedRow(row);
    setOpenDeleteDialog(true);
  };

  return (
    <div>
      <Helmet>
        <title>
          {getTranslation(
            "Recovery Procedure",
            "Recovery Procedure",
            "Recovery Procedure"
          )}
        </title>
      </Helmet>

      <Card>
        <CardContent>
          <ToggleButtonGroup
            size='small'
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            id='tenantStatus'
            value={status}
            exclusive
            onChange={(event, status) => {
              if (status !== null) {
                setStatus(status);
              }
            }}
          >
            <ToggleButton value={"active"} aria-label='centered'>
              <Typography>
                {getTranslation("Active", "Active", "Active")}
              </Typography>
            </ToggleButton>
            <ToggleButton value={"draft"} aria-label='left aligned'>
              <Typography>
                {getTranslation("Draft", "Draft", "Draft")}
              </Typography>
            </ToggleButton>
            <ToggleButton value={"close"} aria-label='centered'>
              <Typography>
                {getTranslation("Close", "Close", "Close")}
              </Typography>
            </ToggleButton>
          </ToggleButtonGroup>

          <Box
            style={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "flex-end",
              marginTop: "10px",
            }}
          >
            <Tooltip
              title={getTranslation(
                "Start Recovery Procedure",
                "Start Recovery Procedure",
                "Start Recovery Procedure"
              )}
              arrow
              placement='left-start'
            >
              <ButtonWithLoading
                variant='contained'
                color='primary'
                // style={{ backgroundColor: "rgb(229,60,22)", color: "white" }}
                title={
                  <>
                    {" "}
                    <AddIcon size='medium' /> &nbsp;&nbsp;{" "}
                    {getTranslation(
                      "Start New Recovery Procedure",
                      "Start New Recovery Procedure",
                      "Start New Recovery Procedure"
                    )}
                  </>
                }
                size='medium'
                loading={false}
                onClick={() => {
                  setEditRecoveryData(null);
                  setIsEdit(false);
                  setStartRecoveryDialog(true);
                }}
              />
            </Tooltip>
          </Box>
          <br />

          <Table
            loading={isLoading}
            dense
            title={getTranslation(
              "Recovery List",
              "Recovery List",
              "Recovery List"
            )}
            page={page}
            rowsPerPage={rowsPerPage}
            count={recoveryData?.totalCount}
            colums={columns}
            rows={recoveryData?.data}
            sortBy={sortBy}
            onSorting={(sort, direction) => {
              setSortBy(sort);
              setSortDirecion(direction);
            }}
            sortDirection={sortDirection == "asc" ? 1 : 0}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            viewSearch
            onSearch={(searchString) => handleSearchTanents(searchString)}
            viewEdit
            onEdit={(row) => {
              handleEditActivity(row);
            }}
            enableCheckbox={true} // Enable checkboxes
            onSelectionChecked={(row) => handleSelectionChecked(row.id)} // Callback for selection
            onSelectionUnChecked={(row) => handleSelectionUnChecked(row.id)} // Callback for deselection
            onSelectionChange={(row) => {
              handleSelectAll(row);
            }}
            selected={selectRow}
            viewDelete={status === "active" ? false : true}
            onDelete={(row) => handleOpenDeleteDialog(row)}
            actions={[
              {
                component: (rowData) =>
                  rowData.recoveryProcedureStatus == 0 ? (
                    <Tooltip
                      title={getTranslation(
                        "Close Recovery",
                        "Close Recovery",
                        "Close Recovery"
                      )}
                      arrow
                      placement='left-start'
                    >
                      <IconButton
                        size='small'
                        variant='contained'
                        onClick={() => {
                          closeRecovery(rowData);
                        }}
                      >
                        <CancelIcon />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    ""
                  ),
              },
              {
                component: (rowData) =>
                  rowData.recoveryProcedureStatus == 2 ? (
                    <Tooltip
                      title={getTranslation(
                        "Edit Recovery",
                        "Edit Recovery",
                        "Edit Recovery"
                      )}
                    >
                      <EditButton
                        onClick={() => {
                          onEditRecovery(rowData);
                        }}
                      />
                    </Tooltip>
                  ) : (
                    ""
                  ),
              },
            ]}
          />

          {status == "draft" ? (
            <Box
              style={{
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "flex-end",
                marginTop: "10px",
              }}
            >
              <ButtonWithLoading
                variant='contained'
                color='primary'
                title={getTranslation(
                  "Confirm Recovery Procedure",
                  "Confirm Recovery Procedure",
                  "Confirm Recovery Procedure"
                )}
                size='medium'
                loading={isLoading}
                onClick={() => {
                  confirmRecoveryProcedures(selectRow);
                }}
              />
            </Box>
          ) : (
            ""
          )}
        </CardContent>
      </Card>

      {/* close recovery procedure  */}
      <DialogComponent
        title='Close Recovery Procedure'
        open={isOpenDialog}
        onClose={() => setIsOpenDialog(false)}
      >
        <CloseRecoveryDialog
          handleSubmit={postCloseRecovery}
          onClose={() => setIsOpenDialog(false)}
          recoveryData={closeRecoveryData}
          isLoading={isLoading}
        />
      </DialogComponent>

      {/* Start recovery  dialog */}
      <DialogComponent
        fullWidth
        maxWidth='lg'
        title={getTranslation(
          "Start Recovery Procedure",
          "Start Recovery Procedure",
          "Start Recovery Procedure"
        )}
        open={startRecoveryDialog}
        onClose={() => {
          setStartRecoveryDialog(false);
        }}
      >
        <RecoveryProcedureStepper
          tenantList={tenantList}
          tenantDetailLis={tenantDetailList}
          tenantData={recoveryInfo}
          isEdit={isEdit}
          editRecoveryData={editRecoveryData}
          onClose={() => setStartRecoveryDialog(false)}
          handleSubmit={postRecoveryProcedure}
          onUpdate={upDateRecoveryProcedure}
          isLoading={isLoading}
        />
      </DialogComponent>

      <AlertDialog
        open={openDeleteDialog}
        deleting={isLoading}
        onClose={() => setOpenDeleteDialog(false)}
        onSubmit={handleDeleteRecovery}
      />
    </div>
  );
};

export default TenantRecoveryProcedure;
