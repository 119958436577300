import { getTranslation } from "../../heplers/translationHelper";
import authUtils from "../../utils/authUtils";
import Dashbord from "../../assests/icons/dashboard.png";
import Building from "../../assests/icons/building.png";
import {
  DashboardIcon,
  NewBankIcon,
  NewDuesIcon,
  NewSettingIcon,
  NewSupplierIcon,
  NewTenantIcon,
} from "../../components/icons";
import { NewBuildingIcon } from "../../components/icons";

const navConfig = [
  {
    subheader: "",
    items: [
      {
        title: getTranslation("Dashboard", "Dashboard", "Dashboard"),
        icon: (props) => <DashboardIcon {...props} />,
        src: Dashbord,
        href: "/dashboard",
      },
      {
        title: getTranslation("Buildings", "Buildings", "Buildings"),
        icon: (props) => <NewBuildingIcon {...props} />,
        src: Building,
        href: "/buildings",
      },
      {
        title: getTranslation("Tenants", "Tenants", "Tenants"),
        icon: (props) => <NewTenantIcon {...props} />,
        href: "/tanents",
      },
      {
        title: getTranslation("Dues", "Dues", "Dues"),
        icon: (props) => <NewDuesIcon {...props} />,
        href: "/dues/duesGenerated",
      },
      {
        title: getTranslation("Bank", "Bank", "Bank"),
        icon: (props) => <NewBankIcon {...props} />,
        href: "/bank",

        component: () => (
          <span style={{ color: "rgb(94,103,121)", fontWeight: "bold" }}>
            {getTranslation(" Bank ", " Banque ", " Bank ") + " "}{" "}
            {authUtils.getIsStarter() == true ? (
              <span style={{ color: "#fb5e39" }}>
                {" "}
                {getTranslation("Upgrade", "Upgrade", "Upgrade")}
              </span>
            ) : null}
          </span>
        ),
      },
      {
        title: getTranslation("Supplier", "Supplier", "Supplier"),
        icon: (props) => <NewSupplierIcon {...props} />,

        href: "/suppliers",
      },

      {
        title: getTranslation("Settings", "Settings", "Settings"),
        icon: (props) => <NewSettingIcon {...props} />,
        href: "/settings/email",
      },
    ],
  },
];

export default navConfig;
