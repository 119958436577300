import React from "react";
import {
	TableHead,
	TableRow,
	TableCell,
	TableSortLabel,
	makeStyles,
	Checkbox,
	Typography,
	FormControlLabel,
} from "@material-ui/core";
import { getTranslation } from "../../heplers/translationHelper";

const useStyles = makeStyles((theme) => ({
	tableHeader: {
		backgroundColor: theme.palette.secondary.light,
		color: theme.palette.common.white,
	},
}));

const TableHeader = (props) => {
	const styleClasses = useStyles();
	const {
		numSelected,
		rowCount,
		enableCheckbox,
		classes,
		order,
		orderBy,
		onRequestSort,
		onSelectAllClick,
	} = props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead
			className={classes.tableHeader}
			style={{ marginBottom: "100px" }}
		>
			<TableRow>
				{enableCheckbox ? (
					<TableCell
						padding="checkbox"
						className={classes.headerRow}
						style={{ borderTopLeftRadius: "20px" }}
					>
						<FormControlLabel
							control={
								<Checkbox
									color="primary"
									indeterminate={numSelected > 0 && numSelected < rowCount}
									checked={rowCount > 0 && numSelected >= rowCount}
									onChange={(event) => {
										onSelectAllClick(event.target.checked);
									}}
									inputProps={{
										"aria-label": "All",
									}}
									style={{ color: "white" }}
								/>
							}
							label={getTranslation("All", "All", "All")}
							style={{ color: "white" }}
						/>
					</TableCell>
				) : null}
				{props.colums?.map((headCell, index) => (
					<TableCell
						width={headCell.width}
						key={headCell.id}
						align={headCell.align}
						padding="default"
						sortDirection={orderBy === headCell.id ? order : false}
						className={classes.headerRow}
						style={
							index == 0 && !enableCheckbox
								? { borderTopLeftRadius: "20px" }
								: index == props?.colums?.length - 1
								? { borderTopRightRadius: "20px" }
								: {}
						}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : "asc"}
							onClick={createSortHandler(headCell.id)}
							style={{ color: "white" }}
						>
							{headCell.label}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
};

export default TableHeader;
