import React from "react";
import { tenantCategories } from "../../enums";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { getTranslation } from "../../../heplers/translationHelper";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { DeleteIConButton } from "../../../components/ButttonsWithIcons";

const SplitFundtoTenant = ({
  balance,
  item,
  onSubmit,
  onChanged,
  remainingItems,
  isDisable,
  index,
  deleteItem,
  enableAmountError,
  disableAmountError,
}) => {
  const initialValues = {
    duesCategory: tenantCategories[0].id,
    amount: 0,
  };
  const basicValidationSchema = Yup.object().shape({
    amount: Yup.number().min(1, "amount is required"),
  });
  const defaultValue = item ? item : initialValues;

  const currentItem = (id) => {
    return tenantCategories.find((x) => x.id == id);
  };
  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={defaultValue}
        validationSchema={basicValidationSchema}
        onSubmit={(values, actions) => {
          actions.setSubmitting(true);
          onSubmit(values, actions);
        }}
      >
        {({
          errors,
          touched,
          values,
          handleSubmit,
          isSubmitting,
          getFieldProps,
          setFieldValue,
        }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={5} sm={5} md={5} lg={5}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="demo-simple-select-label">
                    {getTranslation("Category", "Cat�gorie", "Kategorie")}
                  </InputLabel>
                  <Select
                    style={{
                      height: "40px",
                    }}
                    disabled={isDisable}
                    autoComplete="true"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label={getTranslation("Category", "Category", "Category")}
                    name="duesCategory"
                    value={values.duesCategory}
                    onChange={(e) => {
                      setFieldValue("duesCategory", e.target.value);
                      onChanged({
                        duesCategory: e.target.value,
                        amount: values.amount,
                      });
                    }}
                  >
                    {remainingItems.findIndex(
                      (x) => x.id == currentItem(values.duesCategory)?.id
                    ) == -1 ? (
                      <MenuItem value={currentItem(values.duesCategory)?.id}>
                        {" "}
                        {currentItem(values.duesCategory)?.label}
                      </MenuItem>
                    ) : null}
                    {remainingItems.map((category) => (
                      <MenuItem value={category.id}> {category.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={5} sm={5} md={5} lg={5}>
                <TextField
                  size="small"
                  name="amount"
                  type="number"
                  label={getTranslation(
                    "Enter a value",
                    "Enter a value",
                    "Enter a value"
                  )}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled={index == 3 ? true : isDisable}
                  value={values.amount}
                  onChange={(e) => {
                    if (e.target.value <= balance)
                      setFieldValue("amount", e.target.value);
                  }}
                  inputProps={{ max: balance }}
                  // onBlur={() => {
                  //   if (values.amount <= balance) {
                  //     onChanged(values);
                  //   }
                  // }}

                  onBlur={() => {
                    if (values.amount == balance) {
                      disableAmountError();
                    } else {
                      onChanged(values);
                      enableAmountError();
                    }
                  }}
                  InputProps={{ inputProps: { max: balance } }}
                />
              </Grid>
              <Grid
                item
                xs={2}
                sm={2}
                md={2}
                lg={2}
                xl={2}
                style={{ marginTop: "5px" }}
              >
                {index > 0 ? (
                  <DeleteIConButton
                    onClick={() => {
                      deleteItem(index, values);
                    }}
                  />
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SplitFundtoTenant;
