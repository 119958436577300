import {
	Box,
	Card,
	CardContent,
	Divider,
	Grid,
	IconButton,
	InputAdornment,
	TextField,
	Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Form, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import ButtonWithLoading from "../../../components/ButtonWithLoading";
import { DatePicker } from "../../../components/DatePicker";
import DialogComponent from "../../../components/Dialog";
import { AvailableApartments } from "./availableApartments";
import {
	Get_AllAvailableApartments_Url,
	Post_FileUpload_URL,
} from "../../../constants/apiUrls";
import { useSnackbar } from "notistack";
import { Get, Post } from "../../../actions";
import * as Yup from "yup";
import { getTranslation } from "../../../heplers/translationHelper";
import { UploadFiles } from "../../../components/Upload";
import { CloudUpload } from "@material-ui/icons";
import _ from "lodash";
import SplitAmount from "./splitAmount";
import SplitAmountMapper from "./splitAmountMapper";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CircularProgress from "@material-ui/core/CircularProgress";
import { format } from "../../../heplers/format";

export const StartContractDialog = ({
	buildings,
	tenantId,
	isOpen,
	onClose,
	onSubmit,
}) => {
	const [apartmentList, setApartmentList] = useState([]);
	const [apartmentListLoading, setApartmentListLoading] = useState(false);
	const [selectedFileName, setSelectedFileName] = useState();
	const [selectedFile, setSelectedFile] = useState(null);
	const { enqueueSnackbar } = useSnackbar();
	const [startDate, setStartDate] = useState(new Date());
	const fileInputRef = useRef(null);
	const [fundToCategoryArray, setFundToCategoryArray] = useState([]);
	const [appartmentRent, setAppartmentRent] = useState();
	const [warranty, setWarranty] = useState(0);
	const [refresh, setRefresh] = useState(false);
	const [uploadedFile, setUploadedFile] = useState();
	const [aviableStatus, setAvaibleStatus] = useState(false);
	const [rentAmountError, setrentAmountError] = useState(false);
	const [buildingError, setBuildingError] = useState(true);
	const [initialRent, setInitialRent] = useState();
	const [selectedApt, setSelectedApt] = useState(false);
	const [update, setUpdate] = useState(false);

	useEffect(() => {
		setFundToCategoryArray([
			{
				isIndexed: true,
				// amount: appartmentRent,
				amount: initialRent,
				type: 0,
				description: "",
			},
			{
				isIndexed: false,
				amount: 0,
				type: 1,
				description: "",
			},
		]);
	}, [refresh, initialRent, apartmentList]);

	useEffect(() => {
		if (selectedFile != null) {
			handleSubmitFile();
		}
	}, [selectedFile]);

	const loadAvailableApartments = (buildingId, startDate) => {
		setApartmentListLoading(true);
		Get(
			{
				buildingId: buildingId,
				startDate: startDate,
			},
			Get_AllAvailableApartments_Url,
			null,
			(resp) => {
				setApartmentList(resp.data);
				setApartmentListLoading(false);
			},
			(error) => {
				enqueueSnackbar(
					getTranslation(
						"Something went wrong. Please contact with admin.",
						"Something went wrong. Please contact with admin.",
						"Something went wrong. Please contact with admin."
					),
					{ variant: "error" }
				);
				setApartmentListLoading(false);
			}
		);
	};

	const enableError = () => {
		setrentAmountError(true);
	};

	const disableError = () => {
		setrentAmountError(false);
	};

	const handleFileUpload = (event) => {
		const file = event?.target.files[0];
		const fileName = file?.name;
		const lastDotIndex = _.findLastIndex(fileName, (char) => char === ".");
		const name =
			lastDotIndex !== -1
				? _.slice(fileName, 0, lastDotIndex).join("")
				: fileName;
		const extension =
			lastDotIndex !== -1 ? _.slice(fileName, lastDotIndex).join("") : "";
		setSelectedFileName(name);
		setSelectedFile({
			...selectedFile,
			file: file,
		});
	};

	const handleSubmitFile = () => {
		if (selectedFile?.file == null) {
			enqueueSnackbar(
				getTranslation(
					"No file selected",
					"No file selected",
					"No file selected"
				),
				{
					variant: "warning",
				}
			);
		} else {
			let formData = new FormData();
			formData.append("formFile", selectedFile?.file);
			Post(
				formData,
				Post_FileUpload_URL,
				null,
				(resp) => {
					setUploadedFile(resp?.data);
				},
				(err) => {
					enqueueSnackbar(
						err?.data
							? getTranslation(err?.data, err?.data, err?.data)
							: getTranslation(
									"Something went wrong. Please contact with admin.",
									"Something went wrong. Please contact with admin.",
									"Something went wrong. Please contact with admin."
							  ),
						{
							variant: "error",
						}
					);
				}
			);
		}
	};

	const handleOnAdd = () => {
		const arr = fundToCategoryArray;
		setFundToCategoryArray([
			...arr,
			{ isIndexed: false, type: 0, amount: 0, description: "" },
		]);
	};

	const initialValue = {
		startDate: startDate,
		tenantId: tenantId,
		apartmentId: 0,
		currentRent: 0,
		warranty: 0,
	};

	const basicValidationSchema = Yup.object().shape({
		startDate: Yup.date().required(
			getTranslation(
				"Start Date is Required",
				"Start Date is Required",
				"Start Date is Required"
			)
		),
		warranty: Yup.number().required(
			getTranslation(
				"Warranty is Required",
				"Warranty is Required",
				"Warranty is Required"
			)
		),
	});

	const defaultValue = initialValue;
	return (
		<DialogComponent
			maxWidth="lg"
			title={getTranslation(
				"Start Contract",
				"Dbut du contrat",
				"Vertrag starten"
			)}
			open={isOpen}
			onClose={() => {
				onClose();
				setApartmentList([]);
				setAvaibleStatus(false);
				setrentAmountError(false);
				setAppartmentRent(0);
				setWarranty(0);
				setSelectedFile(null);
				setSelectedFileName();
			}}
		>
			<Card>
				<CardContent>
					<Formik
						enableReinitialize
						initialValues={defaultValue}
						validationSchema={basicValidationSchema}
						onSubmit={(values, actions) => {
							// setAvaibleStatus(false);
							actions.setSubmitting(true);
							values.startDate = values.startDate.toDateString();
							values.additionalPayments = fundToCategoryArray;
							values.warranty = warranty;
							values.document = uploadedFile;
							values.currentRent = appartmentRent;
							console.log(values);
							onSubmit(values, actions);
							setSelectedFile(null);
							setSelectedFileName();
						}}
					>
						{({
							errors,
							touched,
							values,
							handleSubmit,
							isSubmitting,
							getFieldProps,
							setFieldValue,
						}) => (
							<Form>
								<Grid container spacing={2}>
									<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
										<DatePicker
											fullWidth
											id="startDate"
											variant="outlined"
											label={getTranslation(
												"Start Date",
												"Start Date",
												"Start Date"
											)}
											value={values.startDate}
											onChange={(newDate) => {
												setFieldValue("startDate", newDate);

												setStartDate(newDate);
											}}
											style={{
												borderRadius: "5px",
											}}
											error={touched.startDate && Boolean(errors.startDate)}
										/>
									</Grid>
									<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
										<Autocomplete
											id="buildingId"
											options={buildings}
											getOptionLabel={(option) => option.name}
											size="small"
											onChange={(e, building) => {
												if (!building) {
													setApartmentList([]);
													setBuildingError(true);
													setInitialRent();
													setAppartmentRent();
													setInitialRent();
													setSelectedApt(false);
												} else {
													setBuildingError(false);
													loadAvailableApartments(
														building?.id,
														values?.startDate
													);
													setAvaibleStatus(true);
													setAppartmentRent(0);
													setWarranty(0);
													setSelectedApt(true);
												}
											}}
											renderInput={(params) => (
												<TextField
													{...params}
													label={getTranslation(
														"Building",
														"Immeubles",
														"Immobilien"
													)}
													variant="outlined"
												/>
											)}
										/>
									</Grid>

									<Grid
										xs={12}
										sm={12}
										md={12}
										lg={12}
										style={{ marginTop: "20px", textAlign: "center" }}
									>
										{apartmentListLoading && <CircularProgress />}

										{!apartmentListLoading &&
											aviableStatus &&
											apartmentList.length === 0 && (
												<Typography variant="body1" color="textSecondary">
													{!buildingError
														? getTranslation(
																"There are currently no apartments available for this building.",
																"There are currently no apartments available for this building.",
																"There are currently no apartments available for this building."
														  )
														: getTranslation(
																"You have not selected any builing yet",
																"You have not selected any builing yet",
																"You have not selected any builing yet"
														  )}
												</Typography>
											)}

										{!apartmentListLoading &&
											aviableStatus &&
											apartmentList.length > 0 && (
												<AvailableApartments
													loading={apartmentListLoading}
													availableApartments={apartmentList}
													onApartmentSelected={(row) => {
														setFieldValue("apartment", row);
														setFieldValue("apartmentId", row.id);
														setFieldValue("currentRent", row.amount);
														setAppartmentRent(row.amount);
														setInitialRent(row.amount);
														setRefresh(true);
													}}
													selectedApartment={values.apartment}
												/>
											)}
									</Grid>

									{!apartmentListLoading &&
										aviableStatus &&
										apartmentList.length > 0 && (
											<Grid
												xs={12}
												sm={12}
												md={12}
												lg={12}
												xl={12}
												style={{ marginTop: "20px" }}
											>
												<Typography
													variant="h3"
													style={{ color: "rgb(0,68,103)", fontWeight: "bold" }}
												>
													{getTranslation(
														"Current Rent",
														"Current Rent",
														"Current Rent"
													)}
												</Typography>
											</Grid>
										)}

									{!apartmentListLoading &&
										aviableStatus &&
										apartmentList.length > 0 && (
											<Grid
												xs={12}
												sm={12}
												md={6}
												lg={3}
												xl={3}
												style={{ marginTop: "20px" }}
											>
												<TextField
													disabled={true}
													id="currentRent"
													fullWidth
													// type="number"
													label={getTranslation(
														"Current Rent",
														"Loyer actuel",
														"Aktuelle Miete"
													)}
													required
													size="small"
													variant="outlined"
													name={"currentRent"}
													value={format("number", appartmentRent)}
													InputLabelProps={{
														shrink: true,
													}}
													InputProps={{ inputProps: { min: 0 } }}
												/>
											</Grid>
										)}

									<Grid xs={12} sm={12} md={1} lg={1}></Grid>

									{!apartmentListLoading &&
										aviableStatus &&
										apartmentList.length > 0 && (
											<Grid
												xs={12}
												sm={12}
												md={4}
												lg={3}
												style={{ marginTop: "20px" }}
											>
												<TextField
													id="warranty"
													fullWidth
													label={getTranslation(
														"Warranty",
														"Warranty",
														"Warranty"
													)}
													//type="number"
													size="small"
													disabled={true}
													variant="outlined"
													name={"warranty"}
													value={format("number", warranty)}
													InputLabelProps={{
														shrink: true,
													}}
													InputProps={{ inputProps: { min: 0 } }}
												/>
											</Grid>
										)}
									<Grid xs={12} sm={12} md={1} lg={1}></Grid>
									{values.currentRent ? (
										<Grid
											item
											xs={12}
											sm={12}
											md={3}
											lg={3}
											xl={3}
											style={{ marginTop: "12px" }}
										>
											{!apartmentListLoading &&
												appartmentRent !== 0 &&
												aviableStatus &&
												apartmentList.length > 0 && (
													<TextField
														fullWidth
														disabled={selectedFileName ? true : false}
														value={selectedFileName}
														label={getTranslation(
															"Upload Document",
															"Upload Document",
															"Upload Document"
														)}
														variant="outlined"
														size="small"
														InputLabelProps={{
															shrink: true,
														}}
														InputProps={{
															readOnly: true,
															endAdornment: (
																<InputAdornment position="end">
																	<IconButton
																		onClick={() => {
																			fileInputRef.current.click();
																		}}
																	>
																		<CloudUpload
																			style={{
																				color: "#e65837",
																			}}
																		/>
																	</IconButton>
																	<input
																		type="file"
																		ref={fileInputRef}
																		style={{ display: "none" }}
																		onChange={handleFileUpload}
																	/>
																</InputAdornment>
															),
														}}
														style={{
															boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.2)",
															borderRadius: "5px",
														}}
													/>
												)}
										</Grid>
									) : (
										""
									)}
								</Grid>

								<br />
								{appartmentRent !== 0 &&
								values.currentRent &&
								apartmentList.length &&
								selectedApt > 0 ? (
									<>
										<SplitAmountMapper
											update={update}
											title="start"
											array={fundToCategoryArray}
											setArray={setFundToCategoryArray}
											setWarranty={setWarranty}
											appartmentRent={appartmentRent}
											setAppartmentRent={setAppartmentRent}
											enableError={enableError}
											disableError={disableError}
											initialRent={initialRent}
										/>
										<Box
											style={{
												display: "flex",
												alignItems: "flex-start",
												justifyContent: "flex-start",
												marginTop: "20px",
											}}
										>
											<ButtonWithLoading
												title={
													<>
														<Typography
															variant="h5"
															style={{
																fontWeight: "bold",
																color: "rgb(0,68,103)",
															}}
														>
															{getTranslation(
																"Add New Record",
																"Add New Record",
																"Add New Record"
															)}
															&nbsp;&nbsp;{" "}
														</Typography>
														<AddCircleIcon
															style={{ color: "rgb(251,94,57)" }}
														/>
													</>
												}
												size="small"
												variant="contained"
												//loading={isSubmitting}
												onClick={handleOnAdd}
												style={{
													background: "rgb(244,246,248)",
													boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.1)",
													padding: "12px 10px 12px 8px",
												}}
											/>
										</Box>
									</>
								) : (
									""
								)}

								{!apartmentListLoading &&
									aviableStatus &&
									apartmentList.length > 0 && (
										<Box
											style={{
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
												marginTop: "20px",
											}}
										>
											<ButtonWithLoading
												title={
													<Typography
														variant="h5"
														style={{ fontWeight: "bold", color: "white" }}
													>
														{getTranslation(
															"Start Contract",
															"Start Contract",
															"Start Contract"
														)}
													</Typography>
												}
												size="small"
												variant="contained"
												loading={isSubmitting}
												disabled={rentAmountError}
												onClick={handleSubmit}
												style={{
													boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.1)",
													padding: "12px 10px 12px 8px",
													// background: "rgb(251,94,57)",
													background: rentAmountError
														? "rgb(170,170,170)"
														: "rgb(251,94,57)",
													display: appartmentRent == 0 ? "none" : "",
													borderRadius: "20px",
													width: "230px",
												}}
											/>
										</Box>
									)}
								{rentAmountError && (
									<p
										style={{
											color: "red",
											textAlign: "center",
											paddingTop: "15px",
										}}
									>
										{getTranslation(
											"Amount of Rent Cant be less then the initail rent",
											"Amount of Rent Cant be less then the initail rent",
											"Amount of Rent Cant be less then the initail rent"
										)}
									</p>
								)}
							</Form>
						)}
					</Formik>
				</CardContent>
			</Card>
		</DialogComponent>
	);
};
