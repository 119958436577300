// Auth URLs
export const LOGIN_URL = "Auth/AppLogin";
export const Post_Register_URL = "Auth/Register";
export const Post_RefreshToken_URL = "Auth/RefreshToken";
export const GET_USER_PROFILE_URL = "Auth/GetUserProfile";
export const Post_UpdateUSER_PROFILE_URL = "Auth/UpdateUserProfile";
export const Post_UpdateUserPassword_URL = "Auth/ChangePassword";
export const Post_VerifyInternalAppLogin_URL = "Auth/VerifyInternalAppLogin";
export const Get_CustomerApps_URL = "Auth/GetCustomerApps";
export const GET_AllUsers_URL = "Auth/GetAllUsersWithPagination";
export const Post_Logout_URL = "Auth/Logout";
export const Get_GetPackageInfo_URL = "Auth/GetPackageInfo";
export const Get_Packages_GetPackages = "/Packages/GetPackage";

// Language Translation URLs
export const Get_LanguageDictionary_URL = `Language/GetLanguageDictionary`;
export const Post_AddNewText_URL = `Language/AddNewText`;

// File Manager URLs
export const Post_FileUpload_URL = "FileManager/Upload";
export const Get_File_URL = "FileManager/Get";
export const Get_DeleteFile_URL = "FileManager/DeleteFile";
export const Get_FileInfo_URL = "FileManager/GetFileInfo";
export const Get_DeleteFileInfo_URL = "FileManager/DeleteFileinfo";
// Dashboard URLs
export const Get_DashboardCards_URL = "Dashboard/DashboardCards";
export const Get_BuildingsProfitBar_URL = "/Dashboard/BuildingsProfitBar";
export const Get_TotalDuesCard_URL = "Dashboard/TotalDuesCard";
export const Get_OccupationCard_URL = "Dashboard/OccupationCard";
export const Get_ROICard_URL = "Dashboard/ROICard";
export const Get_UntraitedTransactionsCard_URL =
	"Dashboard/UntraitedTransactionsCard";
export const Get_BanksCards_URL = "/Dashboard/BankCards";
export const Get_IndexationCard_URL = "Dashboard/IndexationCard";
export const Get_BankCards_URL = "/Dashboard/BankCards";

//Building's URLs
export const Post_AddBuilding_URL = "Building/InsertBuilding";
export const Get_AllBuildingsWithPagination_URL =
	"Building/GetAllBuildingsWithPagination";
export const Post_UpdateBuilding_URL = "Building/UpdateBuilding";
export const Post_DeleteBuilding_URL = "Building/DeleteBuilding";
export const Get_AllBuildings_URL = "Building/GetAllBuildings";
export const Get_Building_URL = "Building/GetBuilding";
export const Get_BuldingSummary_URL = "Building/GetBuldingSummary";
export const Get_CostWithPagination_URL = "Building/GetCostWithPagination";
export const UpdateBuildingStatus = "Building/UpdateBuildingStatus";
export const Post_AddManager_URL = "Building/AddManager";
export const Get_BuildingManager_URL = "Building/GetBuildingManager";
export const Post_AddOwner_URL = "Building/AddOwner";
export const Post_AddBuildingOwner_URL = "Building/AddBuildingOwner";
export const Get_BuildingOwnersWithPagination_URL =
	"Building/GetAllBuildingOwnersWithPagination";
export const Post_UpdateOwner_URL = "Building/UpdateOwner";
export const Post_UpdateBuildingOwner_URL = "Building/UpdateBuildingOwner";
export const Get_DeleteOwner_URL = "Building/DeleteBuildingOwner";
export const Get_AllBuildingOwners_URL = "Building/GetAllOwners";
export const Get_BuildingPercentage_URL = "Building/GetBuildingPercentage";
//Report URLs
export const Get_AllBuildingsReport_URL = "Building/BuildingsReport";
export const Get_BuildingReport_URL = "Building/BuildingReport";

//Apartments URLs
export const Get_AllAppartmentsWithPagination_URL =
	"Apartment/GetAllAppartmentsWithPagination";
export const Get_AllAppartments_URL = "Apartment/GetAllAppartments";
export const Get_Appartment_Url = "Apartment/GetAppartment";
export const Get_AllAvailableApartments_Url =
	"Apartment/GetAllAvailableApartments";
export const Post_InsertAppartment_URL = "Apartment/InsertAppartment";
export const Post_UpdateAppartment_URL = "Apartment/UpdateAppartment";
export const Post_DeleteAppartment_URL = "Apartment/DeleteAppartment";

//Tanents URL
export const Get_AllTanentsWithPagination_URL =
	"Tenant/GetAllTenantsWithPagination";
export const GetAllBuildingsWithApartments_URL =
	"Tenant/GetAllBuildingsWithApartments";
export const Post_AddTanent_URL = "Tenant/InsertTenant";
export const Post_UpdateTanents_URL = "Tenant/UpdateTenant";
export const Get_AllFundActivitiesWithPagination_URL =
	"Tenant/GetFundActivitiesWithPagination";
export const Get_FundActivities_URL = "Tenant/GetFundActivities";
export const Get_AllTanenets_URL = "Tenant/GetAllTenants";
export const Get_Tanenet_URL = "Tenant/GetTenant";
export const Post_SendReminderEmailToTenant_URL =
	"Tenant/SendReminderEmailToTenant";
export const Post_SendSMSReminderToTenant_URL =
	"Tenant/SendSMSReminderToTenant";
export const Get_SendAutoReminders_URL = "Tenant/SendAutoReminders";
export const Get_SendManualReminders_URL = "Tenant/SendManualReminders";
export const Delete_Tenant_URL = "Tenant/DeleteTenant";
export const Get_AllTenantPrint_URL = "Tenant/GetAllTenantsPrint";
export const Get_TenantDuesSummary_URL = "Tenant/GetTenantDuesSummary";
export const Post_TenantEmailPostMessage_URL = "Tenant/TenantEmailPostMessage";
export const Get_GetTenantBanks_URL = "Tenant/GetTenantBanks";
// export const Get_VerifyPhoneNo_URL="Tenant/VerifyPhoneNo";
// export const Get_GetPhoneNoStatus_URL="Tenant/GetPhoneNoStatus";
export const Post_SendSMS_URL = "Sms/SendSMS";
export const Get_GetSMS_URL = "Sms/GetSMS";
export const Post_UploadTenantFile_URL = "Tenant/UploadTenantFile";
export const Get_GetAllTenantFilesWithPagination_URL =
	"Tenant/GetAllTenantFilesWithPagination";
export const Post_DeleteTenantFile_URL = "Tenant/DeleteTenantFile";
export const Post_UpdateTenantFile_URL = "Tenant/UpdateTenantFile";
export const Get_DeleteTenantBank_URL = "Tenant/DeleteTenantBank";
export const Post_InsertNotes_URL = "Notes/InsertNotes";
export const Get_NotesWithPagination_URL = "Notes/GetNotesWithPagination";
export const Post_StartRecoveryProcedure_URL =
	"RecoveryProcedure/StartRecoveryProcedure";
export const Get_AllRecoveryProceduresWithPagination_URL =
	"RecoveryProcedure/GetAllRecoveryProceduresWithPagination";
export const Get_RecoveryProcedure_URL =
	"/RecoveryProcedure/GetRecoveryProcedure";
export const Post_UpdateRecoveryProcedure_URL =
	"RecoveryProcedure/UpdateRecoveryProcedure";
export const Post_EndRecoveryProcedure_URL =
	"RecoveryProcedure/EndRecoveryProcedure";
export const Post_ConfirmRecoveryProcedures_URL =
	"RecoveryProcedure/ConfirmRecoveryProcedures";
export const Post_DeleteRecoveryProcedure_URL =
	"RecoveryProcedure/DeleteRecoveryProcedure";
export const Get_RecoveryProcedureDaysInterval_URL =
	"SystemSetting/GetRecoveryProcedureDaysInterval";
export const Post_PoneRecoveryProcedure_URL =
	"RecoveryProcedure/PostponeRecoveryProcedure";
export const GET_GetLatestFundActivity_URL = "Tenant/GetLatestFundActivity";
export const GET_VerifyTenantPhoneNumber_URL = "Tenant/VerifyTenantPhoneNumber";

// Contracts
export const Post_StartContract_URL = "Tenant/StartContract";
export const Post_UpdateContract_URL = "Tenant/UpdateContract";
export const Post_ChangeContractDate_URL = "Tenant/ChangeContractDate";
export const Get_AllContractsWithPagination_URL =
	"Tenant/GetAllContractsWithPagination";
export const Get_DeleteContractEndDate_URL = "Tenant/DeleteContractEndDate";
export const Post_EmailCallReminder_URL = "Tenant/PostEmailCallReminder";

//Bank URLs
export const Get_AllBankTransactionsWithPagination_URL =
	"Bank/GetAllBankTransactionsWithPagination";
export const Get_AllBanks_URL = "Bank/GetAllBanks";
export const Post_UpdateBank_URL = "Bank/UpdateBank";
export const Get_AllBankTransactionsAccordingToBankWithPagination_URL =
	"Bank/GetAllBankTransactionsAccordingToBankWithPagination";
export const Get_BankBalance_URL = "Bank/GetBankBalance";
export const Get_BankTransaction_URL = "Bank/GetBankTransaction";
export const Get_ClearBankTransaction_URL = "Bank/ClearBankTransaction";
export const Post_AddFundToSupplier_URL = "Bank/AddFundToSupplier";
export const Post_AddFundToTenant_URL = "Bank/AddFundToTenant";
export const Post_DeleteBank_URL = "Bank/DeleteBank";
export const Post_UpdateBankTransaction_URL = "Bank/UpdateBankTransaction";
export const Post_UpdateBankStatus_URL = "Bank/UpdateBankStatus";
export const Get_ExportBankTransactionsCSV_URL =
	"Bank/ExportBankTransactionsCSV";
export const Get_ExportCSV_URL = "FileManager/ExportCSV";
export const Get_Bank_URL = "Bank/GetBank";
export const Post_AddBankTransactions_URL = "Bank/AddBankTransactions";

//Dues URLs
export const Post_InsertTenantDue_URL = "Due/InsertTenantDue";
export const Post_AddNote_URL = "Due/AddNote";
export const Post_InsertSupplierDue_URL = "Due/InsertSupplierDue";
export const Post_UpdateDue_URL = "Due/UpdateDue";

export const Post_DeleteDue_URL = "Due/DeleteDue";
export const GenerateDues_URL = "Cron/GenerateDues";
export const Post_ConfirmDues_URL = "Due/ConfirmingDues";
export const Post_PostPoneDue_URL = "Due/PostPoneDue";

export const Get_AllDuesWithPagination_URL = "Due/GetAllDuesWithPagination";
export const Get_AllPreviousDuesWithPagination_URL =
	"Due/GetAllConfirmedDuesWithPagination";

//Suppliers URL
export const Get_GetAllSuppliersWithPagination_URL =
	"Supplier/GetAllSuppliersWithPagination";
export const Get_Supplier_Url = "Supplier/GetSupplier";
export const Get_AllSuppliers_Url = "Supplier/GetAllSuppliers";
export const Get_AllSuppliersCategories_URL =
	"Supplier/GetAllSupplierCategoriesList";
export const Post_InsertSupplier_URL = "Supplier/InsertSupplier";
export const Post_UpdateSupplier_URL = "Supplier/UpdateSupplier";
export const Post_DeleteSupplier_URL = "Supplier/DeleteSupplier";
export const Get_SuppliersReport_URL = "Building/SuppliersReport";
export const Get_AttachedSupplierCategories_URL =
	"Supplier/GetAttachedSupplierCategories";
export const Get_AllSupplierCategories_URL =
	"Supplier/GetAllSupplierCategories";
export const Post_InsertSupplierCategory_URL =
	"Supplier/InsertSupplierCategory";
export const Post_DeAttachSupplierCategory_URL =
	"Supplier/DeAttachSupplierCategory";
export const Post_AttachSupplierCategory_URL =
	"Supplier/AttachSupplierCategory";

//System Settings
export const Post_UpdateEmailSenderName_URL =
	"SystemSetting/UpdateEmailSenderName";
export const Get_EmailSenderName_URL = "SystemSetting/GetEmailSenderName";
export const Get_SystemSetting_URL = "SystemSetting/GetSystemSetting";
export const Get_AllBanksWithPagination_URL = "Bank/GetAllBanksWithPagination";
export const Post_UpsertSystemSetting_URL = "Bank/InsertBank";
export const Get_EmailSetting_URL = "SystemSetting/LoadEmailSetting";
export const Post_UpsertEmailSetting_URL = "SystemSetting/UpsertEmailSetting";
export const Post_MakeDefaultSetting_URL =
	"SystemSetting/MakeDefaultEmailSetting";
export const Get_EnableAutomaticSendReminder_URL =
	"SystemSetting/GetEnableAutomaticSendReminder";
export const Post_EnableAutomaticSendReminder_URL =
	"SystemSetting/EnableAutomaticSendReminder";
export const Post_EnablePostAutomaticSendReminder_URL =
	"SystemSetting/EnablePostAutomaticSendReminder";
export const Get_EnablePostAutomaticSendReminder_URL =
	"SystemSetting/GetEnablePostAutomaticSendReminder";
export const Post_UpdateReminderTemplate_URL =
	"SystemSetting/UpdateReminderTemplate";
export const Get_ReminderTemplate_URL = "SystemSetting/GetReminderTemplate";
export const Post_EnableSMSAutomaticSendReminder_URL =
	"SystemSetting/EnableSMSAutomaticSendReminder";
export const Get_EnableSMSAutomaticSendReminder_URL =
	"SystemSetting/GetEnableSMSAutomaticSendReminder";
export const Get_SMSReminderTemplate_URL =
	"SystemSetting/GetSMSReminderTemplate";
export const Get_EnableAutomaticCallReminder_URL =
	"SystemSetting/GetEnableAutomaticCallReminder";
export const Post_EnableAutomaticCallReminder_URL =
	"SystemSetting/PostEnableAutomaticCallReminder";
export const Post_CallReminderTemplate_URL =
	"SystemSetting/PostCallReminderTemplate";
export const Get_CallReminderTemplate_URL =
	"SystemSetting/GetCallReminderTemplate";
export const Post_UpdateSMSReminderTemplate_URL =
	"SystemSetting/UpdateSMSReminderTemplate";
export const Post_EnableAutoIndexation_URL =
	"SystemSetting/PostEnableAutoIndexation";
export const Get_EnableAutoIndexation_URL =
	"SystemSetting/GetEnableAutoIndexation";
export const Post_InsertBankWithoutPonto_URL = "Bank/InsertBankWithoutPonto";
export const Post_SystemSetting_SendTestEmail = "/SystemSetting/SendTestEmail";
export const Post_SystemSetting_SendTestPost = "/SystemSetting/SendTestPost";
export const Post_SystemSetting_SendTestSMS = "/SystemSetting/SendTestSMS";
export const Get_AutoRecoveryProcedure_URL =
	"SystemSetting/GetAutoRecoveryProcedure";
export const Post_EnableAutoRecoveryProcedure_URL =
	"SystemSetting/EnableAutoRecoveryProcedure";
export const Post_UpsertRecoveryProcedureDaysInterval_URL =
	"SystemSetting/UpsertRecoveryProcedureDaysInterval";
export const Post_ManagePostReminderForAppUser_URL =
	"SystemSetting/PostManagePostReminderForAppUser";
export const Post_ManageCallReminderForAppUser_URL =
	"SystemSetting/PostManageCallReminderForAppUser";
export const Post_ReminderCosts_URL = "SystemSetting/ReminderCosts";
export const Get_GetReminderCosts_URL = "SystemSetting/GetReminderCosts";
//Indexation
export const Get_AllIndexationProposalsWithPagination_URL =
	"Indexation/GetAllIndexationProposalsWithPagination";
export const Post_ConfirmIndexationProposals_URL =
	"Indexation/ConfirmIndexationProposals";
export const Get_ManageReminderForAppUser_URL =
	"SystemSetting/GetManageReminderForAppUser";
export const Get_GetAllTenantIndexationProposalsWithPagination_URL =
	"Indexation/GetAllTenantIndexationProposalsWithPagination";

//version 5.2
export const Post_DueConfirmSelectedDues_URL = "Due/ConfirmSelectedDues";
