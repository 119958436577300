import React, { useState, useEffect } from "react";
import {
	Box,
	Card,
	CardContent,
	CardHeader,
	Grid,
	IconButton,
	TextField,
	Tooltip,
	Typography,
} from "@material-ui/core";
import { getTranslation } from "../../../../../../heplers/translationHelper";
import { format } from "../../../../../../heplers/format";
import { PostponIcon } from "../../../../../../components/icons";
import {
	Autocomplete,
	ToggleButton,
	ToggleButtonGroup,
} from "@material-ui/lab";
import TableComponent from "../../../../../../components/table";
import { DownloadFile, Get, Post } from "../../../../../../actions";
import { DownloadButton } from "../../../../../../components/ButttonsWithIcons";

//notes columns
const columns = [
	{
		id: "date",
		label: getTranslation("Date", "Date", "Date"),
		align: "left",
		component: ({ row }) => {
			return <Typography>{format("date", row.date)}</Typography>;
		},
	},
	{
		id: "comments",
		label: getTranslation("Comment", "Comment", "Comment"),
		format: "left",
	},
	{
		id: "actions",
		label: getTranslation("Actions", "Actions", "Actions"),
		align: "right",
	},
];
const tenantColumns = [
	{
		id: "date",
		numeric: false,
		disablePadding: true,
		label: getTranslation("Date", "Date", "Date"),
		format: "date",
		component: ({ row }) => {
			return (
				<>
					<Typography>{format("date", row.date)}</Typography>
					<Typography variant="caption" style={{ color: "red" }}>
						{row.postponedReason}
					</Typography>
				</>
			);
		},
	},
	{
		id: "activity",
		numeric: false,
		disablePadding: true,
		label: getTranslation("Activity", "Type", "Typ"),
		component: ({ row }) => (
			<Typography>
				{" "}
				{getTranslation(row.activity, row.activity, row.activity)}
			</Typography>
		),
	},
	{
		id: "description",
		numeric: false,
		disablePadding: true,
		label: getTranslation("Description", "La description", "Beschreibung"),
		component: ({ row }) => {
			const descriptions = row.description
				.split(/\s*\d+\s*\|\s*/)
				.map((description) => description.trim());

			// Joining the updated descriptions
			const updatedDescription = descriptions.join(" | ");

			return (
				<Typography>
					{updatedDescription
						.replace("Amount from", "")
						.replace("Account", "")
						.replace("|", "")
						.replace("account", "")
						.replace(
							"Generated By Admin",
							getTranslation(
								"Generated By Admin",
								"Transaction générée par le systeme ",
								"Operation vom System generiert"
							)
						)}
				</Typography>
			);
		},
	},
	{
		id: "dueGenerated",
		numeric: true,
		disablePadding: true,
		label: getTranslation("Due", "Montant d�", "Zu zahlen"),
		format: "number",
		align: "right",
		component: ({ row }) => {
			return <Typography>{format("number", row?.dueGenerated)}</Typography>;
		},
	},
	{
		id: "amountDeposit",
		label: getTranslation("Bank", "Banque", "Bank"),
		format: "number",
		align: "right",
		component: ({ row }) => {
			return <Typography>{format("number", row?.amountDeposit)}</Typography>;
		},
	},
	{
		id: "actions",
		label: getTranslation("Action", "Action", "Action"),
		align: "right",
	},
];

function ActionsStep({
	dueList,
	setDueList,
	page,
	rowsPerPage,
	handleChangePage,
	handleChangeRowsPerPage,
	tenantDetailStatus,
	setTenantDetailStatus,
	isLoading,
	setIsLoading,
	notesData,
	pageNotes,
	rowsPerPageNotes,
	handleChangePageNotes,
	handleChangeRowsPerPageNotes,
	setAnimate,
}) {
	//const [tenantDetailStatus, setTenantDetailStatus] = useState("all");
	//const [isLoading, setIsLoading] = useState(false);
	const [showComponent, setShowComponent] = useState(false);

	useEffect(() => {
		setShowComponent(true);
		setAnimate(true);
	}, []);
	return (
		<div
			style={{
				opacity: showComponent ? 1 : 0,
				transition: "opacity 0.9s ease-in-out",
			}}
		>
			<Card style={{ marginTop: "20px", marginBottom: "20px" }}>
				{/* <CardHeader title={getTranslation("Dues", "Dues", "Dues")} /> */}
				<br></br>
				<ToggleButtonGroup
					size="medium"
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
					id="tenantDetailStatus"
					value={tenantDetailStatus}
					exclusive
					onChange={(event, tenantDetailStatus) => {
						if (tenantDetailStatus !== null) {
							setTenantDetailStatus(tenantDetailStatus);
						}
					}}
				>
					<ToggleButton value={"all"} aria-label="left aligned">
						<Typography>{getTranslation("All", "All", "All")}</Typography>
					</ToggleButton>
					<ToggleButton value={"rent"} aria-label="left aligned">
						<Typography>{getTranslation("Rent", "Rent", "Rent")}</Typography>
					</ToggleButton>
					<ToggleButton value={"cost"} aria-label="centered">
						<Typography>{getTranslation("Cost", "Cost", "Cost")}</Typography>
					</ToggleButton>
					<ToggleButton value={"warranty"} aria-label="centered">
						<Typography>
							{getTranslation("Warranty", "Warranty", "Warranty")}
						</Typography>
					</ToggleButton>
					<ToggleButton value={"maintenance"} aria-label="centered">
						<Typography>
							{getTranslation("Maintenance", "Maintenance", "Maintenance")}
						</Typography>
					</ToggleButton>
				</ToggleButtonGroup>
				<br />
				<CardContent>
					{/* dues table  */}
					<TableComponent
						loading={isLoading}
						dense
						title={getTranslation("Dues", "Dues", "Dues")}
						colums={tenantColumns}
						rows={dueList?.data}
						count={dueList?.totalCount}
						page={page}
						rowsPerPage={rowsPerPage}
						handleChangePage={handleChangePage}
						handleChangeRowsPerPage={handleChangeRowsPerPage}
						viewSearch
						actions={[
							{
								component: (row) =>
									row.pdfDocumentId != "" ? (
										<Tooltip
											title={getTranslation("Note", "Noter", "Notiz")}
											arrow
											placement="top"
										>
											<DownloadButton
												onClick={() => DownloadFile(row?.pdfDocumentId)}
											/>
										</Tooltip>
									) : null,
							},
						]}
					/>
				</CardContent>
			</Card>

			<Card>
				<CardContent>
					<TableComponent
						loading={isLoading}
						dense
						title={getTranslation("Notes", "Notes", "Notes")}
						colums={columns}
						rows={notesData?.data}
						count={notesData?.totalCount}
						page={pageNotes}
						rowsPerPage={rowsPerPageNotes}
						handleChangePage={handleChangePageNotes}
						handleChangeRowsPerPage={handleChangeRowsPerPageNotes}
						viewSearch
						actions={[
							{
								component: (row) =>
									row.document !== "" ? (
										<Tooltip
											title={getTranslation("Note", "Noter", "Notiz")}
											arrow
											placement="top"
										>
											<DownloadButton
												onClick={() => DownloadFile(row?.document)}
											/>
										</Tooltip>
									) : null,
							},
						]}
					/>
				</CardContent>
			</Card>
			<br></br>
			<br></br>
			<br></br>
			<br></br>
			<br></br>
		</div>
	);
}

export default ActionsStep;
