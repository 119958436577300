import {
	Box,
	CardContent,
	Checkbox,
	FormControl,
	FormControlLabel,
	IconButton,
	InputAdornment,
	TextField,
	Typography,
} from "@material-ui/core";
import ButtonWithLoading from "../../../../components/ButtonWithLoading";
import { getTranslation } from "../../../../heplers/translationHelper";
import { UploadDropZone } from "../../../../components/Upload";
import { useState, useRef } from "react";
import DialogComponent from "../../../../components/Dialog";
import PreviewUploadDocument from "./previewDocument";
import Table from "../../../../components/table";
import moment from "moment";
import { Post } from "../../../../actions";
import { useParams } from "react-router-dom";
import { Post_AddBankTransactions_URL } from "../../../../constants/apiUrls";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import _, { isEqual } from "lodash";
import CardComponent from "../../../../components/Card";
import GetAppIcon from "@material-ui/icons/GetApp";

export const UploadBankDocumentDialog = () => {
	const [isOpenDialog, setIsOpenDialog] = useState(false);
	const [tableHeader, setTableHeader] = useState(true);
	const [tableData, setTableData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const { enqueueSnackbar } = useSnackbar();
	const bankId = useParams();
	const history = useHistory();

	//image startes
	const [selectedFileName, setSelectedFileName] = useState();
	const [selectedFile, setSelectedFile] = useState(null);
	const fileInputRef = useRef(null);

	const columns = [
		{
			id: "valueDate",
			numeric: false,
			disablePadding: true,
			label: tableHeader ? getTranslation("Date", "Date", "Date") : "",
			format: "date",
		},
		{
			id: "counterPartName",
			numeric: true,
			disablePadding: true,
			label: tableHeader
				? getTranslation(
						"CounterPart Name",
						"CounterPart Name",
						"CounterPart Name"
				  )
				: "",
		},
		{
			id: "counterPartRefrence",
			numeric: true,
			disablePadding: true,
			label: tableHeader
				? getTranslation(
						"CounterPart Refrence",
						"CounterPart Refrence",
						"CounterPart Refrence"
				  )
				: "",
		},
		{
			id: "amount",
			align: "right",
			label: tableHeader ? getTranslation("Amount", "Montant", "Betrag") : "",
			format: "number",
		},
	];

	//upload bank transactions
	const postBankTransaction = () => {
		if (tableData.length > 0) {
			setIsLoading(true);
			Post(
				{
					bankId: bankId?.bankId,
					BankTransactions: tableData,
				},
				Post_AddBankTransactions_URL,
				null,
				(resp) => {
					enqueueSnackbar(
						getTranslation(
							"bank transaction uploaded successfully",
							"bank transaction uploaded successfully",
							"bank transaction uploaded successfully"
						),
						{
							variant: "success",
						}
					);
					setIsLoading(false);
					history.push("/bank", { bankId: bankId });
				},
				(err) => {
					enqueueSnackbar(
						err?.data
							? getTranslation(err?.data, err?.data, err?.data)
							: getTranslation(
									"Something went wrong. Please try again.",
									"Something went wrong. Please try again.",
									"Something went wrong. Please try again."
							  ),
						{
							variant: "error",
						}
					);
					setIsLoading(false);
				}
			);
		} else {
			enqueueSnackbar(
				getTranslation(
					"Upload Bank Transaction File",
					"Upload Bank Transaction File",
					"Upload Bank Transaction File"
				),
				{
					variant: "info",
				}
			);
		}
	};

	const handleFileChange = (event) => {
		const file = event[0];
		const allowedTypes = [
			"text/csv",
			"application/vnd.ms-excel",
			"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
		];

		if (!allowedTypes.includes(file.type)) {
			enqueueSnackbar(
				getTranslation(
					"Please upload a valid CSV or XLSX file.",
					"Please upload a valid CSV or XLSX file.",
					"Please upload a valid CSV or XLSX file."
				),
				{ variant: "error" }
			);
			return;
		}
		const reader = new FileReader();

		reader.onload = (event) => {
			const csvdata = event.target.result;
			const rowData = csvdata.split("\n");

			const newData = [];
			// const duplicates = []; // To store duplicate objects
			const start = 1;

			for (let row = start; row < rowData.length; row++) {
				if (rowData[row] === "") {
					continue;
				}

				const rowColData = rowData[row].split(";");
				if (
					rowColData.length < 4 ||
					!moment(rowColData[0], ["MM/DD/YYYY", "MM-DD-YYYY"]).isValid() ||
					isNaN(parseFloat(rowColData[3]))
				) {
					// Invalid data, skip this row
					continue;
				}
				const dataObject = {
					valueDate: rowColData[0],
					counterPartName: rowColData[1],
					counterPartRefrence: rowColData[2],
					// amount: Number.parseFloat(
					// 	rowColData[3].replace(".", "").replace(",", ".")
					// ),
					amount: rowColData[3].replace(".", "").replace(",", "."),
				};
				newData.push(dataObject);
			}
			setTableData(
				_.uniqWith(newData, (x, y) => {
					return isEqual(x, y);
				})
			);

			if (newData.length == 0) {
				enqueueSnackbar(
					getTranslation(
						"Your uploaded file format is not correct. Please download sample file and try again",
						"Your uploaded file format is not correct. Please download sample file and try again",
						"Your uploaded file format is not correct. Please download sample file and try again"
					),
					{ variant: "error" }
				);
			}
		};
		reader.readAsText(file);
	};

	const handleFileUpload = (event) => {
		const file = event?.target.files[0];
		console.log(file);

		if (file) {
			const fileName = file?.name;
			setSelectedFileName(fileName);
			setSelectedFile(file);
			AcceptedFiled(file);
		}
	};

	const AcceptedFiled = (file) => {
		handleFileChange(file);
	};

	const [csvButtonLoading, setCsvButtonLoading] = useState(false);

	var csvFileData = [
		["07/08/2023", "Marcel jack", "BE1234567890", "30,55"],
		["07/08/2023", "Marcel jack", "BE1234567890", "30,96"],
		["07/08/2023", "Marcel jack", "BE1234567890", "60"],
		["07/08/2023", "Marcel jack", "BE1234567890", "6,98"],
		["07/08/2023", "Marcel jack", "BE1234567890", "30"],
		["07/08/2023", "Marcel jack", "BE1234567890", "70"],
		["07/08/2023", "Marcel jack", "BE1234567890", "40"],
		["07/08/2023", "Marcel jack", "BE1234567890", "30"],
		["07/08/2023", "Marcel jack", "BE1234567890", "-80"],
		["07/08/2023", "Marcel jack", "BE1234567890", "-97"],
		["07/08/2023", "Marcel jack", "BE1234567890", "45"],
		["07/08/2023", "Marcel jack", "BE1234567890", "34"],
		["07/08/2023", "Marcel jack", "BE1234567890", "78"],
		["07/08/2023", "Marcel jack", "BE1234567890", "-14"],
	];

	//create a user-defined function to download CSV file
	const download_csv_file = () => {
		//define the heading for each row of the data
		var csv = "valueDate,counterPartRefrence,counterPartName,amount\n";

		//merge the data with CSV
		csvFileData.forEach(function (row) {
			csv += row.join(";");
			csv += "\n";
		});

		var hiddenElement = document.createElement("a");
		hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
		hiddenElement.target = "_blank";

		//provide the name for the CSV file to be downloaded
		hiddenElement.download = "Bank-Transaction-Sample.csv";
		hiddenElement.click();
	};

	return (
		<CardComponent>
			<CardContent>
				<div>
					<Box
						style={{
							display: "flex",
							marginBottom: "20px",
						}}
					>
						<UploadDropZone
							multiple={true}
							onAccepted={(files) => {
								handleFileChange(files);
							}}
							onRejected={() => {}}
						/>

						{/* <ButtonWithLoading
        			      //   onClick={exportCSV}
        			      style={{ marginRight: "10px" }}
        			      variant='contained'
        			      color='primary'
        			      title={
        			        <>
        			          {getTranslation(
        			            "Preview Sample(.CSV)",
        			            "Preview Sample(.CSV)",
        			            "Preview Sample(.CSV)"
        			          )}
        			        </>
        			      }
        			      size='medium'
        			      loading={false}
        			      onClick={() => {
        			        setIsOpenDialog(true);
        			      }}
        			    /> */}
					</Box>

					<div style={{ display: "flex", justifyContent: "space-between" }}>
						<div>
							<FormControl>
								<FormControlLabel
									label={getTranslation(
										"Remove Header From Table",
										"Remove Header From Table",
										"Remove Header From Table"
									)}
									labelPlacement="end"
									control={
										<Checkbox id="firstPersonDocuments" checked={tableHeader} />
									}
									onChange={(e) => {
										setTableHeader(e.target.checked);
									}}
								/>
							</FormControl>
						</div>
						<div>
							<ButtonWithLoading
								onClick={() => {
									download_csv_file();
								}}
								variant="contained"
								color="primary"
								title={
									<>
										{" "}
										<GetAppIcon />
										&nbsp;{" "}
										{getTranslation(
											"Download Sample(.CSV)",
											"Download Sample(.CSV)",
											"Download Sample(.CSV)"
										)}
									</>
								}
								size="small"
								loading={csvButtonLoading}
							/>
						</div>
					</div>
					{/* <Box
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "1.5rem",
            }}
          >
            <ButtonWithLoading
              //   onClick={exportCSV}
              style={{ marginRight: "10px" }}
              variant='contained'
              color='primary'
              title={
                <>
                  {getTranslation(
                    "Preview Sample(.CSV)",
                    "Preview Sample(.CSV)",
                    "Preview Sample(.CSV)"
                  )}
                </>
              }
              size='medium'
              loading={false}
              onClick={() => {
                setIsOpenDialog(true);
              }}
            />
          </Box> */}

					{/* csv file table  */}
					<Table
						dense
						loading={false}
						viewSearch
						title={getTranslation(
							"Uploaded Data",
							"Uploaded Data",
							"Uploaded Data"
						)}
						colums={columns}
						rows={tableData}
						count={tableData?.length}
					/>

					{/* upload documents sample dialog  */}
					<DialogComponent
						open={isOpenDialog}
						onClose={() => setIsOpenDialog(false)}
						title={"Upload Documents Sample"}
						fullWidth
						maxWidth="md"
					>
						<PreviewUploadDocument />
					</DialogComponent>

					<Box
						style={{
							display: "flex",
							justifyContent: "right",
							marginTop: "20px",
						}}
					>
						<ButtonWithLoading
							title={getTranslation(
								"Submit Bank Transaction",
								"Submit Bank Transaction",
								"Submit Bank Transaction"
							)}
							size="small"
							variant="contained"
							color="primary"
							loading={isLoading}
							onClick={() => {
								postBankTransaction();
							}}
						/>
					</Box>
				</div>
			</CardContent>
		</CardComponent>
	);
};
