import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import * as Yup from "yup";
import React from "react";
import { useRef } from "react";
import { getTranslation } from "../../heplers/translationHelper";
import ButtonWithLoading from "../../components/ButtonWithLoading";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  Get_ReminderTemplate_URL,
  Get_EnableAutomaticCallReminder_URL,
  Post_UpdateReminderTemplate_URL,
  Post_EnableAutomaticCallReminder_URL,
  Post_ManageCallReminderForAppUser_URL,
  Get_ManageReminderForAppUser_URL,
} from "../../constants/apiUrls";
import { useState } from "react";
import { Get, Post } from "../../actions";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { Form, Formik } from "formik";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";

const PhoneCallsRemainder = () => {
  const editorRef = useRef(null);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [callReminderCheck, setCallReminderCheck] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sendReminderType, setSendReminderType] = useState("ForYou");
  const [reminderTemplate, setReminderTemplate] = useState();

  // get enable call auto reminder
  useEffect(() => {
    Get(
      { reminderKey: "CallReminder" },
      Get_EnableAutomaticCallReminder_URL,
      null,
      (resp) => {
        setCallReminderCheck(resp?.data?.automaticCallReminder);
        setSendReminderType(resp?.data?.sendReminderType);
      },
      (error) => {
        enqueueSnackbar(error?.data, { variant: "error" });
      }
    );
  }, []);

  //get the call reminder email and DayOfMonth data
  useEffect(() => {
    Get(
      { reminderKey: "CallReminder" },
      Get_ReminderTemplate_URL,
      null,
      (resp) => {
        setReminderTemplate(resp?.data);
      },
      (err) => {
        enqueueSnackbar(
          err.data
            ? err.data
            : getTranslation(
                "Something went wrong. Please try again.",
                "Something went wrong. Please try again.",
                "Something went wrong. Please try again."
              ),
          {
            variant: "error",
          }
        );
      }
    );
  }, []);

  //post call reminder data
  const uploadCallReminder = (values, actions) => {
    setIsLoading(true);
    values.sendReminderType = sendReminderType;
    values.reminderKey = "CallReminder";
    Post(
      values,
      Post_UpdateReminderTemplate_URL,
      null,
      (resp) => {
        enqueueSnackbar(
          getTranslation(
            "Call Reminder Updated successfully",
            "Call Reminder Updated successfully",
            "Call Reminder Updated successfully"
          ),
          {
            variant: "success",
          }
        );
        postManageCallReminder();
        setIsLoading(false);
      },
      (err) => {
        enqueueSnackbar(
          err?.data
            ? err?.data
            : getTranslation(
                "Something went wrong. Please try again.",
                "Something went wrong. Please try again.",
                "Something went wrong. Please try again."
              ),
          {
            variant: "error",
          }
        );
        setIsLoading(false);
      }
    );
  };

  //post mange call reminder for app user
  const postManageCallReminder = () => {
    Post(
      { GetManageReminderForAppUser: sendReminderType },
      Post_ManageCallReminderForAppUser_URL,
      null,
      (resp) => {},
      (err) => {
        enqueueSnackbar(
          err?.data
            ? err?.data
            : getTranslation(
                "Something went wrong. Please try again.",
                "Something went wrong. Please try again.",
                "Something went wrong. Please try again."
              ),
          {
            variant: "error",
          }
        );
      }
    );
  };

  const initialValues = {
    email: "",
    dayOfMonth: 0,
    CallReminderTemplate: "CallReminder",
  };

  const basicValidationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid Email").required("Email is required"),
    // dayOfMonth: Yup.number().min(1).required("Day Interval is required"),
    dayOfMonth: Yup.number()
      .moreThan(0, "Day Interval must be greater than 0")
      .required("Day Interval is required"),
  });

  const defaultValue = reminderTemplate ? reminderTemplate : initialValues;
  return (
    <>
      <FormControl style={{ marginBottom: "20px" }}>
        <FormControlLabel
          label={getTranslation(
            "Enable Auto Phone Call",
            "Enable Auto Phone Call",
            "Enable Auto Phone Call"
          )}
          labelPlacement='start'
          control={
            <Checkbox
              id='callReminderCheck'
              style={{
                color: "rgb(229,60,22)",
              }}
              checked={callReminderCheck}
              onChange={(e, check) => {
                setCallReminderCheck(check);
                Post(
                  {
                    enable: check,
                  },
                  Post_EnableAutomaticCallReminder_URL,
                  null,
                  (resp) => {},
                  (error) => {
                    enqueueSnackbar(error?.data, { variant: "error" });
                  }
                );
              }}
            />
          }
        />
      </FormControl>
      <FormControl
        size='small'
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <ToggleButtonGroup
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          size='small'
          id='sendReminderType'
          value={sendReminderType}
          exclusive
          onChange={(event, reminderType) => {
            if (reminderType == null) {
              setSendReminderType("ForYou");
            } else {
              setSendReminderType(reminderType);
            }
          }}
        >
          <ToggleButton value={"ForYou"} aria-label='left aligned'>
            <Typography variant='h6'>
              {getTranslation("For You", "For You", "For You")}
            </Typography>
          </ToggleButton>
          <ToggleButton value={"ByYou"} aria-label='left aligned'>
            <Typography variant='h6'>
              {getTranslation("By You", "By You", "By You")}
            </Typography>
          </ToggleButton>
        </ToggleButtonGroup>
        {sendReminderType == "ForYou" ? (
          <FormHelperText style={{ color: "red" }}>
            {getTranslation(
              "Enable reminder by us might occur cost for sending reminder",
              "Enable reminder by us might occur cost for sending reminder",
              "Enable reminder by us might occur cost for sending reminder"
            )}
          </FormHelperText>
        ) : (
          ""
        )}
      </FormControl>

      <Formik
        enableReinitialize
        initialValues={defaultValue}
        validationSchema={basicValidationSchema}
        onSubmit={(values, actions) => {
          actions.setSubmitting(true);
          uploadCallReminder(values, actions);
        }}
      >
        {({
          values,
          setFieldValue,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
          getFieldProps,
        }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  fullWidth
                  variant='outlined'
                  value={values.dayOfMonth}
                  required
                  type='number'
                  id='dayOfMonth'
                  label={getTranslation(
                    "Day Interval",
                    "Day Interval",
                    "Day Interval"
                  )}
                  onChange={(e) => {
                    setFieldValue("dayOfMonth", e.target.value);
                  }}
                  name='DayOfMonth'
                  size='small'
                  error={touched.dayOfMonth && Boolean(errors.dayOfMonth)}
                  helperText={touched.dayOfMonth && errors.dayOfMonth}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{ inputProps: { min: 1 } }}
                />
              </Grid>
              {sendReminderType == "ForYou" ? (
                ""
              ) : (
                <>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <TextField
                      fullWidth
                      variant='outlined'
                      value={values.email}
                      required
                      id='email'
                      size='small'
                      label={getTranslation("Email", "Email", "Email")}
                      name='email'
                      {...getFieldProps("email")}
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email && errors.email}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <ButtonWithLoading
                      onClick={() => {
                        history.push(`/public/phonecalls/1`);
                      }}
                      variant='contained'
                      style={{
                        backgroundColor: "rgb(229,60,22)",
                        color: "white",
                        fontWeight: "bold",
                      }}
                      title={getTranslation(
                        "Public Screen",
                        "Public Screen",
                        "Public Screen"
                      )}
                      size='medium'
                    />
                  </Grid>
                </>
              )}
            </Grid>

            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <ButtonWithLoading
                onClick={handleSubmit}
                variant='contained'
                style={{
                  backgroundColor: "rgb(229,60,22)",
                  color: "white",
                  fontWeight: "bold",
                }}
                title={getTranslation("Confirm", "Confirm", "Confirm")}
                size='medium'
                loading={isLoading}
              />
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default PhoneCallsRemainder;
