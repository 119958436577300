import React from "react";
import { useEffect } from "react";
import {
	userLogin,
	getCustomerApps,
	Post,
	verifyInternalAppLogin,
} from "../src/actions/authActions";
import authUtils from "../src/utils/authUtils";
import { useLocation } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";

const Upgrade = () => {
	const location = useLocation();
	useEffect(() => {
		const params = new URLSearchParams(location.search);
		const userappid = params.get("id");
		const userString = localStorage.getItem("user");
		const user = JSON.parse(userString);
		getCustomerApps(
			{
				email: user.email,
				appId: 1,
			},
			(resp) => {
				authUtils.setCustomerId(userappid);
				authUtils.setCustomerOrganizations(resp);
				window.location.href = `${process.env.REACT_APP_CLIC_RENT_DOMAIN}dashboard`;
			},
			(error) => {
				// setCustomerAppsLoading(false);
				// enqueueSnackbar(error.data, {
				//   variant: "error",
				// });
				console.log("error");
			}
		);
	}, []);
	return (
		<div
			style={{
				display: "flex",
				width: "100%",
				justifyContent: "center",

				alignItems: "center",
			}}
		>
			<CircularProgress />
		</div>
	);
};

export default Upgrade;
