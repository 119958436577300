import { Card, CardContent, CardHeader, Grid } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import Table from "../../../../../components/table";
import { getTranslation } from "../../../../../heplers/translationHelper";
import { Box, IconButton, Tooltip, Typography } from "@material-ui/core";
import { AddPostDueDate } from "../../addPostponDueDate";
import { PostponIcon } from "../../../../../components/icons";
import { DownloadFile, Get, Post } from "../../../../../actions";
import {
  Get_TenantDuesSummary_URL,
  Post_PostPoneDue_URL,
} from "../../../../../constants/apiUrls";
import { useSnackbar } from "notistack";
import { format } from "../../../../../heplers/format";
import {
  DownloadButton,
  PrintButton,
} from "../../../../../components/ButttonsWithIcons";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import CardComponent from "../../../../../components/Card";

const columns = [
  {
    id: "date",
    numeric: false,
    disablePadding: true,
    label: getTranslation("Date", "Date", "Date"),
    format: "date",
    component: ({ row }) => {
      return (
        <>
          <Typography>{format("date", row.date)}</Typography>
        </>
      );
    },
  },
  {
    id: "activity",
    numeric: false,
    disablePadding: true,
    label: getTranslation("Activity", "Type", "Typ"),
    component: ({ row }) => (
      <Typography>
        {" "}
        {getTranslation(row.activity, row.activity, row.activity)}
      </Typography>
    ),
  },
  {
    id: "description",
    numeric: false,
    disablePadding: true,
    label: getTranslation("Description", "La description", "Beschreibung"),
    component: ({ row }) => {
      const descriptions = row.description
        .split(/\s*\d+\s*\|\s*/)
        .map((description) => description.trim());

      // Joining the updated descriptions
      const updatedDescription = descriptions.join(" | ");

      return (
        <>
          <Typography>
            {updatedDescription
              .replace("Amount from", "")
              .replace("Account", "")
              .replace("|", "")
              .replace("account", "")
              .replace(
                "Generated By Admin",
                getTranslation(
                  "Generated By Admin",
                  "Transaction générée par le systeme ",
                  "Operation vom System generiert"
                )
              )
              .replace(
                "generated by admin",
                getTranslation(
                  "Generated By Admin",
                  "Generated By Admin",
                  "Generated By Admin"
                )
              )}
          </Typography>
          <Typography variant='caption' style={{ color: "red" }}>
            {row.postponedReason}
          </Typography>
        </>
      );
    },
  },
  {
    id: "dueGenerated",
    numeric: true,
    disablePadding: true,
    label: getTranslation("Due", "Montant d�", "Zu zahlen"),
    format: "number",
    align: "right",
  },
  {
    id: "amountDeposit",
    label: getTranslation("Bank", "Banque", "Bank"),
    format: "number",
    align: "right",
  },
  {
    id: "actions",
    label: getTranslation("Action", "Action", "Action"),
    align: "right",
  },
];

const Dues = ({ tenantId, printDetails, loading, rows, onFilter }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchString, setSearchString] = useState("");
  const [sort, setSort] = useState("date");
  const [sortDirection, setSortDirection] = useState(1);
  const [addPostponDueDate, setAddPostponDueDate] = useState(false);
  const [due, setDue] = useState(null);
  const [tenantDetailStatus, setTenantDetailStatus] = useState("all");
  const [tenantDuesSummary, setTenanDuesSummary] = useState();

  const { enqueueSnackbar } = useSnackbar();

  // filter useEffect
  useEffect(() => {
    onFilter({
      searchString,
      pageNumber: page ? page : 0,
      pageSize: rowsPerPage ? rowsPerPage : 10,
      sort: sort,
      sortDirection: sortDirection,
      tenantId: tenantId,
      duescategory: tenantDetailStatus,
    });
  }, [
    page,
    searchString,
    rowsPerPage,
    sort,
    sortDirection,
    tenantDetailStatus,
  ]);

  //handlers
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  //set rowperpage
  const handleChangeRowsPerPage = (event) => {
    localStorage.setItem("sizeDue", parseInt(event.target.value));
    setRowsPerPage(localStorage.getItem("sizeDue"));
    setPage(0);
  };
  const handleSearchDetails = (searchString) => {
    setSearchString(searchString);
  };

  const handleAddPostponDueDate = (rowData) => {
    setDue(rowData);
    setAddPostponDueDate(true);
  };

  const handlePostPoneDue = (value, actions) => {
    Post(
      value,
      Post_PostPoneDue_URL,
      null,
      (resp) => {
        setAddPostponDueDate(false);
        enqueueSnackbar(resp.data, { variant: "success" });
        actions.setSubmitting(false);
        actions.resetForm();
        setDue({});
        onFilter({
          searchString,
          pageNumber: page,
          pageSize: rowsPerPage,
          sort: sort,
          sortDirection: sortDirection,
          tenantId: tenantId,
        });
      },
      (error) => {
        enqueueSnackbar(error.data, { variant: "error" });
        actions.setSubmitting(false);
      }
    );
  };

  //for tenant dues summay and tenant bank
  useEffect(() => {
    getTenantDuesSummary();
  }, []);

  const getTenantDuesSummary = () => {
    Get(
      { tenantId: tenantId },
      Get_TenantDuesSummary_URL,
      null,
      (res) => {
        setTenanDuesSummary(res?.data);
      },
      (err) => {
        enqueueSnackbar(err?.data, { variant: "error" });
      }
    );
  };

  return (
    <>
      {/* tenant dues summary  */}
      <CardComponent>
        <CardHeader
          title={
            <Typography variant='h6' style={{ fontWeight: "bold" }}>
              {getTranslation(
                "Tenant Dues Summary",
                "Tenant Dues Summary",
                "Tenant Dues Summary"
              )}
            </Typography>
          }
        />

        <CardContent>
          <Grid
            container
            spacing={2}
            style={{
              alignContent: "center",
              alignItems: "center",
              //marginLeft: "5%",
              //marginTop: "-1.5%",
            }}
          >
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <Box display='flex'>
                <Typography variant='h5'>
                  {getTranslation("Total Rent", "Total Rent", "Total Rent")}
                </Typography>
                <Typography variant='h5' style={{ fontWeight: "bold" }}>
                  : {format("number", tenantDuesSummary?.totalRent)} €
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <Box display='flex'>
                <Typography variant='h5'>
                  {getTranslation(
                    "Total Warranty",
                    "Total Warranty",
                    "Total Warranty"
                  )}
                </Typography>
                <Typography variant='h5' style={{ fontWeight: "bold" }}>
                  : {format("number", tenantDuesSummary?.totalWarranty)} €
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <Box display='flex'>
                <Typography variant='h5'>
                  {getTranslation("Total Cost", "Total Cost", "Total Cost")}
                </Typography>
                <Typography variant='h5' style={{ fontWeight: "bold" }}>
                  : {format("number", tenantDuesSummary?.totalCost)} €
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <Box display='flex'>
                <Typography variant='h5'>
                  {getTranslation(
                    "Total Amount Due",
                    "Total Amount Due",
                    "Total Amount Due"
                  )}
                </Typography>
                <Typography variant='h5' style={{ fontWeight: "bold" }}>
                  : {format("number", tenantDuesSummary?.totalAmountDue)} €
                </Typography>
              </Box>
            </Grid>
            <Grid item container xs={12} sm={6} md={4} lg={3} xl={3}>
              <Grid item xs={8} sm={8} md={7} lg={8}>
                <Typography variant='h5'>
                  {getTranslation(
                    "Total Repair And Maintenance",
                    "Total Repair And Maintenance",
                    "Total Repair And Maintenance"
                  )}
                </Typography>
              </Grid>
              <Grid item xs={4} sm={4} md={5} lg={4}>
                <Typography variant='h5' style={{ fontWeight: "bold" }}>
                  :{" "}
                  {format(
                    "number",
                    tenantDuesSummary?.totalRepairAndMaintenance
                  )}{" "}
                  €
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </CardComponent>
      <br />

      {/* dues summary filter and table  */}
      <CardComponent
      // title={getTranslation("Dues", "Dues", "Dues")}
      >
        <br />
        <br />
        <ToggleButtonGroup
          size='medium'
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          id='tenantDetailStatus'
          value={tenantDetailStatus}
          exclusive
          onChange={(event, tenantDetailStatus) => {
            if (tenantDetailStatus !== null) {
              setTenantDetailStatus(tenantDetailStatus);
            }
          }}
        >
          <ToggleButton value={"all"} aria-label='left aligned'>
            <Typography>{getTranslation("All", "All", "All")}</Typography>
          </ToggleButton>
          <ToggleButton value={"rent"} aria-label='left aligned'>
            <Typography>{getTranslation("Rent", "Rent", "Rent")}</Typography>
          </ToggleButton>
          <ToggleButton value={"cost"} aria-label='centered'>
            <Typography>{getTranslation("Cost", "Cost", "Cost")}</Typography>
          </ToggleButton>
          <ToggleButton value={"warranty"} aria-label='centered'>
            <Typography>
              {getTranslation("Warranty", "Warranty", "Warranty")}
            </Typography>
          </ToggleButton>
          <ToggleButton value={"maintenance"} aria-label='centered'>
            <Typography>
              {getTranslation("Maintenance", "Maintenance", "Maintenance")}
            </Typography>
          </ToggleButton>
        </ToggleButtonGroup>
        <CardContent>
          <Box
            pr={1}
            pb={2}
            width='100%'
            display='flex'
            alignItems='center'
            justifyContent='flex-end'
          >
            <PrintButton
              size='small'
              variant='contained'
              // color="primary"
              onClick={printDetails}
              style={{ backgroundColor: "rgb(251,94,57)" }}
            >
              {getTranslation("Print", "Print", "Print")}
            </PrintButton>
          </Box>
          {/* dues table  */}
          <Table
            loading={loading}
            dense
            colums={columns}
            sortBy='date'
            sortDirection='desc'
            onSorting={(property, direction) => {
              setSort(property);
              setSortDirection(direction === "asc" ? 0 : 1);
            }}
            rows={rows?.data}
            count={rows?.totalCount}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            viewSearch
            onSearch={(searchString) => handleSearchDetails(searchString)}
            actions={[
              {
                component: (row) =>
                  row.pdfDocumentId != null && row.pdfDocumentId != "" ? (
                    <Tooltip
                      title={getTranslation("Note", "Note", "Note")}
                      arrow
                      placement='top'
                    >
                      <DownloadButton
                        onClick={() => DownloadFile(row?.pdfDocumentId)}
                      />
                    </Tooltip>
                  ) : null,
              },
              {
                component: (rowData) => (
                  <Tooltip
                    title={getTranslation(
                      "Postpone Due Date",
                      "Postpone Due Date",
                      "Postpone Due Date"
                    )}
                    arrow
                    placement='left-start'
                  >
                    <IconButton>
                      <PostponIcon
                        onClick={() => {
                          handleAddPostponDueDate(rowData);
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                ),
              },
            ]}
          />
        </CardContent>
      </CardComponent>

      <AddPostDueDate
        due={due}
        isOpen={addPostponDueDate}
        onClose={() => {
          setAddPostponDueDate(false);
          setDue({});
        }}
        onSubmit={handlePostPoneDue}
      />
    </>
  );
};
export default Dues;
