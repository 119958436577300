import { addLanguage, Create, Get } from "../actions";
import {
  Post_AddNewText_URL,
} from "../constants/apiUrls";

let dictionary = null;
export const getTranslation = (text, defaultFrench, defaultGerman) => {
  text = "CR-" + text;
  
  if (dictionary === null) {
    dictionary = JSON.parse(localStorage.getItem("dictionary"));
  }

  try {
    if (!dictionary[text]) {
      dictionary[text] = {
        "en": text,
        "fr": defaultFrench,
        "gr": defaultGerman
      }
      addLanguage(
        {
          text: text,
          english: text,
          french: defaultFrench,
          german: defaultGerman,
        },
        (resp) => {
        },
        (error) => {
        }
      );
    } else if (dictionary[text][localStorage.getItem("lang")] === undefined || dictionary[text][localStorage.getItem("lang")] == null) {
      return text
    }
    return dictionary[text][localStorage.getItem("lang")];
  } catch (error) {
    return text;
  }
};

