import React from "react";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { getTranslation } from "../heplers/translationHelper";

export const DatePicker = ({
  label,
  value,
  error,
  fullWidth,
  required,
  onChange,
  ...rest
}) => {
  const iconColorStyle = {
    color: "rgb(229,60,22)",
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        fullWidth
        disableToolbar
        inputVariant="outlined"
        format="dd-MM-yyyy"
        size="small"
        id="From-Date-inline"
        label={label}
        value={value}
        onChange={onChange}
        KeyboardButtonProps={{
          "aria-label": label,
          style: iconColorStyle,
        }}
        InputLabelProps={{
          shrink: true,
        }}
        required={required}
        error={error}
        {...rest}
        minDateMessage={getTranslation(
          "Date should not be the min date of the calendar.",
          "Date should not be the min date of the calendar.",
          "Date should not be the min date of the calendar."
        )}
      />
    </MuiPickersUtilsProvider>
  );
};
