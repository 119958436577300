import React from "react";
import {
	Box,
	Card,
	CardContent,
	CardHeader,
	Paper,
	TextField,
	IconButton,
	Tooltip,
	Typography,
} from "@material-ui/core";
import {
	DownloadButton,
	EditButton,
} from "../../../components/ButttonsWithIcons";

import Table from "../../../components/table";
import { useState, useEffect } from "react";
import DeleteDialog from "../../../components/DeleteDialog";
import {
	Post_AddNote_URL,
	Post_DeleteDue_URL,
	Get_AllDuesWithPagination_URL,
	Get_AllBuildings_URL,
} from "../../../constants/apiUrls";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { CommentsIcon, ReportSingleIcon } from "../../../components/icons";

import { DownloadFile, Get, Post } from "../../../actions/apiActions";
import { getTranslation } from "../../../heplers/translationHelper";
import { AddNoteDue } from "./noteDuesDialog";
import { string } from "yup";
import { FontDownloadRounded } from "@material-ui/icons";
import { Helmet } from "react-helmet";
import CardComponent from "../../../components/Card";
import { format } from "../../../heplers/format";
import AlertDialog from "../../../components/DeleteDialog";
//name, address, zipcode, Town
const columns = [
	{
		id: "date",
		disablePadding: true,
		label: getTranslation("Date", "Date", "Date"),
		format: "date",
	},
	{
		id: "name",
		numeric: true,
		disablePadding: true,
		label: getTranslation("Name", "Name", "Name"),
	},
	{
		id: "categoryText",
		numeric: true,
		label: getTranslation("Activity", "Activity", "Activity"),
		component: ({ row }) => (
			<Typography>
				{row.categoryText
					? row.categoryText
							.replace(
								"Rent- Due",
								getTranslation("Rent-Due", "Rent-Due", "Rent-Due")
							)
							.replace(
								"Warranty- Payment",
								getTranslation(
									"Warranty-Payment",
									"Warranty-Payment",
									"Warranty-Payment"
								)
							)
							.replace(
								"Rent- Payment",
								getTranslation("Rent-Payment", "Rent-Payment", "Rent-Payment")
							)
							.replace(
								"Repair & Maintenance- Payment",
								getTranslation(
									"Repair & Maintenance-Payment",
									"Repair & Maintenance-Payment",
									"Repair & Maintenance-Payment"
								)
							)
							.replace(
								"Repair & Maintenance- Due",
								getTranslation(
									"Repair & Maintenance-Due",
									"Repair & Maintenance-Due",
									"Repair & Maintenance-Due"
								)
							)
							.replace(
								"Cost- Due",
								getTranslation("Cost-Due", "Cost-Due", "Cost-Due")
							)
					: "-"}
			</Typography>
		),
	},
	{
		id: "buildingName",
		numeric: true,
		disablePadding: true,
		label: getTranslation("Building", "Building", "Building"),
	},
	{
		id: "appartmentNo",
		numeric: true,
		disablePadding: true,
		label: getTranslation("Apartments", "Apartments", "Apartments"),
	},
	{
		id: "paymentAmount",
		format: "number",
		align: "right",
		label: getTranslation("Payment", "Payment", "Payment"),
		component: (row) => {
			return (
				<Typography>{format("number", row?.row?.paymentAmount)}</Typography>
			);
		},
	},
	{
		id: "dueAmount",
		format: "number",
		align: "right",
		label: getTranslation("Due", "Due", "Due"),
		component: (row) => {
			return <Typography>{format("number", row?.row?.dueAmount)}</Typography>;
		},
	},
	{
		id: "comments",
		label: getTranslation("Comments", "Comments", "Comments"),
		component: ({ row }) => {
			return (
				<>
					{" "}
					{row.comments?.split(/\r?\n/).map((item) => {
						return <Typography>{item}</Typography>;
					})}
				</>
			);
		},
	},
	{
		id: "actions",
		numeric: true,
		disablePadding: true,
		label: getTranslation("Actions", "Actions", "Actions"),
		align: "right",
	},
];

const Dues = ({
	loading,
	rows,
	onCreate,
	onFilter,
	onEdit,
	generateDue,
	setIsOpenConfirmDialog,
	isOpenConfirmDialog,
	isSubmitting,
}) => {
	const [searchString, setSearchString] = useState("");
	const [sort, setSort] = useState("date");
	const [sortDirection, setSortDirection] = useState(1);

	const [page, setPage] = useState(0);
	const pageSize = localStorage.getItem("sizeDues");

	const [rowsPerPage, setRowsPerPage] = useState(pageSize ? pageSize : 10);
	const [openDelete, setOpenDelete] = useState(false);
	const [addNote, setAddNote] = useState(false);
	const [deletingDues, setDeletingDues] = useState();
	const [deleteDue, setDeleteDue] = useState();
	const history = useHistory();
	const { enqueueSnackbar } = useSnackbar();
	const [dueId, setDueId] = useState(0);

	useEffect(() => {
		onFilter({
			searchString,
			pageNumber: page,
			pageSize: rowsPerPage,
			sort: sort,
			sortDirection: sortDirection,
		});
	}, [searchString, page, rowsPerPage, sort, sortDirection]);

	const handleDeleteDialog = async (row) => {
		const deleteArray = new Array();
		deleteArray.push(deleteDue.id);
		await Post(
			{
				ids: deleteArray,
			},
			Post_DeleteDue_URL,
			history,
			(resp) => {
				enqueueSnackbar(
					getTranslation(
						"Dues deleted Successfully",
						"Dues deleted Successfully",
						"Dues deleted Successfully"
					),
					{
						variant: "success",
					}
				);
				//localhost:3000/dues/duesGenerated
				http: onFilter({
					searchString,
					pageNumber: page,
					pageSize: rowsPerPage,
				});
				setOpenDelete(false);
				setDeletingDues(false);
			},
			(error) => {
				enqueueSnackbar(
					getTranslation(
						"Can't delete dues",
						"Can't delete dues",
						"Can't delete dues"
					),
					{
						variant: "Error",
					}
				);
				setDeletingDues(false);
			}
		);
	};
	//// handleOpenDeleteDialog
	const handleOpenDeleteDialog = (row) => {
		setDeleteDue(row);
		setOpenDelete(true);
	};
	// handlers
	const handleChangePage = (newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		localStorage.setItem("sizeDues", parseInt(event.target.value));
		setRowsPerPage(localStorage.getItem("sizeDues"));
		setPage(0);
	};

	// handleSearchdue
	const handleSearchDue = (searchString) => {
		setSearchString(searchString);
	};

	const hanldeOnAddNote = (row) => {
		setDueId(row.id);
		setAddNote(true);
	};

	const handleSubmitDuesNote = (values, actions) => {
		values.id = dueId;
		Post(
			values,
			Post_AddNote_URL,
			null,
			(resp) => {
				enqueueSnackbar(
					getTranslation(
						"Note added successfully",
						"Note added successfully",
						"Note added successfully"
					),
					{
						variant: "success",
					}
				);
				setAddNote(false);
			},
			(err) => {
				enqueueSnackbar(
					getTranslation(
						"Note not addedd",
						"Note not addedd",
						"Note not addedd"
					),
					{
						variant: "error",
					}
				);
			}
		);
	};

	return (
		<>
			<Helmet>
				<title>{getTranslation("Dues", "Dues", "Dues")}</title>
			</Helmet>
			<Table
				loading={loading}
				dense
				colums={columns}
				// title={getTranslation("Dues", "Dues", "Dues")}
				sortBy={sort}
				sortDirection={sortDirection == 0 ? "asc" : "desc"}
				rows={rows?.data}
				count={rows?.totalCount}
				page={page}
				rowsPerPage={rowsPerPage}
				handleChangePage={(e, newPage) => handleChangePage(newPage)}
				handleChangeRowsPerPage={handleChangeRowsPerPage}
				viewDelete
				viewCreate
				viewConfirmAll={rows?.data?.length == 0 ? "false" : "true"}
				onConfirmAll={() => setIsOpenConfirmDialog(true)}
				onCreate={onCreate}
				onDelete={(row) => handleOpenDeleteDialog(row)}
				onSorting={(sort, direction) => {
					setSort(sort);
					setSortDirection(direction == "asc" ? 0 : 1);
				}}
				actions={[
					{
						component: (row) =>
							row.pdfDocumentId != null && row.pdfDocumentId != "" ? (
								<Tooltip
									title={getTranslation("Note", "Noter", "Notiz")}
									arrow
									placement="top"
								>
									<DownloadButton
										onClick={() => DownloadFile(row?.pdfDocumentId)}
									/>
								</Tooltip>
							) : null,
					},
				]}
				viewSearch
				onSearch={(searchString) => handleSearchDue(searchString)}
				viewEdit
				onEdit={onEdit}
			/>

			<AddNoteDue
				isOpen={addNote}
				onSubmit={(values, actions) => handleSubmitDuesNote(values, actions)}
				onClose={() => setAddNote(false)}
			/>
			<DeleteDialog
				open={openDelete}
				deleting={deletingDues}
				title={getTranslation("Delete Dues", "Delete Dues", "Delete Dues")}
				onClose={() => setOpenDelete(false)}
				onSubmit={(e) => {
					setDeletingDues(true);
					handleDeleteDialog();
				}}
			/>
			<AlertDialog
				open={isOpenConfirmDialog}
				onClose={() => setIsOpenConfirmDialog(false)}
				deleting={isSubmitting}
				onSubmit={() => {
					generateDue();
				}}
				title={getTranslation("Confirm all", "Confirm all", "Confirm all")}
				description={getTranslation(
					"Are you sure you want to confirm all?",
					"Are you sure you want to confirm all?",
					"Are you sure you want to confirm all?"
				)}
				buttonTitle={getTranslation("Confirm", "Confirm", "Confirm")}
			/>
		</>
	);
};
export default Dues;
