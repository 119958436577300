import _, { filter } from "lodash";
import React from "react";
import SplitAmount from "./splitAmount";
import { Grid } from "@material-ui/core";

const SplitAmountMapper = ({
	update,
	title,
	array,
	setArray,
	appartmentRent,
	setAppartmentRent,
	setWarranty,
	enableError,
	disableError,
	initialRent,
}) => {
	const handleOnChange = (index, values) => {
		const newArray = [...array];
		if (index >= 0 && index < newArray.length) {
			newArray[index] = values;
		} else {
			newArray.push(values);
		}
		setArray([...newArray]);

		// for sumation of rent ,cost,repair and maintaince in in rent
		var rentArray = newArray.filter(
			(item) => item.type === 0 || item.type === 2 || item.type === 3
		);
		setAppartmentRent(
			_.sumBy(rentArray, (item) => parseFloat(item.amount, 10))
		);

		//for summition of warrenty
		var warrantyArray = newArray.filter((item) => item.type === 1);
		setWarranty(_.sumBy(warrantyArray, (item) => parseFloat(item.amount, 10)));
	};

	const removeItem = (index, values) => {
		var newArray = array;
		newArray = array.filter((item, i) => i !== index);
		setArray([...newArray]);

		// for removing  of rent and warranty casts in total rent
		var rentArray = newArray.filter(
			(item) => item.type === 0 || item.type === 2 || item.type === 3
		);
		setAppartmentRent(
			_.sumBy(rentArray, (item) => parseFloat(item.amount, 10))
		);

		var warrantyArray = newArray.filter((item) => item.type === 1);
		setWarranty(_.sumBy(warrantyArray, (item) => parseFloat(item.amount, 10)));
	};
	return (
		<div>
			{array.map((item, index) => {
				return (
					<Grid item xs={12} sm={12} md={12} lg={12} key={index}>
						<SplitAmount
							update={update}
							title={title}
							item={item}
							count={index}
							onSubmit={handleOnChange}
							appartmentRent={appartmentRent}
							lastItem={array.length}
							deleteItem={removeItem}
							enableError={enableError}
							disableError={disableError}
							initialRent={initialRent}
						/>
					</Grid>
				);
			})}
		</div>
	);
};

export default SplitAmountMapper;
