import { Box, Grid, TextField, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { getTranslation } from "../../../../../heplers/translationHelper";
import ButtonWithLoading from "../../../../../components/ButtonWithLoading";
import { format } from "../../../../../heplers/format";

const CloseRecoveryDialog = ({ handleSubmit, onClose, recoveryData,isLoading }) => {
  const [endDate, setEndDate] = useState();
  const [comment, setComment] = useState();

  const handleChange = (data) => {
    handleSubmit(data);
  };
  return (
    <div
      style={{
        paddingTop: "20px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box width="100%">
        <Grid container>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Typography>
              <strong>
                {getTranslation("Tenant Name", "Tenant Name", "Tenant Name")}
              </strong>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Typography>{recoveryData?.tenant?.name}</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Typography>
              <strong>
                {getTranslation(
                  "Appartment Address",
                  "Appartment Address",
                  "Appartment Address"
                )}
              </strong>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Typography>{recoveryData?.appartmentAddress}</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Typography>
              <strong>
                {getTranslation("Start Date", "Start Date", "Start Date")}
              </strong>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Typography>{format("date", recoveryData?.startDate)}</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Typography>
              <strong>
                {getTranslation(
                  "Recovery Persons",
                  "Recovery Persons",
                  "Recovery Persons"
                )}
              </strong>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Typography>
              {recoveryData?.recoveryPersons[0].name} |
              {recoveryData?.recoveryPersons[1]
                ? recoveryData?.recoveryPersons[1].name + " | "
                : ""}
              {recoveryData?.recoveryPersons[2]
                ? recoveryData?.recoveryPersons[2].name + " | "
                : ""}
            </Typography>
          </Grid>
          <br /> <br /> <br />
          
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TextField
              fullWidth
              variant="outlined"
              id="comment"
              value={comment}
              multiline
              rows={5}
              label={getTranslation(
                "Comment",
                "Comment",
                "Comment"
              )}
              name="comment"
              size="small"
              onChange={(e) => {
                setComment(e.target.value);
              }}
              InputLabelProps={{
                shrink: true,
              }}
             
            />
          </Grid>
        </Grid>
      </Box>
      <br />
      <Box
        pr={1}
        pb={1}
        pt={1}
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <ButtonWithLoading
          title={getTranslation("Confirm Close", "Confirm Close", "Confirm Close")}
          size="small"
          variant="contained"
          color="primary"
          type="submit"
            loading={isLoading}
          onClick={() => {
            handleChange({
              comment:comment,
              recoveryId: recoveryData.id,
            });
          }}
        />
      </Box>
    </div>
  );
};

export default CloseRecoveryDialog;
