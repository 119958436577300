import React, { useEffect, useState } from "react";
import {
	Box,
	TextField,
	Typography,
	Grid,
	Divider,
	useMediaQuery,
} from "@material-ui/core";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import { getTranslation } from "../../heplers/translationHelper";
import styles from "../../assests/styles/views/auth/Register";
import ButtonWithLoading from "../../components/ButtonWithLoading";
import LoginNavBar from "./NavBar";
import { getCustomerApps } from "../../actions";
import { getMyPackage } from "../../actions";
import { useSnackbar } from "notistack";
import authService from "../../utils/authUtils";
import authUtils from "../../utils/authUtils";
import FacebookLogin from "react-facebook-login";
import appmanagerApi from "../../heplers/appManagerApiHelper";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import signupBackgroundImage from "../../assests/icons/signupbackground.png";
import signupBackgroundInsideImage from "../../assests/icons/signupinsideimage.png";
import { withRouter } from "react-router-dom";
import LoginFooter from "./Footer";
import { SET_USER_DATA } from "../../constants/types";
import { useDispatch } from "react-redux";
import { v4 as uuid } from "uuid";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Get_Packages_GetPackages } from "../../constants/apiUrls";
import { Get } from "../../actions";

import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import NativeSelect from "@material-ui/core/NativeSelect";
import { values } from "lodash";

const useStyles = makeStyles((theme) => ({
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
}));

const Registration = ({ history }) => {
	const classes = styles();
	const { enqueueSnackbar } = useSnackbar();
	const [selectedPackage, setSelectedPackage] = useState();
	const dispatch = useDispatch();
	let unique_id = localStorage.getItem("visitorId");
	if (unique_id == null) {
		unique_id = uuid();
		localStorage.setItem("visitorId", unique_id);
	}
	const [visitorId, setVisitorId] = useState(unique_id);
	const { myappId, packageId } = useParams();
	const [loading, setLoading] = useState(false);

	const redirectURL = authService.getRedirectURL();

	useEffect(() => {
		if (redirectURL) {
			var n = 25;
			var number = Math.floor(Math.random() * 9999998) + 1;
			let targetSite = "";
			let address = "";
			if (redirectURL.includes("1/18")) {
				targetSite = "clic-rent.com";
				address = "register_for_free";
			}
			if (targetSite != "") {
				const site = `https://ms112online.influencersoft.com/track/click?url=https%3A%2F%2F${targetSite}%2F${address}&referrer=&host=clic-rent.com&client_id=7VkLnzdUXC71Cjuqu7pYTEyRN0zUt3ghrCmkhD85Q6zgakNdFsD4Ot4QsCbwGoip&source_click_id=${number}`;
				fetch(site)
					.then((resp) => resp.data)
					.then((data) => {});
			}
		}
	}, []);

	useEffect(() => {
		if (
			window.location.href.includes("impact-soft") &&
			window.location.protocol !== "https:"
		) {
			window.location.replace(
				`https:${window.location.href.substring(
					window.location.protocol.length
				)}`
			);
		}
	}, []);
	useEffect(() => {
		getMyPackage(
			{ packageId: parseInt(packageId) },
			(resp) => {
				setSelectedPackage(resp);
			},
			(error) => {
				console.log(error);
			}
		);
	}, []);
	function onExternalLogin(provider, params) {
		appmanagerApi()
			.post("Auth/ExternalLogin", {
				provider: provider,
				accessToken: params.accessToken,
				appId: 1,
			})
			.then((res) => {
				getCustomerApps(
					{
						email: res.data.user.email,
						appId: 1,
					},
					(resp) => {
						if (resp?.length === 0) {
							enqueueSnackbar(
								getTranslation(
									"No customer apps exists",
									"No customer apps exists",
									"No customer apps exists"
								),
								{
									variant: "error",
								}
							);
						} else {
							try {
								if (
									authUtils.getCustomerId() != null &&
									resp.findIndex(
										(x) => x.customerId == authUtils.getCustomerId()
									) >= 0
								) {
									res.data.customerId = authUtils.getCustomerId();
								} else {
									res.data.customerId = resp[0].customerId;
								}
								authUtils.setUser(res.data.user);
								authUtils.setSession(res.data.accessToken);
								authUtils.setUserRefreshToken(
									res.data.refreshToken,
									res.data.refreshTokenExpire
								);
								authUtils.setCustomerId(res.data.customerId);
								authUtils.setCustomerOrganizations(resp);
								const selectedOrg = resp.find(
									(x) => x.customerId == res.data.customerId
								);
								authUtils.setIsStarter(selectedOrg.isStarter);
								history.push("/");
							} catch (e) {}
						}
					},
					(error) => {
						enqueueSnackbar(error.data, {
							variant: "error",
						});
					}
				);
			})
			.catch((error) => {
				// enqueueSnackbar("")
			});
	}

	const isMobileTab = useMediaQuery((theme) => theme.breakpoints.down("sm"));
	return (
		// <div>
		<>
			<LoginNavBar />
			<Grid
				container
				style={{ height: "100vh", backgroundColor: "rgb(244,246,248)" }}
			>
				<Grid
					sm={12}
					lg={6}
					md={6}
					style={{
						marginTop: "64px",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<Box
						display="flex"
						alignItems="center"
						justifyContent="center"
						margin="0px 10% "
					>
						{/* <div className={classes.container}> */}
						<div className={classes.paper}>
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
								}}
							>
								<Typography
									className={classes.heading}
									component="h1"
									variant="h4"
								>
									{getTranslation("Sign up", "Sign up", "Sign up")}!
								</Typography>
								{/* <Typography
									component="p"
									variant="h6"
									style={{ color: "rgba(0, 0, 0, 0.6)" }}
								>
									{getTranslation(
										"Get started for Free!",
										"Get started for Free!",
										"Get started for Free!"
									)}
								</Typography> */}
								{myappId && packageId && (
									<>
										<Typography
											component="p"
											variant="h3"
											style={{
												color: "rgba(0, 0, 0, 0.6)",
											}}
										>
											{getTranslation(
												"Your selected package is",
												"Your selected package is",
												"Your selected package is"
											)}
										</Typography>
										<br />
										<Box
											style={{
												boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
												borderTop: "1px solid lightgrey",
												borderRadius: "5px",
												padding: "10px",
											}}
										>
											<Typography
												component="p"
												variant="h6"
												style={{
													color: "rgba(0, 0, 0, 0.6)",
												}}
											>
												{/* {`${selectedPackage?.description} | ${selectedPackage?.monthlyPrice} € `} */}
												{selectedPackage?.description &&
												selectedPackage?.monthlyPrice
													? `${selectedPackage.description} | ${selectedPackage.monthlyPrice} €`
													: "Loading..."}
											</Typography>
										</Box>
									</>
								)}

								{!myappId && !packageId && (
									<Typography
										component="p"
										variant="h6"
										style={{ color: "rgba(0, 0, 0, 0.6)" }}
									>
										{getTranslation(
											"Get started for Free!",
											"Get started for Free!",
											"Get started for Free!"
										)}
									</Typography>
								)}
							</div>
							<Formik
								initialValues={{
									firstName: "",
									lastName: "",
									email: "",
									password: "",
									confirmPassword: "",
								}}
								validationSchema={Yup.object().shape({
									firstName: Yup.string().required("Required"),
									lastName: Yup.string().required("Required"),
									email: Yup.string().email().required("Required"),
									password: Yup.string()
										.min(
											6,
											getTranslation(
												"Password atleast 6 character long",
												"Password atleast 6 character long",
												"Password atleast 6 character long"
											)
										)
										.required("Password is required"),
									confirmPassword: Yup.string()
										.min(6, "Password atleast 6 character long")
										.oneOf(
											[Yup.ref("password"), null],
											getTranslation(
												"Passwords must match",
												"Passwords must match",
												"Passwords must match"
											)
										),
								})}
								onSubmit={(values, { setSubmitting }) => {
									console.log(values);
									console.log(packageId);
									// if (myappId && packageId) {
									var payload = {
										...values,
										// packageId: parseInt(packageId),
										//  appId: parseInt(myappId),
										packageId,
										appId: myappId,
									};

									//  } else payload = values;
									setSubmitting(true);
									setLoading(true);

									appmanagerApi()
										.post("auth/Register", payload)
										.then((res) => {
											enqueueSnackbar(
												getTranslation(
													"Registration Successfull",
													"Registration Successfull",
													"Registration Successfull"
												),
												{
													variant: "success",
												}
											);

											authService.setSession(res.data.accessToken);
											authService.setUserRefreshToken(
												res.data.refreshToken,
												res.data.refreshTokenExpire
											);

											dispatch({
												type: SET_USER_DATA,
												payload: res.data.user,
											});

											getCustomerApps(
												payload,
												(resp) => {
													authUtils.setCustomerId(resp[0].customerId);
													authUtils.setCustomerOrganizations(resp);
													authUtils.setUser(res.data.appUser);
													const selectedOrg = resp[0];
													authUtils.setIsStarter(selectedOrg.isStarter);
													const objToSend = {
														token: authService.getUserToken(),
														refreshToken: authService.getUserRefreshToken(),
														refreshTokenExpiry:
															authService.getUserRefreshTokenExpiry(),
														customerId: authService.getCustomerId(),
														redirectURL: authService.removeRedirectURL(),
														user: res.data.appUser,
														redirectUrlBack: `${process.env.REACT_APP_CLIC_RENT_DOMAIN}upgrade`,
														target: "signup",
													};

													const stringifiedObj = JSON.stringify(objToSend);

													const base64Obj = btoa(stringifiedObj); // Convert to Base64

													const url = `${
														process.env.REACT_APP_APP_MANAGER_DOMAIN
													}identity?data=${encodeURIComponent(base64Obj)}`;
													window.location.href = url;
												},
												(error) => {}
											);
											setSubmitting(false);
											setLoading(false);
										})
										.catch((err) => {
											enqueueSnackbar(
												err?.data
													? err?.data
													: getTranslation(
															"Registration Faild",
															"Registration Faild",
															"Registration Faild"
													  ),
												{ variant: "error" }
											);
											setLoading(false);
										});
								}}
							>
								{({
									values,
									errors,
									touched,
									handleChange,
									handleSubmit,
									isSubmitting,
								}) => (
									<form onSubmit={handleSubmit} className={classes.form}>
										<Grid container spacing={3} style={{ marginTop: "10px" }}>
											<Grid item sm={12} md={6} lg={6}>
												<TextField
													fullWidth
													style={{
														boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.25)",
														borderRadius: "5px",
														height: "40px",
														marginBottom:
															touched.firstName && errors.firstName
																? "10px"
																: "",
													}}
													variant="outlined"
													size="small"
													name="firstName"
													label={getTranslation(
														"First Name",
														"First Name",
														"First Name"
													)}
													value={values.firstName}
													onChange={handleChange}
													error={touched.firstName && Boolean(errors.firstName)}
													helperText={
														touched.firstName && errors.firstName ? (
															<span
																style={{
																	boxShadow: "none",
																}}
															>
																{errors.firstName}
															</span>
														) : null
													}
												/>
											</Grid>
											<Grid item sm={12} md={6} lg={6}>
												<TextField
													fullWidth
													style={{
														boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.25)",
														borderRadius: "5px",
														height: "40px",
														marginBottom:
															touched.firstName && errors.firstName
																? "10px"
																: "",
													}}
													variant="outlined"
													size="small"
													name="lastName"
													label={getTranslation(
														"Last Name",
														"Last Name",
														"Last Name"
													)}
													value={values.lastName}
													onChange={handleChange}
													error={touched.lastName && Boolean(errors.lastName)}
													helperText={
														touched.lastName && errors.lastName ? (
															<span
																style={{
																	boxShadow: "none",
																}}
															>
																{errors.lastName}
															</span>
														) : null
													}
												/>
											</Grid>

											<Grid item sm={12} md={12} lg={12}>
												<TextField
													fullWidth
													style={{
														boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.25)",
														borderRadius: "5px",
														height: "40px",
														marginBottom:
															touched.firstName && errors.firstName
																? "10px"
																: "",
													}}
													variant="outlined"
													size="small"
													label={getTranslation("Email", "Email", "Email")}
													name="email"
													value={values.email}
													onChange={handleChange}
													error={touched.email && Boolean(errors.email)}
													helperText={
														touched.email && errors.email ? (
															<span
																style={{
																	boxShadow: "none",
																}}
															>
																{errors.email}
															</span>
														) : null
													}
												/>
											</Grid>
											<Grid item sm={12} md={12} lg={12}>
												<TextField
													fullWidth
													style={{
														boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.25)",
														borderRadius: "5px",
														height: "40px",
														marginBottom:
															touched.firstName && errors.firstName
																? "10px"
																: "",
													}}
													variant="outlined"
													size="small"
													type="password"
													label={getTranslation(
														"Password",
														"Password",
														"Password"
													)}
													name="password"
													value={values.password}
													onChange={handleChange}
													error={touched.password && Boolean(errors.password)}
													helperText={
														touched.password && errors.password ? (
															<span
																style={{
																	boxShadow: "none",
																}}
															>
																{errors.password}
															</span>
														) : null
													}
												/>
											</Grid>
											<Grid item sm={12} md={12} lg={12}>
												<TextField
													fullWidth
													style={{
														boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.25)",
														borderRadius: "5px",
														height: "40px",
														marginBottom:
															touched.firstName && errors.firstName
																? "10px"
																: "",
													}}
													variant="outlined"
													size="small"
													type="password"
													label={getTranslation(
														"Confirm Password",
														"Confirm Password",
														"Confirm Password"
													)}
													name="confirmPassword"
													value={values.confirmPassword}
													onChange={handleChange}
													error={
														touched.confirmPassword &&
														Boolean(errors.confirmPassword)
													}
													helperText={
														touched.confirmPassword && errors.confirmPassword
													}
													InputLabelProps={{
														style: {
															whiteSpace: "nowrap",
															overflow: "hidden", // Hides any overflowing content
															textOverflow: "ellipsis", // Ensures that the label does not wrap
														},
													}}
												/>
											</Grid>
											<Grid item sm={12} md={12} lg={12}>
												<ButtonWithLoading
													color="primary"
													title={getTranslation(
														"Sign Up",
														"Sign Up",
														"Sign Up"
													)}
													// loading={isSubmitting}
													type="submit"
													variant="contained"
													fullWidth
													className={classes.submitbtn}
													loading={loading}
												/>
											</Grid>
										</Grid>
									</form>
								)}
							</Formik>
							<Box
								mt={1}
								width="100%"
								display="flex"
								alignItems="center"
								justifyContent="center"
							>
								<Link to="/login" variant="h6" className={classes.link}>
									{getTranslation(
										"Already have a account",
										"Already have a account",
										"Already have a account"
									)}
									?{" "}
									<span style={{ fontWeight: "bold" }}>
										{getTranslation("Sign in", "Sign in", "Sign in")}
									</span>
								</Link>
							</Box>
							{/* <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "30px",
                      marginBottom: "25px",
                    }}
                  >
                    <Divider style={{ flex: 1 }} />
                    <p style={{ margin: "0 10px" }}>
                      {getTranslation(
                        "or continue with",
                        "or continue with",
                        "or continue with"
                      )}
                    </p>
                    <Divider style={{ flex: 1 }} />
                  </Box>

                  <Box
                    mt={2}
                    width="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <FacebookLogin
                      appId="652403710332409"
                      locale={navigator.language}
                      // onClick={onFacebookLogin}
                      callback={(params) => onExternalLogin("Facebook", params)}
                      size="small"
                      icon="fa-facebook"
                      buttonStyle={{
                        textTransform: "capitalize",
                        borderRadius: "4px",
                        fontSize: "13px",
                        background: "rgb(8,120,255)",
                        border: "none",
                        marginRight: "10px",
                        padding: "10px 8px 8px 10px",
                      }}
                      textButton="sign in with facebook"
                    ></FacebookLogin>
                    <GoogleOAuthProvider clientId="36618320629-vcv9cr10q4hvetgd15ap5dp1m0gkibup.apps.googleusercontent.com">
                      <GoogleLogin
                        size="medium"
                        locale={navigator.language}
                        onSuccess={(params) =>
                          onExternalLogin("Google", {
                            accessToken: params.credential,
                          })
                        }
                        onError={(params) => {}}
                      ></GoogleLogin>
                    </GoogleOAuthProvider>
                  </Box> */}
						</div>
						{/* </div> */}
					</Box>
				</Grid>
				{isMobileTab ? (
					""
				) : (
					<Grid
						lg={6}
						md={6}
						xl={6}
						style={{
							// backgroundColor: "yellow",
							padding: "20px 20px 20px 0px",
							marginTop: "64px",
						}}
					>
						<Box
							display="flex"
							flexDirection="column"
							justifyContent="center"
							style={{
								background: `url(${signupBackgroundImage})`,
								height: "100%",
								borderRadius: "20px",
							}}
						>
							<img
								src={signupBackgroundInsideImage}
								style={{
									width: "80%",
									marginLeft: "auto",
									marginRight: "auto",
									marginBottom: "20px",
								}}
							/>

							<Typography variant="h2" className={classes.backgroundText}>
								{getTranslation(
									"The easiest way to manage",
									"The easiest way to manage",
									"The easiest way to manage"
								)}
							</Typography>
							<Typography variant="h2" className={classes.backgroundText}>
								{getTranslation(
									"Your information",
									"Your information",
									"Your information"
								)}
							</Typography>
							<Typography
								variant="p"
								style={{
									color: "rgb(163,157,159)",
									marginLeft: "auto",
									marginRight: "auto",
								}}
							>
								{getTranslation(
									"Join our community now!",
									"Join our community now!",
									"Join our community now!"
								)}
							</Typography>
						</Box>
					</Grid>
				)}
			</Grid>
		</>
		// {/* <LoginFooter /> */}
		// </div>
	);
};

export default withRouter(Registration);
