import React, { useRef, useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  FormControl,
  Checkbox,
  FormControlLabel,
  Typography,
  TextField,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Box,
  ButtonGroup,
  Button,
  FormHelperText,
  makeStyles,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import ButtonWithLoading from "../../../../../../components/ButtonWithLoading";
import { getTranslation } from "../../../../../../heplers/translationHelper";
import { HtmlEditor } from "../../../../../../components/HtmlEditor";
import { Get_ReminderTemplate_URL } from "../../../../../../constants/apiUrls";
import { Get } from "../../../../../../actions";
import TextEditor from "../../../../../setting/component/TextEditorCard";

export const buttonStyles = makeStyles((theme) => ({
  root: {
    margin: "5px",
    minWidth: "150px",
    height: "40px",
    border: "1px solid",
  },
}));

const PersonRecovery = ({
  NextStep,
  BackStep,
  initialValue,
  activeStep,
  personKey,
  isLoading,
  isEdit,
  editValue,
  phoneCall,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const editorRef = useRef(null);
  const classes = buttonStyles();
  const [reminderTemplate, setReminderTemplate] = useState();
  const [check, setCheck] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState("english");
  const [loading, setLoading] = useState(false);
  const [editorValue, setEditorValue] = useState("");
  const [enableReloading, setEnableReloading] = useState(false);
  const [testSmsDialoge, setSmsDialoge] = useState(false);

  const AppendTemplate = (template, text, setFieldValue) => {
    editorRef.current.insertContent(text);
  };

  const getTemplateBasedOnLanguage = (template, language) => {
    switch (language) {
      case "english":
        setCheck(template?.template);
        return template?.template;
      case "french":
        setCheck(template?.templateFrench);
        return template?.templateFrench;
      case "german":
        setCheck(template?.templateGerman);
        return template?.templateGerman;
      default:
        return "";
    }
  };

  const handleLanguage = (language) => {
    setSelectedLanguage(language);
  };

  const openTestSmsDialoge = () => {
    setSmsDialoge(true);
  };

  const getReminderTemplate = () => {
    setLoading(true);
    Get(
      {
        reminderKey: personKey,
      },
      Get_ReminderTemplate_URL,
      null,
      (resp) => {
        setReminderTemplate(resp?.data);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
        enqueueSnackbar(
          err.data
            ? err.data
            : getTranslation(
                "Something went wrong. Please try again.",
                "Something went wrong. Please try again.",
                "Something went wrong. Please try again."
              ),
          {
            variant: "error",
          }
        );
      }
    );
  };

  useEffect(() => {
    getReminderTemplate();
  }, [personKey, selectedLanguage]);

  const basicValidationSchema = Yup.object().shape({
    template: Yup.string().required("Reminder text is required"),
    name: Yup.string().required("Person Name is required"),
    email: Yup.string().required("Person Email is required"),
    subject: Yup.string().required("Email Subject is required"),
  });
  const initialValues = {
    recoveryPersonKey: personKey,
    name: "",
    email: "",
    subject: "",
    template: "",
    attachStatement: false,
    attachDocuments: false,
  };

  const defaultValue = editValue
    ? editValue
    : reminderTemplate
    ? reminderTemplate
    : initialValues;

  return (
    <>
      <div>
        <Formik
          enableReinitialize
          initialValues={defaultValue}
          validationSchema={activeStep === 3 ? "" : basicValidationSchema}
          onSubmit={(values, actions) => {
            actions.setSubmitting(true);
            values.recoveryPersonKey = personKey;

            if (activeStep == 3) {
              initialValue.recoveryProcedureStatus =
                values.status == "active" ? 0 : 2;
            }

            //set updatedData as recoverypersons
            const tempArr = initialValue.recoveryPersons;
            var index = tempArr.findIndex(
              (x) => x.recoveryPersonKey == personKey
            );
            if (index >= 0) tempArr[index] = values;
            else tempArr.push(values);
            NextStep({ ...initialValue, recoveryPersons: tempArr });
          }}
        >
          {({
            values,
            setFieldValue,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
            getFieldProps,
          }) => (
            <>
              <Form>
                <>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={3} lg={3}>
                      <TextField
                        fullWidth
                        variant='outlined'
                        required
                        type='text'
                        id='name'
                        label={getTranslation(
                          "Person Name",
                          "Person Name",
                          "Person Name"
                        )}
                        name='name'
                        size='small'
                        {...getFieldProps("name")}
                        error={touched.name && Boolean(errors.name)}
                        helperText={touched.name && errors.name}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) => {
                          setFieldValue("name", e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={3}>
                      <TextField
                        fullWidth
                        variant='outlined'
                        required
                        id='email'
                        label={getTranslation(
                          "Person Email",
                          "Person Email",
                          "Person Email"
                        )}
                        name='email'
                        size='small'
                        {...getFieldProps("email")}
                        error={touched.email && Boolean(errors.email)}
                        helperText={touched.email && errors.email}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={3}>
                      <TextField
                        fullWidth
                        variant='outlined'
                        required
                        id='subject'
                        label={getTranslation(
                          "Email Subject",
                          "Email Subject",
                          "Email Subject"
                        )}
                        name='subject'
                        size='small'
                        {...getFieldProps("subject")}
                        error={touched.subject && Boolean(errors.subject)}
                        helperText={touched.subject && errors.subject}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                  </Grid>
                  {/* text editor */}
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      {/* text editor */}

                      <TextEditor
                        translatedText={getTranslation(
                          "Person",
                          "Person",
                          "Person"
                        )}
                        selectedLanguage={selectedLanguage}
                        handleLanguage={handleLanguage}
                      >
                        <CardContent>
                          <HtmlEditor
                            ref={editorRef}
                            text={getTemplateBasedOnLanguage(
                              reminderTemplate,
                              selectedLanguage
                            )}
                            onChange={(text) => {
                              setEnableReloading(true);
                              switch (selectedLanguage) {
                                case "english":
                                  setFieldValue("template", text);
                                  break;
                                case "french":
                                  setFieldValue("templateFrench", text);
                                  break;
                                case "german":
                                  setFieldValue("templateGerman", text);
                                  break;
                                default:
                                  setFieldValue("template", text);
                              }
                              setEditorValue(text);
                              setEnableReloading(false);
                            }}
                            buttonText={getTranslation("Save", "Save", "Save")}
                            isLoading={false}
                            isSubmitting={loading}
                          />
                        </CardContent>
                      </TextEditor>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Typography variant='h5' style={{ marginLeft: "15px" }}>
                        {getTranslation(
                          "Recovery Procedure Text",
                          "Recovery Procedure Text",
                          "Recovery Procedure Text"
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                  {/* dynamic buttons */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "left",
                      flexWrap: "wrap",
                    }}
                  >
                    <Button
                      className={classes.root}
                      size='small'
                      onClick={() => {
                        AppendTemplate(
                          values.template,
                          "<span>[tenant_name]</span>",
                          setFieldValue
                        );
                      }}
                    >
                      {getTranslation("Name", "Name", "Name")}
                    </Button>
                    <Button
                      size='small'
                      className={classes.root}
                      onClick={() =>
                        AppendTemplate(
                          values.template,
                          "<span>[tenant_email]</span>",
                          setFieldValue
                        )
                      }
                    >
                      {getTranslation("Email", "Email", "Email")}
                    </Button>
                    <Button
                      size='small'
                      className={classes.root}
                      onClick={() =>
                        AppendTemplate(
                          values.template,
                          "<span>[tenant_appartment]</span>",
                          setFieldValue
                        )
                      }
                    >
                      {getTranslation("Apartment", "Apartment", "Apartment")}
                    </Button>

                    <Button
                      size='small'
                      className={classes.root}
                      onClick={() =>
                        AppendTemplate(
                          values.template,
                          "<span>[appartment_address]</span>",
                          setFieldValue
                        )
                      }
                    >
                      {getTranslation(
                        "Apartment Address",
                        "Apartment Address",
                        "Apartment Address"
                      )}
                    </Button>

                    <Button
                      size='small'
                      className={classes.root}
                      onClick={() =>
                        AppendTemplate(
                          values.template,
                          "<span>[town]</span>",
                          setFieldValue
                        )
                      }
                    >
                      {getTranslation("Town", "Town", "Town")}
                    </Button>

                    <Button
                      size='small'
                      className={classes.root}
                      onClick={() =>
                        AppendTemplate(
                          values.template,
                          "<span>[zip_code]</span>",
                          setFieldValue
                        )
                      }
                    >
                      {getTranslation("Zip Code", "Zip Code", "Zip Code")}
                    </Button>
                    <Button
                      size='small'
                      className={classes.root}
                      onClick={() =>
                        AppendTemplate(
                          values.template,
                          "<span>[tenant_reference]</span>",
                          setFieldValue
                        )
                      }
                    >
                      {getTranslation("Reference", "Reference", "Reference")}
                    </Button>
                    <Button
                      size='small'
                      className={classes.root}
                      onClick={() =>
                        AppendTemplate(
                          values.template,
                          "<span>[due_Amount]</span>",
                          setFieldValue
                        )
                      }
                    >
                      {getTranslation(
                        "Due Amount",
                        "Montant dû",
                        "Geburtstermin"
                      )}
                    </Button>
                    <Button
                      size='small'
                      className={classes.root}
                      onClick={() =>
                        AppendTemplate(
                          values.template,
                          "<span>[due_Date]</span>",
                          setFieldValue
                        )
                      }
                    >
                      {getTranslation(
                        "Due Date",
                        "Date d'échéance",
                        "Geburtstermin"
                      )}
                    </Button>
                    <Button
                      size='small'
                      className={classes.root}
                      onClick={() =>
                        AppendTemplate(
                          values.template,
                          "<span>[current_date]</span>",
                          setFieldValue
                        )
                      }
                    >
                      {getTranslation(
                        "Current Date",
                        "Current Date",
                        "Current Date"
                      )}
                    </Button>
                    <Button
                      size='small'
                      className={classes.root}
                      onClick={() =>
                        AppendTemplate(
                          values.template,
                          "<span>[last_payment]</span>",
                          setFieldValue
                        )
                      }
                    >
                      {getTranslation(
                        "Last Payment",
                        "Last Payment",
                        "Last Payment"
                      )}
                    </Button>
                    <Button
                      size='small'
                      className={classes.root}
                      onClick={() =>
                        AppendTemplate(
                          values.template,
                          "<span>[total_rent]</span>",
                          setFieldValue
                        )
                      }
                    >
                      {getTranslation("Total Rent", "Total Rent", "Total Rent")}
                    </Button>
                    <Button
                      size='small'
                      className={classes.root}
                      onClick={() =>
                        AppendTemplate(
                          values.template,
                          "<span>[total_cost]</span>",
                          setFieldValue
                        )
                      }
                    >
                      {getTranslation("Total Cost", "Total Cost", "Total Cost")}
                    </Button>

                    <Button
                      size='small'
                      className={classes.root}
                      onClick={() =>
                        AppendTemplate(
                          values.template,
                          "<span>[total_warranty]</span>",
                          setFieldValue
                        )
                      }
                    >
                      {getTranslation(
                        "Total Warranty",
                        "Total Warranty",
                        "Total Warranty"
                      )}
                    </Button>

                    <Button
                      size='small'
                      className={classes.root}
                      onClick={() =>
                        AppendTemplate(
                          values.template,
                          "<span>[total_maintenance]</span>",
                          setFieldValue
                        )
                      }
                    >
                      {getTranslation(
                        "Total Maintenance",
                        "Total Maintenance",
                        "Total Maintenance"
                      )}
                    </Button>
                  </div>

                  {/* check boxes for documents  */}
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <FormControl>
                        <FormControlLabel
                          label={getTranslation(
                            "Attached complete statement",
                            "Attached complete statement",
                            "Attached complete statement"
                          )}
                          labelPlacement='end'
                          control={
                            <Checkbox
                              id='attachStatement'
                              checked={values.attachStatement}
                            />
                          }
                          onChange={(e) => {
                            setFieldValue("attachStatement", e.target.checked);
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <FormControl>
                        <FormControlLabel
                          label={getTranslation(
                            "Attached copy of all documents",
                            "Attached copy of all documents",
                            "Attached copy of all documents"
                          )}
                          labelPlacement='end'
                          control={
                            <Checkbox
                              id='attachDocuments'
                              checked={values.attachDocuments}
                            />
                          }
                          onChange={(e) => {
                            setFieldValue("attachDocuments", e.target.checked);
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Box
                    pr={1}
                    pb={1}
                    pt={1}
                    width='100%'
                    display='flex'
                    alignItems='right'
                    justifyContent='right'
                  >
                    <ButtonWithLoading
                      title={getTranslation("Back", "Back", "Back")}
                      size='small'
                      variant='contained'
                      onClick={() => BackStep()}
                    />
                    {activeStep == 3 ? (
                      <Box>
                        <ButtonWithLoading
                          title={getTranslation("Finish", "Finish", "Finish")}
                          size='small'
                          variant='contained'
                          color='primary'
                          type='submit'
                          loading={isLoading}
                          disabled={phoneCall ? true : false}
                          onClick={() => {
                            handleSubmit();
                            setFieldValue("status", "active");
                          }}
                          style={{ marginLeft: "10px" }}
                        />
                        <ButtonWithLoading
                          title={getTranslation(
                            "Save as Draft",
                            "Save as Draft",
                            "Save as Draft"
                          )}
                          size='small'
                          variant='contained'
                          color='primary'
                          type='submit'
                          loading={isLoading}
                          onClick={() => {
                            handleSubmit();
                            setFieldValue("status", "draft");
                          }}
                          style={{ marginLeft: "10px" }}
                        />
                      </Box>
                    ) : (
                      <Box>
                        <ButtonWithLoading
                          title={getTranslation(
                            "Save & Next",
                            "Save & Next",
                            "Save & Next"
                          )}
                          size='small'
                          variant='contained'
                          color='primary'
                          type='submit'
                          //loading={isSubmitting}
                          onClick={handleSubmit}
                          style={{ marginLeft: "10px" }}
                        />
                        {activeStep == 2 ? (
                          <ButtonWithLoading
                            title={getTranslation(
                              "Skip & Next",
                              "Skip & Next",
                              "Skip & Next"
                            )}
                            size='small'
                            variant='contained'
                            color='primary'
                            onClick={(e) => {
                              e.preventDefault();
                              if (isEdit) {
                                initialValue.recoveryPersons[1] = initialValues;
                                NextStep(initialValue);
                              } else NextStep(initialValue);
                            }}
                            style={{ marginLeft: "10px" }}
                          />
                        ) : (
                          ""
                        )}
                      </Box>
                    )}
                  </Box>
                </>
              </Form>
            </>
          )}
        </Formik>
      </div>
    </>
  );
};

export default PersonRecovery;
