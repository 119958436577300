import React, { useEffect, useState } from "react";
import Table from "../../components/table";
import { Get, Post } from "../../actions/apiActions";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import { getTranslation } from "../../heplers/translationHelper";
import { useLocation } from "react-router";
import {
	Post_AddFundToTenant_URL,
	Post_AddFundToSupplier_URL,
	Get_AllBankTransactionsWithPagination_URL,
	Get_ClearBankTransaction_URL,
	Get_BankTransaction_URL,
	Get_AllBanks_URL,
	Post_UpdateBankTransaction_URL,
	Get_ExportBankTransactionsCSV_URL,
	Get_Bank_URL,
} from "../../constants/apiUrls";
import {
	FundsToSupplier,
	FundsToTenant,
} from "../../components/ButttonsWithIcons";
import EditIcon from "@material-ui/icons/Edit";
import { AddFundsToSupplierDialog } from "./components/fundstosupplier";
import { AddFundsToTenantDialog } from "./components/fundstotenant";
import { useDispatch } from "react-redux";
import {
	Box,
	Card,
	CardContent,
	CardHeader,
	Grid,
	TextField,
	Typography,
} from "@material-ui/core";
import {
	Autocomplete,
	ToggleButton,
	ToggleButtonGroup,
} from "@material-ui/lab";
import _ from "lodash";
import CloseIcon from "@material-ui/icons/Close";
import authUtils from "../../utils/authUtils";
import { Upgrade } from "../common/Upgrade";
import { Helmet } from "react-helmet";
import ButtonWithLoading from "../../components/ButtonWithLoading";
import { format } from "../../heplers/format";
import PublishIcon from "@material-ui/icons/Publish";
import bankWithPonto from "../../assests/icons/withPonto.png";
import bankWithOutPonto from "../../assests/icons/withoutPonot.png";
import CardComponent from "../../components/Card";
import { useParams } from "react-router-dom";
import AlertDialog from "../../components/DeleteDialog";

//name, address, zipcode, Town
const columns = [
	{
		id: "actions",
		label: getTranslation("Action", "Action", "Action"),
		align: "left",
	},
	{
		id: "valueDate",
		numeric: false,
		disablePadding: true,
		label: getTranslation("Date", "Date", "Date"),
		format: "date",
	},
	{
		id: "counterpartName",
		numeric: true,
		disablePadding: true,
		label: getTranslation(
			"Counter Part Name",
			"Nom contre-partie",
			"Name Gegenpartei"
		),
		component:({row})=>row?.counterpartName ? <Typography>{row.counterpartName}</Typography> : <Typography>{row.remittanceInformation?.substr(0,15)} {row.remittanceInformation?.length>15 ? "..." :""}</Typography>
	},

	{
		id: "matchedWithTenant",
		numeric: true,
		disablePadding: true,
		label: getTranslation("Tenant", "Tenant", "Tenant"),
	},
	{
		id: "matchedWithSupplier",
		numeric: true,
		disablePadding: true,
		label: getTranslation("Supplier", "Supplier", "Supplier"),
	},
	{
		id: "amount",
		align: "right",
		label: getTranslation("Amount", "Montant", "Betrag"),
		format: "number",
		component: (row) => {
			return <Typography>{format("number", row?.row?.amount)}</Typography>;
		},
	},
];

const matchedColumns = [
	{
		id: "actions",
		label: getTranslation("Action", "Action", "Action"),
		align: "left",
	},
	{
		id: "valueDate",
		numeric: false,
		disablePadding: true,
		label: getTranslation("Date", "Date", "Date"),
		format: "date",
	},
	{
		id: "counterpartName",
		numeric: true,
		disablePadding: true,
		label: getTranslation(
			"Counter Part Name",
			"Nom contre-partie",
			"Name Gegenpartei"
		),
	},
	{
		id: "matchedWithTenant",
		numeric: true,
		disablePadding: true,
		label: getTranslation("Tenant", "Locataire", "Mieter"),
	},
	{
		id: "matchedWithSupplier",
		numeric: true,
		disablePadding: true,
		label: getTranslation("Supplier", "Le fournisseur", "Anbieter"),
	},
	{
		id: "amount",
		align: "right",
		label: getTranslation("Amount", "Montant", "Betrag"),
		format: "number",
		component: (row) => {
			return <Typography>{format("number", row?.row?.amount)}</Typography>;
		},
	},
];

const Bank = () => {
	const location = useLocation();
	const { bankId } = location.state || {};
	const [transactionStatus, setTransactionStatus] = useState(2);
	const [transferDirection, setTransferDirection] = useState(0);
	const [searchString, setSearchString] = useState("");
	const [page, setPage] = useState(0);
	let sizeBank = localStorage.getItem("sizeBank");
	if (sizeBank == null) {
		localStorage.setItem("sizeBank", 10);
		sizeBank = 10;
	}
	const [rowsPerPage, setRowsPerPage] = useState(sizeBank);
	const [bank, setBank] = useState();
	const history = useHistory();
	const { enqueueSnackbar } = useSnackbar();
	const [bankloading, setBankLoading] = useState(true);
	const [bankList, setBankList] = useState();
	const [AddToTenantDialogOpen, setAddToTenantDialogOpen] = useState(false);
	const [AddToSupplierDialogOpen, setAddToSupplierDialogOpen] = useState(false);
	const [editTransactionDialogOpen, setEditTransactionDialogOpen] =
		useState(false);
	const [bankDeleting, setBankDeleting] = useState(false);
	const [supplierSubmiting, setSupplierSubmiting] = useState(false);
	const [sortDirection, setSortDirection] = useState(1);
	const [sort, setSort] = useState("valueDate");
	const [bankBalance, setBankBalance] = useState(0);
	const [filterBank, setFilterBank] = useState([]);
	const [selectedBank, setSelectedBank] = useState();
	const [bankloaded, setBankLoaded] = useState(false);
	const dispatch = useDispatch();
	const dashboardIbanFilter = location?.state?.filter;
	const [balanceCard, setBalanceCard] = useState(false);
	const [editSupplierFunds, setEditSupplierFunds] = useState(null);
	const [editTenantFunds, setEditTenantFunds] = useState(null);
	const [supplierData, setSupplierData] = useState(null);
	const [bankDetails, setBankDetails] = useState();
	const [csvButtonLoading, setCsvButtonLoading] = useState(false);
	const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
	const [deletingBankId, setDeletingBankId] = useState();
	const [clearTransctionLoading, setClearTransctionLoading] = useState(false);

	//breadcrumb for bank section and get all banks
	useEffect(() => {
		dispatch({ type: "Clear_All_BreadCrumb" });
		dispatch({
			type: "Add_BreadCrumb",
			payload: {
				title: getTranslation(" Bank ", " Banque ", " Bank "),
				url: "/bank",
			},
		});
		getAllBanks();
		if (bankId) {
			getBank(bankId.bankId);
		}
	}, []);

	useEffect(() => {
		// if (bankloaded)
		if (!selectedBank && !bankId) {
			setSelectedBank({ iban: "All" });
		}
		loadBankTransactions();
	}, [
		page,
		searchString,
		rowsPerPage,
		transferDirection,
		transactionStatus,
		sort,
		sortDirection,
		selectedBank,
	]);

	//get all banks
	const getAllBanks = () => {
		Get(
			{},
			Get_AllBanks_URL,
			null,
			(resp) => {
				setBankLoaded(true);
				setBankDetails(resp?.data[0]);
				setFilterBank([
					...resp?.data,
					{ iban: getTranslation("All", "All", "All") },
				]);
				if (dashboardIbanFilter) {
					setSelectedBank(
						resp?.data.find((x) => x.iban === dashboardIbanFilter)
					);
				}
			},
			(error) => {}
		);
	};

	//get single bank
	const getBank = (bankId) => {
		Get(
			{ bankId: bankId },
			Get_Bank_URL,
			null,
			(resp) => {
				setBankDetails(resp?.data);
				console.log(resp?.data);
				setSelectedBank(resp?.data);
				setBankBalance(resp?.data.accountBalance);
				setBalanceCard(true);
			},
			(err) => {
				enqueueSnackbar(
					err.data
						? err.data
						: getTranslation(
								"Something went wrong. Please try again.",
								"Something went wrong. Please try again.",
								"Something went wrong. Please try again."
						  ),
					{
						variant: "error",
					}
				);
			}
		);
	};

	//load all bank Transaction
	const loadBankTransactions = async () => {
		setBankLoading(true);
		Get(
			{
				bankId: selectedBank?.id
					? selectedBank?.id
					: selectedBank?.iban == "CR-All" ||
					  selectedBank?.iban == "All" ||
					  selectedBank?.iban == "Tout" ||
					  selectedBank?.iban == "Alle"
					? null
					: bankId
					? bankId.bankId
					: null,

				sort: sort,
				sortDirection: sortDirection,
				transferDirection: transferDirection,
				status: transactionStatus,
				searchString: searchString,
				pageNumber: page,
				pageSize: rowsPerPage,
			},
			Get_AllBankTransactionsWithPagination_URL,
			history,
			(resp) => {
				setBankLoading(false);
				setBankList(resp?.data);
			},
			(error) => {
				setBankLoading(false);
				enqueueSnackbar(
					getTranslation(
						"Error occured while processing your request. Please contact with admin",
						"Error occured while processing your request. Please contact with admin",
						"Error occured while processing your request. Please contact with admin"
					),
					{
						variant: "error",
					}
				);
			}
		);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		localStorage.setItem("sizeBank", event.target.value);
		setRowsPerPage(event.target.value);
		setPage(0);
	};

	//handleSearchdue
	const handleSearchBank = (searchString) => {
		setSearchString(searchString);
	};

	const handleOnSubmitSupplier = (values, actions) => {
		setSupplierSubmiting(true);
		values.BankTransactionId = bank.id;
		Post(
			values,
			bank
				? Post_UpdateBankTransaction_URL
				: Post_AddFundToSupplier_URL,
			history,
			(resp) => {
				setAddToSupplierDialogOpen(false);
				enqueueSnackbar(
					getTranslation(
						"Funds added to the supplier",
						"Fonds ajoutés au fournisseur",
						"Dem Lieferanten hinzugefügte Mittel"
					),
					{ variant: "success" }
				);
				setSupplierSubmiting(false);
				loadBankTransactions({
					sort: sort,
					sortDirection: sortDirection,
					transferDirection: transferDirection,
					status: transactionStatus,
					searchString: searchString,
					pageNumber: page,
					pageSize: rowsPerPage,
				});
				setBank(null)
			},
			(error) => {
				enqueueSnackbar(
					error?.data
						? getTranslation(error?.data, error?.data, error?.data)
						: getTranslation(
								"Something went wrong. Please contact with admin.",
								"Something went wrong. Please contact with admin.",
								"Something went wrong. Please contact with admin."
						  ),
					{ variant: "error" }
				);
				actions.setSubmitting(false);
			}
		);
	};

	const handleOnSubmitTenant = (values, actions) => {
		values.bankTransactionId = bank.id;
		Post(
			values,
			bank?.isProcessed
				? Post_UpdateBankTransaction_URL
				: Post_AddFundToTenant_URL,
			history,
			(resp) => {
				setAddToSupplierDialogOpen(false);
				enqueueSnackbar(
					getTranslation(
						" Funds added to tenant ",
						" Fonds ajoutés au locataire ",
						" Dem Mieter hinzugefügte Mittel "
					),
					{ variant: "success" }
				);
				setAddToTenantDialogOpen(false);
				loadBankTransactions({
					sort: sort,
					sortDirection: sortDirection,
					transferDirection: transferDirection,
					status: transactionStatus,
					searchString: searchString,
					pageNumber: page,
					pageSize: rowsPerPage,
				});
				setBank(null);
			},
			(error) => {
				actions.setSubmitting(false)
				enqueueSnackbar(
					getTranslation(
						" The system was not able to handle your request. Please try again ",
						" Le système n'a pas été en mesure de traiter votre demande. Veuillez réessayer ",
						" Das System konnte Ihre Anfrage nicht bearbeiten. Bitte versuche es erneut "
					),
					{ variant: "error" }
				);
			}
		);
	};

	const onClearBankTransaction = (bankTransactionId) => {
		setClearTransctionLoading(true);
		setBankDeleting(true);
		Get(
			{ bankTransactionId: bankTransactionId },
			Get_ClearBankTransaction_URL,
			history,
			(resp) => {
				setAddToTenantDialogOpen(false);
				setAddToSupplierDialogOpen(false);
				setEditTenantFunds(null);
				setEditSupplierFunds(null);
				setClearTransctionLoading(false);
				enqueueSnackbar(
					getTranslation(
						" Bank Transaction Cleared ",
						" Fonds ajoutés au locataire ",
						" Dem Mieter hinzugefügte Mittel "
					),
					{ variant: "success" }
				);
				setBankDeleting(false);
				loadBankTransactions({
					sort: sort,
					sortDirection: sortDirection,
					transferDirection: transferDirection,
					status: transactionStatus,
					searchString: searchString,
					pageNumber: page,
					pageSize: rowsPerPage,
				});
				setIsOpenDeleteDialog(false);
			},
			(error) => {
				setClearTransctionLoading(false);
				enqueueSnackbar(error.data, { variant: "error" });
				setBankDeleting(false);
			}
		);
	};

	const onBankTransactionSelected = (bankTransaction) => {
		if (bankTransaction?.supplierId == null) {
			
			setAddToTenantDialogOpen(true);
		} else {
			
			setAddToSupplierDialogOpen(true);
		}
		// Get(
		// 	{
		// 		bankTransactionId: bankTransactionId,
		// 	},
		// 	Get_BankTransaction_URL,
		// 	history,
		// 	(resp) => {
				
		// 	},
		// 	(error) => {
		// 		enqueueSnackbar(error.data, { variant: "error" });
		// 	}
		// );
	};

	//fund to supplier
	const onSupplierSelected = (row, editSupFunds) => {
		setBank(row);
		setAddToSupplierDialogOpen(true);
	};

	//fund to tenant
	const onTenentSelected = (row) => {
		setBank(row);
		setAddToTenantDialogOpen(true);
	};

	const exportCSV = () => {
		setCsvButtonLoading(true);
		Get(
			null,
			Get_ExportBankTransactionsCSV_URL,
			null,
			(resp) => {
				// Creating a Blob for having a csv file format and passing the data with type
				const CSVfile = new Blob([resp.data], { type: "text/csv" });
				const url = window.URL.createObjectURL(CSVfile);
				window.open(url, "_blank");
				setCsvButtonLoading(false);
			},
			(error) => {
				setCsvButtonLoading(false);
			}
		);
	};

	return authUtils.getIsStarter() == true ? (
		<Upgrade />
	) : (
		<>
			<Helmet>
				<title>{getTranslation("Bank", "Bank", "Bank")}</title>
			</Helmet>

			<CardComponent>
				<br />
				<CardContent>
					{/* Removed Box as on small screens we need these toggleButtons one per line */}
					<Grid container style={{ marginBottom: "50px" }}>
						<Grid item sm={12} md={8} lg={8}></Grid>
						<Grid item xs={12} sm={12} md={4} lg={4}>
							<Autocomplete
								width="200px"
								options={filterBank}
								value={selectedBank ? selectedBank : { iban: "All" }}
								getOptionLabel={(option) =>
									option.iban == "CR-All" ||
									option.iban == "All" ||
									option.iban == "Tout" ||
									option.iban == "Alle"
										? option.iban
										: option.iban +
										  " → " +
										  (option.shortDescription != null
												? option.shortDescription
												: "")
								}
								renderOption={(props, option) => (
									<li {...props}>
										<div display={{ display: "flex" }}>
											{props.iban + " → "}
											{props.pontoStatus ? (
												// <img
												//   src={bankWithPonto}
												//   alt='With Ponto'
												//   style={{ height: "20px" }}
												// />
												<button
													className="button"
													style={{
														backgroundColor: "green",
														borderRadius: "20px",
														color: "white",
														fontSize: "10px",
														fontWeight: "bold",
														padding: "3px 10px 3px 10px",
														border: "none",
													}}
													disabled={true}
												>
													{getTranslation(
														"Ponto Verified",
														"Ponto Verified",
														"Ponto Verified"
													)}
												</button>
											) : (
												// <img
												//   src={bankWithOutPonto}
												//   alt='Without Ponto'
												//   style={{ height: "20px" }}
												// />
												<button
													className="button"
													style={{
														backgroundColor: "red",
														borderRadius: "20px",
														color: "white",
														fontSize: "10px",
														fontWeight: "bold",
														padding: "3px 10px 3px 10px",
														border: "none",
													}}
													disabled={true}
												>
													{getTranslation(
														"Not Verified",
														"Not Verified",
														"Not Verified"
													)}
												</button>
											)}
										</div>
									</li>
								)}
								size="small"
								placeholder="Filter"
								onChange={(e, filter) => {
									if (filter) {
										if (
											filter.iban != "CR-All" &&
											filter.iban != "All" &&
											filter.iban != "Tout" &&
											filter.iban != "Alle"
										) {
											// loadBanksAccordingToId(filter?.id);
											getBank(filter?.id);
											setSelectedBank(filter);
											setBankBalance(filter?.accountBalance);
											setBalanceCard(true);
										} else {
											setSelectedBank(filter);
											setBalanceCard(false);
											setBalanceCard(true);
											// loadBanks();
										}
									} else {
										setSelectedBank({ iban: "All" });
									}
								}}
								renderInput={(params) => (
									<TextField
										{...params}
										label={getTranslation("Filter", "Filtre", "Filter")}
										variant="outlined"
									/>
								)}
							/>
						</Grid>
					</Grid>

					{/* start */}
					{!selectedBank?.pontoStatus &&
						selectedBank?.iban !== "CR-All" &&
						selectedBank?.iban !== "Tout" &&
						selectedBank?.iban !== "Alle" &&
						selectedBank?.iban !== "All" && (
							<Box
								style={{
									display: "flex",
									justifyContent: "flex-end",
									margin: "20px",
									flexWrap: "wrap",
								}}
							>
								<ButtonWithLoading
									onClick={() => {
										history.push(`/bank/uploaddocument/${selectedBank?.id}`);
									}}
									variant="contained"
									style={{
										backgroundColor: "rgb(229,60,22)",
										color: "white",
										fontWeight: "bold",
									}}
									title={
										<>
											{" "}
											<PublishIcon />
											&nbsp;{" "}
											{getTranslation(
												"Upload Bank Documents",
												"Upload Bank Documents",
												"Upload Bank Documents"
											)}
										</>
									}
									size="medium"
									// loading={csvButtonLoading}
								/>
							</Box>
						)}

					{/* 
          {!selectedBank?.pontoStatus &&
            (selectedBank?.iban !== "CR-All" ||
              selectedBank?.iban !== "Tout" ||
              selectedBank?.iban !== "Alle" ||
              selectedBank?.iban !== "All") && (
              <Box
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  margin: "20px",
                  flexWrap: "wrap",
                }}
              >
                <ButtonWithLoading
                  onClick={() => {
                    history.push(`/bank/uploaddocument/${selectedBank?.id}`);
                  }}
                  variant='contained'
                  style={{
                    backgroundColor: "rgb(229,60,22)",
                    color: "white",
                    fontWeight: "bold",
                  }}
                  title={
                    <>
                      {" "}
                      <PublishIcon />
                      &nbsp;{" "}
                      {getTranslation(
                        "Upload Bank Documents",
                        "Upload Bank Documents",
                        "Upload Bank Documents"
                      )}
                    </>
                  }
                  size='medium'
                  // loading={csvButtonLoading}
                />
              </Box>
            )} */}

					{/* {selectedBank?.iban == "CR-All" ||
					selectedBank?.iban == "Tout" ||
					selectedBank?.iban == "Alle" ||
					selectedBank?.iban == "All" ? (
						""
					) : (
						<Box
							style={{
								display: "flex",
								justifyContent: "flex-end",
								margin: "20px",
								flexWrap: "wrap",
							}}
						>
							<ButtonWithLoading
								onClick={() => {
									history.push(`/bank/uploaddocument/${selectedBank?.id}`);
								}}
                variant='contained'
								style={{
									backgroundColor: "rgb(229,60,22)",
									color: "white",
									fontWeight: "bold",
								}}
								title={
									<>
										{" "}
										<PublishIcon />
										&nbsp;{" "}
										{getTranslation(
											"Upload Manual Transactions",
											"Upload Manual Transactions",
											"Upload Manual Transactions"
										)}
									</>
								}
                size='medium'
								// loading={csvButtonLoading}
							/>
						</Box>
          )} */}

					{/* end */}
					{selectedBank?.iban == "CR-All" ||
					selectedBank?.iban == "Tout" ||
					selectedBank?.iban == "Alle" ||
					selectedBank?.iban == "All" ? (
						<div></div>
					) : (
						<Grid container spacing={2}>
							<Grid item xs={12} sm={12} md={12} lg={12}>
								<CardComponent
									title={getTranslation(
										"Bank Information",
										"Information bancaire",
										"Bank Informationen"
									)}
								>
									<CardContent>
										<Grid container>
											<Grid xs={6} sm={6} md={3} lg={3}>
												<Box display="flex">
													<tr>
														<Typography variant="h4">
															{getTranslation("Bank", "Bank", "Bank")}
														</Typography>
														<td>
															<Typography variant="h4">
																{bankDetails?.shortDescription
																	? bankDetails?.shortDescription
																	: "-"}
															</Typography>
														</td>
													</tr>
												</Box>
											</Grid>
											<Grid xs={6} sm={6} md={3} lg={3}>
												<Box display="flex">
													<tr>
														<Typography variant="h4">
															{getTranslation("IBAN", "IBAN", "IBAN")}
														</Typography>
														<td>
															<Typography variant="h4">
																{bankDetails?.iban}
															</Typography>
														</td>
													</tr>
												</Box>
											</Grid>
											<Grid xs={6} sm={6} md={3} lg={3}>
												<Box display="flex">
													<tr>
														<Typography variant="h4">
															{getTranslation(
																"Last Sync",
																"Last Sync",
																"Last Sync"
															)}
														</Typography>
														<td>
															<Typography variant="h4">
																{bankDetails?.lastSyncTime ==
																"0001-01-01T00:00:00"
																	? " -"
																	: format(
																			"dateTime",
																			bankDetails?.lastSyncTime
																	  )}
															</Typography>
														</td>
													</tr>
												</Box>
											</Grid>
											<Grid xs={6} sm={6} md={3} lg={3}>
												{balanceCard == true ? (
													<Box display="flex">
														<tr>
															<Typography variant="h4">
																{getTranslation(
																	"Balance",
																	"Balance",
																	"Balance"
																)}
															</Typography>
															<td>
																<Typography variant="h4">
																	{selectedBank?.iban === "All"
																		? _.sumBy(
																				filterBank,
																				(bank) => bank.accountBalance
																		  )
																		: selectedBank?.accountBalance}{" "}
																	€
																</Typography>
															</td>
														</tr>
													</Box>
												) : (
													""
												)}
											</Grid>
										</Grid>
									</CardContent>
								</CardComponent>
							</Grid>
						</Grid>
					)}

					{/* toggle buttons area  */}
					<Grid container spacing={2} style={{ marginBottom: "20px" }}>
						<Grid
							item
							xs={12}
							sm={12}
							md={12}
							lg={2}
							style={{ display: "flex", alignItems: "flex-end" }}
						></Grid>
						<Grid
							item
							xs={8}
							sm={8}
							md={7}
							lg={5}
							style={{ marginTop: "20px" }}
						>
							<ToggleButtonGroup
								size="small"
								id="transactionStatus"
								defaultChecked={transactionStatus}
								value={transactionStatus}
								exclusive
								onChange={(event, status) => {
									if (status !== null) {
										setTransactionStatus(status);
									}
								}}
								style={{ height: "50px", width: "400px" }}
							>
								<ToggleButton
									value={0}
									aria-label="left aligned"
									style={{
										backgroundColor:
											transactionStatus === 0 ? "rgb(230,231,246)" : "",
									}}
								>
									<Typography>
										{getTranslation(" All ", " Toute ", " Alle ")}
									</Typography>
								</ToggleButton>
								<ToggleButton
									value={1}
									aria-label="left aligned"
									style={{
										backgroundColor:
											transactionStatus === 1 ? "rgb(230,231,246)" : "",
									}}
								>
									<Typography>
										{getTranslation(" Matched ", " Apparié ", " Abgestimmt ")}
									</Typography>
								</ToggleButton>
								<ToggleButton
									value={2}
									aria-label="centered"
									style={{
										backgroundColor:
											transactionStatus === 2 ? "rgb(230,231,246)" : "",
									}}
								>
									<Typography>
										{" "}
										{getTranslation(
											" Not Matched ",
											" Pas adapté ",
											" Nicht passend "
										)}
									</Typography>
								</ToggleButton>
							</ToggleButtonGroup>
						</Grid>
						<Grid
							item
							xs={8}
							sm={8}
							md={4}
							lg={3}
							style={{
								height: "30px",
								marginTop: "20px",
								marginBottom: "20px",
							}}
						>
							<ToggleButtonGroup
								id="transferDirection"
								size="small"
								value={transferDirection}
								defaultChecked={transferDirection}
								exclusive
								onChange={(event, status) => {
									if (status !== null) {
										setTransferDirection(status);
									}
								}}
								style={{ height: "50px", width: "400px" }}
							>
								<ToggleButton
									value={0}
									aria-label="left aligned"
									style={{
										backgroundColor:
											transferDirection === 0 ? "rgb(230,231,246)" : "",
									}}
								>
									<Typography>
										{getTranslation(" All ", " Toute ", " Alle ")}
									</Typography>
								</ToggleButton>
								<ToggleButton
									value={1}
									aria-label="left aligned"
									style={{
										backgroundColor:
											transferDirection === 1 ? "rgb(230,231,246)" : "",
									}}
								>
									<Typography>
										{getTranslation(" Income ", " Revenu ", " Einkommen ")}
									</Typography>
								</ToggleButton>
								<ToggleButton
									value={2}
									aria-label="centered"
									style={{
										backgroundColor:
											transferDirection === 2 ? "rgb(230,231,246)" : "",
									}}
								>
									<Typography>
										{getTranslation(" Expense ", " Frais ", " Aufwand ")}
									</Typography>
								</ToggleButton>
							</ToggleButtonGroup>
						</Grid>
					</Grid>

					<Table
						dense
						loading={bankloading}
						colums={transactionStatus == 1 ? matchedColumns : columns}
						sortBy="valueDate"
						sortDirection="desc"
						onSorting={(property, direction) => {
							setSort(property);
							setSortDirection(direction === "asc" ? 0 : 1);
						}}
						rows={bankList?.data}
						count={bankList?.totalCount}
						page={page}
						rowsPerPage={rowsPerPage}
						handleChangePage={handleChangePage}
						handleChangeRowsPerPage={handleChangeRowsPerPage}
						viewSearch
						onSearch={(searchString) => handleSearchBank(searchString)}
						actions={[
							{
								component: (bankRow) =>
									bankRow.isProcessed ? null : (
										<FundsToTenant
											onClick={() => {
												onTenentSelected(bankRow);
											}}
										/>
									),
							},
							{
								component: (bankRow) =>
									bankRow.isProcessed ? null : (
										<FundsToSupplier
											onClick={() => {
												onSupplierSelected(bankRow);
											}}
										/>
									),
							},
							{
								component: (bankRow) =>
									bankRow.isProcessed ? (
										<CloseIcon
											style={{ cursor: "pointer" }}
											onClick={() => {
												setIsOpenDeleteDialog(true);
												setDeletingBankId(bankRow.id);
											}}
										/>
									) : null,
							},
							{
								component: (bankRow) =>
									bankRow.isProcessed ? (
										<EditIcon
											style={{ cursor: "pointer" }}
											onClick={() => {
												setBank(bankRow);
												onBankTransactionSelected(bankRow);
											}}
										/>
									) : null,
							},
						]}
					/>

					<AddFundsToTenantDialog
						counterpartName={
							bank?.counterpartName === null
								? bank?.remittanceInformation
								: bank?.counterpartName
						}
						remittanceinformation={bank?.remittanceInformation}
						counterpartReference={bank?.counterpartReference}
						amount={bank?.amount}
						isOpen={AddToTenantDialogOpen}
						tenantDetails={supplierData}
						onClose={() => {
							
							setAddToTenantDialogOpen(false);
							setBank(null);
							setEditTenantFunds(null);
						}}
						onSubmit={handleOnSubmitTenant}
						editTenFunds={editTenantFunds}
						enableEdit={editTenantFunds !== null}
						bank={bank}
						clearTransction={() => onClearBankTransaction(bank.id)}
						clearLoading={clearTransctionLoading}
					/>

					<AddFundsToSupplierDialog
						// counterpartName={
						// 	bank?.counterpartName === null
						// 		? bank?.remittanceInformation
						// 		: bank?.counterpartName
						// }
						counterpartName={
							bank?.counterpartName === null ? "-" : bank?.counterpartName
						}
						remittanceinformation={bank?.remittanceInformation}
						amount={bank?.amount}
						supplierDetails={supplierData}
						isOpen={AddToSupplierDialogOpen}
						onClose={() => {
							setAddToSupplierDialogOpen(false);
							setBank(null);
							setEditSupplierFunds(null);
						}}
						onSubmit={handleOnSubmitSupplier}
						editSupFunds={bank}
						
						clearTransction={() => onClearBankTransaction(bank.id)}
						clearLoading={clearTransctionLoading}
					/>

					<AlertDialog
						title={getTranslation(
							"Confirm Reset",
							"Confirm Reset",
							"Confirm Reset"
						)}
						description={getTranslation(
							"Are you sure you want to reset this transaction",
							"Are you sure you want to reset this transaction",
							"Are you sure you want to reset this transaction"
						)}
						buttonTitle={getTranslation("Reset", "Reset", "Reset")}
						open={isOpenDeleteDialog}
						deleting={bankDeleting}
						onClose={() => setIsOpenDeleteDialog(false)}
						onSubmit={() => onClearBankTransaction(deletingBankId)}
					/>
				</CardContent>
			</CardComponent>
		</>
	);
};
export default Bank;
