import { AddTanentDialog, Tanents } from "./components";
import React, { useEffect, useRef, useState } from "react";
import {
	Get_AllBuildings_URL,
	Get_AllTanentsWithPagination_URL,
	Get_AllTenantPrint_URL,
	Post_AddTanent_URL,
	Post_StartContract_URL,
} from "../../constants/apiUrls";
import { Get, Post } from "../../actions/apiActions";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { StartContractDialog } from "./components/startContract";
import { getTranslation } from "../../heplers/translationHelper";
import { AllTenantReport } from "./components/reports";
import { useReactToPrint } from "react-to-print";
import { Helmet } from "react-helmet";
import _ from "lodash";

// state management
const List = () => {
	const [openStartContractDialog, setOpenStartContractDialog] = useState(false);
	const history = useHistory();
	const [buildingList, setBuildingList] = useState([]);
	const [tenantListLoading, setTenantListLoading] = useState(false);
	const [addTenantDialog, setAddTenantDialog] = useState(false);
	const [tanentList, setTanentList] = useState();
	const [editTenant, setEditTenant] = useState();
	const { enqueueSnackbar } = useSnackbar();
	const [isRefresh, setIsRefresh] = useState(false);
	const [tenantId, setTenantId] = useState(0);
	const [printLoader, setPrintLoader] = useState(false);
	const { packageInfo } = useSelector((state) => state.auth);

	const onCreateTenant = () => {
		tanentList?.totalCount >= packageInfo?.appKeys[2].value
			? enqueueSnackbar(
					getTranslation(
						"Limit excceded, please upgrade your package to add new Tenant.",
						"Limit excceded, please upgrade your package to add new Tenant.",
						"Limit excceded, please upgrade your package to add new Tenant."
					),
					{ variant: "info" }
			  )
			: setAddTenantDialog(true);
	};

	let pageSize = localStorage.getItem("sizeTent");
	if (pageSize == null) {
		localStorage.setItem("sizeTent", 10);
		pageSize = 10;
	}
	const [filterItems, setFilterItems] = useState({
		status: "active",
		searchString: " ",
		pageNumber: 0,
		pageSize: pageSize,
		sort: "name",
		sortDirection: 0,
	});

	const [allTenantList, setAllTenantList] = useState();
	const dispatch = useDispatch();

	useEffect(() => {
		loadBuildings();
	}, []);
	useEffect(() => {
		loadTenants(filterItems);
		setIsRefresh(false);
	}, [isRefresh]);

	useEffect(() => {
		dispatch({ type: "Clear_All_BreadCrumb" });
		dispatch({
			type: "Add_BreadCrumb",
			payload: {
				title: getTranslation("Tenants", "Tenants", "Tenants"),
				url: "/tanents",
			},
		});
	}, []);

	const submitTanent = async (values, actions) => {
		setTenantListLoading(true);
		Post(
			values,
			Post_AddTanent_URL,
			history,
			(resp) => {
				if (values.startContract) {
					setTenantId(resp.data);
					setAddTenantDialog(false);
					enqueueSnackbar("Add Contract details", { variant: "info" });
					setIsRefresh(true);
					setOpenStartContractDialog(true);
					setTenantListLoading(false);
				} else {
					enqueueSnackbar(
						getTranslation(
							"Tenant will move to inactive tab, due to missing contract",
							"Tenant will move to inactive tab, due to missing contract",
							"Tenant will move to inactive tab, due to missing contract"
						),
						{ variant: "success" }
					);
					setIsRefresh(true);
					actions.resetForm();
				}
				setAddTenantDialog(false);
			},
			(onError) => {
				setAddTenantDialog(false);
				enqueueSnackbar(
					getTranslation(onError?.data, onError?.data, onError?.data),
					{ variant: "error" }
				);
				setTenantListLoading(false);
			}
		);
	};

	const loadBuildings = (values) => {
		Get(
			values,
			Get_AllBuildings_URL,
			history,
			(resp) => {
				setBuildingList(resp.data);
			},
			(error) => {
				enqueueSnackbar(
					getTranslation(
						"Buildings are not loaded. internal Server error",
						"Buildings are not loaded. internal Server error",
						"Buildings are not loaded. internal Server error"
					),
					{
						variant: "error",
					}
				);
			}
		);
	};

	const loadTenants = (values) => {
		setTenantListLoading(true);
		setFilterItems(values);
		Get(
			values,
			Get_AllTanentsWithPagination_URL,
			history,
			(resp) => {
				setTenantListLoading(false);
				setTanentList(resp?.data);
			},
			(error) => {
				setTenantListLoading(false);
				enqueueSnackbar(
					getTranslation(
						"Can't load tenants. Internal server error",
						"Can't load tenants. Internal server error",
						"Can't load tenants. Internal server error"
					),
					{
						variant: "error",
					}
				);
			}
		);
	};

	//get all tanents
	const GetAllTanentData = () => {
		setPrintLoader(true);
		Get(
			filterItems,
			Get_AllTenantPrint_URL,
			history,
			(resp) => {
				setAllTenantList(resp?.data);
				setTimeout(() => {
					handlePrint();
					setTimeout(() => setPrintLoader(false), 1000);
				}, 1000);
			},
			(error) => {
				setPrintLoader(false);
				setTenantListLoading(false);
				enqueueSnackbar(
					getTranslation(
						"Can't load all tenants. Internal server error",
						"Can't load all tenants. Internal server error",
						"Can't load all tenants. Internal server error"
					),
					{
						variant: "error",
					}
				);
			}
		);
	};

	const tenantTotalBalance = _.sumBy(allTenantList, "accountBalance");

	const handleEditTenant = (row) => {
		setTenantId(row?.id);
		setEditTenant(row);
		setOpenStartContractDialog(true);
	};
	const handleEditActivity = (row) => {
		setEditTenant(row);
		const url = `/tanents/details/${
			row.tenantId ? row.tenantId : row.id
		}/details`;
		dispatch({
			type: "Add_BreadCrumb",
			payload: { title: row.name, url: "/tanents" },
		});
		history.push(url);
	};
	const startTenantContract = (values, actions) => {
		Post(
			values,
			Post_StartContract_URL,
			null,
			(resp) => {
				enqueueSnackbar(
					getTranslation(
						"Your Contract will start on",
						"Your Contract will start on",
						"Your Contract will start on"
					) + values.startDate,
					{
						variant: "success",
					}
				);
				setOpenStartContractDialog(false);
				setIsRefresh(true);
			},
			(error) => {
				enqueueSnackbar(error.data, { variant: "error" });
			}
		);
	};

	const componentRef = useRef();
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	const refreshTable = () => {
		setIsRefresh(true);
	};

	return (
		<div>
			<Helmet>
				<title>{getTranslation("Tenants", "Tenants", "Tenants")}</title>
			</Helmet>

			<Tanents
				loading={tenantListLoading}
				rows={tanentList}
				printLoader={printLoader}
				onFilter={(values) => loadTenants(values)}
				onTenantChange={(row) => handleEditActivity(row)}
				viewCreate
				onCreate={() => onCreateTenant()}
				onPrint={() => GetAllTanentData()}
				change={() => {
					refreshTable();
				}}
				onRefresh={() => {
					loadTenants(filterItems);
				}}
			/>
			{/* Dialog */}
			<AddTanentDialog
				isOpen={addTenantDialog}
				onClose={() => setAddTenantDialog(false)}
				onSubmit={submitTanent}
				// isLoading={tenantListLoading}
			/>
			<StartContractDialog
				buildings={buildingList}
				tenantId={tenantId}
				isOpen={openStartContractDialog}
				onClose={() => {
					setOpenStartContractDialog(false);
				}}
				onSubmit={startTenantContract}
			/>
			<div style={{ display: "none" }}>
				<AllTenantReport
					data={{
						filterItems: filterItems,
						rows: allTenantList,
						totalBalance: tenantTotalBalance,
					}}
					ref={componentRef}
				/>
			</div>
		</div>
	);
};
export default List;
