import React, { useState, useEffect, useRef } from "react";
import Table from "../../../components/table";
import { getTranslation } from "../../../heplers/translationHelper";
import { Typography } from "@material-ui/core";

const columns = [
	{
		id: "apartmentNo",
		numeric: false,
		disablePadding: true,
		label: getTranslation("Apartment Name", "Apartment Name", "Apartment Name"),
	},
	{
		id: "amount",
		numeric: false,
		disablePadding: true,
		label: getTranslation("Rent", "Rent", "Rent"),
	},
	{
		id: "status",
		disablePadding: true,
		label: getTranslation("Status", "Statut", "Status"),
	},
];

export const AvailableApartments = ({
	availableApartments,
	loading,
	selectedApartment,
	onApartmentSelected,
}) => {
	const [searchString, setSearchString] = useState("");
	const [page, setPage] = useState(0);
	let pageSize = localStorage.getItem("sizeContractAppartment");
	if (pageSize == null) {
		localStorage.setItem("sizeContractAppartment", 10);
		pageSize = 10;
	}
	const [rowsPerPage, setRowsPerPage] = useState(pageSize);
	const [currentRent, setCurrentRent] = useState(0);

	//handlers
	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		localStorage.setItem(
			"sizeContractAppartment",
			parseInt(event.target.value)
		);
		setRowsPerPage(localStorage.getItem("sizeContractAppartment"));
		setPage(0);
	};

	const handleSearchBuilding = (searchString) => {
		setSearchString(searchString);
	};
	const handleCurrentRent = (row) => {
		setCurrentRent(row?.rent);
	};
	return (
		<div>
			{availableApartments.length > 0 ? (
				<Table
					dense
					auto
					title={getTranslation(
						"Available Apartments",
						"Available Apartments",
						"Available Apartments"
					)}
					loading={loading}
					colums={columns}
					sortBy="name"
					rows={availableApartments}
					count={availableApartments.length}
					page={page}
					rowsPerPage={rowsPerPage}
					handleChangePage={handleChangePage}
					handleChangeRowsPerPage={handleChangeRowsPerPage}
					viewEdit
					selected={selectedApartment ? [selectedApartment] : []}
					onEdit={(row) => {
						onApartmentSelected(row);
					}}
				/>
			) : (
				""
			)}
		</div>
	);
};
